.ggzm1z0 {
  margin: 0;
  padding: 0;
  border: 0;
  min-width: 0;
  box-sizing: border-box;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
.ggzm1z1 {
  background: 0;
  border: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.ggzm1z2 {
  display: block;
}
.ggzm1z3 {
  line-height: 1;
}
.ggzm1z4 {
  list-style: none;
}
.ggzm1z5 {
  quotes: none;
}
.ggzm1z5:before, .ggzm1z5:after {
  content: '';
}
.ggzm1z6 {
  border-collapse: collapse;
  border-spacing: 0;
}
.ggzm1z7 {
  background-color: transparent;
  color: inherit;
}
.ggzm1z8 {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.ggzm1z8::-ms-expand {
  display: none;
}
:root {
  --colors-light-white50: rgba(255, 255, 255, 0.05);
  --colors-light-white100: rgba(255, 255, 255, 0.1);
  --colors-light-white200: rgba(255, 255, 255, 0.2);
  --colors-light-white300: rgba(255, 255, 255, 0.3);
  --colors-light-white500: rgba(255, 255, 255, 0.5);
  --colors-light-white800: rgba(255, 255, 255, 0.8);
  --colors-light-white: #fff;
  --colors-light-black: #000;
  --colors-light-failure: #F33434;
  --colors-light-failure33: #ED4B9E33;
  --colors-light-red100: rgba(243, 52, 52, 0.10);
  --colors-light-primary: #1FC7D4;
  --colors-light-primary0f: #1FC7D40f;
  --colors-light-primary3D: #1FC7D43D;
  --colors-light-primaryBright: #53DEE9;
  --colors-light-primaryDark: #0098A1;
  --colors-light-success: #29B837;
  --colors-light-success19: #31D0AA19;
  --colors-light-warning: #FFA337;
  --colors-light-warning2D: #ED4B9E2D;
  --colors-light-warning33: #ED4B9E33;
  --colors-light-yellow100: rgba(255, 163, 55, 0.10);
  --colors-light-gray50: rgba(13, 16, 20, 0.05);
  --colors-light-gray100: #E6E6E6;
  --colors-light-gray200: #5B616B;
  --colors-light-gray300: #494D53;
  --colors-light-gray390: #303338;
  --colors-light-gray400: #3A3E44;
  --colors-light-gray500: #2E333C;
  --colors-light-gray700: #2F333A;
  --colors-light-gray800: #21252C;
  --colors-light-gray850: #191D22;
  --colors-light-gray900: #16191E;
  --colors-light-gray1000: #0C0F14;
  --colors-light-tintGray80: rgba(13, 16, 20, 0.80);
  --colors-light-gradientPrimary: linear-gradient(90deg, #EE8100 0%, #EA2B57 50%, #7322A6 100%);;
  --colors-light-gradientPrimary10: linear-gradient(90deg, rgba(238, 129, 0, 0.10) 0%, rgba(234, 43, 87, 0.10) 50%, rgba(115, 34, 166, 0.10) 100%);;
  --colors-light-gradientPrimary20: linear-gradient(90deg, rgba(238, 129, 0, 0.20) 0%, rgba(234, 43, 87, 0.20) 50%, rgba(115, 34, 166, 0.20) 100%);;
  --colors-light-gradientPrimaryRadial: radial-gradient(70.71% 70.71% at 50% 50%, #7322a6 0%, #ea2b57 50%, #ee8100 100%);
  --colors-light-orange100: rgba(238, 129, 0, 0.1);
  --colors-light-orange: #EE8100;
  --colors-light-purple: #7322A6;
  --colors-light-purple60: rgba(115, 34, 166, 0.60);
  --colors-light-orangeDark: #BB6500;
  --colors-light-binance: #F0B90B;
  --colors-light-overlay: #452a7a;
  --colors-light-gold: #FFC700;
  --colors-light-silver: #B2B2B2;
  --colors-light-bronze: #E7974D;
  --colors-light-secondary: #7645D9;
  --colors-light-secondary80: #7645D980;
  --colors-light-background: #FAF9FA;
  --colors-light-backgroundDisabled: #E9EAEB;
  --colors-light-backgroundAlt: #FFFFFF;
  --colors-light-backgroundAlt2: rgba(255, 255, 255, 0.7);
  --colors-light-cardBorder: #E7E3EB;
  --colors-light-contrast: #191326;
  --colors-light-dropdown: #F6F6F6;
  --colors-light-dropdownDeep: #EEEEEE;
  --colors-light-invertedContrast: #FFFFFF;
  --colors-light-input: #eeeaf4;
  --colors-light-inputSecondary: #d7caec;
  --colors-light-tertiary: #EFF4F5;
  --colors-light-text: #280D5F;
  --colors-light-text99: #280D5F99;
  --colors-light-textDisabled: #BDC2C4;
  --colors-light-textSubtle: #7A6EAA;
  --colors-light-disabled: #E9EAEB;
  --colors-light-gradientBubblegum: linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%);
  --colors-light-gradientInverseBubblegum: linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%);
  --colors-light-gradientCardHeader: linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%);
  --colors-light-gradientBlue: linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%);
  --colors-light-gradientViolet: linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%);
  --colors-light-gradientVioletAlt: linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%);
  --colors-light-gradientGold: linear-gradient(180deg, #FFD800 0%, #FDAB32 100%);
  --colors-light-gradientBold: linear-gradient(#53DEE9, #7645D9);
  --colors-dark-white50: rgba(255, 255, 255, 0.05);
  --colors-dark-white100: rgba(255, 255, 255, 0.1);
  --colors-dark-white200: rgba(255, 255, 255, 0.2);
  --colors-dark-white300: rgba(255, 255, 255, 0.3);
  --colors-dark-white500: rgba(255, 255, 255, 0.5);
  --colors-dark-white800: rgba(255, 255, 255, 0.8);
  --colors-dark-white: #fff;
  --colors-dark-black: #000;
  --colors-dark-failure: #F33434;
  --colors-dark-failure33: #ED4B9E33;
  --colors-dark-red100: rgba(243, 52, 52, 0.10);
  --colors-dark-primary: #1FC7D4;
  --colors-dark-primary0f: #1FC7D40f;
  --colors-dark-primary3D: #1FC7D43D;
  --colors-dark-primaryBright: #53DEE9;
  --colors-dark-primaryDark: #0098A1;
  --colors-dark-success: #29B837;
  --colors-dark-success19: #31D0AA19;
  --colors-dark-warning: #FFA337;
  --colors-dark-warning2D: #ED4B9E2D;
  --colors-dark-warning33: #ED4B9E33;
  --colors-dark-yellow100: rgba(255, 163, 55, 0.10);
  --colors-dark-gray50: rgba(13, 16, 20, 0.05);
  --colors-dark-gray100: #E6E6E6;
  --colors-dark-gray200: #5B616B;
  --colors-dark-gray300: #494D53;
  --colors-dark-gray390: #303338;
  --colors-dark-gray400: #3A3E44;
  --colors-dark-gray500: #2E333C;
  --colors-dark-gray700: #2F333A;
  --colors-dark-gray800: #21252C;
  --colors-dark-gray850: #191D22;
  --colors-dark-gray900: #16191E;
  --colors-dark-gray1000: #0C0F14;
  --colors-dark-tintGray80: rgba(13, 16, 20, 0.80);
  --colors-dark-gradientPrimary: linear-gradient(90deg, #EE8100 0%, #EA2B57 50%, #7322A6 100%);;
  --colors-dark-gradientPrimary10: linear-gradient(90deg, rgba(238, 129, 0, 0.10) 0%, rgba(234, 43, 87, 0.10) 50%, rgba(115, 34, 166, 0.10) 100%);;
  --colors-dark-gradientPrimary20: linear-gradient(90deg, rgba(238, 129, 0, 0.20) 0%, rgba(234, 43, 87, 0.20) 50%, rgba(115, 34, 166, 0.20) 100%);;
  --colors-dark-gradientPrimaryRadial: radial-gradient(70.71% 70.71% at 50% 50%, #7322a6 0%, #ea2b57 50%, #ee8100 100%);
  --colors-dark-orange100: rgba(238, 129, 0, 0.1);
  --colors-dark-orange: #EE8100;
  --colors-dark-purple: #7322A6;
  --colors-dark-purple60: rgba(115, 34, 166, 0.60);
  --colors-dark-orangeDark: #BB6500;
  --colors-dark-binance: #F0B90B;
  --colors-dark-overlay: #452a7a;
  --colors-dark-gold: #FFC700;
  --colors-dark-silver: #B2B2B2;
  --colors-dark-bronze: #E7974D;
  --colors-dark-secondary: #9A6AFF;
  --colors-dark-secondary80: #9A6AFF80;
  --colors-dark-background: #050B12;
  --colors-dark-backgroundDisabled: #3A3E44;
  --colors-dark-backgroundAlt: rgba(13, 16, 20, 0.8);
  --colors-dark-backgroundAlt2: rgba(39, 38, 44, 0.7);
  --colors-dark-cardBorder: rgba(255, 255, 255, 0.10);
  --colors-dark-contrast: #FFFFFF;
  --colors-dark-dropdown: #1E1D20;
  --colors-dark-dropdownDeep: #100C18;
  --colors-dark-invertedContrast: #191326;
  --colors-dark-input: #16191E;
  --colors-dark-inputSecondary: rgba(255, 255, 255, 0.10);
  --colors-dark-tertiary: #353547;
  --colors-dark-text: #F4EEFF;
  --colors-dark-text99: #F4EEFF99;
  --colors-dark-textDisabled: #666171;
  --colors-dark-textSubtle: #B8ADD2;
  --colors-dark-disabled: #524B63;
  --colors-dark-gradientBubblegum: linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%);
  --colors-dark-gradientInverseBubblegum: linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%);
  --colors-dark-gradientCardHeader: linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%);
  --colors-dark-gradientBlue: linear-gradient(180deg, #00707F 0%, #19778C 100%);
  --colors-dark-gradientViolet: linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%);
  --colors-dark-gradientVioletAlt: linear-gradient(180deg, #434575 0%, #66578D 100%);
  --colors-dark-gradientGold: linear-gradient(180deg, #FFD800 0%, #FDAB32 100%);
  --colors-dark-gradientBold: linear-gradient(#53DEE9, #9A6AFF);
  --fonts-normal: var(--bs-body-font-family), sans-serif;
  --fonts-mono: SFMono, ui-monospace, monospace;
  --space-0px: 0px;
  --space-1rem: 1rem;
  --space-1px: 1px;
  --space-2px: 2px;
  --space-6px: 6px;
  --space-4px: 4px;
  --space-8px: 8px;
  --space-12px: 12px;
  --space-14px: 14px;
  --space-16px: 16px;
  --space-20px: 20px;
  --space-24px: 24px;
  --space-32px: 32px;
  --space-40px: 40px;
  --space-48px: 48px;
  --space-56px: 56px;
  --space-64px: 64px;
  --borderWidths-0: 0px;
  --borderWidths-1: 1px;
  --borderWidths-2: 2px;
  --radii-0: 0px;
  --radii-8px: 8px;
  --radii-12px: 12px;
  --radii-20px: 20px;
  --radii-32px: 32px;
  --radii-small: 4px;
  --radii-default: 16px;
  --radii-card: 16px;
  --radii-circle: 50%;
  --fontSizes-10px: 10px;
  --fontSizes-12px: 12px;
  --fontSizes-16px: 16px;
  --fontSizes-14px: 14px;
  --fontSizes-20px: 20px;
  --fontSizes-40px: 40px;
  --shadows-level1: 0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05);
  --shadows-active: 0px 0px 0px 1px #0098A1, 0px 0px 4px 8px rgba(31, 199, 212, 0.4);
  --shadows-success: 0px 0px 0px 1px #31D0AA, 0px 0px 0px 4px rgba(49, 208, 170, 0.2);
  --shadows-warning: 0px 0px 0px 1px #D67E0A, 0px 0px 0px 4px rgba(214, 126, 10, 0.2);
  --shadows-danger: 0px 0px 0px 1px #F33434, 0px 0px 0px 4px rgba(237, 75, 158, 0.2);
  --shadows-focus: 0px 0px 0px 2px rgba(255, 255, 255, 0.05);
  --shadows-inset: inset 0px 2px 2px -1px rgba(74, 74, 104, 0.1);
  --shadows-tooltip: 0px 0px 2px rgba(0, 0, 0, 0.2), 0px 4px 12px -8px rgba(14, 14, 44, 0.1);
}
[data-theme="light"] {
  --colors-white50: rgba(255, 255, 255, 0.05);
  --colors-white100: rgba(255, 255, 255, 0.1);
  --colors-white200: rgba(255, 255, 255, 0.2);
  --colors-white300: rgba(255, 255, 255, 0.3);
  --colors-white500: rgba(255, 255, 255, 0.5);
  --colors-white800: rgba(255, 255, 255, 0.8);
  --colors-white: #fff;
  --colors-black: #000;
  --colors-failure: #F33434;
  --colors-failure33: #ED4B9E33;
  --colors-red100: rgba(243, 52, 52, 0.10);
  --colors-primary: #1FC7D4;
  --colors-primary0f: #1FC7D40f;
  --colors-primary3D: #1FC7D43D;
  --colors-primaryBright: #53DEE9;
  --colors-primaryDark: #0098A1;
  --colors-success: #29B837;
  --colors-success19: #31D0AA19;
  --colors-warning: #FFA337;
  --colors-warning2D: #ED4B9E2D;
  --colors-warning33: #ED4B9E33;
  --colors-yellow100: rgba(255, 163, 55, 0.10);
  --colors-gray50: rgba(13, 16, 20, 0.05);
  --colors-gray100: #E6E6E6;
  --colors-gray200: #5B616B;
  --colors-gray300: #494D53;
  --colors-gray390: #303338;
  --colors-gray400: #3A3E44;
  --colors-gray500: #2E333C;
  --colors-gray700: #2F333A;
  --colors-gray800: #21252C;
  --colors-gray850: #191D22;
  --colors-gray900: #16191E;
  --colors-gray1000: #0C0F14;
  --colors-tintGray80: rgba(13, 16, 20, 0.80);
  --colors-gradientPrimary: linear-gradient(90deg, #EE8100 0%, #EA2B57 50%, #7322A6 100%);;
  --colors-gradientPrimary10: linear-gradient(90deg, rgba(238, 129, 0, 0.10) 0%, rgba(234, 43, 87, 0.10) 50%, rgba(115, 34, 166, 0.10) 100%);;
  --colors-gradientPrimary20: linear-gradient(90deg, rgba(238, 129, 0, 0.20) 0%, rgba(234, 43, 87, 0.20) 50%, rgba(115, 34, 166, 0.20) 100%);;
  --colors-gradientPrimaryRadial: radial-gradient(70.71% 70.71% at 50% 50%, #7322a6 0%, #ea2b57 50%, #ee8100 100%);
  --colors-orange100: rgba(238, 129, 0, 0.1);
  --colors-orange: #EE8100;
  --colors-purple: #7322A6;
  --colors-purple60: rgba(115, 34, 166, 0.60);
  --colors-orangeDark: #BB6500;
  --colors-binance: #F0B90B;
  --colors-overlay: #452a7a;
  --colors-gold: #FFC700;
  --colors-silver: #B2B2B2;
  --colors-bronze: #E7974D;
  --colors-secondary: #7645D9;
  --colors-secondary80: #7645D980;
  --colors-background: #FAF9FA;
  --colors-backgroundDisabled: #E9EAEB;
  --colors-backgroundAlt: #FFFFFF;
  --colors-backgroundAlt2: rgba(255, 255, 255, 0.7);
  --colors-cardBorder: #E7E3EB;
  --colors-contrast: #191326;
  --colors-dropdown: #F6F6F6;
  --colors-dropdownDeep: #EEEEEE;
  --colors-invertedContrast: #FFFFFF;
  --colors-input: #eeeaf4;
  --colors-inputSecondary: #d7caec;
  --colors-tertiary: #EFF4F5;
  --colors-text: #280D5F;
  --colors-text99: #280D5F99;
  --colors-textDisabled: #BDC2C4;
  --colors-textSubtle: #7A6EAA;
  --colors-disabled: #E9EAEB;
  --colors-gradientBubblegum: linear-gradient(139.73deg, #E5FDFF 0%, #F3EFFF 100%);
  --colors-gradientInverseBubblegum: linear-gradient(139.73deg, #F3EFFF 0%, #E5FDFF 100%);
  --colors-gradientCardHeader: linear-gradient(111.68deg, #F2ECF2 0%, #E8F2F6 100%);
  --colors-gradientBlue: linear-gradient(180deg, #A7E8F1 0%, #94E1F2 100%);
  --colors-gradientViolet: linear-gradient(180deg, #E2C9FB 0%, #CDB8FA 100%);
  --colors-gradientVioletAlt: linear-gradient(180deg, #CBD7EF 0%, #9A9FD0 100%);
  --colors-gradientGold: linear-gradient(180deg, #FFD800 0%, #FDAB32 100%);
  --colors-gradientBold: linear-gradient(#53DEE9, #7645D9);
}
[data-theme="dark"] {
  --colors-white50: rgba(255, 255, 255, 0.05);
  --colors-white100: rgba(255, 255, 255, 0.1);
  --colors-white200: rgba(255, 255, 255, 0.2);
  --colors-white300: rgba(255, 255, 255, 0.3);
  --colors-white500: rgba(255, 255, 255, 0.5);
  --colors-white800: rgba(255, 255, 255, 0.8);
  --colors-white: #fff;
  --colors-black: #000;
  --colors-failure: #F33434;
  --colors-failure33: #ED4B9E33;
  --colors-red100: rgba(243, 52, 52, 0.10);
  --colors-primary: #1FC7D4;
  --colors-primary0f: #1FC7D40f;
  --colors-primary3D: #1FC7D43D;
  --colors-primaryBright: #53DEE9;
  --colors-primaryDark: #0098A1;
  --colors-success: #29B837;
  --colors-success19: #31D0AA19;
  --colors-warning: #FFA337;
  --colors-warning2D: #ED4B9E2D;
  --colors-warning33: #ED4B9E33;
  --colors-yellow100: rgba(255, 163, 55, 0.10);
  --colors-gray50: rgba(13, 16, 20, 0.05);
  --colors-gray100: #E6E6E6;
  --colors-gray200: #5B616B;
  --colors-gray300: #494D53;
  --colors-gray390: #303338;
  --colors-gray400: #3A3E44;
  --colors-gray500: #2E333C;
  --colors-gray700: #2F333A;
  --colors-gray800: #21252C;
  --colors-gray850: #191D22;
  --colors-gray900: #16191E;
  --colors-gray1000: #0C0F14;
  --colors-tintGray80: rgba(13, 16, 20, 0.80);
  --colors-gradientPrimary: linear-gradient(90deg, #EE8100 0%, #EA2B57 50%, #7322A6 100%);;
  --colors-gradientPrimary10: linear-gradient(90deg, rgba(238, 129, 0, 0.10) 0%, rgba(234, 43, 87, 0.10) 50%, rgba(115, 34, 166, 0.10) 100%);;
  --colors-gradientPrimary20: linear-gradient(90deg, rgba(238, 129, 0, 0.20) 0%, rgba(234, 43, 87, 0.20) 50%, rgba(115, 34, 166, 0.20) 100%);;
  --colors-gradientPrimaryRadial: radial-gradient(70.71% 70.71% at 50% 50%, #7322a6 0%, #ea2b57 50%, #ee8100 100%);
  --colors-orange100: rgba(238, 129, 0, 0.1);
  --colors-orange: #EE8100;
  --colors-purple: #7322A6;
  --colors-purple60: rgba(115, 34, 166, 0.60);
  --colors-orangeDark: #BB6500;
  --colors-binance: #F0B90B;
  --colors-overlay: #452a7a;
  --colors-gold: #FFC700;
  --colors-silver: #B2B2B2;
  --colors-bronze: #E7974D;
  --colors-secondary: #9A6AFF;
  --colors-secondary80: #9A6AFF80;
  --colors-background: #050B12;
  --colors-backgroundDisabled: #3A3E44;
  --colors-backgroundAlt: rgba(13, 16, 20, 0.8);
  --colors-backgroundAlt2: rgba(39, 38, 44, 0.7);
  --colors-cardBorder: rgba(255, 255, 255, 0.10);
  --colors-contrast: #FFFFFF;
  --colors-dropdown: #1E1D20;
  --colors-dropdownDeep: #100C18;
  --colors-invertedContrast: #191326;
  --colors-input: #16191E;
  --colors-inputSecondary: rgba(255, 255, 255, 0.10);
  --colors-tertiary: #353547;
  --colors-text: #F4EEFF;
  --colors-text99: #F4EEFF99;
  --colors-textDisabled: #666171;
  --colors-textSubtle: #B8ADD2;
  --colors-disabled: #524B63;
  --colors-gradientBubblegum: linear-gradient(139.73deg, #313D5C 0%, #3D2A54 100%);
  --colors-gradientInverseBubblegum: linear-gradient(139.73deg, #3D2A54 0%, #313D5C 100%);
  --colors-gradientCardHeader: linear-gradient(166.77deg, #3B4155 0%, #3A3045 100%);
  --colors-gradientBlue: linear-gradient(180deg, #00707F 0%, #19778C 100%);
  --colors-gradientViolet: linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%);
  --colors-gradientVioletAlt: linear-gradient(180deg, #434575 0%, #66578D 100%);
  --colors-gradientGold: linear-gradient(180deg, #FFD800 0%, #FDAB32 100%);
  --colors-gradientBold: linear-gradient(#53DEE9, #9A6AFF);
}
._1nzuaz70 {
  display: block;
}
._1nzuaz76 {
  display: flex;
}
._1nzuaz7c {
  display: grid;
}
._1nzuaz7i {
  display: inline;
}
._1nzuaz7o {
  display: inline-flex;
}
._1nzuaz7u {
  display: inline-block;
}
._1nzuaz710 {
  display: none;
}
._1nzuaz716 {
  flex-direction: column;
}
._1nzuaz71c {
  flex-direction: row;
}
._1nzuaz71i {
  flex-direction: column-reverse;
}
._1nzuaz71o {
  align-items: center;
}
._1nzuaz71u {
  align-items: end;
}
._1nzuaz720 {
  align-items: baseLine;
}
._1nzuaz726 {
  align-items: inherit;
}
._1nzuaz72c {
  align-items: flex-start;
}
._1nzuaz72i {
  align-items: center;
}
._1nzuaz72o {
  align-items: start;
}
._1nzuaz72u {
  align-items: flex-end;
}
._1nzuaz730 {
  align-items: stretch;
}
._1nzuaz736 {
  align-self: flex-start;
}
._1nzuaz73c {
  align-self: center;
}
._1nzuaz73i {
  align-self: start;
}
._1nzuaz73o {
  align-self: flex-end;
}
._1nzuaz73u {
  align-self: stretch;
}
._1nzuaz740 {
  flex-wrap: wrap;
}
._1nzuaz746 {
  flex-wrap: nowrap;
}
._1nzuaz74c {
  flex-grow: 1;
}
._1nzuaz74i {
  overflow: auto;
}
._1nzuaz74o {
  overflow: hidden;
}
._1nzuaz74u {
  overflow: scroll;
}
._1nzuaz750 {
  overflow: unset;
}
._1nzuaz756 {
  overflow-y: auto;
}
._1nzuaz75c {
  overflow-y: hidden;
}
._1nzuaz75i {
  overflow-y: scroll;
}
._1nzuaz75o {
  overflow-x: auto;
}
._1nzuaz75u {
  overflow-x: hidden;
}
._1nzuaz760 {
  overflow-x: scroll;
}
._1nzuaz766 {
  position: absolute;
}
._1nzuaz76c {
  position: fixed;
}
._1nzuaz76i {
  position: relative;
}
._1nzuaz76o {
  position: sticky;
}
._1nzuaz76u {
  text-align: center;
}
._1nzuaz770 {
  text-align: left;
}
._1nzuaz776 {
  text-align: right;
}
._1nzuaz77c {
  justify-content: flex-start;
}
._1nzuaz77i {
  justify-content: center;
}
._1nzuaz77o {
  justify-content: start;
}
._1nzuaz77u {
  justify-content: flex-end;
}
._1nzuaz780 {
  justify-content: stretch;
}
._1nzuaz786 {
  justify-content: space-around;
}
._1nzuaz78c {
  justify-content: space-between;
}
._1nzuaz78i {
  justify-items: flex-start;
}
._1nzuaz78o {
  justify-items: center;
}
._1nzuaz78u {
  justify-items: start;
}
._1nzuaz790 {
  justify-items: flex-end;
}
._1nzuaz796 {
  justify-items: stretch;
}
._1nzuaz79c {
  justify-items: space-around;
}
._1nzuaz79i {
  justify-items: space-between;
}
._1nzuaz79o {
  justify-self: flex-start;
}
._1nzuaz79u {
  justify-self: center;
}
._1nzuaz7a0 {
  justify-self: start;
}
._1nzuaz7a6 {
  justify-self: flex-end;
}
._1nzuaz7ac {
  justify-self: stretch;
}
._1nzuaz7ai {
  inset: 0px;
}
._1nzuaz7ao {
  height: var(--space-0px);
}
._1nzuaz7au {
  height: var(--space-1rem);
}
._1nzuaz7b0 {
  height: var(--space-1px);
}
._1nzuaz7b6 {
  height: var(--space-2px);
}
._1nzuaz7bc {
  height: var(--space-6px);
}
._1nzuaz7bi {
  height: var(--space-4px);
}
._1nzuaz7bo {
  height: var(--space-8px);
}
._1nzuaz7bu {
  height: var(--space-12px);
}
._1nzuaz7c0 {
  height: var(--space-14px);
}
._1nzuaz7c6 {
  height: var(--space-16px);
}
._1nzuaz7cc {
  height: var(--space-20px);
}
._1nzuaz7ci {
  height: var(--space-24px);
}
._1nzuaz7co {
  height: var(--space-32px);
}
._1nzuaz7cu {
  height: var(--space-40px);
}
._1nzuaz7d0 {
  height: var(--space-48px);
}
._1nzuaz7d6 {
  height: var(--space-56px);
}
._1nzuaz7dc {
  height: var(--space-64px);
}
._1nzuaz7di {
  height: 100%;
}
._1nzuaz7do {
  height: auto;
}
._1nzuaz7du {
  height: -moz-fit-content;
  height: fit-content;
}
._1nzuaz7e0 {
  height: 420px;
}
._1nzuaz7e6 {
  height: 576px;
}
._1nzuaz7ec {
  height: 768px;
}
._1nzuaz7ei {
  height: 968px;
}
._1nzuaz7eo {
  height: 1024px;
}
._1nzuaz7eu {
  height: 100vh;
}
._1nzuaz7f0 {
  margin-bottom: var(--space-0px);
}
._1nzuaz7f6 {
  margin-bottom: var(--space-1rem);
}
._1nzuaz7fc {
  margin-bottom: var(--space-1px);
}
._1nzuaz7fi {
  margin-bottom: var(--space-2px);
}
._1nzuaz7fo {
  margin-bottom: var(--space-6px);
}
._1nzuaz7fu {
  margin-bottom: var(--space-4px);
}
._1nzuaz7g0 {
  margin-bottom: var(--space-8px);
}
._1nzuaz7g6 {
  margin-bottom: var(--space-12px);
}
._1nzuaz7gc {
  margin-bottom: var(--space-14px);
}
._1nzuaz7gi {
  margin-bottom: var(--space-16px);
}
._1nzuaz7go {
  margin-bottom: var(--space-20px);
}
._1nzuaz7gu {
  margin-bottom: var(--space-24px);
}
._1nzuaz7h0 {
  margin-bottom: var(--space-32px);
}
._1nzuaz7h6 {
  margin-bottom: var(--space-40px);
}
._1nzuaz7hc {
  margin-bottom: var(--space-48px);
}
._1nzuaz7hi {
  margin-bottom: var(--space-56px);
}
._1nzuaz7ho {
  margin-bottom: var(--space-64px);
}
._1nzuaz7hu {
  margin-bottom: auto;
}
._1nzuaz7i0 {
  margin-left: var(--space-0px);
}
._1nzuaz7i6 {
  margin-left: var(--space-1rem);
}
._1nzuaz7ic {
  margin-left: var(--space-1px);
}
._1nzuaz7ii {
  margin-left: var(--space-2px);
}
._1nzuaz7io {
  margin-left: var(--space-6px);
}
._1nzuaz7iu {
  margin-left: var(--space-4px);
}
._1nzuaz7j0 {
  margin-left: var(--space-8px);
}
._1nzuaz7j6 {
  margin-left: var(--space-12px);
}
._1nzuaz7jc {
  margin-left: var(--space-14px);
}
._1nzuaz7ji {
  margin-left: var(--space-16px);
}
._1nzuaz7jo {
  margin-left: var(--space-20px);
}
._1nzuaz7ju {
  margin-left: var(--space-24px);
}
._1nzuaz7k0 {
  margin-left: var(--space-32px);
}
._1nzuaz7k6 {
  margin-left: var(--space-40px);
}
._1nzuaz7kc {
  margin-left: var(--space-48px);
}
._1nzuaz7ki {
  margin-left: var(--space-56px);
}
._1nzuaz7ko {
  margin-left: var(--space-64px);
}
._1nzuaz7ku {
  margin-left: auto;
}
._1nzuaz7l0 {
  margin-right: var(--space-0px);
}
._1nzuaz7l6 {
  margin-right: var(--space-1rem);
}
._1nzuaz7lc {
  margin-right: var(--space-1px);
}
._1nzuaz7li {
  margin-right: var(--space-2px);
}
._1nzuaz7lo {
  margin-right: var(--space-6px);
}
._1nzuaz7lu {
  margin-right: var(--space-4px);
}
._1nzuaz7m0 {
  margin-right: var(--space-8px);
}
._1nzuaz7m6 {
  margin-right: var(--space-12px);
}
._1nzuaz7mc {
  margin-right: var(--space-14px);
}
._1nzuaz7mi {
  margin-right: var(--space-16px);
}
._1nzuaz7mo {
  margin-right: var(--space-20px);
}
._1nzuaz7mu {
  margin-right: var(--space-24px);
}
._1nzuaz7n0 {
  margin-right: var(--space-32px);
}
._1nzuaz7n6 {
  margin-right: var(--space-40px);
}
._1nzuaz7nc {
  margin-right: var(--space-48px);
}
._1nzuaz7ni {
  margin-right: var(--space-56px);
}
._1nzuaz7no {
  margin-right: var(--space-64px);
}
._1nzuaz7nu {
  margin-right: auto;
}
._1nzuaz7o0 {
  margin-top: var(--space-0px);
}
._1nzuaz7o6 {
  margin-top: var(--space-1rem);
}
._1nzuaz7oc {
  margin-top: var(--space-1px);
}
._1nzuaz7oi {
  margin-top: var(--space-2px);
}
._1nzuaz7oo {
  margin-top: var(--space-6px);
}
._1nzuaz7ou {
  margin-top: var(--space-4px);
}
._1nzuaz7p0 {
  margin-top: var(--space-8px);
}
._1nzuaz7p6 {
  margin-top: var(--space-12px);
}
._1nzuaz7pc {
  margin-top: var(--space-14px);
}
._1nzuaz7pi {
  margin-top: var(--space-16px);
}
._1nzuaz7po {
  margin-top: var(--space-20px);
}
._1nzuaz7pu {
  margin-top: var(--space-24px);
}
._1nzuaz7q0 {
  margin-top: var(--space-32px);
}
._1nzuaz7q6 {
  margin-top: var(--space-40px);
}
._1nzuaz7qc {
  margin-top: var(--space-48px);
}
._1nzuaz7qi {
  margin-top: var(--space-56px);
}
._1nzuaz7qo {
  margin-top: var(--space-64px);
}
._1nzuaz7qu {
  margin-top: auto;
}
._1nzuaz7r0 {
  margin: var(--space-0px);
}
._1nzuaz7r6 {
  margin: var(--space-1rem);
}
._1nzuaz7rc {
  margin: var(--space-1px);
}
._1nzuaz7ri {
  margin: var(--space-2px);
}
._1nzuaz7ro {
  margin: var(--space-6px);
}
._1nzuaz7ru {
  margin: var(--space-4px);
}
._1nzuaz7s0 {
  margin: var(--space-8px);
}
._1nzuaz7s6 {
  margin: var(--space-12px);
}
._1nzuaz7sc {
  margin: var(--space-14px);
}
._1nzuaz7si {
  margin: var(--space-16px);
}
._1nzuaz7so {
  margin: var(--space-20px);
}
._1nzuaz7su {
  margin: var(--space-24px);
}
._1nzuaz7t0 {
  margin: var(--space-32px);
}
._1nzuaz7t6 {
  margin: var(--space-40px);
}
._1nzuaz7tc {
  margin: var(--space-48px);
}
._1nzuaz7ti {
  margin: var(--space-56px);
}
._1nzuaz7to {
  margin: var(--space-64px);
}
._1nzuaz7tu {
  margin: auto;
}
._1nzuaz7u0 {
  padding: var(--space-0px);
}
._1nzuaz7u6 {
  padding: var(--space-1rem);
}
._1nzuaz7uc {
  padding: var(--space-1px);
}
._1nzuaz7ui {
  padding: var(--space-2px);
}
._1nzuaz7uo {
  padding: var(--space-6px);
}
._1nzuaz7uu {
  padding: var(--space-4px);
}
._1nzuaz7v0 {
  padding: var(--space-8px);
}
._1nzuaz7v6 {
  padding: var(--space-12px);
}
._1nzuaz7vc {
  padding: var(--space-14px);
}
._1nzuaz7vi {
  padding: var(--space-16px);
}
._1nzuaz7vo {
  padding: var(--space-20px);
}
._1nzuaz7vu {
  padding: var(--space-24px);
}
._1nzuaz7w0 {
  padding: var(--space-32px);
}
._1nzuaz7w6 {
  padding: var(--space-40px);
}
._1nzuaz7wc {
  padding: var(--space-48px);
}
._1nzuaz7wi {
  padding: var(--space-56px);
}
._1nzuaz7wo {
  padding: var(--space-64px);
}
._1nzuaz7wu {
  max-height: var(--space-0px);
}
._1nzuaz7x0 {
  max-height: var(--space-1rem);
}
._1nzuaz7x6 {
  max-height: var(--space-1px);
}
._1nzuaz7xc {
  max-height: var(--space-2px);
}
._1nzuaz7xi {
  max-height: var(--space-6px);
}
._1nzuaz7xo {
  max-height: var(--space-4px);
}
._1nzuaz7xu {
  max-height: var(--space-8px);
}
._1nzuaz7y0 {
  max-height: var(--space-12px);
}
._1nzuaz7y6 {
  max-height: var(--space-14px);
}
._1nzuaz7yc {
  max-height: var(--space-16px);
}
._1nzuaz7yi {
  max-height: var(--space-20px);
}
._1nzuaz7yo {
  max-height: var(--space-24px);
}
._1nzuaz7yu {
  max-height: var(--space-32px);
}
._1nzuaz7z0 {
  max-height: var(--space-40px);
}
._1nzuaz7z6 {
  max-height: var(--space-48px);
}
._1nzuaz7zc {
  max-height: var(--space-56px);
}
._1nzuaz7zi {
  max-height: var(--space-64px);
}
._1nzuaz7zo {
  max-width: var(--space-0px);
}
._1nzuaz7zu {
  max-width: var(--space-1rem);
}
._1nzuaz7100 {
  max-width: var(--space-1px);
}
._1nzuaz7106 {
  max-width: var(--space-2px);
}
._1nzuaz710c {
  max-width: var(--space-6px);
}
._1nzuaz710i {
  max-width: var(--space-4px);
}
._1nzuaz710o {
  max-width: var(--space-8px);
}
._1nzuaz710u {
  max-width: var(--space-12px);
}
._1nzuaz7110 {
  max-width: var(--space-14px);
}
._1nzuaz7116 {
  max-width: var(--space-16px);
}
._1nzuaz711c {
  max-width: var(--space-20px);
}
._1nzuaz711i {
  max-width: var(--space-24px);
}
._1nzuaz711o {
  max-width: var(--space-32px);
}
._1nzuaz711u {
  max-width: var(--space-40px);
}
._1nzuaz7120 {
  max-width: var(--space-48px);
}
._1nzuaz7126 {
  max-width: var(--space-56px);
}
._1nzuaz712c {
  max-width: var(--space-64px);
}
._1nzuaz712i {
  max-width: 100%;
}
._1nzuaz712o {
  max-width: auto;
}
._1nzuaz712u {
  max-width: -moz-fit-content;
  max-width: fit-content;
}
._1nzuaz7130 {
  max-width: 420px;
}
._1nzuaz7136 {
  max-width: 576px;
}
._1nzuaz713c {
  max-width: 768px;
}
._1nzuaz713i {
  max-width: 968px;
}
._1nzuaz713o {
  max-width: 1024px;
}
._1nzuaz713u {
  max-width: none;
}
._1nzuaz7140 {
  min-height: var(--space-0px);
}
._1nzuaz7146 {
  min-height: var(--space-1rem);
}
._1nzuaz714c {
  min-height: var(--space-1px);
}
._1nzuaz714i {
  min-height: var(--space-2px);
}
._1nzuaz714o {
  min-height: var(--space-6px);
}
._1nzuaz714u {
  min-height: var(--space-4px);
}
._1nzuaz7150 {
  min-height: var(--space-8px);
}
._1nzuaz7156 {
  min-height: var(--space-12px);
}
._1nzuaz715c {
  min-height: var(--space-14px);
}
._1nzuaz715i {
  min-height: var(--space-16px);
}
._1nzuaz715o {
  min-height: var(--space-20px);
}
._1nzuaz715u {
  min-height: var(--space-24px);
}
._1nzuaz7160 {
  min-height: var(--space-32px);
}
._1nzuaz7166 {
  min-height: var(--space-40px);
}
._1nzuaz716c {
  min-height: var(--space-48px);
}
._1nzuaz716i {
  min-height: var(--space-56px);
}
._1nzuaz716o {
  min-height: var(--space-64px);
}
._1nzuaz716u {
  min-height: 100%;
}
._1nzuaz7170 {
  min-height: auto;
}
._1nzuaz7176 {
  min-height: -moz-fit-content;
  min-height: fit-content;
}
._1nzuaz717c {
  min-height: 420px;
}
._1nzuaz717i {
  min-height: 576px;
}
._1nzuaz717o {
  min-height: 768px;
}
._1nzuaz717u {
  min-height: 968px;
}
._1nzuaz7180 {
  min-height: 1024px;
}
._1nzuaz7186 {
  min-height: 100vh;
}
._1nzuaz718c {
  min-width: var(--space-0px);
}
._1nzuaz718i {
  min-width: var(--space-1rem);
}
._1nzuaz718o {
  min-width: var(--space-1px);
}
._1nzuaz718u {
  min-width: var(--space-2px);
}
._1nzuaz7190 {
  min-width: var(--space-6px);
}
._1nzuaz7196 {
  min-width: var(--space-4px);
}
._1nzuaz719c {
  min-width: var(--space-8px);
}
._1nzuaz719i {
  min-width: var(--space-12px);
}
._1nzuaz719o {
  min-width: var(--space-14px);
}
._1nzuaz719u {
  min-width: var(--space-16px);
}
._1nzuaz71a0 {
  min-width: var(--space-20px);
}
._1nzuaz71a6 {
  min-width: var(--space-24px);
}
._1nzuaz71ac {
  min-width: var(--space-32px);
}
._1nzuaz71ai {
  min-width: var(--space-40px);
}
._1nzuaz71ao {
  min-width: var(--space-48px);
}
._1nzuaz71au {
  min-width: var(--space-56px);
}
._1nzuaz71b0 {
  min-width: var(--space-64px);
}
._1nzuaz71b6 {
  padding-bottom: var(--space-0px);
}
._1nzuaz71bc {
  padding-bottom: var(--space-1rem);
}
._1nzuaz71bi {
  padding-bottom: var(--space-1px);
}
._1nzuaz71bo {
  padding-bottom: var(--space-2px);
}
._1nzuaz71bu {
  padding-bottom: var(--space-6px);
}
._1nzuaz71c0 {
  padding-bottom: var(--space-4px);
}
._1nzuaz71c6 {
  padding-bottom: var(--space-8px);
}
._1nzuaz71cc {
  padding-bottom: var(--space-12px);
}
._1nzuaz71ci {
  padding-bottom: var(--space-14px);
}
._1nzuaz71co {
  padding-bottom: var(--space-16px);
}
._1nzuaz71cu {
  padding-bottom: var(--space-20px);
}
._1nzuaz71d0 {
  padding-bottom: var(--space-24px);
}
._1nzuaz71d6 {
  padding-bottom: var(--space-32px);
}
._1nzuaz71dc {
  padding-bottom: var(--space-40px);
}
._1nzuaz71di {
  padding-bottom: var(--space-48px);
}
._1nzuaz71do {
  padding-bottom: var(--space-56px);
}
._1nzuaz71du {
  padding-bottom: var(--space-64px);
}
._1nzuaz71e0 {
  padding-left: var(--space-0px);
}
._1nzuaz71e6 {
  padding-left: var(--space-1rem);
}
._1nzuaz71ec {
  padding-left: var(--space-1px);
}
._1nzuaz71ei {
  padding-left: var(--space-2px);
}
._1nzuaz71eo {
  padding-left: var(--space-6px);
}
._1nzuaz71eu {
  padding-left: var(--space-4px);
}
._1nzuaz71f0 {
  padding-left: var(--space-8px);
}
._1nzuaz71f6 {
  padding-left: var(--space-12px);
}
._1nzuaz71fc {
  padding-left: var(--space-14px);
}
._1nzuaz71fi {
  padding-left: var(--space-16px);
}
._1nzuaz71fo {
  padding-left: var(--space-20px);
}
._1nzuaz71fu {
  padding-left: var(--space-24px);
}
._1nzuaz71g0 {
  padding-left: var(--space-32px);
}
._1nzuaz71g6 {
  padding-left: var(--space-40px);
}
._1nzuaz71gc {
  padding-left: var(--space-48px);
}
._1nzuaz71gi {
  padding-left: var(--space-56px);
}
._1nzuaz71go {
  padding-left: var(--space-64px);
}
._1nzuaz71gu {
  padding-right: var(--space-0px);
}
._1nzuaz71h0 {
  padding-right: var(--space-1rem);
}
._1nzuaz71h6 {
  padding-right: var(--space-1px);
}
._1nzuaz71hc {
  padding-right: var(--space-2px);
}
._1nzuaz71hi {
  padding-right: var(--space-6px);
}
._1nzuaz71ho {
  padding-right: var(--space-4px);
}
._1nzuaz71hu {
  padding-right: var(--space-8px);
}
._1nzuaz71i0 {
  padding-right: var(--space-12px);
}
._1nzuaz71i6 {
  padding-right: var(--space-14px);
}
._1nzuaz71ic {
  padding-right: var(--space-16px);
}
._1nzuaz71ii {
  padding-right: var(--space-20px);
}
._1nzuaz71io {
  padding-right: var(--space-24px);
}
._1nzuaz71iu {
  padding-right: var(--space-32px);
}
._1nzuaz71j0 {
  padding-right: var(--space-40px);
}
._1nzuaz71j6 {
  padding-right: var(--space-48px);
}
._1nzuaz71jc {
  padding-right: var(--space-56px);
}
._1nzuaz71ji {
  padding-right: var(--space-64px);
}
._1nzuaz71jo {
  padding-top: var(--space-0px);
}
._1nzuaz71ju {
  padding-top: var(--space-1rem);
}
._1nzuaz71k0 {
  padding-top: var(--space-1px);
}
._1nzuaz71k6 {
  padding-top: var(--space-2px);
}
._1nzuaz71kc {
  padding-top: var(--space-6px);
}
._1nzuaz71ki {
  padding-top: var(--space-4px);
}
._1nzuaz71ko {
  padding-top: var(--space-8px);
}
._1nzuaz71ku {
  padding-top: var(--space-12px);
}
._1nzuaz71l0 {
  padding-top: var(--space-14px);
}
._1nzuaz71l6 {
  padding-top: var(--space-16px);
}
._1nzuaz71lc {
  padding-top: var(--space-20px);
}
._1nzuaz71li {
  padding-top: var(--space-24px);
}
._1nzuaz71lo {
  padding-top: var(--space-32px);
}
._1nzuaz71lu {
  padding-top: var(--space-40px);
}
._1nzuaz71m0 {
  padding-top: var(--space-48px);
}
._1nzuaz71m6 {
  padding-top: var(--space-56px);
}
._1nzuaz71mc {
  padding-top: var(--space-64px);
}
._1nzuaz71mi {
  font-size: var(--fontSizes-10px);
}
._1nzuaz71mo {
  font-size: var(--fontSizes-12px);
}
._1nzuaz71mu {
  font-size: var(--fontSizes-16px);
}
._1nzuaz71n0 {
  font-size: var(--fontSizes-14px);
}
._1nzuaz71n6 {
  font-size: var(--fontSizes-20px);
}
._1nzuaz71nc {
  font-size: var(--fontSizes-40px);
}
._1nzuaz71ni {
  font-size: inherit;
}
._1nzuaz71no {
  flex: 1 1;
}
._1nzuaz71nu {
  flex: 1 1 auto;
}
._1nzuaz71o0 {
  flex: 0 1 auto;
}
._1nzuaz71o6 {
  flex: none;
}
._1nzuaz71oc {
  box-shadow: var(--shadows-level1);
}
._1nzuaz71oi {
  box-shadow: var(--shadows-active);
}
._1nzuaz71oo {
  box-shadow: var(--shadows-success);
}
._1nzuaz71ou {
  box-shadow: var(--shadows-warning);
}
._1nzuaz71p0 {
  box-shadow: var(--shadows-danger);
}
._1nzuaz71p6 {
  box-shadow: var(--shadows-focus);
}
._1nzuaz71pc {
  box-shadow: var(--shadows-inset);
}
._1nzuaz71pi {
  box-shadow: var(--shadows-tooltip);
}
._1nzuaz71po {
  width: var(--space-0px);
}
._1nzuaz71pu {
  width: var(--space-1rem);
}
._1nzuaz71q0 {
  width: var(--space-1px);
}
._1nzuaz71q6 {
  width: var(--space-2px);
}
._1nzuaz71qc {
  width: var(--space-6px);
}
._1nzuaz71qi {
  width: var(--space-4px);
}
._1nzuaz71qo {
  width: var(--space-8px);
}
._1nzuaz71qu {
  width: var(--space-12px);
}
._1nzuaz71r0 {
  width: var(--space-14px);
}
._1nzuaz71r6 {
  width: var(--space-16px);
}
._1nzuaz71rc {
  width: var(--space-20px);
}
._1nzuaz71ri {
  width: var(--space-24px);
}
._1nzuaz71ro {
  width: var(--space-32px);
}
._1nzuaz71ru {
  width: var(--space-40px);
}
._1nzuaz71s0 {
  width: var(--space-48px);
}
._1nzuaz71s6 {
  width: var(--space-56px);
}
._1nzuaz71sc {
  width: var(--space-64px);
}
._1nzuaz71si {
  width: 100%;
}
._1nzuaz71so {
  width: auto;
}
._1nzuaz71su {
  width: -moz-fit-content;
  width: fit-content;
}
._1nzuaz71t0 {
  width: 420px;
}
._1nzuaz71t6 {
  width: 576px;
}
._1nzuaz71tc {
  width: 768px;
}
._1nzuaz71ti {
  width: 968px;
}
._1nzuaz71to {
  width: 1024px;
}
._1nzuaz71tu {
  z-index: 0;
}
._1nzuaz71u0 {
  z-index: 1;
}
._1nzuaz71u6 {
  z-index: 10;
}
._1nzuaz71uc {
  z-index: 20;
}
._1nzuaz71ui {
  z-index: 30;
}
._1nzuaz71uo {
  z-index: 40;
}
._1nzuaz71uu {
  z-index: 50;
}
._1nzuaz71v0 {
  z-index: 75;
}
._1nzuaz71v6 {
  z-index: 99;
}
._1nzuaz71vc {
  z-index: 100;
}
._1nzuaz71vi {
  z-index: 9;
}
._1nzuaz71vo {
  z-index: 10;
}
._1nzuaz71vu {
  z-index: 100;
}
._1nzuaz71w0 {
  z-index: auto;
}
._1nzuaz71w6 {
  border-top: 1px solid var(--colors-cardBorder);
}
._1nzuaz71wc {
  border-radius: var(--radii-0);
}
._1nzuaz71wi {
  border-radius: var(--radii-8px);
}
._1nzuaz71wo {
  border-radius: var(--radii-12px);
}
._1nzuaz71wu {
  border-radius: var(--radii-20px);
}
._1nzuaz71x0 {
  border-radius: var(--radii-32px);
}
._1nzuaz71x6 {
  border-radius: var(--radii-small);
}
._1nzuaz71xc {
  border-radius: var(--radii-default);
}
._1nzuaz71xi {
  border-radius: var(--radii-card);
}
._1nzuaz71xo {
  border-radius: var(--radii-circle);
}
._1nzuaz71xu {
  border-top-left-radius: var(--radii-0);
}
._1nzuaz71y0 {
  border-top-left-radius: var(--radii-8px);
}
._1nzuaz71y6 {
  border-top-left-radius: var(--radii-12px);
}
._1nzuaz71yc {
  border-top-left-radius: var(--radii-20px);
}
._1nzuaz71yi {
  border-top-left-radius: var(--radii-32px);
}
._1nzuaz71yo {
  border-top-left-radius: var(--radii-small);
}
._1nzuaz71yu {
  border-top-left-radius: var(--radii-default);
}
._1nzuaz71z0 {
  border-top-left-radius: var(--radii-card);
}
._1nzuaz71z6 {
  border-top-left-radius: var(--radii-circle);
}
._1nzuaz71zc {
  border-bottom-right-radius: var(--radii-0);
}
._1nzuaz71zi {
  border-bottom-right-radius: var(--radii-8px);
}
._1nzuaz71zo {
  border-bottom-right-radius: var(--radii-12px);
}
._1nzuaz71zu {
  border-bottom-right-radius: var(--radii-20px);
}
._1nzuaz7200 {
  border-bottom-right-radius: var(--radii-32px);
}
._1nzuaz7206 {
  border-bottom-right-radius: var(--radii-small);
}
._1nzuaz720c {
  border-bottom-right-radius: var(--radii-default);
}
._1nzuaz720i {
  border-bottom-right-radius: var(--radii-card);
}
._1nzuaz720o {
  border-bottom-right-radius: var(--radii-circle);
}
._1nzuaz720u {
  border-top-right-radius: var(--radii-0);
}
._1nzuaz7210 {
  border-top-right-radius: var(--radii-8px);
}
._1nzuaz7216 {
  border-top-right-radius: var(--radii-12px);
}
._1nzuaz721c {
  border-top-right-radius: var(--radii-20px);
}
._1nzuaz721i {
  border-top-right-radius: var(--radii-32px);
}
._1nzuaz721o {
  border-top-right-radius: var(--radii-small);
}
._1nzuaz721u {
  border-top-right-radius: var(--radii-default);
}
._1nzuaz7220 {
  border-top-right-radius: var(--radii-card);
}
._1nzuaz7226 {
  border-top-right-radius: var(--radii-circle);
}
._1nzuaz722c {
  border-bottom-left-radius: var(--radii-0);
}
._1nzuaz722i {
  border-bottom-left-radius: var(--radii-8px);
}
._1nzuaz722o {
  border-bottom-left-radius: var(--radii-12px);
}
._1nzuaz722u {
  border-bottom-left-radius: var(--radii-20px);
}
._1nzuaz7230 {
  border-bottom-left-radius: var(--radii-32px);
}
._1nzuaz7236 {
  border-bottom-left-radius: var(--radii-small);
}
._1nzuaz723c {
  border-bottom-left-radius: var(--radii-default);
}
._1nzuaz723i {
  border-bottom-left-radius: var(--radii-card);
}
._1nzuaz723o {
  border-bottom-left-radius: var(--radii-circle);
}
._1nzuaz723u {
  gap: var(--space-0px);
}
._1nzuaz7240 {
  gap: var(--space-1rem);
}
._1nzuaz7246 {
  gap: var(--space-1px);
}
._1nzuaz724c {
  gap: var(--space-2px);
}
._1nzuaz724i {
  gap: var(--space-6px);
}
._1nzuaz724o {
  gap: var(--space-4px);
}
._1nzuaz724u {
  gap: var(--space-8px);
}
._1nzuaz7250 {
  gap: var(--space-12px);
}
._1nzuaz7256 {
  gap: var(--space-14px);
}
._1nzuaz725c {
  gap: var(--space-16px);
}
._1nzuaz725i {
  gap: var(--space-20px);
}
._1nzuaz725o {
  gap: var(--space-24px);
}
._1nzuaz725u {
  gap: var(--space-32px);
}
._1nzuaz7260 {
  gap: var(--space-40px);
}
._1nzuaz7266 {
  gap: var(--space-48px);
}
._1nzuaz726c {
  gap: var(--space-56px);
}
._1nzuaz726i {
  gap: var(--space-64px);
}
._1nzuaz726o {
  gap: var(--space-8px);
}
._1nzuaz726u {
  gap: var(--space-12px);
}
._1nzuaz7270 {
  gap: var(--space-24px);
}
._1nzuaz7276 {
  row-gap: var(--space-0px);
}
._1nzuaz727c {
  row-gap: var(--space-1rem);
}
._1nzuaz727i {
  row-gap: var(--space-1px);
}
._1nzuaz727o {
  row-gap: var(--space-2px);
}
._1nzuaz727u {
  row-gap: var(--space-6px);
}
._1nzuaz7280 {
  row-gap: var(--space-4px);
}
._1nzuaz7286 {
  row-gap: var(--space-8px);
}
._1nzuaz728c {
  row-gap: var(--space-12px);
}
._1nzuaz728i {
  row-gap: var(--space-14px);
}
._1nzuaz728o {
  row-gap: var(--space-16px);
}
._1nzuaz728u {
  row-gap: var(--space-20px);
}
._1nzuaz7290 {
  row-gap: var(--space-24px);
}
._1nzuaz7296 {
  row-gap: var(--space-32px);
}
._1nzuaz729c {
  row-gap: var(--space-40px);
}
._1nzuaz729i {
  row-gap: var(--space-48px);
}
._1nzuaz729o {
  row-gap: var(--space-56px);
}
._1nzuaz729u {
  row-gap: var(--space-64px);
}
._1nzuaz72a0 {
  row-gap: var(--space-8px);
}
._1nzuaz72a6 {
  row-gap: var(--space-12px);
}
._1nzuaz72ac {
  row-gap: var(--space-24px);
}
._1nzuaz72ai {
  column-gap: var(--space-0px);
}
._1nzuaz72ao {
  column-gap: var(--space-1rem);
}
._1nzuaz72au {
  column-gap: var(--space-1px);
}
._1nzuaz72b0 {
  column-gap: var(--space-2px);
}
._1nzuaz72b6 {
  column-gap: var(--space-6px);
}
._1nzuaz72bc {
  column-gap: var(--space-4px);
}
._1nzuaz72bi {
  column-gap: var(--space-8px);
}
._1nzuaz72bo {
  column-gap: var(--space-12px);
}
._1nzuaz72bu {
  column-gap: var(--space-14px);
}
._1nzuaz72c0 {
  column-gap: var(--space-16px);
}
._1nzuaz72c6 {
  column-gap: var(--space-20px);
}
._1nzuaz72cc {
  column-gap: var(--space-24px);
}
._1nzuaz72ci {
  column-gap: var(--space-32px);
}
._1nzuaz72co {
  column-gap: var(--space-40px);
}
._1nzuaz72cu {
  column-gap: var(--space-48px);
}
._1nzuaz72d0 {
  column-gap: var(--space-56px);
}
._1nzuaz72d6 {
  column-gap: var(--space-64px);
}
._1nzuaz72dc {
  column-gap: var(--space-8px);
}
._1nzuaz72di {
  column-gap: var(--space-12px);
}
._1nzuaz72do {
  column-gap: var(--space-24px);
}
._1nzuaz72du {
  grid-auto-rows: auto;
}
._1nzuaz72e0 {
  opacity: 0;
}
._1nzuaz72e6 {
  opacity: 1;
}
._1nzuaz72ec {
  opacity: 0.5;
}
._1nzuaz72ei {
  opacity: 0.6;
}
._1nzuaz72eo {
  line-height: var(--space-16px);
}
._1nzuaz72eu {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz72f0 {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz72f6 {
  border-bottom-color: var(--colors-white50);
}
._1nzuaz72fc {
  border-bottom-color: var(--colors-white100);
}
._1nzuaz72fi {
  border-bottom-color: var(--colors-white200);
}
._1nzuaz72fo {
  border-bottom-color: var(--colors-white300);
}
._1nzuaz72fu {
  border-bottom-color: var(--colors-white500);
}
._1nzuaz72g0 {
  border-bottom-color: var(--colors-white800);
}
._1nzuaz72g6 {
  border-bottom-color: var(--colors-white);
}
._1nzuaz72gc {
  border-bottom-color: var(--colors-black);
}
._1nzuaz72gi {
  border-bottom-color: var(--colors-failure);
}
._1nzuaz72go {
  border-bottom-color: var(--colors-failure33);
}
._1nzuaz72gu {
  border-bottom-color: var(--colors-red100);
}
._1nzuaz72h0 {
  border-bottom-color: var(--colors-primary);
}
._1nzuaz72h6 {
  border-bottom-color: var(--colors-primary0f);
}
._1nzuaz72hc {
  border-bottom-color: var(--colors-primary3D);
}
._1nzuaz72hi {
  border-bottom-color: var(--colors-primaryBright);
}
._1nzuaz72ho {
  border-bottom-color: var(--colors-primaryDark);
}
._1nzuaz72hu {
  border-bottom-color: var(--colors-success);
}
._1nzuaz72i0 {
  border-bottom-color: var(--colors-success19);
}
._1nzuaz72i6 {
  border-bottom-color: var(--colors-warning);
}
._1nzuaz72ic {
  border-bottom-color: var(--colors-warning2D);
}
._1nzuaz72ii {
  border-bottom-color: var(--colors-warning33);
}
._1nzuaz72io {
  border-bottom-color: var(--colors-yellow100);
}
._1nzuaz72iu {
  border-bottom-color: var(--colors-gray50);
}
._1nzuaz72j0 {
  border-bottom-color: var(--colors-gray100);
}
._1nzuaz72j6 {
  border-bottom-color: var(--colors-gray200);
}
._1nzuaz72jc {
  border-bottom-color: var(--colors-gray300);
}
._1nzuaz72ji {
  border-bottom-color: var(--colors-gray390);
}
._1nzuaz72jo {
  border-bottom-color: var(--colors-gray400);
}
._1nzuaz72ju {
  border-bottom-color: var(--colors-gray500);
}
._1nzuaz72k0 {
  border-bottom-color: var(--colors-gray700);
}
._1nzuaz72k6 {
  border-bottom-color: var(--colors-gray800);
}
._1nzuaz72kc {
  border-bottom-color: var(--colors-gray850);
}
._1nzuaz72ki {
  border-bottom-color: var(--colors-gray900);
}
._1nzuaz72ko {
  border-bottom-color: var(--colors-gray1000);
}
._1nzuaz72ku {
  border-bottom-color: var(--colors-tintGray80);
}
._1nzuaz72l0 {
  border-bottom-color: var(--colors-gradientPrimary);
}
._1nzuaz72l6 {
  border-bottom-color: var(--colors-gradientPrimary10);
}
._1nzuaz72lc {
  border-bottom-color: var(--colors-gradientPrimary20);
}
._1nzuaz72li {
  border-bottom-color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz72lo {
  border-bottom-color: var(--colors-orange100);
}
._1nzuaz72lu {
  border-bottom-color: var(--colors-orange);
}
._1nzuaz72m0 {
  border-bottom-color: var(--colors-purple);
}
._1nzuaz72m6 {
  border-bottom-color: var(--colors-purple60);
}
._1nzuaz72mc {
  border-bottom-color: var(--colors-orangeDark);
}
._1nzuaz72mi {
  border-bottom-color: var(--colors-binance);
}
._1nzuaz72mo {
  border-bottom-color: var(--colors-overlay);
}
._1nzuaz72mu {
  border-bottom-color: var(--colors-gold);
}
._1nzuaz72n0 {
  border-bottom-color: var(--colors-silver);
}
._1nzuaz72n6 {
  border-bottom-color: var(--colors-bronze);
}
._1nzuaz72nc {
  border-bottom-color: var(--colors-secondary);
}
._1nzuaz72ni {
  border-bottom-color: var(--colors-secondary80);
}
._1nzuaz72no {
  border-bottom-color: var(--colors-background);
}
._1nzuaz72nu {
  border-bottom-color: var(--colors-backgroundDisabled);
}
._1nzuaz72o0 {
  border-bottom-color: var(--colors-backgroundAlt);
}
._1nzuaz72o6 {
  border-bottom-color: var(--colors-backgroundAlt2);
}
._1nzuaz72oc {
  border-bottom-color: var(--colors-cardBorder);
}
._1nzuaz72oi {
  border-bottom-color: var(--colors-contrast);
}
._1nzuaz72oo {
  border-bottom-color: var(--colors-dropdown);
}
._1nzuaz72ou {
  border-bottom-color: var(--colors-dropdownDeep);
}
._1nzuaz72p0 {
  border-bottom-color: var(--colors-invertedContrast);
}
._1nzuaz72p6 {
  border-bottom-color: var(--colors-input);
}
._1nzuaz72pc {
  border-bottom-color: var(--colors-inputSecondary);
}
._1nzuaz72pi {
  border-bottom-color: var(--colors-tertiary);
}
._1nzuaz72po {
  border-bottom-color: var(--colors-text);
}
._1nzuaz72pu {
  border-bottom-color: var(--colors-text99);
}
._1nzuaz72q0 {
  border-bottom-color: var(--colors-textDisabled);
}
._1nzuaz72q6 {
  border-bottom-color: var(--colors-textSubtle);
}
._1nzuaz72qc {
  border-bottom-color: var(--colors-disabled);
}
._1nzuaz72qi {
  border-bottom-color: var(--colors-gradientBubblegum);
}
._1nzuaz72qo {
  border-bottom-color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz72qu {
  border-bottom-color: var(--colors-gradientCardHeader);
}
._1nzuaz72r0 {
  border-bottom-color: var(--colors-gradientBlue);
}
._1nzuaz72r6 {
  border-bottom-color: var(--colors-gradientViolet);
}
._1nzuaz72rc {
  border-bottom-color: var(--colors-gradientVioletAlt);
}
._1nzuaz72ri {
  border-bottom-color: var(--colors-gradientGold);
}
._1nzuaz72ro {
  border-bottom-color: var(--colors-gradientBold);
}
._1nzuaz72ru {
  border: 1px solid var(--colors-cardBorder);
}
._1nzuaz72s0 {
  border-bottom: 1px solid var(--colors-cardBorder);
}
._1nzuaz72s6 {
  isolation: isolate;
}
._1nzuaz72s7 {
  object-fit: contain;
}
._1nzuaz72s8 {
  object-fit: cover;
}
._1nzuaz72s9 {
  object-fit: none;
}
._1nzuaz72sa {
  pointer-events: none;
}
._1nzuaz72sb {
  text-transform: capitalize;
}
._1nzuaz72sc {
  text-transform: lowercase;
}
._1nzuaz72sd {
  text-transform: uppercase;
}
._1nzuaz72se {
  cursor: default;
}
._1nzuaz72sf {
  cursor: pointer;
}
._1nzuaz72sg {
  cursor: not-allowed;
}
._1nzuaz72sh {
  visibility: hidden;
}
._1nzuaz72si {
  visibility: visible;
}
._1nzuaz72sj {
  white-space: normal;
}
._1nzuaz72sk {
  white-space: nowrap;
}
._1nzuaz72sl {
  white-space: pre;
}
._1nzuaz72sm {
  white-space: pre-line;
}
._1nzuaz72sn {
  white-space: pre-wrap;
}
._1nzuaz72so {
  word-break: break-word;
}
._1nzuaz72sp {
  word-wrap: normal;
}
._1nzuaz72sq {
  word-wrap: break-word;
}
._1nzuaz72sr {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz72ss:hover {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz72st:focus {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz72su:active {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz72sv {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz72sw:hover {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz72sx:focus {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz72sy:active {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz72sz {
  background: var(--colors-white50);
}
._1nzuaz72t0:hover {
  background: var(--colors-white50);
}
._1nzuaz72t1:focus {
  background: var(--colors-white50);
}
._1nzuaz72t2:active {
  background: var(--colors-white50);
}
._1nzuaz72t3 {
  background: var(--colors-white100);
}
._1nzuaz72t4:hover {
  background: var(--colors-white100);
}
._1nzuaz72t5:focus {
  background: var(--colors-white100);
}
._1nzuaz72t6:active {
  background: var(--colors-white100);
}
._1nzuaz72t7 {
  background: var(--colors-white200);
}
._1nzuaz72t8:hover {
  background: var(--colors-white200);
}
._1nzuaz72t9:focus {
  background: var(--colors-white200);
}
._1nzuaz72ta:active {
  background: var(--colors-white200);
}
._1nzuaz72tb {
  background: var(--colors-white300);
}
._1nzuaz72tc:hover {
  background: var(--colors-white300);
}
._1nzuaz72td:focus {
  background: var(--colors-white300);
}
._1nzuaz72te:active {
  background: var(--colors-white300);
}
._1nzuaz72tf {
  background: var(--colors-white500);
}
._1nzuaz72tg:hover {
  background: var(--colors-white500);
}
._1nzuaz72th:focus {
  background: var(--colors-white500);
}
._1nzuaz72ti:active {
  background: var(--colors-white500);
}
._1nzuaz72tj {
  background: var(--colors-white800);
}
._1nzuaz72tk:hover {
  background: var(--colors-white800);
}
._1nzuaz72tl:focus {
  background: var(--colors-white800);
}
._1nzuaz72tm:active {
  background: var(--colors-white800);
}
._1nzuaz72tn {
  background: var(--colors-white);
}
._1nzuaz72to:hover {
  background: var(--colors-white);
}
._1nzuaz72tp:focus {
  background: var(--colors-white);
}
._1nzuaz72tq:active {
  background: var(--colors-white);
}
._1nzuaz72tr {
  background: var(--colors-black);
}
._1nzuaz72ts:hover {
  background: var(--colors-black);
}
._1nzuaz72tt:focus {
  background: var(--colors-black);
}
._1nzuaz72tu:active {
  background: var(--colors-black);
}
._1nzuaz72tv {
  background: var(--colors-failure);
}
._1nzuaz72tw:hover {
  background: var(--colors-failure);
}
._1nzuaz72tx:focus {
  background: var(--colors-failure);
}
._1nzuaz72ty:active {
  background: var(--colors-failure);
}
._1nzuaz72tz {
  background: var(--colors-failure33);
}
._1nzuaz72u0:hover {
  background: var(--colors-failure33);
}
._1nzuaz72u1:focus {
  background: var(--colors-failure33);
}
._1nzuaz72u2:active {
  background: var(--colors-failure33);
}
._1nzuaz72u3 {
  background: var(--colors-red100);
}
._1nzuaz72u4:hover {
  background: var(--colors-red100);
}
._1nzuaz72u5:focus {
  background: var(--colors-red100);
}
._1nzuaz72u6:active {
  background: var(--colors-red100);
}
._1nzuaz72u7 {
  background: var(--colors-primary);
}
._1nzuaz72u8:hover {
  background: var(--colors-primary);
}
._1nzuaz72u9:focus {
  background: var(--colors-primary);
}
._1nzuaz72ua:active {
  background: var(--colors-primary);
}
._1nzuaz72ub {
  background: var(--colors-primary0f);
}
._1nzuaz72uc:hover {
  background: var(--colors-primary0f);
}
._1nzuaz72ud:focus {
  background: var(--colors-primary0f);
}
._1nzuaz72ue:active {
  background: var(--colors-primary0f);
}
._1nzuaz72uf {
  background: var(--colors-primary3D);
}
._1nzuaz72ug:hover {
  background: var(--colors-primary3D);
}
._1nzuaz72uh:focus {
  background: var(--colors-primary3D);
}
._1nzuaz72ui:active {
  background: var(--colors-primary3D);
}
._1nzuaz72uj {
  background: var(--colors-primaryBright);
}
._1nzuaz72uk:hover {
  background: var(--colors-primaryBright);
}
._1nzuaz72ul:focus {
  background: var(--colors-primaryBright);
}
._1nzuaz72um:active {
  background: var(--colors-primaryBright);
}
._1nzuaz72un {
  background: var(--colors-primaryDark);
}
._1nzuaz72uo:hover {
  background: var(--colors-primaryDark);
}
._1nzuaz72up:focus {
  background: var(--colors-primaryDark);
}
._1nzuaz72uq:active {
  background: var(--colors-primaryDark);
}
._1nzuaz72ur {
  background: var(--colors-success);
}
._1nzuaz72us:hover {
  background: var(--colors-success);
}
._1nzuaz72ut:focus {
  background: var(--colors-success);
}
._1nzuaz72uu:active {
  background: var(--colors-success);
}
._1nzuaz72uv {
  background: var(--colors-success19);
}
._1nzuaz72uw:hover {
  background: var(--colors-success19);
}
._1nzuaz72ux:focus {
  background: var(--colors-success19);
}
._1nzuaz72uy:active {
  background: var(--colors-success19);
}
._1nzuaz72uz {
  background: var(--colors-warning);
}
._1nzuaz72v0:hover {
  background: var(--colors-warning);
}
._1nzuaz72v1:focus {
  background: var(--colors-warning);
}
._1nzuaz72v2:active {
  background: var(--colors-warning);
}
._1nzuaz72v3 {
  background: var(--colors-warning2D);
}
._1nzuaz72v4:hover {
  background: var(--colors-warning2D);
}
._1nzuaz72v5:focus {
  background: var(--colors-warning2D);
}
._1nzuaz72v6:active {
  background: var(--colors-warning2D);
}
._1nzuaz72v7 {
  background: var(--colors-warning33);
}
._1nzuaz72v8:hover {
  background: var(--colors-warning33);
}
._1nzuaz72v9:focus {
  background: var(--colors-warning33);
}
._1nzuaz72va:active {
  background: var(--colors-warning33);
}
._1nzuaz72vb {
  background: var(--colors-yellow100);
}
._1nzuaz72vc:hover {
  background: var(--colors-yellow100);
}
._1nzuaz72vd:focus {
  background: var(--colors-yellow100);
}
._1nzuaz72ve:active {
  background: var(--colors-yellow100);
}
._1nzuaz72vf {
  background: var(--colors-gray50);
}
._1nzuaz72vg:hover {
  background: var(--colors-gray50);
}
._1nzuaz72vh:focus {
  background: var(--colors-gray50);
}
._1nzuaz72vi:active {
  background: var(--colors-gray50);
}
._1nzuaz72vj {
  background: var(--colors-gray100);
}
._1nzuaz72vk:hover {
  background: var(--colors-gray100);
}
._1nzuaz72vl:focus {
  background: var(--colors-gray100);
}
._1nzuaz72vm:active {
  background: var(--colors-gray100);
}
._1nzuaz72vn {
  background: var(--colors-gray200);
}
._1nzuaz72vo:hover {
  background: var(--colors-gray200);
}
._1nzuaz72vp:focus {
  background: var(--colors-gray200);
}
._1nzuaz72vq:active {
  background: var(--colors-gray200);
}
._1nzuaz72vr {
  background: var(--colors-gray300);
}
._1nzuaz72vs:hover {
  background: var(--colors-gray300);
}
._1nzuaz72vt:focus {
  background: var(--colors-gray300);
}
._1nzuaz72vu:active {
  background: var(--colors-gray300);
}
._1nzuaz72vv {
  background: var(--colors-gray390);
}
._1nzuaz72vw:hover {
  background: var(--colors-gray390);
}
._1nzuaz72vx:focus {
  background: var(--colors-gray390);
}
._1nzuaz72vy:active {
  background: var(--colors-gray390);
}
._1nzuaz72vz {
  background: var(--colors-gray400);
}
._1nzuaz72w0:hover {
  background: var(--colors-gray400);
}
._1nzuaz72w1:focus {
  background: var(--colors-gray400);
}
._1nzuaz72w2:active {
  background: var(--colors-gray400);
}
._1nzuaz72w3 {
  background: var(--colors-gray500);
}
._1nzuaz72w4:hover {
  background: var(--colors-gray500);
}
._1nzuaz72w5:focus {
  background: var(--colors-gray500);
}
._1nzuaz72w6:active {
  background: var(--colors-gray500);
}
._1nzuaz72w7 {
  background: var(--colors-gray700);
}
._1nzuaz72w8:hover {
  background: var(--colors-gray700);
}
._1nzuaz72w9:focus {
  background: var(--colors-gray700);
}
._1nzuaz72wa:active {
  background: var(--colors-gray700);
}
._1nzuaz72wb {
  background: var(--colors-gray800);
}
._1nzuaz72wc:hover {
  background: var(--colors-gray800);
}
._1nzuaz72wd:focus {
  background: var(--colors-gray800);
}
._1nzuaz72we:active {
  background: var(--colors-gray800);
}
._1nzuaz72wf {
  background: var(--colors-gray850);
}
._1nzuaz72wg:hover {
  background: var(--colors-gray850);
}
._1nzuaz72wh:focus {
  background: var(--colors-gray850);
}
._1nzuaz72wi:active {
  background: var(--colors-gray850);
}
._1nzuaz72wj {
  background: var(--colors-gray900);
}
._1nzuaz72wk:hover {
  background: var(--colors-gray900);
}
._1nzuaz72wl:focus {
  background: var(--colors-gray900);
}
._1nzuaz72wm:active {
  background: var(--colors-gray900);
}
._1nzuaz72wn {
  background: var(--colors-gray1000);
}
._1nzuaz72wo:hover {
  background: var(--colors-gray1000);
}
._1nzuaz72wp:focus {
  background: var(--colors-gray1000);
}
._1nzuaz72wq:active {
  background: var(--colors-gray1000);
}
._1nzuaz72wr {
  background: var(--colors-tintGray80);
}
._1nzuaz72ws:hover {
  background: var(--colors-tintGray80);
}
._1nzuaz72wt:focus {
  background: var(--colors-tintGray80);
}
._1nzuaz72wu:active {
  background: var(--colors-tintGray80);
}
._1nzuaz72wv {
  background: var(--colors-gradientPrimary);
}
._1nzuaz72ww:hover {
  background: var(--colors-gradientPrimary);
}
._1nzuaz72wx:focus {
  background: var(--colors-gradientPrimary);
}
._1nzuaz72wy:active {
  background: var(--colors-gradientPrimary);
}
._1nzuaz72wz {
  background: var(--colors-gradientPrimary10);
}
._1nzuaz72x0:hover {
  background: var(--colors-gradientPrimary10);
}
._1nzuaz72x1:focus {
  background: var(--colors-gradientPrimary10);
}
._1nzuaz72x2:active {
  background: var(--colors-gradientPrimary10);
}
._1nzuaz72x3 {
  background: var(--colors-gradientPrimary20);
}
._1nzuaz72x4:hover {
  background: var(--colors-gradientPrimary20);
}
._1nzuaz72x5:focus {
  background: var(--colors-gradientPrimary20);
}
._1nzuaz72x6:active {
  background: var(--colors-gradientPrimary20);
}
._1nzuaz72x7 {
  background: var(--colors-gradientPrimaryRadial);
}
._1nzuaz72x8:hover {
  background: var(--colors-gradientPrimaryRadial);
}
._1nzuaz72x9:focus {
  background: var(--colors-gradientPrimaryRadial);
}
._1nzuaz72xa:active {
  background: var(--colors-gradientPrimaryRadial);
}
._1nzuaz72xb {
  background: var(--colors-orange100);
}
._1nzuaz72xc:hover {
  background: var(--colors-orange100);
}
._1nzuaz72xd:focus {
  background: var(--colors-orange100);
}
._1nzuaz72xe:active {
  background: var(--colors-orange100);
}
._1nzuaz72xf {
  background: var(--colors-orange);
}
._1nzuaz72xg:hover {
  background: var(--colors-orange);
}
._1nzuaz72xh:focus {
  background: var(--colors-orange);
}
._1nzuaz72xi:active {
  background: var(--colors-orange);
}
._1nzuaz72xj {
  background: var(--colors-purple);
}
._1nzuaz72xk:hover {
  background: var(--colors-purple);
}
._1nzuaz72xl:focus {
  background: var(--colors-purple);
}
._1nzuaz72xm:active {
  background: var(--colors-purple);
}
._1nzuaz72xn {
  background: var(--colors-purple60);
}
._1nzuaz72xo:hover {
  background: var(--colors-purple60);
}
._1nzuaz72xp:focus {
  background: var(--colors-purple60);
}
._1nzuaz72xq:active {
  background: var(--colors-purple60);
}
._1nzuaz72xr {
  background: var(--colors-orangeDark);
}
._1nzuaz72xs:hover {
  background: var(--colors-orangeDark);
}
._1nzuaz72xt:focus {
  background: var(--colors-orangeDark);
}
._1nzuaz72xu:active {
  background: var(--colors-orangeDark);
}
._1nzuaz72xv {
  background: var(--colors-binance);
}
._1nzuaz72xw:hover {
  background: var(--colors-binance);
}
._1nzuaz72xx:focus {
  background: var(--colors-binance);
}
._1nzuaz72xy:active {
  background: var(--colors-binance);
}
._1nzuaz72xz {
  background: var(--colors-overlay);
}
._1nzuaz72y0:hover {
  background: var(--colors-overlay);
}
._1nzuaz72y1:focus {
  background: var(--colors-overlay);
}
._1nzuaz72y2:active {
  background: var(--colors-overlay);
}
._1nzuaz72y3 {
  background: var(--colors-gold);
}
._1nzuaz72y4:hover {
  background: var(--colors-gold);
}
._1nzuaz72y5:focus {
  background: var(--colors-gold);
}
._1nzuaz72y6:active {
  background: var(--colors-gold);
}
._1nzuaz72y7 {
  background: var(--colors-silver);
}
._1nzuaz72y8:hover {
  background: var(--colors-silver);
}
._1nzuaz72y9:focus {
  background: var(--colors-silver);
}
._1nzuaz72ya:active {
  background: var(--colors-silver);
}
._1nzuaz72yb {
  background: var(--colors-bronze);
}
._1nzuaz72yc:hover {
  background: var(--colors-bronze);
}
._1nzuaz72yd:focus {
  background: var(--colors-bronze);
}
._1nzuaz72ye:active {
  background: var(--colors-bronze);
}
._1nzuaz72yf {
  background: var(--colors-secondary);
}
._1nzuaz72yg:hover {
  background: var(--colors-secondary);
}
._1nzuaz72yh:focus {
  background: var(--colors-secondary);
}
._1nzuaz72yi:active {
  background: var(--colors-secondary);
}
._1nzuaz72yj {
  background: var(--colors-secondary80);
}
._1nzuaz72yk:hover {
  background: var(--colors-secondary80);
}
._1nzuaz72yl:focus {
  background: var(--colors-secondary80);
}
._1nzuaz72ym:active {
  background: var(--colors-secondary80);
}
._1nzuaz72yn {
  background: var(--colors-background);
}
._1nzuaz72yo:hover {
  background: var(--colors-background);
}
._1nzuaz72yp:focus {
  background: var(--colors-background);
}
._1nzuaz72yq:active {
  background: var(--colors-background);
}
._1nzuaz72yr {
  background: var(--colors-backgroundDisabled);
}
._1nzuaz72ys:hover {
  background: var(--colors-backgroundDisabled);
}
._1nzuaz72yt:focus {
  background: var(--colors-backgroundDisabled);
}
._1nzuaz72yu:active {
  background: var(--colors-backgroundDisabled);
}
._1nzuaz72yv {
  background: var(--colors-backgroundAlt);
}
._1nzuaz72yw:hover {
  background: var(--colors-backgroundAlt);
}
._1nzuaz72yx:focus {
  background: var(--colors-backgroundAlt);
}
._1nzuaz72yy:active {
  background: var(--colors-backgroundAlt);
}
._1nzuaz72yz {
  background: var(--colors-backgroundAlt2);
}
._1nzuaz72z0:hover {
  background: var(--colors-backgroundAlt2);
}
._1nzuaz72z1:focus {
  background: var(--colors-backgroundAlt2);
}
._1nzuaz72z2:active {
  background: var(--colors-backgroundAlt2);
}
._1nzuaz72z3 {
  background: var(--colors-cardBorder);
}
._1nzuaz72z4:hover {
  background: var(--colors-cardBorder);
}
._1nzuaz72z5:focus {
  background: var(--colors-cardBorder);
}
._1nzuaz72z6:active {
  background: var(--colors-cardBorder);
}
._1nzuaz72z7 {
  background: var(--colors-contrast);
}
._1nzuaz72z8:hover {
  background: var(--colors-contrast);
}
._1nzuaz72z9:focus {
  background: var(--colors-contrast);
}
._1nzuaz72za:active {
  background: var(--colors-contrast);
}
._1nzuaz72zb {
  background: var(--colors-dropdown);
}
._1nzuaz72zc:hover {
  background: var(--colors-dropdown);
}
._1nzuaz72zd:focus {
  background: var(--colors-dropdown);
}
._1nzuaz72ze:active {
  background: var(--colors-dropdown);
}
._1nzuaz72zf {
  background: var(--colors-dropdownDeep);
}
._1nzuaz72zg:hover {
  background: var(--colors-dropdownDeep);
}
._1nzuaz72zh:focus {
  background: var(--colors-dropdownDeep);
}
._1nzuaz72zi:active {
  background: var(--colors-dropdownDeep);
}
._1nzuaz72zj {
  background: var(--colors-invertedContrast);
}
._1nzuaz72zk:hover {
  background: var(--colors-invertedContrast);
}
._1nzuaz72zl:focus {
  background: var(--colors-invertedContrast);
}
._1nzuaz72zm:active {
  background: var(--colors-invertedContrast);
}
._1nzuaz72zn {
  background: var(--colors-input);
}
._1nzuaz72zo:hover {
  background: var(--colors-input);
}
._1nzuaz72zp:focus {
  background: var(--colors-input);
}
._1nzuaz72zq:active {
  background: var(--colors-input);
}
._1nzuaz72zr {
  background: var(--colors-inputSecondary);
}
._1nzuaz72zs:hover {
  background: var(--colors-inputSecondary);
}
._1nzuaz72zt:focus {
  background: var(--colors-inputSecondary);
}
._1nzuaz72zu:active {
  background: var(--colors-inputSecondary);
}
._1nzuaz72zv {
  background: var(--colors-tertiary);
}
._1nzuaz72zw:hover {
  background: var(--colors-tertiary);
}
._1nzuaz72zx:focus {
  background: var(--colors-tertiary);
}
._1nzuaz72zy:active {
  background: var(--colors-tertiary);
}
._1nzuaz72zz {
  background: var(--colors-text);
}
._1nzuaz7300:hover {
  background: var(--colors-text);
}
._1nzuaz7301:focus {
  background: var(--colors-text);
}
._1nzuaz7302:active {
  background: var(--colors-text);
}
._1nzuaz7303 {
  background: var(--colors-text99);
}
._1nzuaz7304:hover {
  background: var(--colors-text99);
}
._1nzuaz7305:focus {
  background: var(--colors-text99);
}
._1nzuaz7306:active {
  background: var(--colors-text99);
}
._1nzuaz7307 {
  background: var(--colors-textDisabled);
}
._1nzuaz7308:hover {
  background: var(--colors-textDisabled);
}
._1nzuaz7309:focus {
  background: var(--colors-textDisabled);
}
._1nzuaz730a:active {
  background: var(--colors-textDisabled);
}
._1nzuaz730b {
  background: var(--colors-textSubtle);
}
._1nzuaz730c:hover {
  background: var(--colors-textSubtle);
}
._1nzuaz730d:focus {
  background: var(--colors-textSubtle);
}
._1nzuaz730e:active {
  background: var(--colors-textSubtle);
}
._1nzuaz730f {
  background: var(--colors-disabled);
}
._1nzuaz730g:hover {
  background: var(--colors-disabled);
}
._1nzuaz730h:focus {
  background: var(--colors-disabled);
}
._1nzuaz730i:active {
  background: var(--colors-disabled);
}
._1nzuaz730j {
  background: var(--colors-gradientBubblegum);
}
._1nzuaz730k:hover {
  background: var(--colors-gradientBubblegum);
}
._1nzuaz730l:focus {
  background: var(--colors-gradientBubblegum);
}
._1nzuaz730m:active {
  background: var(--colors-gradientBubblegum);
}
._1nzuaz730n {
  background: var(--colors-gradientInverseBubblegum);
}
._1nzuaz730o:hover {
  background: var(--colors-gradientInverseBubblegum);
}
._1nzuaz730p:focus {
  background: var(--colors-gradientInverseBubblegum);
}
._1nzuaz730q:active {
  background: var(--colors-gradientInverseBubblegum);
}
._1nzuaz730r {
  background: var(--colors-gradientCardHeader);
}
._1nzuaz730s:hover {
  background: var(--colors-gradientCardHeader);
}
._1nzuaz730t:focus {
  background: var(--colors-gradientCardHeader);
}
._1nzuaz730u:active {
  background: var(--colors-gradientCardHeader);
}
._1nzuaz730v {
  background: var(--colors-gradientBlue);
}
._1nzuaz730w:hover {
  background: var(--colors-gradientBlue);
}
._1nzuaz730x:focus {
  background: var(--colors-gradientBlue);
}
._1nzuaz730y:active {
  background: var(--colors-gradientBlue);
}
._1nzuaz730z {
  background: var(--colors-gradientViolet);
}
._1nzuaz7310:hover {
  background: var(--colors-gradientViolet);
}
._1nzuaz7311:focus {
  background: var(--colors-gradientViolet);
}
._1nzuaz7312:active {
  background: var(--colors-gradientViolet);
}
._1nzuaz7313 {
  background: var(--colors-gradientVioletAlt);
}
._1nzuaz7314:hover {
  background: var(--colors-gradientVioletAlt);
}
._1nzuaz7315:focus {
  background: var(--colors-gradientVioletAlt);
}
._1nzuaz7316:active {
  background: var(--colors-gradientVioletAlt);
}
._1nzuaz7317 {
  background: var(--colors-gradientGold);
}
._1nzuaz7318:hover {
  background: var(--colors-gradientGold);
}
._1nzuaz7319:focus {
  background: var(--colors-gradientGold);
}
._1nzuaz731a:active {
  background: var(--colors-gradientGold);
}
._1nzuaz731b {
  background: var(--colors-gradientBold);
}
._1nzuaz731c:hover {
  background: var(--colors-gradientBold);
}
._1nzuaz731d:focus {
  background: var(--colors-gradientBold);
}
._1nzuaz731e:active {
  background: var(--colors-gradientBold);
}
._1nzuaz731f {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz731g:hover {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz731h:focus {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz731i:active {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz731j {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz731k:hover {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz731l:focus {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz731m:active {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz731n {
  background-color: var(--colors-white50);
}
._1nzuaz731o:hover {
  background-color: var(--colors-white50);
}
._1nzuaz731p:focus {
  background-color: var(--colors-white50);
}
._1nzuaz731q:active {
  background-color: var(--colors-white50);
}
._1nzuaz731r {
  background-color: var(--colors-white100);
}
._1nzuaz731s:hover {
  background-color: var(--colors-white100);
}
._1nzuaz731t:focus {
  background-color: var(--colors-white100);
}
._1nzuaz731u:active {
  background-color: var(--colors-white100);
}
._1nzuaz731v {
  background-color: var(--colors-white200);
}
._1nzuaz731w:hover {
  background-color: var(--colors-white200);
}
._1nzuaz731x:focus {
  background-color: var(--colors-white200);
}
._1nzuaz731y:active {
  background-color: var(--colors-white200);
}
._1nzuaz731z {
  background-color: var(--colors-white300);
}
._1nzuaz7320:hover {
  background-color: var(--colors-white300);
}
._1nzuaz7321:focus {
  background-color: var(--colors-white300);
}
._1nzuaz7322:active {
  background-color: var(--colors-white300);
}
._1nzuaz7323 {
  background-color: var(--colors-white500);
}
._1nzuaz7324:hover {
  background-color: var(--colors-white500);
}
._1nzuaz7325:focus {
  background-color: var(--colors-white500);
}
._1nzuaz7326:active {
  background-color: var(--colors-white500);
}
._1nzuaz7327 {
  background-color: var(--colors-white800);
}
._1nzuaz7328:hover {
  background-color: var(--colors-white800);
}
._1nzuaz7329:focus {
  background-color: var(--colors-white800);
}
._1nzuaz732a:active {
  background-color: var(--colors-white800);
}
._1nzuaz732b {
  background-color: var(--colors-white);
}
._1nzuaz732c:hover {
  background-color: var(--colors-white);
}
._1nzuaz732d:focus {
  background-color: var(--colors-white);
}
._1nzuaz732e:active {
  background-color: var(--colors-white);
}
._1nzuaz732f {
  background-color: var(--colors-black);
}
._1nzuaz732g:hover {
  background-color: var(--colors-black);
}
._1nzuaz732h:focus {
  background-color: var(--colors-black);
}
._1nzuaz732i:active {
  background-color: var(--colors-black);
}
._1nzuaz732j {
  background-color: var(--colors-failure);
}
._1nzuaz732k:hover {
  background-color: var(--colors-failure);
}
._1nzuaz732l:focus {
  background-color: var(--colors-failure);
}
._1nzuaz732m:active {
  background-color: var(--colors-failure);
}
._1nzuaz732n {
  background-color: var(--colors-failure33);
}
._1nzuaz732o:hover {
  background-color: var(--colors-failure33);
}
._1nzuaz732p:focus {
  background-color: var(--colors-failure33);
}
._1nzuaz732q:active {
  background-color: var(--colors-failure33);
}
._1nzuaz732r {
  background-color: var(--colors-red100);
}
._1nzuaz732s:hover {
  background-color: var(--colors-red100);
}
._1nzuaz732t:focus {
  background-color: var(--colors-red100);
}
._1nzuaz732u:active {
  background-color: var(--colors-red100);
}
._1nzuaz732v {
  background-color: var(--colors-primary);
}
._1nzuaz732w:hover {
  background-color: var(--colors-primary);
}
._1nzuaz732x:focus {
  background-color: var(--colors-primary);
}
._1nzuaz732y:active {
  background-color: var(--colors-primary);
}
._1nzuaz732z {
  background-color: var(--colors-primary0f);
}
._1nzuaz7330:hover {
  background-color: var(--colors-primary0f);
}
._1nzuaz7331:focus {
  background-color: var(--colors-primary0f);
}
._1nzuaz7332:active {
  background-color: var(--colors-primary0f);
}
._1nzuaz7333 {
  background-color: var(--colors-primary3D);
}
._1nzuaz7334:hover {
  background-color: var(--colors-primary3D);
}
._1nzuaz7335:focus {
  background-color: var(--colors-primary3D);
}
._1nzuaz7336:active {
  background-color: var(--colors-primary3D);
}
._1nzuaz7337 {
  background-color: var(--colors-primaryBright);
}
._1nzuaz7338:hover {
  background-color: var(--colors-primaryBright);
}
._1nzuaz7339:focus {
  background-color: var(--colors-primaryBright);
}
._1nzuaz733a:active {
  background-color: var(--colors-primaryBright);
}
._1nzuaz733b {
  background-color: var(--colors-primaryDark);
}
._1nzuaz733c:hover {
  background-color: var(--colors-primaryDark);
}
._1nzuaz733d:focus {
  background-color: var(--colors-primaryDark);
}
._1nzuaz733e:active {
  background-color: var(--colors-primaryDark);
}
._1nzuaz733f {
  background-color: var(--colors-success);
}
._1nzuaz733g:hover {
  background-color: var(--colors-success);
}
._1nzuaz733h:focus {
  background-color: var(--colors-success);
}
._1nzuaz733i:active {
  background-color: var(--colors-success);
}
._1nzuaz733j {
  background-color: var(--colors-success19);
}
._1nzuaz733k:hover {
  background-color: var(--colors-success19);
}
._1nzuaz733l:focus {
  background-color: var(--colors-success19);
}
._1nzuaz733m:active {
  background-color: var(--colors-success19);
}
._1nzuaz733n {
  background-color: var(--colors-warning);
}
._1nzuaz733o:hover {
  background-color: var(--colors-warning);
}
._1nzuaz733p:focus {
  background-color: var(--colors-warning);
}
._1nzuaz733q:active {
  background-color: var(--colors-warning);
}
._1nzuaz733r {
  background-color: var(--colors-warning2D);
}
._1nzuaz733s:hover {
  background-color: var(--colors-warning2D);
}
._1nzuaz733t:focus {
  background-color: var(--colors-warning2D);
}
._1nzuaz733u:active {
  background-color: var(--colors-warning2D);
}
._1nzuaz733v {
  background-color: var(--colors-warning33);
}
._1nzuaz733w:hover {
  background-color: var(--colors-warning33);
}
._1nzuaz733x:focus {
  background-color: var(--colors-warning33);
}
._1nzuaz733y:active {
  background-color: var(--colors-warning33);
}
._1nzuaz733z {
  background-color: var(--colors-yellow100);
}
._1nzuaz7340:hover {
  background-color: var(--colors-yellow100);
}
._1nzuaz7341:focus {
  background-color: var(--colors-yellow100);
}
._1nzuaz7342:active {
  background-color: var(--colors-yellow100);
}
._1nzuaz7343 {
  background-color: var(--colors-gray50);
}
._1nzuaz7344:hover {
  background-color: var(--colors-gray50);
}
._1nzuaz7345:focus {
  background-color: var(--colors-gray50);
}
._1nzuaz7346:active {
  background-color: var(--colors-gray50);
}
._1nzuaz7347 {
  background-color: var(--colors-gray100);
}
._1nzuaz7348:hover {
  background-color: var(--colors-gray100);
}
._1nzuaz7349:focus {
  background-color: var(--colors-gray100);
}
._1nzuaz734a:active {
  background-color: var(--colors-gray100);
}
._1nzuaz734b {
  background-color: var(--colors-gray200);
}
._1nzuaz734c:hover {
  background-color: var(--colors-gray200);
}
._1nzuaz734d:focus {
  background-color: var(--colors-gray200);
}
._1nzuaz734e:active {
  background-color: var(--colors-gray200);
}
._1nzuaz734f {
  background-color: var(--colors-gray300);
}
._1nzuaz734g:hover {
  background-color: var(--colors-gray300);
}
._1nzuaz734h:focus {
  background-color: var(--colors-gray300);
}
._1nzuaz734i:active {
  background-color: var(--colors-gray300);
}
._1nzuaz734j {
  background-color: var(--colors-gray390);
}
._1nzuaz734k:hover {
  background-color: var(--colors-gray390);
}
._1nzuaz734l:focus {
  background-color: var(--colors-gray390);
}
._1nzuaz734m:active {
  background-color: var(--colors-gray390);
}
._1nzuaz734n {
  background-color: var(--colors-gray400);
}
._1nzuaz734o:hover {
  background-color: var(--colors-gray400);
}
._1nzuaz734p:focus {
  background-color: var(--colors-gray400);
}
._1nzuaz734q:active {
  background-color: var(--colors-gray400);
}
._1nzuaz734r {
  background-color: var(--colors-gray500);
}
._1nzuaz734s:hover {
  background-color: var(--colors-gray500);
}
._1nzuaz734t:focus {
  background-color: var(--colors-gray500);
}
._1nzuaz734u:active {
  background-color: var(--colors-gray500);
}
._1nzuaz734v {
  background-color: var(--colors-gray700);
}
._1nzuaz734w:hover {
  background-color: var(--colors-gray700);
}
._1nzuaz734x:focus {
  background-color: var(--colors-gray700);
}
._1nzuaz734y:active {
  background-color: var(--colors-gray700);
}
._1nzuaz734z {
  background-color: var(--colors-gray800);
}
._1nzuaz7350:hover {
  background-color: var(--colors-gray800);
}
._1nzuaz7351:focus {
  background-color: var(--colors-gray800);
}
._1nzuaz7352:active {
  background-color: var(--colors-gray800);
}
._1nzuaz7353 {
  background-color: var(--colors-gray850);
}
._1nzuaz7354:hover {
  background-color: var(--colors-gray850);
}
._1nzuaz7355:focus {
  background-color: var(--colors-gray850);
}
._1nzuaz7356:active {
  background-color: var(--colors-gray850);
}
._1nzuaz7357 {
  background-color: var(--colors-gray900);
}
._1nzuaz7358:hover {
  background-color: var(--colors-gray900);
}
._1nzuaz7359:focus {
  background-color: var(--colors-gray900);
}
._1nzuaz735a:active {
  background-color: var(--colors-gray900);
}
._1nzuaz735b {
  background-color: var(--colors-gray1000);
}
._1nzuaz735c:hover {
  background-color: var(--colors-gray1000);
}
._1nzuaz735d:focus {
  background-color: var(--colors-gray1000);
}
._1nzuaz735e:active {
  background-color: var(--colors-gray1000);
}
._1nzuaz735f {
  background-color: var(--colors-tintGray80);
}
._1nzuaz735g:hover {
  background-color: var(--colors-tintGray80);
}
._1nzuaz735h:focus {
  background-color: var(--colors-tintGray80);
}
._1nzuaz735i:active {
  background-color: var(--colors-tintGray80);
}
._1nzuaz735j {
  background-color: var(--colors-gradientPrimary);
}
._1nzuaz735k:hover {
  background-color: var(--colors-gradientPrimary);
}
._1nzuaz735l:focus {
  background-color: var(--colors-gradientPrimary);
}
._1nzuaz735m:active {
  background-color: var(--colors-gradientPrimary);
}
._1nzuaz735n {
  background-color: var(--colors-gradientPrimary10);
}
._1nzuaz735o:hover {
  background-color: var(--colors-gradientPrimary10);
}
._1nzuaz735p:focus {
  background-color: var(--colors-gradientPrimary10);
}
._1nzuaz735q:active {
  background-color: var(--colors-gradientPrimary10);
}
._1nzuaz735r {
  background-color: var(--colors-gradientPrimary20);
}
._1nzuaz735s:hover {
  background-color: var(--colors-gradientPrimary20);
}
._1nzuaz735t:focus {
  background-color: var(--colors-gradientPrimary20);
}
._1nzuaz735u:active {
  background-color: var(--colors-gradientPrimary20);
}
._1nzuaz735v {
  background-color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz735w:hover {
  background-color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz735x:focus {
  background-color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz735y:active {
  background-color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz735z {
  background-color: var(--colors-orange100);
}
._1nzuaz7360:hover {
  background-color: var(--colors-orange100);
}
._1nzuaz7361:focus {
  background-color: var(--colors-orange100);
}
._1nzuaz7362:active {
  background-color: var(--colors-orange100);
}
._1nzuaz7363 {
  background-color: var(--colors-orange);
}
._1nzuaz7364:hover {
  background-color: var(--colors-orange);
}
._1nzuaz7365:focus {
  background-color: var(--colors-orange);
}
._1nzuaz7366:active {
  background-color: var(--colors-orange);
}
._1nzuaz7367 {
  background-color: var(--colors-purple);
}
._1nzuaz7368:hover {
  background-color: var(--colors-purple);
}
._1nzuaz7369:focus {
  background-color: var(--colors-purple);
}
._1nzuaz736a:active {
  background-color: var(--colors-purple);
}
._1nzuaz736b {
  background-color: var(--colors-purple60);
}
._1nzuaz736c:hover {
  background-color: var(--colors-purple60);
}
._1nzuaz736d:focus {
  background-color: var(--colors-purple60);
}
._1nzuaz736e:active {
  background-color: var(--colors-purple60);
}
._1nzuaz736f {
  background-color: var(--colors-orangeDark);
}
._1nzuaz736g:hover {
  background-color: var(--colors-orangeDark);
}
._1nzuaz736h:focus {
  background-color: var(--colors-orangeDark);
}
._1nzuaz736i:active {
  background-color: var(--colors-orangeDark);
}
._1nzuaz736j {
  background-color: var(--colors-binance);
}
._1nzuaz736k:hover {
  background-color: var(--colors-binance);
}
._1nzuaz736l:focus {
  background-color: var(--colors-binance);
}
._1nzuaz736m:active {
  background-color: var(--colors-binance);
}
._1nzuaz736n {
  background-color: var(--colors-overlay);
}
._1nzuaz736o:hover {
  background-color: var(--colors-overlay);
}
._1nzuaz736p:focus {
  background-color: var(--colors-overlay);
}
._1nzuaz736q:active {
  background-color: var(--colors-overlay);
}
._1nzuaz736r {
  background-color: var(--colors-gold);
}
._1nzuaz736s:hover {
  background-color: var(--colors-gold);
}
._1nzuaz736t:focus {
  background-color: var(--colors-gold);
}
._1nzuaz736u:active {
  background-color: var(--colors-gold);
}
._1nzuaz736v {
  background-color: var(--colors-silver);
}
._1nzuaz736w:hover {
  background-color: var(--colors-silver);
}
._1nzuaz736x:focus {
  background-color: var(--colors-silver);
}
._1nzuaz736y:active {
  background-color: var(--colors-silver);
}
._1nzuaz736z {
  background-color: var(--colors-bronze);
}
._1nzuaz7370:hover {
  background-color: var(--colors-bronze);
}
._1nzuaz7371:focus {
  background-color: var(--colors-bronze);
}
._1nzuaz7372:active {
  background-color: var(--colors-bronze);
}
._1nzuaz7373 {
  background-color: var(--colors-secondary);
}
._1nzuaz7374:hover {
  background-color: var(--colors-secondary);
}
._1nzuaz7375:focus {
  background-color: var(--colors-secondary);
}
._1nzuaz7376:active {
  background-color: var(--colors-secondary);
}
._1nzuaz7377 {
  background-color: var(--colors-secondary80);
}
._1nzuaz7378:hover {
  background-color: var(--colors-secondary80);
}
._1nzuaz7379:focus {
  background-color: var(--colors-secondary80);
}
._1nzuaz737a:active {
  background-color: var(--colors-secondary80);
}
._1nzuaz737b {
  background-color: var(--colors-background);
}
._1nzuaz737c:hover {
  background-color: var(--colors-background);
}
._1nzuaz737d:focus {
  background-color: var(--colors-background);
}
._1nzuaz737e:active {
  background-color: var(--colors-background);
}
._1nzuaz737f {
  background-color: var(--colors-backgroundDisabled);
}
._1nzuaz737g:hover {
  background-color: var(--colors-backgroundDisabled);
}
._1nzuaz737h:focus {
  background-color: var(--colors-backgroundDisabled);
}
._1nzuaz737i:active {
  background-color: var(--colors-backgroundDisabled);
}
._1nzuaz737j {
  background-color: var(--colors-backgroundAlt);
}
._1nzuaz737k:hover {
  background-color: var(--colors-backgroundAlt);
}
._1nzuaz737l:focus {
  background-color: var(--colors-backgroundAlt);
}
._1nzuaz737m:active {
  background-color: var(--colors-backgroundAlt);
}
._1nzuaz737n {
  background-color: var(--colors-backgroundAlt2);
}
._1nzuaz737o:hover {
  background-color: var(--colors-backgroundAlt2);
}
._1nzuaz737p:focus {
  background-color: var(--colors-backgroundAlt2);
}
._1nzuaz737q:active {
  background-color: var(--colors-backgroundAlt2);
}
._1nzuaz737r {
  background-color: var(--colors-cardBorder);
}
._1nzuaz737s:hover {
  background-color: var(--colors-cardBorder);
}
._1nzuaz737t:focus {
  background-color: var(--colors-cardBorder);
}
._1nzuaz737u:active {
  background-color: var(--colors-cardBorder);
}
._1nzuaz737v {
  background-color: var(--colors-contrast);
}
._1nzuaz737w:hover {
  background-color: var(--colors-contrast);
}
._1nzuaz737x:focus {
  background-color: var(--colors-contrast);
}
._1nzuaz737y:active {
  background-color: var(--colors-contrast);
}
._1nzuaz737z {
  background-color: var(--colors-dropdown);
}
._1nzuaz7380:hover {
  background-color: var(--colors-dropdown);
}
._1nzuaz7381:focus {
  background-color: var(--colors-dropdown);
}
._1nzuaz7382:active {
  background-color: var(--colors-dropdown);
}
._1nzuaz7383 {
  background-color: var(--colors-dropdownDeep);
}
._1nzuaz7384:hover {
  background-color: var(--colors-dropdownDeep);
}
._1nzuaz7385:focus {
  background-color: var(--colors-dropdownDeep);
}
._1nzuaz7386:active {
  background-color: var(--colors-dropdownDeep);
}
._1nzuaz7387 {
  background-color: var(--colors-invertedContrast);
}
._1nzuaz7388:hover {
  background-color: var(--colors-invertedContrast);
}
._1nzuaz7389:focus {
  background-color: var(--colors-invertedContrast);
}
._1nzuaz738a:active {
  background-color: var(--colors-invertedContrast);
}
._1nzuaz738b {
  background-color: var(--colors-input);
}
._1nzuaz738c:hover {
  background-color: var(--colors-input);
}
._1nzuaz738d:focus {
  background-color: var(--colors-input);
}
._1nzuaz738e:active {
  background-color: var(--colors-input);
}
._1nzuaz738f {
  background-color: var(--colors-inputSecondary);
}
._1nzuaz738g:hover {
  background-color: var(--colors-inputSecondary);
}
._1nzuaz738h:focus {
  background-color: var(--colors-inputSecondary);
}
._1nzuaz738i:active {
  background-color: var(--colors-inputSecondary);
}
._1nzuaz738j {
  background-color: var(--colors-tertiary);
}
._1nzuaz738k:hover {
  background-color: var(--colors-tertiary);
}
._1nzuaz738l:focus {
  background-color: var(--colors-tertiary);
}
._1nzuaz738m:active {
  background-color: var(--colors-tertiary);
}
._1nzuaz738n {
  background-color: var(--colors-text);
}
._1nzuaz738o:hover {
  background-color: var(--colors-text);
}
._1nzuaz738p:focus {
  background-color: var(--colors-text);
}
._1nzuaz738q:active {
  background-color: var(--colors-text);
}
._1nzuaz738r {
  background-color: var(--colors-text99);
}
._1nzuaz738s:hover {
  background-color: var(--colors-text99);
}
._1nzuaz738t:focus {
  background-color: var(--colors-text99);
}
._1nzuaz738u:active {
  background-color: var(--colors-text99);
}
._1nzuaz738v {
  background-color: var(--colors-textDisabled);
}
._1nzuaz738w:hover {
  background-color: var(--colors-textDisabled);
}
._1nzuaz738x:focus {
  background-color: var(--colors-textDisabled);
}
._1nzuaz738y:active {
  background-color: var(--colors-textDisabled);
}
._1nzuaz738z {
  background-color: var(--colors-textSubtle);
}
._1nzuaz7390:hover {
  background-color: var(--colors-textSubtle);
}
._1nzuaz7391:focus {
  background-color: var(--colors-textSubtle);
}
._1nzuaz7392:active {
  background-color: var(--colors-textSubtle);
}
._1nzuaz7393 {
  background-color: var(--colors-disabled);
}
._1nzuaz7394:hover {
  background-color: var(--colors-disabled);
}
._1nzuaz7395:focus {
  background-color: var(--colors-disabled);
}
._1nzuaz7396:active {
  background-color: var(--colors-disabled);
}
._1nzuaz7397 {
  background-color: var(--colors-gradientBubblegum);
}
._1nzuaz7398:hover {
  background-color: var(--colors-gradientBubblegum);
}
._1nzuaz7399:focus {
  background-color: var(--colors-gradientBubblegum);
}
._1nzuaz739a:active {
  background-color: var(--colors-gradientBubblegum);
}
._1nzuaz739b {
  background-color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz739c:hover {
  background-color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz739d:focus {
  background-color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz739e:active {
  background-color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz739f {
  background-color: var(--colors-gradientCardHeader);
}
._1nzuaz739g:hover {
  background-color: var(--colors-gradientCardHeader);
}
._1nzuaz739h:focus {
  background-color: var(--colors-gradientCardHeader);
}
._1nzuaz739i:active {
  background-color: var(--colors-gradientCardHeader);
}
._1nzuaz739j {
  background-color: var(--colors-gradientBlue);
}
._1nzuaz739k:hover {
  background-color: var(--colors-gradientBlue);
}
._1nzuaz739l:focus {
  background-color: var(--colors-gradientBlue);
}
._1nzuaz739m:active {
  background-color: var(--colors-gradientBlue);
}
._1nzuaz739n {
  background-color: var(--colors-gradientViolet);
}
._1nzuaz739o:hover {
  background-color: var(--colors-gradientViolet);
}
._1nzuaz739p:focus {
  background-color: var(--colors-gradientViolet);
}
._1nzuaz739q:active {
  background-color: var(--colors-gradientViolet);
}
._1nzuaz739r {
  background-color: var(--colors-gradientVioletAlt);
}
._1nzuaz739s:hover {
  background-color: var(--colors-gradientVioletAlt);
}
._1nzuaz739t:focus {
  background-color: var(--colors-gradientVioletAlt);
}
._1nzuaz739u:active {
  background-color: var(--colors-gradientVioletAlt);
}
._1nzuaz739v {
  background-color: var(--colors-gradientGold);
}
._1nzuaz739w:hover {
  background-color: var(--colors-gradientGold);
}
._1nzuaz739x:focus {
  background-color: var(--colors-gradientGold);
}
._1nzuaz739y:active {
  background-color: var(--colors-gradientGold);
}
._1nzuaz739z {
  background-color: var(--colors-gradientBold);
}
._1nzuaz73a0:hover {
  background-color: var(--colors-gradientBold);
}
._1nzuaz73a1:focus {
  background-color: var(--colors-gradientBold);
}
._1nzuaz73a2:active {
  background-color: var(--colors-gradientBold);
}
._1nzuaz73a3 {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz73a4:hover {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz73a5:focus {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz73a6:active {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz73a7 {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz73a8:hover {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz73a9:focus {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz73aa:active {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz73ab {
  border-color: var(--colors-white50);
}
._1nzuaz73ac:hover {
  border-color: var(--colors-white50);
}
._1nzuaz73ad:focus {
  border-color: var(--colors-white50);
}
._1nzuaz73ae:active {
  border-color: var(--colors-white50);
}
._1nzuaz73af {
  border-color: var(--colors-white100);
}
._1nzuaz73ag:hover {
  border-color: var(--colors-white100);
}
._1nzuaz73ah:focus {
  border-color: var(--colors-white100);
}
._1nzuaz73ai:active {
  border-color: var(--colors-white100);
}
._1nzuaz73aj {
  border-color: var(--colors-white200);
}
._1nzuaz73ak:hover {
  border-color: var(--colors-white200);
}
._1nzuaz73al:focus {
  border-color: var(--colors-white200);
}
._1nzuaz73am:active {
  border-color: var(--colors-white200);
}
._1nzuaz73an {
  border-color: var(--colors-white300);
}
._1nzuaz73ao:hover {
  border-color: var(--colors-white300);
}
._1nzuaz73ap:focus {
  border-color: var(--colors-white300);
}
._1nzuaz73aq:active {
  border-color: var(--colors-white300);
}
._1nzuaz73ar {
  border-color: var(--colors-white500);
}
._1nzuaz73as:hover {
  border-color: var(--colors-white500);
}
._1nzuaz73at:focus {
  border-color: var(--colors-white500);
}
._1nzuaz73au:active {
  border-color: var(--colors-white500);
}
._1nzuaz73av {
  border-color: var(--colors-white800);
}
._1nzuaz73aw:hover {
  border-color: var(--colors-white800);
}
._1nzuaz73ax:focus {
  border-color: var(--colors-white800);
}
._1nzuaz73ay:active {
  border-color: var(--colors-white800);
}
._1nzuaz73az {
  border-color: var(--colors-white);
}
._1nzuaz73b0:hover {
  border-color: var(--colors-white);
}
._1nzuaz73b1:focus {
  border-color: var(--colors-white);
}
._1nzuaz73b2:active {
  border-color: var(--colors-white);
}
._1nzuaz73b3 {
  border-color: var(--colors-black);
}
._1nzuaz73b4:hover {
  border-color: var(--colors-black);
}
._1nzuaz73b5:focus {
  border-color: var(--colors-black);
}
._1nzuaz73b6:active {
  border-color: var(--colors-black);
}
._1nzuaz73b7 {
  border-color: var(--colors-failure);
}
._1nzuaz73b8:hover {
  border-color: var(--colors-failure);
}
._1nzuaz73b9:focus {
  border-color: var(--colors-failure);
}
._1nzuaz73ba:active {
  border-color: var(--colors-failure);
}
._1nzuaz73bb {
  border-color: var(--colors-failure33);
}
._1nzuaz73bc:hover {
  border-color: var(--colors-failure33);
}
._1nzuaz73bd:focus {
  border-color: var(--colors-failure33);
}
._1nzuaz73be:active {
  border-color: var(--colors-failure33);
}
._1nzuaz73bf {
  border-color: var(--colors-red100);
}
._1nzuaz73bg:hover {
  border-color: var(--colors-red100);
}
._1nzuaz73bh:focus {
  border-color: var(--colors-red100);
}
._1nzuaz73bi:active {
  border-color: var(--colors-red100);
}
._1nzuaz73bj {
  border-color: var(--colors-primary);
}
._1nzuaz73bk:hover {
  border-color: var(--colors-primary);
}
._1nzuaz73bl:focus {
  border-color: var(--colors-primary);
}
._1nzuaz73bm:active {
  border-color: var(--colors-primary);
}
._1nzuaz73bn {
  border-color: var(--colors-primary0f);
}
._1nzuaz73bo:hover {
  border-color: var(--colors-primary0f);
}
._1nzuaz73bp:focus {
  border-color: var(--colors-primary0f);
}
._1nzuaz73bq:active {
  border-color: var(--colors-primary0f);
}
._1nzuaz73br {
  border-color: var(--colors-primary3D);
}
._1nzuaz73bs:hover {
  border-color: var(--colors-primary3D);
}
._1nzuaz73bt:focus {
  border-color: var(--colors-primary3D);
}
._1nzuaz73bu:active {
  border-color: var(--colors-primary3D);
}
._1nzuaz73bv {
  border-color: var(--colors-primaryBright);
}
._1nzuaz73bw:hover {
  border-color: var(--colors-primaryBright);
}
._1nzuaz73bx:focus {
  border-color: var(--colors-primaryBright);
}
._1nzuaz73by:active {
  border-color: var(--colors-primaryBright);
}
._1nzuaz73bz {
  border-color: var(--colors-primaryDark);
}
._1nzuaz73c0:hover {
  border-color: var(--colors-primaryDark);
}
._1nzuaz73c1:focus {
  border-color: var(--colors-primaryDark);
}
._1nzuaz73c2:active {
  border-color: var(--colors-primaryDark);
}
._1nzuaz73c3 {
  border-color: var(--colors-success);
}
._1nzuaz73c4:hover {
  border-color: var(--colors-success);
}
._1nzuaz73c5:focus {
  border-color: var(--colors-success);
}
._1nzuaz73c6:active {
  border-color: var(--colors-success);
}
._1nzuaz73c7 {
  border-color: var(--colors-success19);
}
._1nzuaz73c8:hover {
  border-color: var(--colors-success19);
}
._1nzuaz73c9:focus {
  border-color: var(--colors-success19);
}
._1nzuaz73ca:active {
  border-color: var(--colors-success19);
}
._1nzuaz73cb {
  border-color: var(--colors-warning);
}
._1nzuaz73cc:hover {
  border-color: var(--colors-warning);
}
._1nzuaz73cd:focus {
  border-color: var(--colors-warning);
}
._1nzuaz73ce:active {
  border-color: var(--colors-warning);
}
._1nzuaz73cf {
  border-color: var(--colors-warning2D);
}
._1nzuaz73cg:hover {
  border-color: var(--colors-warning2D);
}
._1nzuaz73ch:focus {
  border-color: var(--colors-warning2D);
}
._1nzuaz73ci:active {
  border-color: var(--colors-warning2D);
}
._1nzuaz73cj {
  border-color: var(--colors-warning33);
}
._1nzuaz73ck:hover {
  border-color: var(--colors-warning33);
}
._1nzuaz73cl:focus {
  border-color: var(--colors-warning33);
}
._1nzuaz73cm:active {
  border-color: var(--colors-warning33);
}
._1nzuaz73cn {
  border-color: var(--colors-yellow100);
}
._1nzuaz73co:hover {
  border-color: var(--colors-yellow100);
}
._1nzuaz73cp:focus {
  border-color: var(--colors-yellow100);
}
._1nzuaz73cq:active {
  border-color: var(--colors-yellow100);
}
._1nzuaz73cr {
  border-color: var(--colors-gray50);
}
._1nzuaz73cs:hover {
  border-color: var(--colors-gray50);
}
._1nzuaz73ct:focus {
  border-color: var(--colors-gray50);
}
._1nzuaz73cu:active {
  border-color: var(--colors-gray50);
}
._1nzuaz73cv {
  border-color: var(--colors-gray100);
}
._1nzuaz73cw:hover {
  border-color: var(--colors-gray100);
}
._1nzuaz73cx:focus {
  border-color: var(--colors-gray100);
}
._1nzuaz73cy:active {
  border-color: var(--colors-gray100);
}
._1nzuaz73cz {
  border-color: var(--colors-gray200);
}
._1nzuaz73d0:hover {
  border-color: var(--colors-gray200);
}
._1nzuaz73d1:focus {
  border-color: var(--colors-gray200);
}
._1nzuaz73d2:active {
  border-color: var(--colors-gray200);
}
._1nzuaz73d3 {
  border-color: var(--colors-gray300);
}
._1nzuaz73d4:hover {
  border-color: var(--colors-gray300);
}
._1nzuaz73d5:focus {
  border-color: var(--colors-gray300);
}
._1nzuaz73d6:active {
  border-color: var(--colors-gray300);
}
._1nzuaz73d7 {
  border-color: var(--colors-gray390);
}
._1nzuaz73d8:hover {
  border-color: var(--colors-gray390);
}
._1nzuaz73d9:focus {
  border-color: var(--colors-gray390);
}
._1nzuaz73da:active {
  border-color: var(--colors-gray390);
}
._1nzuaz73db {
  border-color: var(--colors-gray400);
}
._1nzuaz73dc:hover {
  border-color: var(--colors-gray400);
}
._1nzuaz73dd:focus {
  border-color: var(--colors-gray400);
}
._1nzuaz73de:active {
  border-color: var(--colors-gray400);
}
._1nzuaz73df {
  border-color: var(--colors-gray500);
}
._1nzuaz73dg:hover {
  border-color: var(--colors-gray500);
}
._1nzuaz73dh:focus {
  border-color: var(--colors-gray500);
}
._1nzuaz73di:active {
  border-color: var(--colors-gray500);
}
._1nzuaz73dj {
  border-color: var(--colors-gray700);
}
._1nzuaz73dk:hover {
  border-color: var(--colors-gray700);
}
._1nzuaz73dl:focus {
  border-color: var(--colors-gray700);
}
._1nzuaz73dm:active {
  border-color: var(--colors-gray700);
}
._1nzuaz73dn {
  border-color: var(--colors-gray800);
}
._1nzuaz73do:hover {
  border-color: var(--colors-gray800);
}
._1nzuaz73dp:focus {
  border-color: var(--colors-gray800);
}
._1nzuaz73dq:active {
  border-color: var(--colors-gray800);
}
._1nzuaz73dr {
  border-color: var(--colors-gray850);
}
._1nzuaz73ds:hover {
  border-color: var(--colors-gray850);
}
._1nzuaz73dt:focus {
  border-color: var(--colors-gray850);
}
._1nzuaz73du:active {
  border-color: var(--colors-gray850);
}
._1nzuaz73dv {
  border-color: var(--colors-gray900);
}
._1nzuaz73dw:hover {
  border-color: var(--colors-gray900);
}
._1nzuaz73dx:focus {
  border-color: var(--colors-gray900);
}
._1nzuaz73dy:active {
  border-color: var(--colors-gray900);
}
._1nzuaz73dz {
  border-color: var(--colors-gray1000);
}
._1nzuaz73e0:hover {
  border-color: var(--colors-gray1000);
}
._1nzuaz73e1:focus {
  border-color: var(--colors-gray1000);
}
._1nzuaz73e2:active {
  border-color: var(--colors-gray1000);
}
._1nzuaz73e3 {
  border-color: var(--colors-tintGray80);
}
._1nzuaz73e4:hover {
  border-color: var(--colors-tintGray80);
}
._1nzuaz73e5:focus {
  border-color: var(--colors-tintGray80);
}
._1nzuaz73e6:active {
  border-color: var(--colors-tintGray80);
}
._1nzuaz73e7 {
  border-color: var(--colors-gradientPrimary);
}
._1nzuaz73e8:hover {
  border-color: var(--colors-gradientPrimary);
}
._1nzuaz73e9:focus {
  border-color: var(--colors-gradientPrimary);
}
._1nzuaz73ea:active {
  border-color: var(--colors-gradientPrimary);
}
._1nzuaz73eb {
  border-color: var(--colors-gradientPrimary10);
}
._1nzuaz73ec:hover {
  border-color: var(--colors-gradientPrimary10);
}
._1nzuaz73ed:focus {
  border-color: var(--colors-gradientPrimary10);
}
._1nzuaz73ee:active {
  border-color: var(--colors-gradientPrimary10);
}
._1nzuaz73ef {
  border-color: var(--colors-gradientPrimary20);
}
._1nzuaz73eg:hover {
  border-color: var(--colors-gradientPrimary20);
}
._1nzuaz73eh:focus {
  border-color: var(--colors-gradientPrimary20);
}
._1nzuaz73ei:active {
  border-color: var(--colors-gradientPrimary20);
}
._1nzuaz73ej {
  border-color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz73ek:hover {
  border-color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz73el:focus {
  border-color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz73em:active {
  border-color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz73en {
  border-color: var(--colors-orange100);
}
._1nzuaz73eo:hover {
  border-color: var(--colors-orange100);
}
._1nzuaz73ep:focus {
  border-color: var(--colors-orange100);
}
._1nzuaz73eq:active {
  border-color: var(--colors-orange100);
}
._1nzuaz73er {
  border-color: var(--colors-orange);
}
._1nzuaz73es:hover {
  border-color: var(--colors-orange);
}
._1nzuaz73et:focus {
  border-color: var(--colors-orange);
}
._1nzuaz73eu:active {
  border-color: var(--colors-orange);
}
._1nzuaz73ev {
  border-color: var(--colors-purple);
}
._1nzuaz73ew:hover {
  border-color: var(--colors-purple);
}
._1nzuaz73ex:focus {
  border-color: var(--colors-purple);
}
._1nzuaz73ey:active {
  border-color: var(--colors-purple);
}
._1nzuaz73ez {
  border-color: var(--colors-purple60);
}
._1nzuaz73f0:hover {
  border-color: var(--colors-purple60);
}
._1nzuaz73f1:focus {
  border-color: var(--colors-purple60);
}
._1nzuaz73f2:active {
  border-color: var(--colors-purple60);
}
._1nzuaz73f3 {
  border-color: var(--colors-orangeDark);
}
._1nzuaz73f4:hover {
  border-color: var(--colors-orangeDark);
}
._1nzuaz73f5:focus {
  border-color: var(--colors-orangeDark);
}
._1nzuaz73f6:active {
  border-color: var(--colors-orangeDark);
}
._1nzuaz73f7 {
  border-color: var(--colors-binance);
}
._1nzuaz73f8:hover {
  border-color: var(--colors-binance);
}
._1nzuaz73f9:focus {
  border-color: var(--colors-binance);
}
._1nzuaz73fa:active {
  border-color: var(--colors-binance);
}
._1nzuaz73fb {
  border-color: var(--colors-overlay);
}
._1nzuaz73fc:hover {
  border-color: var(--colors-overlay);
}
._1nzuaz73fd:focus {
  border-color: var(--colors-overlay);
}
._1nzuaz73fe:active {
  border-color: var(--colors-overlay);
}
._1nzuaz73ff {
  border-color: var(--colors-gold);
}
._1nzuaz73fg:hover {
  border-color: var(--colors-gold);
}
._1nzuaz73fh:focus {
  border-color: var(--colors-gold);
}
._1nzuaz73fi:active {
  border-color: var(--colors-gold);
}
._1nzuaz73fj {
  border-color: var(--colors-silver);
}
._1nzuaz73fk:hover {
  border-color: var(--colors-silver);
}
._1nzuaz73fl:focus {
  border-color: var(--colors-silver);
}
._1nzuaz73fm:active {
  border-color: var(--colors-silver);
}
._1nzuaz73fn {
  border-color: var(--colors-bronze);
}
._1nzuaz73fo:hover {
  border-color: var(--colors-bronze);
}
._1nzuaz73fp:focus {
  border-color: var(--colors-bronze);
}
._1nzuaz73fq:active {
  border-color: var(--colors-bronze);
}
._1nzuaz73fr {
  border-color: var(--colors-secondary);
}
._1nzuaz73fs:hover {
  border-color: var(--colors-secondary);
}
._1nzuaz73ft:focus {
  border-color: var(--colors-secondary);
}
._1nzuaz73fu:active {
  border-color: var(--colors-secondary);
}
._1nzuaz73fv {
  border-color: var(--colors-secondary80);
}
._1nzuaz73fw:hover {
  border-color: var(--colors-secondary80);
}
._1nzuaz73fx:focus {
  border-color: var(--colors-secondary80);
}
._1nzuaz73fy:active {
  border-color: var(--colors-secondary80);
}
._1nzuaz73fz {
  border-color: var(--colors-background);
}
._1nzuaz73g0:hover {
  border-color: var(--colors-background);
}
._1nzuaz73g1:focus {
  border-color: var(--colors-background);
}
._1nzuaz73g2:active {
  border-color: var(--colors-background);
}
._1nzuaz73g3 {
  border-color: var(--colors-backgroundDisabled);
}
._1nzuaz73g4:hover {
  border-color: var(--colors-backgroundDisabled);
}
._1nzuaz73g5:focus {
  border-color: var(--colors-backgroundDisabled);
}
._1nzuaz73g6:active {
  border-color: var(--colors-backgroundDisabled);
}
._1nzuaz73g7 {
  border-color: var(--colors-backgroundAlt);
}
._1nzuaz73g8:hover {
  border-color: var(--colors-backgroundAlt);
}
._1nzuaz73g9:focus {
  border-color: var(--colors-backgroundAlt);
}
._1nzuaz73ga:active {
  border-color: var(--colors-backgroundAlt);
}
._1nzuaz73gb {
  border-color: var(--colors-backgroundAlt2);
}
._1nzuaz73gc:hover {
  border-color: var(--colors-backgroundAlt2);
}
._1nzuaz73gd:focus {
  border-color: var(--colors-backgroundAlt2);
}
._1nzuaz73ge:active {
  border-color: var(--colors-backgroundAlt2);
}
._1nzuaz73gf {
  border-color: var(--colors-cardBorder);
}
._1nzuaz73gg:hover {
  border-color: var(--colors-cardBorder);
}
._1nzuaz73gh:focus {
  border-color: var(--colors-cardBorder);
}
._1nzuaz73gi:active {
  border-color: var(--colors-cardBorder);
}
._1nzuaz73gj {
  border-color: var(--colors-contrast);
}
._1nzuaz73gk:hover {
  border-color: var(--colors-contrast);
}
._1nzuaz73gl:focus {
  border-color: var(--colors-contrast);
}
._1nzuaz73gm:active {
  border-color: var(--colors-contrast);
}
._1nzuaz73gn {
  border-color: var(--colors-dropdown);
}
._1nzuaz73go:hover {
  border-color: var(--colors-dropdown);
}
._1nzuaz73gp:focus {
  border-color: var(--colors-dropdown);
}
._1nzuaz73gq:active {
  border-color: var(--colors-dropdown);
}
._1nzuaz73gr {
  border-color: var(--colors-dropdownDeep);
}
._1nzuaz73gs:hover {
  border-color: var(--colors-dropdownDeep);
}
._1nzuaz73gt:focus {
  border-color: var(--colors-dropdownDeep);
}
._1nzuaz73gu:active {
  border-color: var(--colors-dropdownDeep);
}
._1nzuaz73gv {
  border-color: var(--colors-invertedContrast);
}
._1nzuaz73gw:hover {
  border-color: var(--colors-invertedContrast);
}
._1nzuaz73gx:focus {
  border-color: var(--colors-invertedContrast);
}
._1nzuaz73gy:active {
  border-color: var(--colors-invertedContrast);
}
._1nzuaz73gz {
  border-color: var(--colors-input);
}
._1nzuaz73h0:hover {
  border-color: var(--colors-input);
}
._1nzuaz73h1:focus {
  border-color: var(--colors-input);
}
._1nzuaz73h2:active {
  border-color: var(--colors-input);
}
._1nzuaz73h3 {
  border-color: var(--colors-inputSecondary);
}
._1nzuaz73h4:hover {
  border-color: var(--colors-inputSecondary);
}
._1nzuaz73h5:focus {
  border-color: var(--colors-inputSecondary);
}
._1nzuaz73h6:active {
  border-color: var(--colors-inputSecondary);
}
._1nzuaz73h7 {
  border-color: var(--colors-tertiary);
}
._1nzuaz73h8:hover {
  border-color: var(--colors-tertiary);
}
._1nzuaz73h9:focus {
  border-color: var(--colors-tertiary);
}
._1nzuaz73ha:active {
  border-color: var(--colors-tertiary);
}
._1nzuaz73hb {
  border-color: var(--colors-text);
}
._1nzuaz73hc:hover {
  border-color: var(--colors-text);
}
._1nzuaz73hd:focus {
  border-color: var(--colors-text);
}
._1nzuaz73he:active {
  border-color: var(--colors-text);
}
._1nzuaz73hf {
  border-color: var(--colors-text99);
}
._1nzuaz73hg:hover {
  border-color: var(--colors-text99);
}
._1nzuaz73hh:focus {
  border-color: var(--colors-text99);
}
._1nzuaz73hi:active {
  border-color: var(--colors-text99);
}
._1nzuaz73hj {
  border-color: var(--colors-textDisabled);
}
._1nzuaz73hk:hover {
  border-color: var(--colors-textDisabled);
}
._1nzuaz73hl:focus {
  border-color: var(--colors-textDisabled);
}
._1nzuaz73hm:active {
  border-color: var(--colors-textDisabled);
}
._1nzuaz73hn {
  border-color: var(--colors-textSubtle);
}
._1nzuaz73ho:hover {
  border-color: var(--colors-textSubtle);
}
._1nzuaz73hp:focus {
  border-color: var(--colors-textSubtle);
}
._1nzuaz73hq:active {
  border-color: var(--colors-textSubtle);
}
._1nzuaz73hr {
  border-color: var(--colors-disabled);
}
._1nzuaz73hs:hover {
  border-color: var(--colors-disabled);
}
._1nzuaz73ht:focus {
  border-color: var(--colors-disabled);
}
._1nzuaz73hu:active {
  border-color: var(--colors-disabled);
}
._1nzuaz73hv {
  border-color: var(--colors-gradientBubblegum);
}
._1nzuaz73hw:hover {
  border-color: var(--colors-gradientBubblegum);
}
._1nzuaz73hx:focus {
  border-color: var(--colors-gradientBubblegum);
}
._1nzuaz73hy:active {
  border-color: var(--colors-gradientBubblegum);
}
._1nzuaz73hz {
  border-color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz73i0:hover {
  border-color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz73i1:focus {
  border-color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz73i2:active {
  border-color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz73i3 {
  border-color: var(--colors-gradientCardHeader);
}
._1nzuaz73i4:hover {
  border-color: var(--colors-gradientCardHeader);
}
._1nzuaz73i5:focus {
  border-color: var(--colors-gradientCardHeader);
}
._1nzuaz73i6:active {
  border-color: var(--colors-gradientCardHeader);
}
._1nzuaz73i7 {
  border-color: var(--colors-gradientBlue);
}
._1nzuaz73i8:hover {
  border-color: var(--colors-gradientBlue);
}
._1nzuaz73i9:focus {
  border-color: var(--colors-gradientBlue);
}
._1nzuaz73ia:active {
  border-color: var(--colors-gradientBlue);
}
._1nzuaz73ib {
  border-color: var(--colors-gradientViolet);
}
._1nzuaz73ic:hover {
  border-color: var(--colors-gradientViolet);
}
._1nzuaz73id:focus {
  border-color: var(--colors-gradientViolet);
}
._1nzuaz73ie:active {
  border-color: var(--colors-gradientViolet);
}
._1nzuaz73if {
  border-color: var(--colors-gradientVioletAlt);
}
._1nzuaz73ig:hover {
  border-color: var(--colors-gradientVioletAlt);
}
._1nzuaz73ih:focus {
  border-color: var(--colors-gradientVioletAlt);
}
._1nzuaz73ii:active {
  border-color: var(--colors-gradientVioletAlt);
}
._1nzuaz73ij {
  border-color: var(--colors-gradientGold);
}
._1nzuaz73ik:hover {
  border-color: var(--colors-gradientGold);
}
._1nzuaz73il:focus {
  border-color: var(--colors-gradientGold);
}
._1nzuaz73im:active {
  border-color: var(--colors-gradientGold);
}
._1nzuaz73in {
  border-color: var(--colors-gradientBold);
}
._1nzuaz73io:hover {
  border-color: var(--colors-gradientBold);
}
._1nzuaz73ip:focus {
  border-color: var(--colors-gradientBold);
}
._1nzuaz73iq:active {
  border-color: var(--colors-gradientBold);
}
._1nzuaz73ir {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz73is:hover {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz73it:focus {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz73iu:active {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz73iv {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz73iw:hover {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz73ix:focus {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz73iy:active {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz73iz {
  color: var(--colors-white50);
}
._1nzuaz73j0:hover {
  color: var(--colors-white50);
}
._1nzuaz73j1:focus {
  color: var(--colors-white50);
}
._1nzuaz73j2:active {
  color: var(--colors-white50);
}
._1nzuaz73j3 {
  color: var(--colors-white100);
}
._1nzuaz73j4:hover {
  color: var(--colors-white100);
}
._1nzuaz73j5:focus {
  color: var(--colors-white100);
}
._1nzuaz73j6:active {
  color: var(--colors-white100);
}
._1nzuaz73j7 {
  color: var(--colors-white200);
}
._1nzuaz73j8:hover {
  color: var(--colors-white200);
}
._1nzuaz73j9:focus {
  color: var(--colors-white200);
}
._1nzuaz73ja:active {
  color: var(--colors-white200);
}
._1nzuaz73jb {
  color: var(--colors-white300);
}
._1nzuaz73jc:hover {
  color: var(--colors-white300);
}
._1nzuaz73jd:focus {
  color: var(--colors-white300);
}
._1nzuaz73je:active {
  color: var(--colors-white300);
}
._1nzuaz73jf {
  color: var(--colors-white500);
}
._1nzuaz73jg:hover {
  color: var(--colors-white500);
}
._1nzuaz73jh:focus {
  color: var(--colors-white500);
}
._1nzuaz73ji:active {
  color: var(--colors-white500);
}
._1nzuaz73jj {
  color: var(--colors-white800);
}
._1nzuaz73jk:hover {
  color: var(--colors-white800);
}
._1nzuaz73jl:focus {
  color: var(--colors-white800);
}
._1nzuaz73jm:active {
  color: var(--colors-white800);
}
._1nzuaz73jn {
  color: var(--colors-white);
}
._1nzuaz73jo:hover {
  color: var(--colors-white);
}
._1nzuaz73jp:focus {
  color: var(--colors-white);
}
._1nzuaz73jq:active {
  color: var(--colors-white);
}
._1nzuaz73jr {
  color: var(--colors-black);
}
._1nzuaz73js:hover {
  color: var(--colors-black);
}
._1nzuaz73jt:focus {
  color: var(--colors-black);
}
._1nzuaz73ju:active {
  color: var(--colors-black);
}
._1nzuaz73jv {
  color: var(--colors-failure);
}
._1nzuaz73jw:hover {
  color: var(--colors-failure);
}
._1nzuaz73jx:focus {
  color: var(--colors-failure);
}
._1nzuaz73jy:active {
  color: var(--colors-failure);
}
._1nzuaz73jz {
  color: var(--colors-failure33);
}
._1nzuaz73k0:hover {
  color: var(--colors-failure33);
}
._1nzuaz73k1:focus {
  color: var(--colors-failure33);
}
._1nzuaz73k2:active {
  color: var(--colors-failure33);
}
._1nzuaz73k3 {
  color: var(--colors-red100);
}
._1nzuaz73k4:hover {
  color: var(--colors-red100);
}
._1nzuaz73k5:focus {
  color: var(--colors-red100);
}
._1nzuaz73k6:active {
  color: var(--colors-red100);
}
._1nzuaz73k7 {
  color: var(--colors-primary);
}
._1nzuaz73k8:hover {
  color: var(--colors-primary);
}
._1nzuaz73k9:focus {
  color: var(--colors-primary);
}
._1nzuaz73ka:active {
  color: var(--colors-primary);
}
._1nzuaz73kb {
  color: var(--colors-primary0f);
}
._1nzuaz73kc:hover {
  color: var(--colors-primary0f);
}
._1nzuaz73kd:focus {
  color: var(--colors-primary0f);
}
._1nzuaz73ke:active {
  color: var(--colors-primary0f);
}
._1nzuaz73kf {
  color: var(--colors-primary3D);
}
._1nzuaz73kg:hover {
  color: var(--colors-primary3D);
}
._1nzuaz73kh:focus {
  color: var(--colors-primary3D);
}
._1nzuaz73ki:active {
  color: var(--colors-primary3D);
}
._1nzuaz73kj {
  color: var(--colors-primaryBright);
}
._1nzuaz73kk:hover {
  color: var(--colors-primaryBright);
}
._1nzuaz73kl:focus {
  color: var(--colors-primaryBright);
}
._1nzuaz73km:active {
  color: var(--colors-primaryBright);
}
._1nzuaz73kn {
  color: var(--colors-primaryDark);
}
._1nzuaz73ko:hover {
  color: var(--colors-primaryDark);
}
._1nzuaz73kp:focus {
  color: var(--colors-primaryDark);
}
._1nzuaz73kq:active {
  color: var(--colors-primaryDark);
}
._1nzuaz73kr {
  color: var(--colors-success);
}
._1nzuaz73ks:hover {
  color: var(--colors-success);
}
._1nzuaz73kt:focus {
  color: var(--colors-success);
}
._1nzuaz73ku:active {
  color: var(--colors-success);
}
._1nzuaz73kv {
  color: var(--colors-success19);
}
._1nzuaz73kw:hover {
  color: var(--colors-success19);
}
._1nzuaz73kx:focus {
  color: var(--colors-success19);
}
._1nzuaz73ky:active {
  color: var(--colors-success19);
}
._1nzuaz73kz {
  color: var(--colors-warning);
}
._1nzuaz73l0:hover {
  color: var(--colors-warning);
}
._1nzuaz73l1:focus {
  color: var(--colors-warning);
}
._1nzuaz73l2:active {
  color: var(--colors-warning);
}
._1nzuaz73l3 {
  color: var(--colors-warning2D);
}
._1nzuaz73l4:hover {
  color: var(--colors-warning2D);
}
._1nzuaz73l5:focus {
  color: var(--colors-warning2D);
}
._1nzuaz73l6:active {
  color: var(--colors-warning2D);
}
._1nzuaz73l7 {
  color: var(--colors-warning33);
}
._1nzuaz73l8:hover {
  color: var(--colors-warning33);
}
._1nzuaz73l9:focus {
  color: var(--colors-warning33);
}
._1nzuaz73la:active {
  color: var(--colors-warning33);
}
._1nzuaz73lb {
  color: var(--colors-yellow100);
}
._1nzuaz73lc:hover {
  color: var(--colors-yellow100);
}
._1nzuaz73ld:focus {
  color: var(--colors-yellow100);
}
._1nzuaz73le:active {
  color: var(--colors-yellow100);
}
._1nzuaz73lf {
  color: var(--colors-gray50);
}
._1nzuaz73lg:hover {
  color: var(--colors-gray50);
}
._1nzuaz73lh:focus {
  color: var(--colors-gray50);
}
._1nzuaz73li:active {
  color: var(--colors-gray50);
}
._1nzuaz73lj {
  color: var(--colors-gray100);
}
._1nzuaz73lk:hover {
  color: var(--colors-gray100);
}
._1nzuaz73ll:focus {
  color: var(--colors-gray100);
}
._1nzuaz73lm:active {
  color: var(--colors-gray100);
}
._1nzuaz73ln {
  color: var(--colors-gray200);
}
._1nzuaz73lo:hover {
  color: var(--colors-gray200);
}
._1nzuaz73lp:focus {
  color: var(--colors-gray200);
}
._1nzuaz73lq:active {
  color: var(--colors-gray200);
}
._1nzuaz73lr {
  color: var(--colors-gray300);
}
._1nzuaz73ls:hover {
  color: var(--colors-gray300);
}
._1nzuaz73lt:focus {
  color: var(--colors-gray300);
}
._1nzuaz73lu:active {
  color: var(--colors-gray300);
}
._1nzuaz73lv {
  color: var(--colors-gray390);
}
._1nzuaz73lw:hover {
  color: var(--colors-gray390);
}
._1nzuaz73lx:focus {
  color: var(--colors-gray390);
}
._1nzuaz73ly:active {
  color: var(--colors-gray390);
}
._1nzuaz73lz {
  color: var(--colors-gray400);
}
._1nzuaz73m0:hover {
  color: var(--colors-gray400);
}
._1nzuaz73m1:focus {
  color: var(--colors-gray400);
}
._1nzuaz73m2:active {
  color: var(--colors-gray400);
}
._1nzuaz73m3 {
  color: var(--colors-gray500);
}
._1nzuaz73m4:hover {
  color: var(--colors-gray500);
}
._1nzuaz73m5:focus {
  color: var(--colors-gray500);
}
._1nzuaz73m6:active {
  color: var(--colors-gray500);
}
._1nzuaz73m7 {
  color: var(--colors-gray700);
}
._1nzuaz73m8:hover {
  color: var(--colors-gray700);
}
._1nzuaz73m9:focus {
  color: var(--colors-gray700);
}
._1nzuaz73ma:active {
  color: var(--colors-gray700);
}
._1nzuaz73mb {
  color: var(--colors-gray800);
}
._1nzuaz73mc:hover {
  color: var(--colors-gray800);
}
._1nzuaz73md:focus {
  color: var(--colors-gray800);
}
._1nzuaz73me:active {
  color: var(--colors-gray800);
}
._1nzuaz73mf {
  color: var(--colors-gray850);
}
._1nzuaz73mg:hover {
  color: var(--colors-gray850);
}
._1nzuaz73mh:focus {
  color: var(--colors-gray850);
}
._1nzuaz73mi:active {
  color: var(--colors-gray850);
}
._1nzuaz73mj {
  color: var(--colors-gray900);
}
._1nzuaz73mk:hover {
  color: var(--colors-gray900);
}
._1nzuaz73ml:focus {
  color: var(--colors-gray900);
}
._1nzuaz73mm:active {
  color: var(--colors-gray900);
}
._1nzuaz73mn {
  color: var(--colors-gray1000);
}
._1nzuaz73mo:hover {
  color: var(--colors-gray1000);
}
._1nzuaz73mp:focus {
  color: var(--colors-gray1000);
}
._1nzuaz73mq:active {
  color: var(--colors-gray1000);
}
._1nzuaz73mr {
  color: var(--colors-tintGray80);
}
._1nzuaz73ms:hover {
  color: var(--colors-tintGray80);
}
._1nzuaz73mt:focus {
  color: var(--colors-tintGray80);
}
._1nzuaz73mu:active {
  color: var(--colors-tintGray80);
}
._1nzuaz73mv {
  color: var(--colors-gradientPrimary);
}
._1nzuaz73mw:hover {
  color: var(--colors-gradientPrimary);
}
._1nzuaz73mx:focus {
  color: var(--colors-gradientPrimary);
}
._1nzuaz73my:active {
  color: var(--colors-gradientPrimary);
}
._1nzuaz73mz {
  color: var(--colors-gradientPrimary10);
}
._1nzuaz73n0:hover {
  color: var(--colors-gradientPrimary10);
}
._1nzuaz73n1:focus {
  color: var(--colors-gradientPrimary10);
}
._1nzuaz73n2:active {
  color: var(--colors-gradientPrimary10);
}
._1nzuaz73n3 {
  color: var(--colors-gradientPrimary20);
}
._1nzuaz73n4:hover {
  color: var(--colors-gradientPrimary20);
}
._1nzuaz73n5:focus {
  color: var(--colors-gradientPrimary20);
}
._1nzuaz73n6:active {
  color: var(--colors-gradientPrimary20);
}
._1nzuaz73n7 {
  color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz73n8:hover {
  color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz73n9:focus {
  color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz73na:active {
  color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz73nb {
  color: var(--colors-orange100);
}
._1nzuaz73nc:hover {
  color: var(--colors-orange100);
}
._1nzuaz73nd:focus {
  color: var(--colors-orange100);
}
._1nzuaz73ne:active {
  color: var(--colors-orange100);
}
._1nzuaz73nf {
  color: var(--colors-orange);
}
._1nzuaz73ng:hover {
  color: var(--colors-orange);
}
._1nzuaz73nh:focus {
  color: var(--colors-orange);
}
._1nzuaz73ni:active {
  color: var(--colors-orange);
}
._1nzuaz73nj {
  color: var(--colors-purple);
}
._1nzuaz73nk:hover {
  color: var(--colors-purple);
}
._1nzuaz73nl:focus {
  color: var(--colors-purple);
}
._1nzuaz73nm:active {
  color: var(--colors-purple);
}
._1nzuaz73nn {
  color: var(--colors-purple60);
}
._1nzuaz73no:hover {
  color: var(--colors-purple60);
}
._1nzuaz73np:focus {
  color: var(--colors-purple60);
}
._1nzuaz73nq:active {
  color: var(--colors-purple60);
}
._1nzuaz73nr {
  color: var(--colors-orangeDark);
}
._1nzuaz73ns:hover {
  color: var(--colors-orangeDark);
}
._1nzuaz73nt:focus {
  color: var(--colors-orangeDark);
}
._1nzuaz73nu:active {
  color: var(--colors-orangeDark);
}
._1nzuaz73nv {
  color: var(--colors-binance);
}
._1nzuaz73nw:hover {
  color: var(--colors-binance);
}
._1nzuaz73nx:focus {
  color: var(--colors-binance);
}
._1nzuaz73ny:active {
  color: var(--colors-binance);
}
._1nzuaz73nz {
  color: var(--colors-overlay);
}
._1nzuaz73o0:hover {
  color: var(--colors-overlay);
}
._1nzuaz73o1:focus {
  color: var(--colors-overlay);
}
._1nzuaz73o2:active {
  color: var(--colors-overlay);
}
._1nzuaz73o3 {
  color: var(--colors-gold);
}
._1nzuaz73o4:hover {
  color: var(--colors-gold);
}
._1nzuaz73o5:focus {
  color: var(--colors-gold);
}
._1nzuaz73o6:active {
  color: var(--colors-gold);
}
._1nzuaz73o7 {
  color: var(--colors-silver);
}
._1nzuaz73o8:hover {
  color: var(--colors-silver);
}
._1nzuaz73o9:focus {
  color: var(--colors-silver);
}
._1nzuaz73oa:active {
  color: var(--colors-silver);
}
._1nzuaz73ob {
  color: var(--colors-bronze);
}
._1nzuaz73oc:hover {
  color: var(--colors-bronze);
}
._1nzuaz73od:focus {
  color: var(--colors-bronze);
}
._1nzuaz73oe:active {
  color: var(--colors-bronze);
}
._1nzuaz73of {
  color: var(--colors-secondary);
}
._1nzuaz73og:hover {
  color: var(--colors-secondary);
}
._1nzuaz73oh:focus {
  color: var(--colors-secondary);
}
._1nzuaz73oi:active {
  color: var(--colors-secondary);
}
._1nzuaz73oj {
  color: var(--colors-secondary80);
}
._1nzuaz73ok:hover {
  color: var(--colors-secondary80);
}
._1nzuaz73ol:focus {
  color: var(--colors-secondary80);
}
._1nzuaz73om:active {
  color: var(--colors-secondary80);
}
._1nzuaz73on {
  color: var(--colors-background);
}
._1nzuaz73oo:hover {
  color: var(--colors-background);
}
._1nzuaz73op:focus {
  color: var(--colors-background);
}
._1nzuaz73oq:active {
  color: var(--colors-background);
}
._1nzuaz73or {
  color: var(--colors-backgroundDisabled);
}
._1nzuaz73os:hover {
  color: var(--colors-backgroundDisabled);
}
._1nzuaz73ot:focus {
  color: var(--colors-backgroundDisabled);
}
._1nzuaz73ou:active {
  color: var(--colors-backgroundDisabled);
}
._1nzuaz73ov {
  color: var(--colors-backgroundAlt);
}
._1nzuaz73ow:hover {
  color: var(--colors-backgroundAlt);
}
._1nzuaz73ox:focus {
  color: var(--colors-backgroundAlt);
}
._1nzuaz73oy:active {
  color: var(--colors-backgroundAlt);
}
._1nzuaz73oz {
  color: var(--colors-backgroundAlt2);
}
._1nzuaz73p0:hover {
  color: var(--colors-backgroundAlt2);
}
._1nzuaz73p1:focus {
  color: var(--colors-backgroundAlt2);
}
._1nzuaz73p2:active {
  color: var(--colors-backgroundAlt2);
}
._1nzuaz73p3 {
  color: var(--colors-cardBorder);
}
._1nzuaz73p4:hover {
  color: var(--colors-cardBorder);
}
._1nzuaz73p5:focus {
  color: var(--colors-cardBorder);
}
._1nzuaz73p6:active {
  color: var(--colors-cardBorder);
}
._1nzuaz73p7 {
  color: var(--colors-contrast);
}
._1nzuaz73p8:hover {
  color: var(--colors-contrast);
}
._1nzuaz73p9:focus {
  color: var(--colors-contrast);
}
._1nzuaz73pa:active {
  color: var(--colors-contrast);
}
._1nzuaz73pb {
  color: var(--colors-dropdown);
}
._1nzuaz73pc:hover {
  color: var(--colors-dropdown);
}
._1nzuaz73pd:focus {
  color: var(--colors-dropdown);
}
._1nzuaz73pe:active {
  color: var(--colors-dropdown);
}
._1nzuaz73pf {
  color: var(--colors-dropdownDeep);
}
._1nzuaz73pg:hover {
  color: var(--colors-dropdownDeep);
}
._1nzuaz73ph:focus {
  color: var(--colors-dropdownDeep);
}
._1nzuaz73pi:active {
  color: var(--colors-dropdownDeep);
}
._1nzuaz73pj {
  color: var(--colors-invertedContrast);
}
._1nzuaz73pk:hover {
  color: var(--colors-invertedContrast);
}
._1nzuaz73pl:focus {
  color: var(--colors-invertedContrast);
}
._1nzuaz73pm:active {
  color: var(--colors-invertedContrast);
}
._1nzuaz73pn {
  color: var(--colors-input);
}
._1nzuaz73po:hover {
  color: var(--colors-input);
}
._1nzuaz73pp:focus {
  color: var(--colors-input);
}
._1nzuaz73pq:active {
  color: var(--colors-input);
}
._1nzuaz73pr {
  color: var(--colors-inputSecondary);
}
._1nzuaz73ps:hover {
  color: var(--colors-inputSecondary);
}
._1nzuaz73pt:focus {
  color: var(--colors-inputSecondary);
}
._1nzuaz73pu:active {
  color: var(--colors-inputSecondary);
}
._1nzuaz73pv {
  color: var(--colors-tertiary);
}
._1nzuaz73pw:hover {
  color: var(--colors-tertiary);
}
._1nzuaz73px:focus {
  color: var(--colors-tertiary);
}
._1nzuaz73py:active {
  color: var(--colors-tertiary);
}
._1nzuaz73pz {
  color: var(--colors-text);
}
._1nzuaz73q0:hover {
  color: var(--colors-text);
}
._1nzuaz73q1:focus {
  color: var(--colors-text);
}
._1nzuaz73q2:active {
  color: var(--colors-text);
}
._1nzuaz73q3 {
  color: var(--colors-text99);
}
._1nzuaz73q4:hover {
  color: var(--colors-text99);
}
._1nzuaz73q5:focus {
  color: var(--colors-text99);
}
._1nzuaz73q6:active {
  color: var(--colors-text99);
}
._1nzuaz73q7 {
  color: var(--colors-textDisabled);
}
._1nzuaz73q8:hover {
  color: var(--colors-textDisabled);
}
._1nzuaz73q9:focus {
  color: var(--colors-textDisabled);
}
._1nzuaz73qa:active {
  color: var(--colors-textDisabled);
}
._1nzuaz73qb {
  color: var(--colors-textSubtle);
}
._1nzuaz73qc:hover {
  color: var(--colors-textSubtle);
}
._1nzuaz73qd:focus {
  color: var(--colors-textSubtle);
}
._1nzuaz73qe:active {
  color: var(--colors-textSubtle);
}
._1nzuaz73qf {
  color: var(--colors-disabled);
}
._1nzuaz73qg:hover {
  color: var(--colors-disabled);
}
._1nzuaz73qh:focus {
  color: var(--colors-disabled);
}
._1nzuaz73qi:active {
  color: var(--colors-disabled);
}
._1nzuaz73qj {
  color: var(--colors-gradientBubblegum);
}
._1nzuaz73qk:hover {
  color: var(--colors-gradientBubblegum);
}
._1nzuaz73ql:focus {
  color: var(--colors-gradientBubblegum);
}
._1nzuaz73qm:active {
  color: var(--colors-gradientBubblegum);
}
._1nzuaz73qn {
  color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz73qo:hover {
  color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz73qp:focus {
  color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz73qq:active {
  color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz73qr {
  color: var(--colors-gradientCardHeader);
}
._1nzuaz73qs:hover {
  color: var(--colors-gradientCardHeader);
}
._1nzuaz73qt:focus {
  color: var(--colors-gradientCardHeader);
}
._1nzuaz73qu:active {
  color: var(--colors-gradientCardHeader);
}
._1nzuaz73qv {
  color: var(--colors-gradientBlue);
}
._1nzuaz73qw:hover {
  color: var(--colors-gradientBlue);
}
._1nzuaz73qx:focus {
  color: var(--colors-gradientBlue);
}
._1nzuaz73qy:active {
  color: var(--colors-gradientBlue);
}
._1nzuaz73qz {
  color: var(--colors-gradientViolet);
}
._1nzuaz73r0:hover {
  color: var(--colors-gradientViolet);
}
._1nzuaz73r1:focus {
  color: var(--colors-gradientViolet);
}
._1nzuaz73r2:active {
  color: var(--colors-gradientViolet);
}
._1nzuaz73r3 {
  color: var(--colors-gradientVioletAlt);
}
._1nzuaz73r4:hover {
  color: var(--colors-gradientVioletAlt);
}
._1nzuaz73r5:focus {
  color: var(--colors-gradientVioletAlt);
}
._1nzuaz73r6:active {
  color: var(--colors-gradientVioletAlt);
}
._1nzuaz73r7 {
  color: var(--colors-gradientGold);
}
._1nzuaz73r8:hover {
  color: var(--colors-gradientGold);
}
._1nzuaz73r9:focus {
  color: var(--colors-gradientGold);
}
._1nzuaz73ra:active {
  color: var(--colors-gradientGold);
}
._1nzuaz73rb {
  color: var(--colors-gradientBold);
}
._1nzuaz73rc:hover {
  color: var(--colors-gradientBold);
}
._1nzuaz73rd:focus {
  color: var(--colors-gradientBold);
}
._1nzuaz73re:active {
  color: var(--colors-gradientBold);
}
._1nzuaz73rf {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz73rg:hover {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz73rh:focus {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz73ri:active {
  white50: var(--colors-light-white50);
  white100: var(--colors-light-white100);
  white200: var(--colors-light-white200);
  white300: var(--colors-light-white300);
  white500: var(--colors-light-white500);
  white800: var(--colors-light-white800);
  white: var(--colors-light-white);
  black: var(--colors-light-black);
  failure: var(--colors-light-failure);
  failure33: var(--colors-light-failure33);
  red100: var(--colors-light-red100);
  primary: var(--colors-light-primary);
  primary0f: var(--colors-light-primary0f);
  primary3-d: var(--colors-light-primary3D);
  primary-bright: var(--colors-light-primaryBright);
  primary-dark: var(--colors-light-primaryDark);
  success: var(--colors-light-success);
  success19: var(--colors-light-success19);
  warning: var(--colors-light-warning);
  warning2-d: var(--colors-light-warning2D);
  warning33: var(--colors-light-warning33);
  yellow100: var(--colors-light-yellow100);
  gray50: var(--colors-light-gray50);
  gray100: var(--colors-light-gray100);
  gray200: var(--colors-light-gray200);
  gray300: var(--colors-light-gray300);
  gray390: var(--colors-light-gray390);
  gray400: var(--colors-light-gray400);
  gray500: var(--colors-light-gray500);
  gray700: var(--colors-light-gray700);
  gray800: var(--colors-light-gray800);
  gray850: var(--colors-light-gray850);
  gray900: var(--colors-light-gray900);
  gray1000: var(--colors-light-gray1000);
  tint-gray80: var(--colors-light-tintGray80);
  gradient-primary: var(--colors-light-gradientPrimary);
  gradient-primary10: var(--colors-light-gradientPrimary10);
  gradient-primary20: var(--colors-light-gradientPrimary20);
  gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
  orange100: var(--colors-light-orange100);
  orange: var(--colors-light-orange);
  purple: var(--colors-light-purple);
  purple60: var(--colors-light-purple60);
  orange-dark: var(--colors-light-orangeDark);
  binance: var(--colors-light-binance);
  overlay: var(--colors-light-overlay);
  gold: var(--colors-light-gold);
  silver: var(--colors-light-silver);
  bronze: var(--colors-light-bronze);
  secondary: var(--colors-light-secondary);
  secondary80: var(--colors-light-secondary80);
  background: var(--colors-light-background);
  background-disabled: var(--colors-light-backgroundDisabled);
  background-alt: var(--colors-light-backgroundAlt);
  background-alt2: var(--colors-light-backgroundAlt2);
  card-border: var(--colors-light-cardBorder);
  contrast: var(--colors-light-contrast);
  dropdown: var(--colors-light-dropdown);
  dropdown-deep: var(--colors-light-dropdownDeep);
  inverted-contrast: var(--colors-light-invertedContrast);
  input: var(--colors-light-input);
  input-secondary: var(--colors-light-inputSecondary);
  tertiary: var(--colors-light-tertiary);
  text: var(--colors-light-text);
  text99: var(--colors-light-text99);
  text-disabled: var(--colors-light-textDisabled);
  text-subtle: var(--colors-light-textSubtle);
  disabled: var(--colors-light-disabled);
  gradient-bubblegum: var(--colors-light-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
  gradient-card-header: var(--colors-light-gradientCardHeader);
  gradient-blue: var(--colors-light-gradientBlue);
  gradient-violet: var(--colors-light-gradientViolet);
  gradient-violet-alt: var(--colors-light-gradientVioletAlt);
  gradient-gold: var(--colors-light-gradientGold);
  gradient-bold: var(--colors-light-gradientBold);
}
._1nzuaz73rj {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz73rk:hover {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz73rl:focus {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz73rm:active {
  white50: var(--colors-dark-white50);
  white100: var(--colors-dark-white100);
  white200: var(--colors-dark-white200);
  white300: var(--colors-dark-white300);
  white500: var(--colors-dark-white500);
  white800: var(--colors-dark-white800);
  white: var(--colors-dark-white);
  black: var(--colors-dark-black);
  failure: var(--colors-dark-failure);
  failure33: var(--colors-dark-failure33);
  red100: var(--colors-dark-red100);
  primary: var(--colors-dark-primary);
  primary0f: var(--colors-dark-primary0f);
  primary3-d: var(--colors-dark-primary3D);
  primary-bright: var(--colors-dark-primaryBright);
  primary-dark: var(--colors-dark-primaryDark);
  success: var(--colors-dark-success);
  success19: var(--colors-dark-success19);
  warning: var(--colors-dark-warning);
  warning2-d: var(--colors-dark-warning2D);
  warning33: var(--colors-dark-warning33);
  yellow100: var(--colors-dark-yellow100);
  gray50: var(--colors-dark-gray50);
  gray100: var(--colors-dark-gray100);
  gray200: var(--colors-dark-gray200);
  gray300: var(--colors-dark-gray300);
  gray390: var(--colors-dark-gray390);
  gray400: var(--colors-dark-gray400);
  gray500: var(--colors-dark-gray500);
  gray700: var(--colors-dark-gray700);
  gray800: var(--colors-dark-gray800);
  gray850: var(--colors-dark-gray850);
  gray900: var(--colors-dark-gray900);
  gray1000: var(--colors-dark-gray1000);
  tint-gray80: var(--colors-dark-tintGray80);
  gradient-primary: var(--colors-dark-gradientPrimary);
  gradient-primary10: var(--colors-dark-gradientPrimary10);
  gradient-primary20: var(--colors-dark-gradientPrimary20);
  gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
  orange100: var(--colors-dark-orange100);
  orange: var(--colors-dark-orange);
  purple: var(--colors-dark-purple);
  purple60: var(--colors-dark-purple60);
  orange-dark: var(--colors-dark-orangeDark);
  binance: var(--colors-dark-binance);
  overlay: var(--colors-dark-overlay);
  gold: var(--colors-dark-gold);
  silver: var(--colors-dark-silver);
  bronze: var(--colors-dark-bronze);
  secondary: var(--colors-dark-secondary);
  secondary80: var(--colors-dark-secondary80);
  background: var(--colors-dark-background);
  background-disabled: var(--colors-dark-backgroundDisabled);
  background-alt: var(--colors-dark-backgroundAlt);
  background-alt2: var(--colors-dark-backgroundAlt2);
  card-border: var(--colors-dark-cardBorder);
  contrast: var(--colors-dark-contrast);
  dropdown: var(--colors-dark-dropdown);
  dropdown-deep: var(--colors-dark-dropdownDeep);
  inverted-contrast: var(--colors-dark-invertedContrast);
  input: var(--colors-dark-input);
  input-secondary: var(--colors-dark-inputSecondary);
  tertiary: var(--colors-dark-tertiary);
  text: var(--colors-dark-text);
  text99: var(--colors-dark-text99);
  text-disabled: var(--colors-dark-textDisabled);
  text-subtle: var(--colors-dark-textSubtle);
  disabled: var(--colors-dark-disabled);
  gradient-bubblegum: var(--colors-dark-gradientBubblegum);
  gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
  gradient-card-header: var(--colors-dark-gradientCardHeader);
  gradient-blue: var(--colors-dark-gradientBlue);
  gradient-violet: var(--colors-dark-gradientViolet);
  gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
  gradient-gold: var(--colors-dark-gradientGold);
  gradient-bold: var(--colors-dark-gradientBold);
}
._1nzuaz73rn {
  outline-color: var(--colors-white50);
}
._1nzuaz73ro:hover {
  outline-color: var(--colors-white50);
}
._1nzuaz73rp:focus {
  outline-color: var(--colors-white50);
}
._1nzuaz73rq:active {
  outline-color: var(--colors-white50);
}
._1nzuaz73rr {
  outline-color: var(--colors-white100);
}
._1nzuaz73rs:hover {
  outline-color: var(--colors-white100);
}
._1nzuaz73rt:focus {
  outline-color: var(--colors-white100);
}
._1nzuaz73ru:active {
  outline-color: var(--colors-white100);
}
._1nzuaz73rv {
  outline-color: var(--colors-white200);
}
._1nzuaz73rw:hover {
  outline-color: var(--colors-white200);
}
._1nzuaz73rx:focus {
  outline-color: var(--colors-white200);
}
._1nzuaz73ry:active {
  outline-color: var(--colors-white200);
}
._1nzuaz73rz {
  outline-color: var(--colors-white300);
}
._1nzuaz73s0:hover {
  outline-color: var(--colors-white300);
}
._1nzuaz73s1:focus {
  outline-color: var(--colors-white300);
}
._1nzuaz73s2:active {
  outline-color: var(--colors-white300);
}
._1nzuaz73s3 {
  outline-color: var(--colors-white500);
}
._1nzuaz73s4:hover {
  outline-color: var(--colors-white500);
}
._1nzuaz73s5:focus {
  outline-color: var(--colors-white500);
}
._1nzuaz73s6:active {
  outline-color: var(--colors-white500);
}
._1nzuaz73s7 {
  outline-color: var(--colors-white800);
}
._1nzuaz73s8:hover {
  outline-color: var(--colors-white800);
}
._1nzuaz73s9:focus {
  outline-color: var(--colors-white800);
}
._1nzuaz73sa:active {
  outline-color: var(--colors-white800);
}
._1nzuaz73sb {
  outline-color: var(--colors-white);
}
._1nzuaz73sc:hover {
  outline-color: var(--colors-white);
}
._1nzuaz73sd:focus {
  outline-color: var(--colors-white);
}
._1nzuaz73se:active {
  outline-color: var(--colors-white);
}
._1nzuaz73sf {
  outline-color: var(--colors-black);
}
._1nzuaz73sg:hover {
  outline-color: var(--colors-black);
}
._1nzuaz73sh:focus {
  outline-color: var(--colors-black);
}
._1nzuaz73si:active {
  outline-color: var(--colors-black);
}
._1nzuaz73sj {
  outline-color: var(--colors-failure);
}
._1nzuaz73sk:hover {
  outline-color: var(--colors-failure);
}
._1nzuaz73sl:focus {
  outline-color: var(--colors-failure);
}
._1nzuaz73sm:active {
  outline-color: var(--colors-failure);
}
._1nzuaz73sn {
  outline-color: var(--colors-failure33);
}
._1nzuaz73so:hover {
  outline-color: var(--colors-failure33);
}
._1nzuaz73sp:focus {
  outline-color: var(--colors-failure33);
}
._1nzuaz73sq:active {
  outline-color: var(--colors-failure33);
}
._1nzuaz73sr {
  outline-color: var(--colors-red100);
}
._1nzuaz73ss:hover {
  outline-color: var(--colors-red100);
}
._1nzuaz73st:focus {
  outline-color: var(--colors-red100);
}
._1nzuaz73su:active {
  outline-color: var(--colors-red100);
}
._1nzuaz73sv {
  outline-color: var(--colors-primary);
}
._1nzuaz73sw:hover {
  outline-color: var(--colors-primary);
}
._1nzuaz73sx:focus {
  outline-color: var(--colors-primary);
}
._1nzuaz73sy:active {
  outline-color: var(--colors-primary);
}
._1nzuaz73sz {
  outline-color: var(--colors-primary0f);
}
._1nzuaz73t0:hover {
  outline-color: var(--colors-primary0f);
}
._1nzuaz73t1:focus {
  outline-color: var(--colors-primary0f);
}
._1nzuaz73t2:active {
  outline-color: var(--colors-primary0f);
}
._1nzuaz73t3 {
  outline-color: var(--colors-primary3D);
}
._1nzuaz73t4:hover {
  outline-color: var(--colors-primary3D);
}
._1nzuaz73t5:focus {
  outline-color: var(--colors-primary3D);
}
._1nzuaz73t6:active {
  outline-color: var(--colors-primary3D);
}
._1nzuaz73t7 {
  outline-color: var(--colors-primaryBright);
}
._1nzuaz73t8:hover {
  outline-color: var(--colors-primaryBright);
}
._1nzuaz73t9:focus {
  outline-color: var(--colors-primaryBright);
}
._1nzuaz73ta:active {
  outline-color: var(--colors-primaryBright);
}
._1nzuaz73tb {
  outline-color: var(--colors-primaryDark);
}
._1nzuaz73tc:hover {
  outline-color: var(--colors-primaryDark);
}
._1nzuaz73td:focus {
  outline-color: var(--colors-primaryDark);
}
._1nzuaz73te:active {
  outline-color: var(--colors-primaryDark);
}
._1nzuaz73tf {
  outline-color: var(--colors-success);
}
._1nzuaz73tg:hover {
  outline-color: var(--colors-success);
}
._1nzuaz73th:focus {
  outline-color: var(--colors-success);
}
._1nzuaz73ti:active {
  outline-color: var(--colors-success);
}
._1nzuaz73tj {
  outline-color: var(--colors-success19);
}
._1nzuaz73tk:hover {
  outline-color: var(--colors-success19);
}
._1nzuaz73tl:focus {
  outline-color: var(--colors-success19);
}
._1nzuaz73tm:active {
  outline-color: var(--colors-success19);
}
._1nzuaz73tn {
  outline-color: var(--colors-warning);
}
._1nzuaz73to:hover {
  outline-color: var(--colors-warning);
}
._1nzuaz73tp:focus {
  outline-color: var(--colors-warning);
}
._1nzuaz73tq:active {
  outline-color: var(--colors-warning);
}
._1nzuaz73tr {
  outline-color: var(--colors-warning2D);
}
._1nzuaz73ts:hover {
  outline-color: var(--colors-warning2D);
}
._1nzuaz73tt:focus {
  outline-color: var(--colors-warning2D);
}
._1nzuaz73tu:active {
  outline-color: var(--colors-warning2D);
}
._1nzuaz73tv {
  outline-color: var(--colors-warning33);
}
._1nzuaz73tw:hover {
  outline-color: var(--colors-warning33);
}
._1nzuaz73tx:focus {
  outline-color: var(--colors-warning33);
}
._1nzuaz73ty:active {
  outline-color: var(--colors-warning33);
}
._1nzuaz73tz {
  outline-color: var(--colors-yellow100);
}
._1nzuaz73u0:hover {
  outline-color: var(--colors-yellow100);
}
._1nzuaz73u1:focus {
  outline-color: var(--colors-yellow100);
}
._1nzuaz73u2:active {
  outline-color: var(--colors-yellow100);
}
._1nzuaz73u3 {
  outline-color: var(--colors-gray50);
}
._1nzuaz73u4:hover {
  outline-color: var(--colors-gray50);
}
._1nzuaz73u5:focus {
  outline-color: var(--colors-gray50);
}
._1nzuaz73u6:active {
  outline-color: var(--colors-gray50);
}
._1nzuaz73u7 {
  outline-color: var(--colors-gray100);
}
._1nzuaz73u8:hover {
  outline-color: var(--colors-gray100);
}
._1nzuaz73u9:focus {
  outline-color: var(--colors-gray100);
}
._1nzuaz73ua:active {
  outline-color: var(--colors-gray100);
}
._1nzuaz73ub {
  outline-color: var(--colors-gray200);
}
._1nzuaz73uc:hover {
  outline-color: var(--colors-gray200);
}
._1nzuaz73ud:focus {
  outline-color: var(--colors-gray200);
}
._1nzuaz73ue:active {
  outline-color: var(--colors-gray200);
}
._1nzuaz73uf {
  outline-color: var(--colors-gray300);
}
._1nzuaz73ug:hover {
  outline-color: var(--colors-gray300);
}
._1nzuaz73uh:focus {
  outline-color: var(--colors-gray300);
}
._1nzuaz73ui:active {
  outline-color: var(--colors-gray300);
}
._1nzuaz73uj {
  outline-color: var(--colors-gray390);
}
._1nzuaz73uk:hover {
  outline-color: var(--colors-gray390);
}
._1nzuaz73ul:focus {
  outline-color: var(--colors-gray390);
}
._1nzuaz73um:active {
  outline-color: var(--colors-gray390);
}
._1nzuaz73un {
  outline-color: var(--colors-gray400);
}
._1nzuaz73uo:hover {
  outline-color: var(--colors-gray400);
}
._1nzuaz73up:focus {
  outline-color: var(--colors-gray400);
}
._1nzuaz73uq:active {
  outline-color: var(--colors-gray400);
}
._1nzuaz73ur {
  outline-color: var(--colors-gray500);
}
._1nzuaz73us:hover {
  outline-color: var(--colors-gray500);
}
._1nzuaz73ut:focus {
  outline-color: var(--colors-gray500);
}
._1nzuaz73uu:active {
  outline-color: var(--colors-gray500);
}
._1nzuaz73uv {
  outline-color: var(--colors-gray700);
}
._1nzuaz73uw:hover {
  outline-color: var(--colors-gray700);
}
._1nzuaz73ux:focus {
  outline-color: var(--colors-gray700);
}
._1nzuaz73uy:active {
  outline-color: var(--colors-gray700);
}
._1nzuaz73uz {
  outline-color: var(--colors-gray800);
}
._1nzuaz73v0:hover {
  outline-color: var(--colors-gray800);
}
._1nzuaz73v1:focus {
  outline-color: var(--colors-gray800);
}
._1nzuaz73v2:active {
  outline-color: var(--colors-gray800);
}
._1nzuaz73v3 {
  outline-color: var(--colors-gray850);
}
._1nzuaz73v4:hover {
  outline-color: var(--colors-gray850);
}
._1nzuaz73v5:focus {
  outline-color: var(--colors-gray850);
}
._1nzuaz73v6:active {
  outline-color: var(--colors-gray850);
}
._1nzuaz73v7 {
  outline-color: var(--colors-gray900);
}
._1nzuaz73v8:hover {
  outline-color: var(--colors-gray900);
}
._1nzuaz73v9:focus {
  outline-color: var(--colors-gray900);
}
._1nzuaz73va:active {
  outline-color: var(--colors-gray900);
}
._1nzuaz73vb {
  outline-color: var(--colors-gray1000);
}
._1nzuaz73vc:hover {
  outline-color: var(--colors-gray1000);
}
._1nzuaz73vd:focus {
  outline-color: var(--colors-gray1000);
}
._1nzuaz73ve:active {
  outline-color: var(--colors-gray1000);
}
._1nzuaz73vf {
  outline-color: var(--colors-tintGray80);
}
._1nzuaz73vg:hover {
  outline-color: var(--colors-tintGray80);
}
._1nzuaz73vh:focus {
  outline-color: var(--colors-tintGray80);
}
._1nzuaz73vi:active {
  outline-color: var(--colors-tintGray80);
}
._1nzuaz73vj {
  outline-color: var(--colors-gradientPrimary);
}
._1nzuaz73vk:hover {
  outline-color: var(--colors-gradientPrimary);
}
._1nzuaz73vl:focus {
  outline-color: var(--colors-gradientPrimary);
}
._1nzuaz73vm:active {
  outline-color: var(--colors-gradientPrimary);
}
._1nzuaz73vn {
  outline-color: var(--colors-gradientPrimary10);
}
._1nzuaz73vo:hover {
  outline-color: var(--colors-gradientPrimary10);
}
._1nzuaz73vp:focus {
  outline-color: var(--colors-gradientPrimary10);
}
._1nzuaz73vq:active {
  outline-color: var(--colors-gradientPrimary10);
}
._1nzuaz73vr {
  outline-color: var(--colors-gradientPrimary20);
}
._1nzuaz73vs:hover {
  outline-color: var(--colors-gradientPrimary20);
}
._1nzuaz73vt:focus {
  outline-color: var(--colors-gradientPrimary20);
}
._1nzuaz73vu:active {
  outline-color: var(--colors-gradientPrimary20);
}
._1nzuaz73vv {
  outline-color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz73vw:hover {
  outline-color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz73vx:focus {
  outline-color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz73vy:active {
  outline-color: var(--colors-gradientPrimaryRadial);
}
._1nzuaz73vz {
  outline-color: var(--colors-orange100);
}
._1nzuaz73w0:hover {
  outline-color: var(--colors-orange100);
}
._1nzuaz73w1:focus {
  outline-color: var(--colors-orange100);
}
._1nzuaz73w2:active {
  outline-color: var(--colors-orange100);
}
._1nzuaz73w3 {
  outline-color: var(--colors-orange);
}
._1nzuaz73w4:hover {
  outline-color: var(--colors-orange);
}
._1nzuaz73w5:focus {
  outline-color: var(--colors-orange);
}
._1nzuaz73w6:active {
  outline-color: var(--colors-orange);
}
._1nzuaz73w7 {
  outline-color: var(--colors-purple);
}
._1nzuaz73w8:hover {
  outline-color: var(--colors-purple);
}
._1nzuaz73w9:focus {
  outline-color: var(--colors-purple);
}
._1nzuaz73wa:active {
  outline-color: var(--colors-purple);
}
._1nzuaz73wb {
  outline-color: var(--colors-purple60);
}
._1nzuaz73wc:hover {
  outline-color: var(--colors-purple60);
}
._1nzuaz73wd:focus {
  outline-color: var(--colors-purple60);
}
._1nzuaz73we:active {
  outline-color: var(--colors-purple60);
}
._1nzuaz73wf {
  outline-color: var(--colors-orangeDark);
}
._1nzuaz73wg:hover {
  outline-color: var(--colors-orangeDark);
}
._1nzuaz73wh:focus {
  outline-color: var(--colors-orangeDark);
}
._1nzuaz73wi:active {
  outline-color: var(--colors-orangeDark);
}
._1nzuaz73wj {
  outline-color: var(--colors-binance);
}
._1nzuaz73wk:hover {
  outline-color: var(--colors-binance);
}
._1nzuaz73wl:focus {
  outline-color: var(--colors-binance);
}
._1nzuaz73wm:active {
  outline-color: var(--colors-binance);
}
._1nzuaz73wn {
  outline-color: var(--colors-overlay);
}
._1nzuaz73wo:hover {
  outline-color: var(--colors-overlay);
}
._1nzuaz73wp:focus {
  outline-color: var(--colors-overlay);
}
._1nzuaz73wq:active {
  outline-color: var(--colors-overlay);
}
._1nzuaz73wr {
  outline-color: var(--colors-gold);
}
._1nzuaz73ws:hover {
  outline-color: var(--colors-gold);
}
._1nzuaz73wt:focus {
  outline-color: var(--colors-gold);
}
._1nzuaz73wu:active {
  outline-color: var(--colors-gold);
}
._1nzuaz73wv {
  outline-color: var(--colors-silver);
}
._1nzuaz73ww:hover {
  outline-color: var(--colors-silver);
}
._1nzuaz73wx:focus {
  outline-color: var(--colors-silver);
}
._1nzuaz73wy:active {
  outline-color: var(--colors-silver);
}
._1nzuaz73wz {
  outline-color: var(--colors-bronze);
}
._1nzuaz73x0:hover {
  outline-color: var(--colors-bronze);
}
._1nzuaz73x1:focus {
  outline-color: var(--colors-bronze);
}
._1nzuaz73x2:active {
  outline-color: var(--colors-bronze);
}
._1nzuaz73x3 {
  outline-color: var(--colors-secondary);
}
._1nzuaz73x4:hover {
  outline-color: var(--colors-secondary);
}
._1nzuaz73x5:focus {
  outline-color: var(--colors-secondary);
}
._1nzuaz73x6:active {
  outline-color: var(--colors-secondary);
}
._1nzuaz73x7 {
  outline-color: var(--colors-secondary80);
}
._1nzuaz73x8:hover {
  outline-color: var(--colors-secondary80);
}
._1nzuaz73x9:focus {
  outline-color: var(--colors-secondary80);
}
._1nzuaz73xa:active {
  outline-color: var(--colors-secondary80);
}
._1nzuaz73xb {
  outline-color: var(--colors-background);
}
._1nzuaz73xc:hover {
  outline-color: var(--colors-background);
}
._1nzuaz73xd:focus {
  outline-color: var(--colors-background);
}
._1nzuaz73xe:active {
  outline-color: var(--colors-background);
}
._1nzuaz73xf {
  outline-color: var(--colors-backgroundDisabled);
}
._1nzuaz73xg:hover {
  outline-color: var(--colors-backgroundDisabled);
}
._1nzuaz73xh:focus {
  outline-color: var(--colors-backgroundDisabled);
}
._1nzuaz73xi:active {
  outline-color: var(--colors-backgroundDisabled);
}
._1nzuaz73xj {
  outline-color: var(--colors-backgroundAlt);
}
._1nzuaz73xk:hover {
  outline-color: var(--colors-backgroundAlt);
}
._1nzuaz73xl:focus {
  outline-color: var(--colors-backgroundAlt);
}
._1nzuaz73xm:active {
  outline-color: var(--colors-backgroundAlt);
}
._1nzuaz73xn {
  outline-color: var(--colors-backgroundAlt2);
}
._1nzuaz73xo:hover {
  outline-color: var(--colors-backgroundAlt2);
}
._1nzuaz73xp:focus {
  outline-color: var(--colors-backgroundAlt2);
}
._1nzuaz73xq:active {
  outline-color: var(--colors-backgroundAlt2);
}
._1nzuaz73xr {
  outline-color: var(--colors-cardBorder);
}
._1nzuaz73xs:hover {
  outline-color: var(--colors-cardBorder);
}
._1nzuaz73xt:focus {
  outline-color: var(--colors-cardBorder);
}
._1nzuaz73xu:active {
  outline-color: var(--colors-cardBorder);
}
._1nzuaz73xv {
  outline-color: var(--colors-contrast);
}
._1nzuaz73xw:hover {
  outline-color: var(--colors-contrast);
}
._1nzuaz73xx:focus {
  outline-color: var(--colors-contrast);
}
._1nzuaz73xy:active {
  outline-color: var(--colors-contrast);
}
._1nzuaz73xz {
  outline-color: var(--colors-dropdown);
}
._1nzuaz73y0:hover {
  outline-color: var(--colors-dropdown);
}
._1nzuaz73y1:focus {
  outline-color: var(--colors-dropdown);
}
._1nzuaz73y2:active {
  outline-color: var(--colors-dropdown);
}
._1nzuaz73y3 {
  outline-color: var(--colors-dropdownDeep);
}
._1nzuaz73y4:hover {
  outline-color: var(--colors-dropdownDeep);
}
._1nzuaz73y5:focus {
  outline-color: var(--colors-dropdownDeep);
}
._1nzuaz73y6:active {
  outline-color: var(--colors-dropdownDeep);
}
._1nzuaz73y7 {
  outline-color: var(--colors-invertedContrast);
}
._1nzuaz73y8:hover {
  outline-color: var(--colors-invertedContrast);
}
._1nzuaz73y9:focus {
  outline-color: var(--colors-invertedContrast);
}
._1nzuaz73ya:active {
  outline-color: var(--colors-invertedContrast);
}
._1nzuaz73yb {
  outline-color: var(--colors-input);
}
._1nzuaz73yc:hover {
  outline-color: var(--colors-input);
}
._1nzuaz73yd:focus {
  outline-color: var(--colors-input);
}
._1nzuaz73ye:active {
  outline-color: var(--colors-input);
}
._1nzuaz73yf {
  outline-color: var(--colors-inputSecondary);
}
._1nzuaz73yg:hover {
  outline-color: var(--colors-inputSecondary);
}
._1nzuaz73yh:focus {
  outline-color: var(--colors-inputSecondary);
}
._1nzuaz73yi:active {
  outline-color: var(--colors-inputSecondary);
}
._1nzuaz73yj {
  outline-color: var(--colors-tertiary);
}
._1nzuaz73yk:hover {
  outline-color: var(--colors-tertiary);
}
._1nzuaz73yl:focus {
  outline-color: var(--colors-tertiary);
}
._1nzuaz73ym:active {
  outline-color: var(--colors-tertiary);
}
._1nzuaz73yn {
  outline-color: var(--colors-text);
}
._1nzuaz73yo:hover {
  outline-color: var(--colors-text);
}
._1nzuaz73yp:focus {
  outline-color: var(--colors-text);
}
._1nzuaz73yq:active {
  outline-color: var(--colors-text);
}
._1nzuaz73yr {
  outline-color: var(--colors-text99);
}
._1nzuaz73ys:hover {
  outline-color: var(--colors-text99);
}
._1nzuaz73yt:focus {
  outline-color: var(--colors-text99);
}
._1nzuaz73yu:active {
  outline-color: var(--colors-text99);
}
._1nzuaz73yv {
  outline-color: var(--colors-textDisabled);
}
._1nzuaz73yw:hover {
  outline-color: var(--colors-textDisabled);
}
._1nzuaz73yx:focus {
  outline-color: var(--colors-textDisabled);
}
._1nzuaz73yy:active {
  outline-color: var(--colors-textDisabled);
}
._1nzuaz73yz {
  outline-color: var(--colors-textSubtle);
}
._1nzuaz73z0:hover {
  outline-color: var(--colors-textSubtle);
}
._1nzuaz73z1:focus {
  outline-color: var(--colors-textSubtle);
}
._1nzuaz73z2:active {
  outline-color: var(--colors-textSubtle);
}
._1nzuaz73z3 {
  outline-color: var(--colors-disabled);
}
._1nzuaz73z4:hover {
  outline-color: var(--colors-disabled);
}
._1nzuaz73z5:focus {
  outline-color: var(--colors-disabled);
}
._1nzuaz73z6:active {
  outline-color: var(--colors-disabled);
}
._1nzuaz73z7 {
  outline-color: var(--colors-gradientBubblegum);
}
._1nzuaz73z8:hover {
  outline-color: var(--colors-gradientBubblegum);
}
._1nzuaz73z9:focus {
  outline-color: var(--colors-gradientBubblegum);
}
._1nzuaz73za:active {
  outline-color: var(--colors-gradientBubblegum);
}
._1nzuaz73zb {
  outline-color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz73zc:hover {
  outline-color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz73zd:focus {
  outline-color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz73ze:active {
  outline-color: var(--colors-gradientInverseBubblegum);
}
._1nzuaz73zf {
  outline-color: var(--colors-gradientCardHeader);
}
._1nzuaz73zg:hover {
  outline-color: var(--colors-gradientCardHeader);
}
._1nzuaz73zh:focus {
  outline-color: var(--colors-gradientCardHeader);
}
._1nzuaz73zi:active {
  outline-color: var(--colors-gradientCardHeader);
}
._1nzuaz73zj {
  outline-color: var(--colors-gradientBlue);
}
._1nzuaz73zk:hover {
  outline-color: var(--colors-gradientBlue);
}
._1nzuaz73zl:focus {
  outline-color: var(--colors-gradientBlue);
}
._1nzuaz73zm:active {
  outline-color: var(--colors-gradientBlue);
}
._1nzuaz73zn {
  outline-color: var(--colors-gradientViolet);
}
._1nzuaz73zo:hover {
  outline-color: var(--colors-gradientViolet);
}
._1nzuaz73zp:focus {
  outline-color: var(--colors-gradientViolet);
}
._1nzuaz73zq:active {
  outline-color: var(--colors-gradientViolet);
}
._1nzuaz73zr {
  outline-color: var(--colors-gradientVioletAlt);
}
._1nzuaz73zs:hover {
  outline-color: var(--colors-gradientVioletAlt);
}
._1nzuaz73zt:focus {
  outline-color: var(--colors-gradientVioletAlt);
}
._1nzuaz73zu:active {
  outline-color: var(--colors-gradientVioletAlt);
}
._1nzuaz73zv {
  outline-color: var(--colors-gradientGold);
}
._1nzuaz73zw:hover {
  outline-color: var(--colors-gradientGold);
}
._1nzuaz73zx:focus {
  outline-color: var(--colors-gradientGold);
}
._1nzuaz73zy:active {
  outline-color: var(--colors-gradientGold);
}
._1nzuaz73zz {
  outline-color: var(--colors-gradientBold);
}
._1nzuaz7400:hover {
  outline-color: var(--colors-gradientBold);
}
._1nzuaz7401:focus {
  outline-color: var(--colors-gradientBold);
}
._1nzuaz7402:active {
  outline-color: var(--colors-gradientBold);
}
@media (min-width: 576px) {
  ._1nzuaz71 {
    display: block;
  }
  ._1nzuaz77 {
    display: flex;
  }
  ._1nzuaz7d {
    display: grid;
  }
  ._1nzuaz7j {
    display: inline;
  }
  ._1nzuaz7p {
    display: inline-flex;
  }
  ._1nzuaz7v {
    display: inline-block;
  }
  ._1nzuaz711 {
    display: none;
  }
  ._1nzuaz717 {
    flex-direction: column;
  }
  ._1nzuaz71d {
    flex-direction: row;
  }
  ._1nzuaz71j {
    flex-direction: column-reverse;
  }
  ._1nzuaz71p {
    align-items: center;
  }
  ._1nzuaz71v {
    align-items: end;
  }
  ._1nzuaz721 {
    align-items: baseLine;
  }
  ._1nzuaz727 {
    align-items: inherit;
  }
  ._1nzuaz72d {
    align-items: flex-start;
  }
  ._1nzuaz72j {
    align-items: center;
  }
  ._1nzuaz72p {
    align-items: start;
  }
  ._1nzuaz72v {
    align-items: flex-end;
  }
  ._1nzuaz731 {
    align-items: stretch;
  }
  ._1nzuaz737 {
    align-self: flex-start;
  }
  ._1nzuaz73d {
    align-self: center;
  }
  ._1nzuaz73j {
    align-self: start;
  }
  ._1nzuaz73p {
    align-self: flex-end;
  }
  ._1nzuaz73v {
    align-self: stretch;
  }
  ._1nzuaz741 {
    flex-wrap: wrap;
  }
  ._1nzuaz747 {
    flex-wrap: nowrap;
  }
  ._1nzuaz74d {
    flex-grow: 1;
  }
  ._1nzuaz74j {
    overflow: auto;
  }
  ._1nzuaz74p {
    overflow: hidden;
  }
  ._1nzuaz74v {
    overflow: scroll;
  }
  ._1nzuaz751 {
    overflow: unset;
  }
  ._1nzuaz757 {
    overflow-y: auto;
  }
  ._1nzuaz75d {
    overflow-y: hidden;
  }
  ._1nzuaz75j {
    overflow-y: scroll;
  }
  ._1nzuaz75p {
    overflow-x: auto;
  }
  ._1nzuaz75v {
    overflow-x: hidden;
  }
  ._1nzuaz761 {
    overflow-x: scroll;
  }
  ._1nzuaz767 {
    position: absolute;
  }
  ._1nzuaz76d {
    position: fixed;
  }
  ._1nzuaz76j {
    position: relative;
  }
  ._1nzuaz76p {
    position: sticky;
  }
  ._1nzuaz76v {
    text-align: center;
  }
  ._1nzuaz771 {
    text-align: left;
  }
  ._1nzuaz777 {
    text-align: right;
  }
  ._1nzuaz77d {
    justify-content: flex-start;
  }
  ._1nzuaz77j {
    justify-content: center;
  }
  ._1nzuaz77p {
    justify-content: start;
  }
  ._1nzuaz77v {
    justify-content: flex-end;
  }
  ._1nzuaz781 {
    justify-content: stretch;
  }
  ._1nzuaz787 {
    justify-content: space-around;
  }
  ._1nzuaz78d {
    justify-content: space-between;
  }
  ._1nzuaz78j {
    justify-items: flex-start;
  }
  ._1nzuaz78p {
    justify-items: center;
  }
  ._1nzuaz78v {
    justify-items: start;
  }
  ._1nzuaz791 {
    justify-items: flex-end;
  }
  ._1nzuaz797 {
    justify-items: stretch;
  }
  ._1nzuaz79d {
    justify-items: space-around;
  }
  ._1nzuaz79j {
    justify-items: space-between;
  }
  ._1nzuaz79p {
    justify-self: flex-start;
  }
  ._1nzuaz79v {
    justify-self: center;
  }
  ._1nzuaz7a1 {
    justify-self: start;
  }
  ._1nzuaz7a7 {
    justify-self: flex-end;
  }
  ._1nzuaz7ad {
    justify-self: stretch;
  }
  ._1nzuaz7aj {
    inset: 0px;
  }
  ._1nzuaz7ap {
    height: var(--space-0px);
  }
  ._1nzuaz7av {
    height: var(--space-1rem);
  }
  ._1nzuaz7b1 {
    height: var(--space-1px);
  }
  ._1nzuaz7b7 {
    height: var(--space-2px);
  }
  ._1nzuaz7bd {
    height: var(--space-6px);
  }
  ._1nzuaz7bj {
    height: var(--space-4px);
  }
  ._1nzuaz7bp {
    height: var(--space-8px);
  }
  ._1nzuaz7bv {
    height: var(--space-12px);
  }
  ._1nzuaz7c1 {
    height: var(--space-14px);
  }
  ._1nzuaz7c7 {
    height: var(--space-16px);
  }
  ._1nzuaz7cd {
    height: var(--space-20px);
  }
  ._1nzuaz7cj {
    height: var(--space-24px);
  }
  ._1nzuaz7cp {
    height: var(--space-32px);
  }
  ._1nzuaz7cv {
    height: var(--space-40px);
  }
  ._1nzuaz7d1 {
    height: var(--space-48px);
  }
  ._1nzuaz7d7 {
    height: var(--space-56px);
  }
  ._1nzuaz7dd {
    height: var(--space-64px);
  }
  ._1nzuaz7dj {
    height: 100%;
  }
  ._1nzuaz7dp {
    height: auto;
  }
  ._1nzuaz7dv {
    height: -moz-fit-content;
    height: fit-content;
  }
  ._1nzuaz7e1 {
    height: 420px;
  }
  ._1nzuaz7e7 {
    height: 576px;
  }
  ._1nzuaz7ed {
    height: 768px;
  }
  ._1nzuaz7ej {
    height: 968px;
  }
  ._1nzuaz7ep {
    height: 1024px;
  }
  ._1nzuaz7ev {
    height: 100vh;
  }
  ._1nzuaz7f1 {
    margin-bottom: var(--space-0px);
  }
  ._1nzuaz7f7 {
    margin-bottom: var(--space-1rem);
  }
  ._1nzuaz7fd {
    margin-bottom: var(--space-1px);
  }
  ._1nzuaz7fj {
    margin-bottom: var(--space-2px);
  }
  ._1nzuaz7fp {
    margin-bottom: var(--space-6px);
  }
  ._1nzuaz7fv {
    margin-bottom: var(--space-4px);
  }
  ._1nzuaz7g1 {
    margin-bottom: var(--space-8px);
  }
  ._1nzuaz7g7 {
    margin-bottom: var(--space-12px);
  }
  ._1nzuaz7gd {
    margin-bottom: var(--space-14px);
  }
  ._1nzuaz7gj {
    margin-bottom: var(--space-16px);
  }
  ._1nzuaz7gp {
    margin-bottom: var(--space-20px);
  }
  ._1nzuaz7gv {
    margin-bottom: var(--space-24px);
  }
  ._1nzuaz7h1 {
    margin-bottom: var(--space-32px);
  }
  ._1nzuaz7h7 {
    margin-bottom: var(--space-40px);
  }
  ._1nzuaz7hd {
    margin-bottom: var(--space-48px);
  }
  ._1nzuaz7hj {
    margin-bottom: var(--space-56px);
  }
  ._1nzuaz7hp {
    margin-bottom: var(--space-64px);
  }
  ._1nzuaz7hv {
    margin-bottom: auto;
  }
  ._1nzuaz7i1 {
    margin-left: var(--space-0px);
  }
  ._1nzuaz7i7 {
    margin-left: var(--space-1rem);
  }
  ._1nzuaz7id {
    margin-left: var(--space-1px);
  }
  ._1nzuaz7ij {
    margin-left: var(--space-2px);
  }
  ._1nzuaz7ip {
    margin-left: var(--space-6px);
  }
  ._1nzuaz7iv {
    margin-left: var(--space-4px);
  }
  ._1nzuaz7j1 {
    margin-left: var(--space-8px);
  }
  ._1nzuaz7j7 {
    margin-left: var(--space-12px);
  }
  ._1nzuaz7jd {
    margin-left: var(--space-14px);
  }
  ._1nzuaz7jj {
    margin-left: var(--space-16px);
  }
  ._1nzuaz7jp {
    margin-left: var(--space-20px);
  }
  ._1nzuaz7jv {
    margin-left: var(--space-24px);
  }
  ._1nzuaz7k1 {
    margin-left: var(--space-32px);
  }
  ._1nzuaz7k7 {
    margin-left: var(--space-40px);
  }
  ._1nzuaz7kd {
    margin-left: var(--space-48px);
  }
  ._1nzuaz7kj {
    margin-left: var(--space-56px);
  }
  ._1nzuaz7kp {
    margin-left: var(--space-64px);
  }
  ._1nzuaz7kv {
    margin-left: auto;
  }
  ._1nzuaz7l1 {
    margin-right: var(--space-0px);
  }
  ._1nzuaz7l7 {
    margin-right: var(--space-1rem);
  }
  ._1nzuaz7ld {
    margin-right: var(--space-1px);
  }
  ._1nzuaz7lj {
    margin-right: var(--space-2px);
  }
  ._1nzuaz7lp {
    margin-right: var(--space-6px);
  }
  ._1nzuaz7lv {
    margin-right: var(--space-4px);
  }
  ._1nzuaz7m1 {
    margin-right: var(--space-8px);
  }
  ._1nzuaz7m7 {
    margin-right: var(--space-12px);
  }
  ._1nzuaz7md {
    margin-right: var(--space-14px);
  }
  ._1nzuaz7mj {
    margin-right: var(--space-16px);
  }
  ._1nzuaz7mp {
    margin-right: var(--space-20px);
  }
  ._1nzuaz7mv {
    margin-right: var(--space-24px);
  }
  ._1nzuaz7n1 {
    margin-right: var(--space-32px);
  }
  ._1nzuaz7n7 {
    margin-right: var(--space-40px);
  }
  ._1nzuaz7nd {
    margin-right: var(--space-48px);
  }
  ._1nzuaz7nj {
    margin-right: var(--space-56px);
  }
  ._1nzuaz7np {
    margin-right: var(--space-64px);
  }
  ._1nzuaz7nv {
    margin-right: auto;
  }
  ._1nzuaz7o1 {
    margin-top: var(--space-0px);
  }
  ._1nzuaz7o7 {
    margin-top: var(--space-1rem);
  }
  ._1nzuaz7od {
    margin-top: var(--space-1px);
  }
  ._1nzuaz7oj {
    margin-top: var(--space-2px);
  }
  ._1nzuaz7op {
    margin-top: var(--space-6px);
  }
  ._1nzuaz7ov {
    margin-top: var(--space-4px);
  }
  ._1nzuaz7p1 {
    margin-top: var(--space-8px);
  }
  ._1nzuaz7p7 {
    margin-top: var(--space-12px);
  }
  ._1nzuaz7pd {
    margin-top: var(--space-14px);
  }
  ._1nzuaz7pj {
    margin-top: var(--space-16px);
  }
  ._1nzuaz7pp {
    margin-top: var(--space-20px);
  }
  ._1nzuaz7pv {
    margin-top: var(--space-24px);
  }
  ._1nzuaz7q1 {
    margin-top: var(--space-32px);
  }
  ._1nzuaz7q7 {
    margin-top: var(--space-40px);
  }
  ._1nzuaz7qd {
    margin-top: var(--space-48px);
  }
  ._1nzuaz7qj {
    margin-top: var(--space-56px);
  }
  ._1nzuaz7qp {
    margin-top: var(--space-64px);
  }
  ._1nzuaz7qv {
    margin-top: auto;
  }
  ._1nzuaz7r1 {
    margin: var(--space-0px);
  }
  ._1nzuaz7r7 {
    margin: var(--space-1rem);
  }
  ._1nzuaz7rd {
    margin: var(--space-1px);
  }
  ._1nzuaz7rj {
    margin: var(--space-2px);
  }
  ._1nzuaz7rp {
    margin: var(--space-6px);
  }
  ._1nzuaz7rv {
    margin: var(--space-4px);
  }
  ._1nzuaz7s1 {
    margin: var(--space-8px);
  }
  ._1nzuaz7s7 {
    margin: var(--space-12px);
  }
  ._1nzuaz7sd {
    margin: var(--space-14px);
  }
  ._1nzuaz7sj {
    margin: var(--space-16px);
  }
  ._1nzuaz7sp {
    margin: var(--space-20px);
  }
  ._1nzuaz7sv {
    margin: var(--space-24px);
  }
  ._1nzuaz7t1 {
    margin: var(--space-32px);
  }
  ._1nzuaz7t7 {
    margin: var(--space-40px);
  }
  ._1nzuaz7td {
    margin: var(--space-48px);
  }
  ._1nzuaz7tj {
    margin: var(--space-56px);
  }
  ._1nzuaz7tp {
    margin: var(--space-64px);
  }
  ._1nzuaz7tv {
    margin: auto;
  }
  ._1nzuaz7u1 {
    padding: var(--space-0px);
  }
  ._1nzuaz7u7 {
    padding: var(--space-1rem);
  }
  ._1nzuaz7ud {
    padding: var(--space-1px);
  }
  ._1nzuaz7uj {
    padding: var(--space-2px);
  }
  ._1nzuaz7up {
    padding: var(--space-6px);
  }
  ._1nzuaz7uv {
    padding: var(--space-4px);
  }
  ._1nzuaz7v1 {
    padding: var(--space-8px);
  }
  ._1nzuaz7v7 {
    padding: var(--space-12px);
  }
  ._1nzuaz7vd {
    padding: var(--space-14px);
  }
  ._1nzuaz7vj {
    padding: var(--space-16px);
  }
  ._1nzuaz7vp {
    padding: var(--space-20px);
  }
  ._1nzuaz7vv {
    padding: var(--space-24px);
  }
  ._1nzuaz7w1 {
    padding: var(--space-32px);
  }
  ._1nzuaz7w7 {
    padding: var(--space-40px);
  }
  ._1nzuaz7wd {
    padding: var(--space-48px);
  }
  ._1nzuaz7wj {
    padding: var(--space-56px);
  }
  ._1nzuaz7wp {
    padding: var(--space-64px);
  }
  ._1nzuaz7wv {
    max-height: var(--space-0px);
  }
  ._1nzuaz7x1 {
    max-height: var(--space-1rem);
  }
  ._1nzuaz7x7 {
    max-height: var(--space-1px);
  }
  ._1nzuaz7xd {
    max-height: var(--space-2px);
  }
  ._1nzuaz7xj {
    max-height: var(--space-6px);
  }
  ._1nzuaz7xp {
    max-height: var(--space-4px);
  }
  ._1nzuaz7xv {
    max-height: var(--space-8px);
  }
  ._1nzuaz7y1 {
    max-height: var(--space-12px);
  }
  ._1nzuaz7y7 {
    max-height: var(--space-14px);
  }
  ._1nzuaz7yd {
    max-height: var(--space-16px);
  }
  ._1nzuaz7yj {
    max-height: var(--space-20px);
  }
  ._1nzuaz7yp {
    max-height: var(--space-24px);
  }
  ._1nzuaz7yv {
    max-height: var(--space-32px);
  }
  ._1nzuaz7z1 {
    max-height: var(--space-40px);
  }
  ._1nzuaz7z7 {
    max-height: var(--space-48px);
  }
  ._1nzuaz7zd {
    max-height: var(--space-56px);
  }
  ._1nzuaz7zj {
    max-height: var(--space-64px);
  }
  ._1nzuaz7zp {
    max-width: var(--space-0px);
  }
  ._1nzuaz7zv {
    max-width: var(--space-1rem);
  }
  ._1nzuaz7101 {
    max-width: var(--space-1px);
  }
  ._1nzuaz7107 {
    max-width: var(--space-2px);
  }
  ._1nzuaz710d {
    max-width: var(--space-6px);
  }
  ._1nzuaz710j {
    max-width: var(--space-4px);
  }
  ._1nzuaz710p {
    max-width: var(--space-8px);
  }
  ._1nzuaz710v {
    max-width: var(--space-12px);
  }
  ._1nzuaz7111 {
    max-width: var(--space-14px);
  }
  ._1nzuaz7117 {
    max-width: var(--space-16px);
  }
  ._1nzuaz711d {
    max-width: var(--space-20px);
  }
  ._1nzuaz711j {
    max-width: var(--space-24px);
  }
  ._1nzuaz711p {
    max-width: var(--space-32px);
  }
  ._1nzuaz711v {
    max-width: var(--space-40px);
  }
  ._1nzuaz7121 {
    max-width: var(--space-48px);
  }
  ._1nzuaz7127 {
    max-width: var(--space-56px);
  }
  ._1nzuaz712d {
    max-width: var(--space-64px);
  }
  ._1nzuaz712j {
    max-width: 100%;
  }
  ._1nzuaz712p {
    max-width: auto;
  }
  ._1nzuaz712v {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  ._1nzuaz7131 {
    max-width: 420px;
  }
  ._1nzuaz7137 {
    max-width: 576px;
  }
  ._1nzuaz713d {
    max-width: 768px;
  }
  ._1nzuaz713j {
    max-width: 968px;
  }
  ._1nzuaz713p {
    max-width: 1024px;
  }
  ._1nzuaz713v {
    max-width: none;
  }
  ._1nzuaz7141 {
    min-height: var(--space-0px);
  }
  ._1nzuaz7147 {
    min-height: var(--space-1rem);
  }
  ._1nzuaz714d {
    min-height: var(--space-1px);
  }
  ._1nzuaz714j {
    min-height: var(--space-2px);
  }
  ._1nzuaz714p {
    min-height: var(--space-6px);
  }
  ._1nzuaz714v {
    min-height: var(--space-4px);
  }
  ._1nzuaz7151 {
    min-height: var(--space-8px);
  }
  ._1nzuaz7157 {
    min-height: var(--space-12px);
  }
  ._1nzuaz715d {
    min-height: var(--space-14px);
  }
  ._1nzuaz715j {
    min-height: var(--space-16px);
  }
  ._1nzuaz715p {
    min-height: var(--space-20px);
  }
  ._1nzuaz715v {
    min-height: var(--space-24px);
  }
  ._1nzuaz7161 {
    min-height: var(--space-32px);
  }
  ._1nzuaz7167 {
    min-height: var(--space-40px);
  }
  ._1nzuaz716d {
    min-height: var(--space-48px);
  }
  ._1nzuaz716j {
    min-height: var(--space-56px);
  }
  ._1nzuaz716p {
    min-height: var(--space-64px);
  }
  ._1nzuaz716v {
    min-height: 100%;
  }
  ._1nzuaz7171 {
    min-height: auto;
  }
  ._1nzuaz7177 {
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
  ._1nzuaz717d {
    min-height: 420px;
  }
  ._1nzuaz717j {
    min-height: 576px;
  }
  ._1nzuaz717p {
    min-height: 768px;
  }
  ._1nzuaz717v {
    min-height: 968px;
  }
  ._1nzuaz7181 {
    min-height: 1024px;
  }
  ._1nzuaz7187 {
    min-height: 100vh;
  }
  ._1nzuaz718d {
    min-width: var(--space-0px);
  }
  ._1nzuaz718j {
    min-width: var(--space-1rem);
  }
  ._1nzuaz718p {
    min-width: var(--space-1px);
  }
  ._1nzuaz718v {
    min-width: var(--space-2px);
  }
  ._1nzuaz7191 {
    min-width: var(--space-6px);
  }
  ._1nzuaz7197 {
    min-width: var(--space-4px);
  }
  ._1nzuaz719d {
    min-width: var(--space-8px);
  }
  ._1nzuaz719j {
    min-width: var(--space-12px);
  }
  ._1nzuaz719p {
    min-width: var(--space-14px);
  }
  ._1nzuaz719v {
    min-width: var(--space-16px);
  }
  ._1nzuaz71a1 {
    min-width: var(--space-20px);
  }
  ._1nzuaz71a7 {
    min-width: var(--space-24px);
  }
  ._1nzuaz71ad {
    min-width: var(--space-32px);
  }
  ._1nzuaz71aj {
    min-width: var(--space-40px);
  }
  ._1nzuaz71ap {
    min-width: var(--space-48px);
  }
  ._1nzuaz71av {
    min-width: var(--space-56px);
  }
  ._1nzuaz71b1 {
    min-width: var(--space-64px);
  }
  ._1nzuaz71b7 {
    padding-bottom: var(--space-0px);
  }
  ._1nzuaz71bd {
    padding-bottom: var(--space-1rem);
  }
  ._1nzuaz71bj {
    padding-bottom: var(--space-1px);
  }
  ._1nzuaz71bp {
    padding-bottom: var(--space-2px);
  }
  ._1nzuaz71bv {
    padding-bottom: var(--space-6px);
  }
  ._1nzuaz71c1 {
    padding-bottom: var(--space-4px);
  }
  ._1nzuaz71c7 {
    padding-bottom: var(--space-8px);
  }
  ._1nzuaz71cd {
    padding-bottom: var(--space-12px);
  }
  ._1nzuaz71cj {
    padding-bottom: var(--space-14px);
  }
  ._1nzuaz71cp {
    padding-bottom: var(--space-16px);
  }
  ._1nzuaz71cv {
    padding-bottom: var(--space-20px);
  }
  ._1nzuaz71d1 {
    padding-bottom: var(--space-24px);
  }
  ._1nzuaz71d7 {
    padding-bottom: var(--space-32px);
  }
  ._1nzuaz71dd {
    padding-bottom: var(--space-40px);
  }
  ._1nzuaz71dj {
    padding-bottom: var(--space-48px);
  }
  ._1nzuaz71dp {
    padding-bottom: var(--space-56px);
  }
  ._1nzuaz71dv {
    padding-bottom: var(--space-64px);
  }
  ._1nzuaz71e1 {
    padding-left: var(--space-0px);
  }
  ._1nzuaz71e7 {
    padding-left: var(--space-1rem);
  }
  ._1nzuaz71ed {
    padding-left: var(--space-1px);
  }
  ._1nzuaz71ej {
    padding-left: var(--space-2px);
  }
  ._1nzuaz71ep {
    padding-left: var(--space-6px);
  }
  ._1nzuaz71ev {
    padding-left: var(--space-4px);
  }
  ._1nzuaz71f1 {
    padding-left: var(--space-8px);
  }
  ._1nzuaz71f7 {
    padding-left: var(--space-12px);
  }
  ._1nzuaz71fd {
    padding-left: var(--space-14px);
  }
  ._1nzuaz71fj {
    padding-left: var(--space-16px);
  }
  ._1nzuaz71fp {
    padding-left: var(--space-20px);
  }
  ._1nzuaz71fv {
    padding-left: var(--space-24px);
  }
  ._1nzuaz71g1 {
    padding-left: var(--space-32px);
  }
  ._1nzuaz71g7 {
    padding-left: var(--space-40px);
  }
  ._1nzuaz71gd {
    padding-left: var(--space-48px);
  }
  ._1nzuaz71gj {
    padding-left: var(--space-56px);
  }
  ._1nzuaz71gp {
    padding-left: var(--space-64px);
  }
  ._1nzuaz71gv {
    padding-right: var(--space-0px);
  }
  ._1nzuaz71h1 {
    padding-right: var(--space-1rem);
  }
  ._1nzuaz71h7 {
    padding-right: var(--space-1px);
  }
  ._1nzuaz71hd {
    padding-right: var(--space-2px);
  }
  ._1nzuaz71hj {
    padding-right: var(--space-6px);
  }
  ._1nzuaz71hp {
    padding-right: var(--space-4px);
  }
  ._1nzuaz71hv {
    padding-right: var(--space-8px);
  }
  ._1nzuaz71i1 {
    padding-right: var(--space-12px);
  }
  ._1nzuaz71i7 {
    padding-right: var(--space-14px);
  }
  ._1nzuaz71id {
    padding-right: var(--space-16px);
  }
  ._1nzuaz71ij {
    padding-right: var(--space-20px);
  }
  ._1nzuaz71ip {
    padding-right: var(--space-24px);
  }
  ._1nzuaz71iv {
    padding-right: var(--space-32px);
  }
  ._1nzuaz71j1 {
    padding-right: var(--space-40px);
  }
  ._1nzuaz71j7 {
    padding-right: var(--space-48px);
  }
  ._1nzuaz71jd {
    padding-right: var(--space-56px);
  }
  ._1nzuaz71jj {
    padding-right: var(--space-64px);
  }
  ._1nzuaz71jp {
    padding-top: var(--space-0px);
  }
  ._1nzuaz71jv {
    padding-top: var(--space-1rem);
  }
  ._1nzuaz71k1 {
    padding-top: var(--space-1px);
  }
  ._1nzuaz71k7 {
    padding-top: var(--space-2px);
  }
  ._1nzuaz71kd {
    padding-top: var(--space-6px);
  }
  ._1nzuaz71kj {
    padding-top: var(--space-4px);
  }
  ._1nzuaz71kp {
    padding-top: var(--space-8px);
  }
  ._1nzuaz71kv {
    padding-top: var(--space-12px);
  }
  ._1nzuaz71l1 {
    padding-top: var(--space-14px);
  }
  ._1nzuaz71l7 {
    padding-top: var(--space-16px);
  }
  ._1nzuaz71ld {
    padding-top: var(--space-20px);
  }
  ._1nzuaz71lj {
    padding-top: var(--space-24px);
  }
  ._1nzuaz71lp {
    padding-top: var(--space-32px);
  }
  ._1nzuaz71lv {
    padding-top: var(--space-40px);
  }
  ._1nzuaz71m1 {
    padding-top: var(--space-48px);
  }
  ._1nzuaz71m7 {
    padding-top: var(--space-56px);
  }
  ._1nzuaz71md {
    padding-top: var(--space-64px);
  }
  ._1nzuaz71mj {
    font-size: var(--fontSizes-10px);
  }
  ._1nzuaz71mp {
    font-size: var(--fontSizes-12px);
  }
  ._1nzuaz71mv {
    font-size: var(--fontSizes-16px);
  }
  ._1nzuaz71n1 {
    font-size: var(--fontSizes-14px);
  }
  ._1nzuaz71n7 {
    font-size: var(--fontSizes-20px);
  }
  ._1nzuaz71nd {
    font-size: var(--fontSizes-40px);
  }
  ._1nzuaz71nj {
    font-size: inherit;
  }
  ._1nzuaz71np {
    flex: 1 1;
  }
  ._1nzuaz71nv {
    flex: 1 1 auto;
  }
  ._1nzuaz71o1 {
    flex: 0 1 auto;
  }
  ._1nzuaz71o7 {
    flex: none;
  }
  ._1nzuaz71od {
    box-shadow: var(--shadows-level1);
  }
  ._1nzuaz71oj {
    box-shadow: var(--shadows-active);
  }
  ._1nzuaz71op {
    box-shadow: var(--shadows-success);
  }
  ._1nzuaz71ov {
    box-shadow: var(--shadows-warning);
  }
  ._1nzuaz71p1 {
    box-shadow: var(--shadows-danger);
  }
  ._1nzuaz71p7 {
    box-shadow: var(--shadows-focus);
  }
  ._1nzuaz71pd {
    box-shadow: var(--shadows-inset);
  }
  ._1nzuaz71pj {
    box-shadow: var(--shadows-tooltip);
  }
  ._1nzuaz71pp {
    width: var(--space-0px);
  }
  ._1nzuaz71pv {
    width: var(--space-1rem);
  }
  ._1nzuaz71q1 {
    width: var(--space-1px);
  }
  ._1nzuaz71q7 {
    width: var(--space-2px);
  }
  ._1nzuaz71qd {
    width: var(--space-6px);
  }
  ._1nzuaz71qj {
    width: var(--space-4px);
  }
  ._1nzuaz71qp {
    width: var(--space-8px);
  }
  ._1nzuaz71qv {
    width: var(--space-12px);
  }
  ._1nzuaz71r1 {
    width: var(--space-14px);
  }
  ._1nzuaz71r7 {
    width: var(--space-16px);
  }
  ._1nzuaz71rd {
    width: var(--space-20px);
  }
  ._1nzuaz71rj {
    width: var(--space-24px);
  }
  ._1nzuaz71rp {
    width: var(--space-32px);
  }
  ._1nzuaz71rv {
    width: var(--space-40px);
  }
  ._1nzuaz71s1 {
    width: var(--space-48px);
  }
  ._1nzuaz71s7 {
    width: var(--space-56px);
  }
  ._1nzuaz71sd {
    width: var(--space-64px);
  }
  ._1nzuaz71sj {
    width: 100%;
  }
  ._1nzuaz71sp {
    width: auto;
  }
  ._1nzuaz71sv {
    width: -moz-fit-content;
    width: fit-content;
  }
  ._1nzuaz71t1 {
    width: 420px;
  }
  ._1nzuaz71t7 {
    width: 576px;
  }
  ._1nzuaz71td {
    width: 768px;
  }
  ._1nzuaz71tj {
    width: 968px;
  }
  ._1nzuaz71tp {
    width: 1024px;
  }
  ._1nzuaz71tv {
    z-index: 0;
  }
  ._1nzuaz71u1 {
    z-index: 1;
  }
  ._1nzuaz71u7 {
    z-index: 10;
  }
  ._1nzuaz71ud {
    z-index: 20;
  }
  ._1nzuaz71uj {
    z-index: 30;
  }
  ._1nzuaz71up {
    z-index: 40;
  }
  ._1nzuaz71uv {
    z-index: 50;
  }
  ._1nzuaz71v1 {
    z-index: 75;
  }
  ._1nzuaz71v7 {
    z-index: 99;
  }
  ._1nzuaz71vd {
    z-index: 100;
  }
  ._1nzuaz71vj {
    z-index: 9;
  }
  ._1nzuaz71vp {
    z-index: 10;
  }
  ._1nzuaz71vv {
    z-index: 100;
  }
  ._1nzuaz71w1 {
    z-index: auto;
  }
  ._1nzuaz71w7 {
    border-top: 1px solid var(--colors-cardBorder);
  }
  ._1nzuaz71wd {
    border-radius: var(--radii-0);
  }
  ._1nzuaz71wj {
    border-radius: var(--radii-8px);
  }
  ._1nzuaz71wp {
    border-radius: var(--radii-12px);
  }
  ._1nzuaz71wv {
    border-radius: var(--radii-20px);
  }
  ._1nzuaz71x1 {
    border-radius: var(--radii-32px);
  }
  ._1nzuaz71x7 {
    border-radius: var(--radii-small);
  }
  ._1nzuaz71xd {
    border-radius: var(--radii-default);
  }
  ._1nzuaz71xj {
    border-radius: var(--radii-card);
  }
  ._1nzuaz71xp {
    border-radius: var(--radii-circle);
  }
  ._1nzuaz71xv {
    border-top-left-radius: var(--radii-0);
  }
  ._1nzuaz71y1 {
    border-top-left-radius: var(--radii-8px);
  }
  ._1nzuaz71y7 {
    border-top-left-radius: var(--radii-12px);
  }
  ._1nzuaz71yd {
    border-top-left-radius: var(--radii-20px);
  }
  ._1nzuaz71yj {
    border-top-left-radius: var(--radii-32px);
  }
  ._1nzuaz71yp {
    border-top-left-radius: var(--radii-small);
  }
  ._1nzuaz71yv {
    border-top-left-radius: var(--radii-default);
  }
  ._1nzuaz71z1 {
    border-top-left-radius: var(--radii-card);
  }
  ._1nzuaz71z7 {
    border-top-left-radius: var(--radii-circle);
  }
  ._1nzuaz71zd {
    border-bottom-right-radius: var(--radii-0);
  }
  ._1nzuaz71zj {
    border-bottom-right-radius: var(--radii-8px);
  }
  ._1nzuaz71zp {
    border-bottom-right-radius: var(--radii-12px);
  }
  ._1nzuaz71zv {
    border-bottom-right-radius: var(--radii-20px);
  }
  ._1nzuaz7201 {
    border-bottom-right-radius: var(--radii-32px);
  }
  ._1nzuaz7207 {
    border-bottom-right-radius: var(--radii-small);
  }
  ._1nzuaz720d {
    border-bottom-right-radius: var(--radii-default);
  }
  ._1nzuaz720j {
    border-bottom-right-radius: var(--radii-card);
  }
  ._1nzuaz720p {
    border-bottom-right-radius: var(--radii-circle);
  }
  ._1nzuaz720v {
    border-top-right-radius: var(--radii-0);
  }
  ._1nzuaz7211 {
    border-top-right-radius: var(--radii-8px);
  }
  ._1nzuaz7217 {
    border-top-right-radius: var(--radii-12px);
  }
  ._1nzuaz721d {
    border-top-right-radius: var(--radii-20px);
  }
  ._1nzuaz721j {
    border-top-right-radius: var(--radii-32px);
  }
  ._1nzuaz721p {
    border-top-right-radius: var(--radii-small);
  }
  ._1nzuaz721v {
    border-top-right-radius: var(--radii-default);
  }
  ._1nzuaz7221 {
    border-top-right-radius: var(--radii-card);
  }
  ._1nzuaz7227 {
    border-top-right-radius: var(--radii-circle);
  }
  ._1nzuaz722d {
    border-bottom-left-radius: var(--radii-0);
  }
  ._1nzuaz722j {
    border-bottom-left-radius: var(--radii-8px);
  }
  ._1nzuaz722p {
    border-bottom-left-radius: var(--radii-12px);
  }
  ._1nzuaz722v {
    border-bottom-left-radius: var(--radii-20px);
  }
  ._1nzuaz7231 {
    border-bottom-left-radius: var(--radii-32px);
  }
  ._1nzuaz7237 {
    border-bottom-left-radius: var(--radii-small);
  }
  ._1nzuaz723d {
    border-bottom-left-radius: var(--radii-default);
  }
  ._1nzuaz723j {
    border-bottom-left-radius: var(--radii-card);
  }
  ._1nzuaz723p {
    border-bottom-left-radius: var(--radii-circle);
  }
  ._1nzuaz723v {
    gap: var(--space-0px);
  }
  ._1nzuaz7241 {
    gap: var(--space-1rem);
  }
  ._1nzuaz7247 {
    gap: var(--space-1px);
  }
  ._1nzuaz724d {
    gap: var(--space-2px);
  }
  ._1nzuaz724j {
    gap: var(--space-6px);
  }
  ._1nzuaz724p {
    gap: var(--space-4px);
  }
  ._1nzuaz724v {
    gap: var(--space-8px);
  }
  ._1nzuaz7251 {
    gap: var(--space-12px);
  }
  ._1nzuaz7257 {
    gap: var(--space-14px);
  }
  ._1nzuaz725d {
    gap: var(--space-16px);
  }
  ._1nzuaz725j {
    gap: var(--space-20px);
  }
  ._1nzuaz725p {
    gap: var(--space-24px);
  }
  ._1nzuaz725v {
    gap: var(--space-32px);
  }
  ._1nzuaz7261 {
    gap: var(--space-40px);
  }
  ._1nzuaz7267 {
    gap: var(--space-48px);
  }
  ._1nzuaz726d {
    gap: var(--space-56px);
  }
  ._1nzuaz726j {
    gap: var(--space-64px);
  }
  ._1nzuaz726p {
    gap: var(--space-8px);
  }
  ._1nzuaz726v {
    gap: var(--space-12px);
  }
  ._1nzuaz7271 {
    gap: var(--space-24px);
  }
  ._1nzuaz7277 {
    row-gap: var(--space-0px);
  }
  ._1nzuaz727d {
    row-gap: var(--space-1rem);
  }
  ._1nzuaz727j {
    row-gap: var(--space-1px);
  }
  ._1nzuaz727p {
    row-gap: var(--space-2px);
  }
  ._1nzuaz727v {
    row-gap: var(--space-6px);
  }
  ._1nzuaz7281 {
    row-gap: var(--space-4px);
  }
  ._1nzuaz7287 {
    row-gap: var(--space-8px);
  }
  ._1nzuaz728d {
    row-gap: var(--space-12px);
  }
  ._1nzuaz728j {
    row-gap: var(--space-14px);
  }
  ._1nzuaz728p {
    row-gap: var(--space-16px);
  }
  ._1nzuaz728v {
    row-gap: var(--space-20px);
  }
  ._1nzuaz7291 {
    row-gap: var(--space-24px);
  }
  ._1nzuaz7297 {
    row-gap: var(--space-32px);
  }
  ._1nzuaz729d {
    row-gap: var(--space-40px);
  }
  ._1nzuaz729j {
    row-gap: var(--space-48px);
  }
  ._1nzuaz729p {
    row-gap: var(--space-56px);
  }
  ._1nzuaz729v {
    row-gap: var(--space-64px);
  }
  ._1nzuaz72a1 {
    row-gap: var(--space-8px);
  }
  ._1nzuaz72a7 {
    row-gap: var(--space-12px);
  }
  ._1nzuaz72ad {
    row-gap: var(--space-24px);
  }
  ._1nzuaz72aj {
    column-gap: var(--space-0px);
  }
  ._1nzuaz72ap {
    column-gap: var(--space-1rem);
  }
  ._1nzuaz72av {
    column-gap: var(--space-1px);
  }
  ._1nzuaz72b1 {
    column-gap: var(--space-2px);
  }
  ._1nzuaz72b7 {
    column-gap: var(--space-6px);
  }
  ._1nzuaz72bd {
    column-gap: var(--space-4px);
  }
  ._1nzuaz72bj {
    column-gap: var(--space-8px);
  }
  ._1nzuaz72bp {
    column-gap: var(--space-12px);
  }
  ._1nzuaz72bv {
    column-gap: var(--space-14px);
  }
  ._1nzuaz72c1 {
    column-gap: var(--space-16px);
  }
  ._1nzuaz72c7 {
    column-gap: var(--space-20px);
  }
  ._1nzuaz72cd {
    column-gap: var(--space-24px);
  }
  ._1nzuaz72cj {
    column-gap: var(--space-32px);
  }
  ._1nzuaz72cp {
    column-gap: var(--space-40px);
  }
  ._1nzuaz72cv {
    column-gap: var(--space-48px);
  }
  ._1nzuaz72d1 {
    column-gap: var(--space-56px);
  }
  ._1nzuaz72d7 {
    column-gap: var(--space-64px);
  }
  ._1nzuaz72dd {
    column-gap: var(--space-8px);
  }
  ._1nzuaz72dj {
    column-gap: var(--space-12px);
  }
  ._1nzuaz72dp {
    column-gap: var(--space-24px);
  }
  ._1nzuaz72dv {
    grid-auto-rows: auto;
  }
  ._1nzuaz72e1 {
    opacity: 0;
  }
  ._1nzuaz72e7 {
    opacity: 1;
  }
  ._1nzuaz72ed {
    opacity: 0.5;
  }
  ._1nzuaz72ej {
    opacity: 0.6;
  }
  ._1nzuaz72ep {
    line-height: var(--space-16px);
  }
  ._1nzuaz72ev {
    white50: var(--colors-light-white50);
    white100: var(--colors-light-white100);
    white200: var(--colors-light-white200);
    white300: var(--colors-light-white300);
    white500: var(--colors-light-white500);
    white800: var(--colors-light-white800);
    white: var(--colors-light-white);
    black: var(--colors-light-black);
    failure: var(--colors-light-failure);
    failure33: var(--colors-light-failure33);
    red100: var(--colors-light-red100);
    primary: var(--colors-light-primary);
    primary0f: var(--colors-light-primary0f);
    primary3-d: var(--colors-light-primary3D);
    primary-bright: var(--colors-light-primaryBright);
    primary-dark: var(--colors-light-primaryDark);
    success: var(--colors-light-success);
    success19: var(--colors-light-success19);
    warning: var(--colors-light-warning);
    warning2-d: var(--colors-light-warning2D);
    warning33: var(--colors-light-warning33);
    yellow100: var(--colors-light-yellow100);
    gray50: var(--colors-light-gray50);
    gray100: var(--colors-light-gray100);
    gray200: var(--colors-light-gray200);
    gray300: var(--colors-light-gray300);
    gray390: var(--colors-light-gray390);
    gray400: var(--colors-light-gray400);
    gray500: var(--colors-light-gray500);
    gray700: var(--colors-light-gray700);
    gray800: var(--colors-light-gray800);
    gray850: var(--colors-light-gray850);
    gray900: var(--colors-light-gray900);
    gray1000: var(--colors-light-gray1000);
    tint-gray80: var(--colors-light-tintGray80);
    gradient-primary: var(--colors-light-gradientPrimary);
    gradient-primary10: var(--colors-light-gradientPrimary10);
    gradient-primary20: var(--colors-light-gradientPrimary20);
    gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
    orange100: var(--colors-light-orange100);
    orange: var(--colors-light-orange);
    purple: var(--colors-light-purple);
    purple60: var(--colors-light-purple60);
    orange-dark: var(--colors-light-orangeDark);
    binance: var(--colors-light-binance);
    overlay: var(--colors-light-overlay);
    gold: var(--colors-light-gold);
    silver: var(--colors-light-silver);
    bronze: var(--colors-light-bronze);
    secondary: var(--colors-light-secondary);
    secondary80: var(--colors-light-secondary80);
    background: var(--colors-light-background);
    background-disabled: var(--colors-light-backgroundDisabled);
    background-alt: var(--colors-light-backgroundAlt);
    background-alt2: var(--colors-light-backgroundAlt2);
    card-border: var(--colors-light-cardBorder);
    contrast: var(--colors-light-contrast);
    dropdown: var(--colors-light-dropdown);
    dropdown-deep: var(--colors-light-dropdownDeep);
    inverted-contrast: var(--colors-light-invertedContrast);
    input: var(--colors-light-input);
    input-secondary: var(--colors-light-inputSecondary);
    tertiary: var(--colors-light-tertiary);
    text: var(--colors-light-text);
    text99: var(--colors-light-text99);
    text-disabled: var(--colors-light-textDisabled);
    text-subtle: var(--colors-light-textSubtle);
    disabled: var(--colors-light-disabled);
    gradient-bubblegum: var(--colors-light-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
    gradient-card-header: var(--colors-light-gradientCardHeader);
    gradient-blue: var(--colors-light-gradientBlue);
    gradient-violet: var(--colors-light-gradientViolet);
    gradient-violet-alt: var(--colors-light-gradientVioletAlt);
    gradient-gold: var(--colors-light-gradientGold);
    gradient-bold: var(--colors-light-gradientBold);
  }
  ._1nzuaz72f1 {
    white50: var(--colors-dark-white50);
    white100: var(--colors-dark-white100);
    white200: var(--colors-dark-white200);
    white300: var(--colors-dark-white300);
    white500: var(--colors-dark-white500);
    white800: var(--colors-dark-white800);
    white: var(--colors-dark-white);
    black: var(--colors-dark-black);
    failure: var(--colors-dark-failure);
    failure33: var(--colors-dark-failure33);
    red100: var(--colors-dark-red100);
    primary: var(--colors-dark-primary);
    primary0f: var(--colors-dark-primary0f);
    primary3-d: var(--colors-dark-primary3D);
    primary-bright: var(--colors-dark-primaryBright);
    primary-dark: var(--colors-dark-primaryDark);
    success: var(--colors-dark-success);
    success19: var(--colors-dark-success19);
    warning: var(--colors-dark-warning);
    warning2-d: var(--colors-dark-warning2D);
    warning33: var(--colors-dark-warning33);
    yellow100: var(--colors-dark-yellow100);
    gray50: var(--colors-dark-gray50);
    gray100: var(--colors-dark-gray100);
    gray200: var(--colors-dark-gray200);
    gray300: var(--colors-dark-gray300);
    gray390: var(--colors-dark-gray390);
    gray400: var(--colors-dark-gray400);
    gray500: var(--colors-dark-gray500);
    gray700: var(--colors-dark-gray700);
    gray800: var(--colors-dark-gray800);
    gray850: var(--colors-dark-gray850);
    gray900: var(--colors-dark-gray900);
    gray1000: var(--colors-dark-gray1000);
    tint-gray80: var(--colors-dark-tintGray80);
    gradient-primary: var(--colors-dark-gradientPrimary);
    gradient-primary10: var(--colors-dark-gradientPrimary10);
    gradient-primary20: var(--colors-dark-gradientPrimary20);
    gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
    orange100: var(--colors-dark-orange100);
    orange: var(--colors-dark-orange);
    purple: var(--colors-dark-purple);
    purple60: var(--colors-dark-purple60);
    orange-dark: var(--colors-dark-orangeDark);
    binance: var(--colors-dark-binance);
    overlay: var(--colors-dark-overlay);
    gold: var(--colors-dark-gold);
    silver: var(--colors-dark-silver);
    bronze: var(--colors-dark-bronze);
    secondary: var(--colors-dark-secondary);
    secondary80: var(--colors-dark-secondary80);
    background: var(--colors-dark-background);
    background-disabled: var(--colors-dark-backgroundDisabled);
    background-alt: var(--colors-dark-backgroundAlt);
    background-alt2: var(--colors-dark-backgroundAlt2);
    card-border: var(--colors-dark-cardBorder);
    contrast: var(--colors-dark-contrast);
    dropdown: var(--colors-dark-dropdown);
    dropdown-deep: var(--colors-dark-dropdownDeep);
    inverted-contrast: var(--colors-dark-invertedContrast);
    input: var(--colors-dark-input);
    input-secondary: var(--colors-dark-inputSecondary);
    tertiary: var(--colors-dark-tertiary);
    text: var(--colors-dark-text);
    text99: var(--colors-dark-text99);
    text-disabled: var(--colors-dark-textDisabled);
    text-subtle: var(--colors-dark-textSubtle);
    disabled: var(--colors-dark-disabled);
    gradient-bubblegum: var(--colors-dark-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
    gradient-card-header: var(--colors-dark-gradientCardHeader);
    gradient-blue: var(--colors-dark-gradientBlue);
    gradient-violet: var(--colors-dark-gradientViolet);
    gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
    gradient-gold: var(--colors-dark-gradientGold);
    gradient-bold: var(--colors-dark-gradientBold);
  }
  ._1nzuaz72f7 {
    border-bottom-color: var(--colors-white50);
  }
  ._1nzuaz72fd {
    border-bottom-color: var(--colors-white100);
  }
  ._1nzuaz72fj {
    border-bottom-color: var(--colors-white200);
  }
  ._1nzuaz72fp {
    border-bottom-color: var(--colors-white300);
  }
  ._1nzuaz72fv {
    border-bottom-color: var(--colors-white500);
  }
  ._1nzuaz72g1 {
    border-bottom-color: var(--colors-white800);
  }
  ._1nzuaz72g7 {
    border-bottom-color: var(--colors-white);
  }
  ._1nzuaz72gd {
    border-bottom-color: var(--colors-black);
  }
  ._1nzuaz72gj {
    border-bottom-color: var(--colors-failure);
  }
  ._1nzuaz72gp {
    border-bottom-color: var(--colors-failure33);
  }
  ._1nzuaz72gv {
    border-bottom-color: var(--colors-red100);
  }
  ._1nzuaz72h1 {
    border-bottom-color: var(--colors-primary);
  }
  ._1nzuaz72h7 {
    border-bottom-color: var(--colors-primary0f);
  }
  ._1nzuaz72hd {
    border-bottom-color: var(--colors-primary3D);
  }
  ._1nzuaz72hj {
    border-bottom-color: var(--colors-primaryBright);
  }
  ._1nzuaz72hp {
    border-bottom-color: var(--colors-primaryDark);
  }
  ._1nzuaz72hv {
    border-bottom-color: var(--colors-success);
  }
  ._1nzuaz72i1 {
    border-bottom-color: var(--colors-success19);
  }
  ._1nzuaz72i7 {
    border-bottom-color: var(--colors-warning);
  }
  ._1nzuaz72id {
    border-bottom-color: var(--colors-warning2D);
  }
  ._1nzuaz72ij {
    border-bottom-color: var(--colors-warning33);
  }
  ._1nzuaz72ip {
    border-bottom-color: var(--colors-yellow100);
  }
  ._1nzuaz72iv {
    border-bottom-color: var(--colors-gray50);
  }
  ._1nzuaz72j1 {
    border-bottom-color: var(--colors-gray100);
  }
  ._1nzuaz72j7 {
    border-bottom-color: var(--colors-gray200);
  }
  ._1nzuaz72jd {
    border-bottom-color: var(--colors-gray300);
  }
  ._1nzuaz72jj {
    border-bottom-color: var(--colors-gray390);
  }
  ._1nzuaz72jp {
    border-bottom-color: var(--colors-gray400);
  }
  ._1nzuaz72jv {
    border-bottom-color: var(--colors-gray500);
  }
  ._1nzuaz72k1 {
    border-bottom-color: var(--colors-gray700);
  }
  ._1nzuaz72k7 {
    border-bottom-color: var(--colors-gray800);
  }
  ._1nzuaz72kd {
    border-bottom-color: var(--colors-gray850);
  }
  ._1nzuaz72kj {
    border-bottom-color: var(--colors-gray900);
  }
  ._1nzuaz72kp {
    border-bottom-color: var(--colors-gray1000);
  }
  ._1nzuaz72kv {
    border-bottom-color: var(--colors-tintGray80);
  }
  ._1nzuaz72l1 {
    border-bottom-color: var(--colors-gradientPrimary);
  }
  ._1nzuaz72l7 {
    border-bottom-color: var(--colors-gradientPrimary10);
  }
  ._1nzuaz72ld {
    border-bottom-color: var(--colors-gradientPrimary20);
  }
  ._1nzuaz72lj {
    border-bottom-color: var(--colors-gradientPrimaryRadial);
  }
  ._1nzuaz72lp {
    border-bottom-color: var(--colors-orange100);
  }
  ._1nzuaz72lv {
    border-bottom-color: var(--colors-orange);
  }
  ._1nzuaz72m1 {
    border-bottom-color: var(--colors-purple);
  }
  ._1nzuaz72m7 {
    border-bottom-color: var(--colors-purple60);
  }
  ._1nzuaz72md {
    border-bottom-color: var(--colors-orangeDark);
  }
  ._1nzuaz72mj {
    border-bottom-color: var(--colors-binance);
  }
  ._1nzuaz72mp {
    border-bottom-color: var(--colors-overlay);
  }
  ._1nzuaz72mv {
    border-bottom-color: var(--colors-gold);
  }
  ._1nzuaz72n1 {
    border-bottom-color: var(--colors-silver);
  }
  ._1nzuaz72n7 {
    border-bottom-color: var(--colors-bronze);
  }
  ._1nzuaz72nd {
    border-bottom-color: var(--colors-secondary);
  }
  ._1nzuaz72nj {
    border-bottom-color: var(--colors-secondary80);
  }
  ._1nzuaz72np {
    border-bottom-color: var(--colors-background);
  }
  ._1nzuaz72nv {
    border-bottom-color: var(--colors-backgroundDisabled);
  }
  ._1nzuaz72o1 {
    border-bottom-color: var(--colors-backgroundAlt);
  }
  ._1nzuaz72o7 {
    border-bottom-color: var(--colors-backgroundAlt2);
  }
  ._1nzuaz72od {
    border-bottom-color: var(--colors-cardBorder);
  }
  ._1nzuaz72oj {
    border-bottom-color: var(--colors-contrast);
  }
  ._1nzuaz72op {
    border-bottom-color: var(--colors-dropdown);
  }
  ._1nzuaz72ov {
    border-bottom-color: var(--colors-dropdownDeep);
  }
  ._1nzuaz72p1 {
    border-bottom-color: var(--colors-invertedContrast);
  }
  ._1nzuaz72p7 {
    border-bottom-color: var(--colors-input);
  }
  ._1nzuaz72pd {
    border-bottom-color: var(--colors-inputSecondary);
  }
  ._1nzuaz72pj {
    border-bottom-color: var(--colors-tertiary);
  }
  ._1nzuaz72pp {
    border-bottom-color: var(--colors-text);
  }
  ._1nzuaz72pv {
    border-bottom-color: var(--colors-text99);
  }
  ._1nzuaz72q1 {
    border-bottom-color: var(--colors-textDisabled);
  }
  ._1nzuaz72q7 {
    border-bottom-color: var(--colors-textSubtle);
  }
  ._1nzuaz72qd {
    border-bottom-color: var(--colors-disabled);
  }
  ._1nzuaz72qj {
    border-bottom-color: var(--colors-gradientBubblegum);
  }
  ._1nzuaz72qp {
    border-bottom-color: var(--colors-gradientInverseBubblegum);
  }
  ._1nzuaz72qv {
    border-bottom-color: var(--colors-gradientCardHeader);
  }
  ._1nzuaz72r1 {
    border-bottom-color: var(--colors-gradientBlue);
  }
  ._1nzuaz72r7 {
    border-bottom-color: var(--colors-gradientViolet);
  }
  ._1nzuaz72rd {
    border-bottom-color: var(--colors-gradientVioletAlt);
  }
  ._1nzuaz72rj {
    border-bottom-color: var(--colors-gradientGold);
  }
  ._1nzuaz72rp {
    border-bottom-color: var(--colors-gradientBold);
  }
  ._1nzuaz72rv {
    border: 1px solid var(--colors-cardBorder);
  }
  ._1nzuaz72s1 {
    border-bottom: 1px solid var(--colors-cardBorder);
  }
}
@media (min-width: 768px) {
  ._1nzuaz72 {
    display: block;
  }
  ._1nzuaz78 {
    display: flex;
  }
  ._1nzuaz7e {
    display: grid;
  }
  ._1nzuaz7k {
    display: inline;
  }
  ._1nzuaz7q {
    display: inline-flex;
  }
  ._1nzuaz7w {
    display: inline-block;
  }
  ._1nzuaz712 {
    display: none;
  }
  ._1nzuaz718 {
    flex-direction: column;
  }
  ._1nzuaz71e {
    flex-direction: row;
  }
  ._1nzuaz71k {
    flex-direction: column-reverse;
  }
  ._1nzuaz71q {
    align-items: center;
  }
  ._1nzuaz71w {
    align-items: end;
  }
  ._1nzuaz722 {
    align-items: baseLine;
  }
  ._1nzuaz728 {
    align-items: inherit;
  }
  ._1nzuaz72e {
    align-items: flex-start;
  }
  ._1nzuaz72k {
    align-items: center;
  }
  ._1nzuaz72q {
    align-items: start;
  }
  ._1nzuaz72w {
    align-items: flex-end;
  }
  ._1nzuaz732 {
    align-items: stretch;
  }
  ._1nzuaz738 {
    align-self: flex-start;
  }
  ._1nzuaz73e {
    align-self: center;
  }
  ._1nzuaz73k {
    align-self: start;
  }
  ._1nzuaz73q {
    align-self: flex-end;
  }
  ._1nzuaz73w {
    align-self: stretch;
  }
  ._1nzuaz742 {
    flex-wrap: wrap;
  }
  ._1nzuaz748 {
    flex-wrap: nowrap;
  }
  ._1nzuaz74e {
    flex-grow: 1;
  }
  ._1nzuaz74k {
    overflow: auto;
  }
  ._1nzuaz74q {
    overflow: hidden;
  }
  ._1nzuaz74w {
    overflow: scroll;
  }
  ._1nzuaz752 {
    overflow: unset;
  }
  ._1nzuaz758 {
    overflow-y: auto;
  }
  ._1nzuaz75e {
    overflow-y: hidden;
  }
  ._1nzuaz75k {
    overflow-y: scroll;
  }
  ._1nzuaz75q {
    overflow-x: auto;
  }
  ._1nzuaz75w {
    overflow-x: hidden;
  }
  ._1nzuaz762 {
    overflow-x: scroll;
  }
  ._1nzuaz768 {
    position: absolute;
  }
  ._1nzuaz76e {
    position: fixed;
  }
  ._1nzuaz76k {
    position: relative;
  }
  ._1nzuaz76q {
    position: sticky;
  }
  ._1nzuaz76w {
    text-align: center;
  }
  ._1nzuaz772 {
    text-align: left;
  }
  ._1nzuaz778 {
    text-align: right;
  }
  ._1nzuaz77e {
    justify-content: flex-start;
  }
  ._1nzuaz77k {
    justify-content: center;
  }
  ._1nzuaz77q {
    justify-content: start;
  }
  ._1nzuaz77w {
    justify-content: flex-end;
  }
  ._1nzuaz782 {
    justify-content: stretch;
  }
  ._1nzuaz788 {
    justify-content: space-around;
  }
  ._1nzuaz78e {
    justify-content: space-between;
  }
  ._1nzuaz78k {
    justify-items: flex-start;
  }
  ._1nzuaz78q {
    justify-items: center;
  }
  ._1nzuaz78w {
    justify-items: start;
  }
  ._1nzuaz792 {
    justify-items: flex-end;
  }
  ._1nzuaz798 {
    justify-items: stretch;
  }
  ._1nzuaz79e {
    justify-items: space-around;
  }
  ._1nzuaz79k {
    justify-items: space-between;
  }
  ._1nzuaz79q {
    justify-self: flex-start;
  }
  ._1nzuaz79w {
    justify-self: center;
  }
  ._1nzuaz7a2 {
    justify-self: start;
  }
  ._1nzuaz7a8 {
    justify-self: flex-end;
  }
  ._1nzuaz7ae {
    justify-self: stretch;
  }
  ._1nzuaz7ak {
    inset: 0px;
  }
  ._1nzuaz7aq {
    height: var(--space-0px);
  }
  ._1nzuaz7aw {
    height: var(--space-1rem);
  }
  ._1nzuaz7b2 {
    height: var(--space-1px);
  }
  ._1nzuaz7b8 {
    height: var(--space-2px);
  }
  ._1nzuaz7be {
    height: var(--space-6px);
  }
  ._1nzuaz7bk {
    height: var(--space-4px);
  }
  ._1nzuaz7bq {
    height: var(--space-8px);
  }
  ._1nzuaz7bw {
    height: var(--space-12px);
  }
  ._1nzuaz7c2 {
    height: var(--space-14px);
  }
  ._1nzuaz7c8 {
    height: var(--space-16px);
  }
  ._1nzuaz7ce {
    height: var(--space-20px);
  }
  ._1nzuaz7ck {
    height: var(--space-24px);
  }
  ._1nzuaz7cq {
    height: var(--space-32px);
  }
  ._1nzuaz7cw {
    height: var(--space-40px);
  }
  ._1nzuaz7d2 {
    height: var(--space-48px);
  }
  ._1nzuaz7d8 {
    height: var(--space-56px);
  }
  ._1nzuaz7de {
    height: var(--space-64px);
  }
  ._1nzuaz7dk {
    height: 100%;
  }
  ._1nzuaz7dq {
    height: auto;
  }
  ._1nzuaz7dw {
    height: -moz-fit-content;
    height: fit-content;
  }
  ._1nzuaz7e2 {
    height: 420px;
  }
  ._1nzuaz7e8 {
    height: 576px;
  }
  ._1nzuaz7ee {
    height: 768px;
  }
  ._1nzuaz7ek {
    height: 968px;
  }
  ._1nzuaz7eq {
    height: 1024px;
  }
  ._1nzuaz7ew {
    height: 100vh;
  }
  ._1nzuaz7f2 {
    margin-bottom: var(--space-0px);
  }
  ._1nzuaz7f8 {
    margin-bottom: var(--space-1rem);
  }
  ._1nzuaz7fe {
    margin-bottom: var(--space-1px);
  }
  ._1nzuaz7fk {
    margin-bottom: var(--space-2px);
  }
  ._1nzuaz7fq {
    margin-bottom: var(--space-6px);
  }
  ._1nzuaz7fw {
    margin-bottom: var(--space-4px);
  }
  ._1nzuaz7g2 {
    margin-bottom: var(--space-8px);
  }
  ._1nzuaz7g8 {
    margin-bottom: var(--space-12px);
  }
  ._1nzuaz7ge {
    margin-bottom: var(--space-14px);
  }
  ._1nzuaz7gk {
    margin-bottom: var(--space-16px);
  }
  ._1nzuaz7gq {
    margin-bottom: var(--space-20px);
  }
  ._1nzuaz7gw {
    margin-bottom: var(--space-24px);
  }
  ._1nzuaz7h2 {
    margin-bottom: var(--space-32px);
  }
  ._1nzuaz7h8 {
    margin-bottom: var(--space-40px);
  }
  ._1nzuaz7he {
    margin-bottom: var(--space-48px);
  }
  ._1nzuaz7hk {
    margin-bottom: var(--space-56px);
  }
  ._1nzuaz7hq {
    margin-bottom: var(--space-64px);
  }
  ._1nzuaz7hw {
    margin-bottom: auto;
  }
  ._1nzuaz7i2 {
    margin-left: var(--space-0px);
  }
  ._1nzuaz7i8 {
    margin-left: var(--space-1rem);
  }
  ._1nzuaz7ie {
    margin-left: var(--space-1px);
  }
  ._1nzuaz7ik {
    margin-left: var(--space-2px);
  }
  ._1nzuaz7iq {
    margin-left: var(--space-6px);
  }
  ._1nzuaz7iw {
    margin-left: var(--space-4px);
  }
  ._1nzuaz7j2 {
    margin-left: var(--space-8px);
  }
  ._1nzuaz7j8 {
    margin-left: var(--space-12px);
  }
  ._1nzuaz7je {
    margin-left: var(--space-14px);
  }
  ._1nzuaz7jk {
    margin-left: var(--space-16px);
  }
  ._1nzuaz7jq {
    margin-left: var(--space-20px);
  }
  ._1nzuaz7jw {
    margin-left: var(--space-24px);
  }
  ._1nzuaz7k2 {
    margin-left: var(--space-32px);
  }
  ._1nzuaz7k8 {
    margin-left: var(--space-40px);
  }
  ._1nzuaz7ke {
    margin-left: var(--space-48px);
  }
  ._1nzuaz7kk {
    margin-left: var(--space-56px);
  }
  ._1nzuaz7kq {
    margin-left: var(--space-64px);
  }
  ._1nzuaz7kw {
    margin-left: auto;
  }
  ._1nzuaz7l2 {
    margin-right: var(--space-0px);
  }
  ._1nzuaz7l8 {
    margin-right: var(--space-1rem);
  }
  ._1nzuaz7le {
    margin-right: var(--space-1px);
  }
  ._1nzuaz7lk {
    margin-right: var(--space-2px);
  }
  ._1nzuaz7lq {
    margin-right: var(--space-6px);
  }
  ._1nzuaz7lw {
    margin-right: var(--space-4px);
  }
  ._1nzuaz7m2 {
    margin-right: var(--space-8px);
  }
  ._1nzuaz7m8 {
    margin-right: var(--space-12px);
  }
  ._1nzuaz7me {
    margin-right: var(--space-14px);
  }
  ._1nzuaz7mk {
    margin-right: var(--space-16px);
  }
  ._1nzuaz7mq {
    margin-right: var(--space-20px);
  }
  ._1nzuaz7mw {
    margin-right: var(--space-24px);
  }
  ._1nzuaz7n2 {
    margin-right: var(--space-32px);
  }
  ._1nzuaz7n8 {
    margin-right: var(--space-40px);
  }
  ._1nzuaz7ne {
    margin-right: var(--space-48px);
  }
  ._1nzuaz7nk {
    margin-right: var(--space-56px);
  }
  ._1nzuaz7nq {
    margin-right: var(--space-64px);
  }
  ._1nzuaz7nw {
    margin-right: auto;
  }
  ._1nzuaz7o2 {
    margin-top: var(--space-0px);
  }
  ._1nzuaz7o8 {
    margin-top: var(--space-1rem);
  }
  ._1nzuaz7oe {
    margin-top: var(--space-1px);
  }
  ._1nzuaz7ok {
    margin-top: var(--space-2px);
  }
  ._1nzuaz7oq {
    margin-top: var(--space-6px);
  }
  ._1nzuaz7ow {
    margin-top: var(--space-4px);
  }
  ._1nzuaz7p2 {
    margin-top: var(--space-8px);
  }
  ._1nzuaz7p8 {
    margin-top: var(--space-12px);
  }
  ._1nzuaz7pe {
    margin-top: var(--space-14px);
  }
  ._1nzuaz7pk {
    margin-top: var(--space-16px);
  }
  ._1nzuaz7pq {
    margin-top: var(--space-20px);
  }
  ._1nzuaz7pw {
    margin-top: var(--space-24px);
  }
  ._1nzuaz7q2 {
    margin-top: var(--space-32px);
  }
  ._1nzuaz7q8 {
    margin-top: var(--space-40px);
  }
  ._1nzuaz7qe {
    margin-top: var(--space-48px);
  }
  ._1nzuaz7qk {
    margin-top: var(--space-56px);
  }
  ._1nzuaz7qq {
    margin-top: var(--space-64px);
  }
  ._1nzuaz7qw {
    margin-top: auto;
  }
  ._1nzuaz7r2 {
    margin: var(--space-0px);
  }
  ._1nzuaz7r8 {
    margin: var(--space-1rem);
  }
  ._1nzuaz7re {
    margin: var(--space-1px);
  }
  ._1nzuaz7rk {
    margin: var(--space-2px);
  }
  ._1nzuaz7rq {
    margin: var(--space-6px);
  }
  ._1nzuaz7rw {
    margin: var(--space-4px);
  }
  ._1nzuaz7s2 {
    margin: var(--space-8px);
  }
  ._1nzuaz7s8 {
    margin: var(--space-12px);
  }
  ._1nzuaz7se {
    margin: var(--space-14px);
  }
  ._1nzuaz7sk {
    margin: var(--space-16px);
  }
  ._1nzuaz7sq {
    margin: var(--space-20px);
  }
  ._1nzuaz7sw {
    margin: var(--space-24px);
  }
  ._1nzuaz7t2 {
    margin: var(--space-32px);
  }
  ._1nzuaz7t8 {
    margin: var(--space-40px);
  }
  ._1nzuaz7te {
    margin: var(--space-48px);
  }
  ._1nzuaz7tk {
    margin: var(--space-56px);
  }
  ._1nzuaz7tq {
    margin: var(--space-64px);
  }
  ._1nzuaz7tw {
    margin: auto;
  }
  ._1nzuaz7u2 {
    padding: var(--space-0px);
  }
  ._1nzuaz7u8 {
    padding: var(--space-1rem);
  }
  ._1nzuaz7ue {
    padding: var(--space-1px);
  }
  ._1nzuaz7uk {
    padding: var(--space-2px);
  }
  ._1nzuaz7uq {
    padding: var(--space-6px);
  }
  ._1nzuaz7uw {
    padding: var(--space-4px);
  }
  ._1nzuaz7v2 {
    padding: var(--space-8px);
  }
  ._1nzuaz7v8 {
    padding: var(--space-12px);
  }
  ._1nzuaz7ve {
    padding: var(--space-14px);
  }
  ._1nzuaz7vk {
    padding: var(--space-16px);
  }
  ._1nzuaz7vq {
    padding: var(--space-20px);
  }
  ._1nzuaz7vw {
    padding: var(--space-24px);
  }
  ._1nzuaz7w2 {
    padding: var(--space-32px);
  }
  ._1nzuaz7w8 {
    padding: var(--space-40px);
  }
  ._1nzuaz7we {
    padding: var(--space-48px);
  }
  ._1nzuaz7wk {
    padding: var(--space-56px);
  }
  ._1nzuaz7wq {
    padding: var(--space-64px);
  }
  ._1nzuaz7ww {
    max-height: var(--space-0px);
  }
  ._1nzuaz7x2 {
    max-height: var(--space-1rem);
  }
  ._1nzuaz7x8 {
    max-height: var(--space-1px);
  }
  ._1nzuaz7xe {
    max-height: var(--space-2px);
  }
  ._1nzuaz7xk {
    max-height: var(--space-6px);
  }
  ._1nzuaz7xq {
    max-height: var(--space-4px);
  }
  ._1nzuaz7xw {
    max-height: var(--space-8px);
  }
  ._1nzuaz7y2 {
    max-height: var(--space-12px);
  }
  ._1nzuaz7y8 {
    max-height: var(--space-14px);
  }
  ._1nzuaz7ye {
    max-height: var(--space-16px);
  }
  ._1nzuaz7yk {
    max-height: var(--space-20px);
  }
  ._1nzuaz7yq {
    max-height: var(--space-24px);
  }
  ._1nzuaz7yw {
    max-height: var(--space-32px);
  }
  ._1nzuaz7z2 {
    max-height: var(--space-40px);
  }
  ._1nzuaz7z8 {
    max-height: var(--space-48px);
  }
  ._1nzuaz7ze {
    max-height: var(--space-56px);
  }
  ._1nzuaz7zk {
    max-height: var(--space-64px);
  }
  ._1nzuaz7zq {
    max-width: var(--space-0px);
  }
  ._1nzuaz7zw {
    max-width: var(--space-1rem);
  }
  ._1nzuaz7102 {
    max-width: var(--space-1px);
  }
  ._1nzuaz7108 {
    max-width: var(--space-2px);
  }
  ._1nzuaz710e {
    max-width: var(--space-6px);
  }
  ._1nzuaz710k {
    max-width: var(--space-4px);
  }
  ._1nzuaz710q {
    max-width: var(--space-8px);
  }
  ._1nzuaz710w {
    max-width: var(--space-12px);
  }
  ._1nzuaz7112 {
    max-width: var(--space-14px);
  }
  ._1nzuaz7118 {
    max-width: var(--space-16px);
  }
  ._1nzuaz711e {
    max-width: var(--space-20px);
  }
  ._1nzuaz711k {
    max-width: var(--space-24px);
  }
  ._1nzuaz711q {
    max-width: var(--space-32px);
  }
  ._1nzuaz711w {
    max-width: var(--space-40px);
  }
  ._1nzuaz7122 {
    max-width: var(--space-48px);
  }
  ._1nzuaz7128 {
    max-width: var(--space-56px);
  }
  ._1nzuaz712e {
    max-width: var(--space-64px);
  }
  ._1nzuaz712k {
    max-width: 100%;
  }
  ._1nzuaz712q {
    max-width: auto;
  }
  ._1nzuaz712w {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  ._1nzuaz7132 {
    max-width: 420px;
  }
  ._1nzuaz7138 {
    max-width: 576px;
  }
  ._1nzuaz713e {
    max-width: 768px;
  }
  ._1nzuaz713k {
    max-width: 968px;
  }
  ._1nzuaz713q {
    max-width: 1024px;
  }
  ._1nzuaz713w {
    max-width: none;
  }
  ._1nzuaz7142 {
    min-height: var(--space-0px);
  }
  ._1nzuaz7148 {
    min-height: var(--space-1rem);
  }
  ._1nzuaz714e {
    min-height: var(--space-1px);
  }
  ._1nzuaz714k {
    min-height: var(--space-2px);
  }
  ._1nzuaz714q {
    min-height: var(--space-6px);
  }
  ._1nzuaz714w {
    min-height: var(--space-4px);
  }
  ._1nzuaz7152 {
    min-height: var(--space-8px);
  }
  ._1nzuaz7158 {
    min-height: var(--space-12px);
  }
  ._1nzuaz715e {
    min-height: var(--space-14px);
  }
  ._1nzuaz715k {
    min-height: var(--space-16px);
  }
  ._1nzuaz715q {
    min-height: var(--space-20px);
  }
  ._1nzuaz715w {
    min-height: var(--space-24px);
  }
  ._1nzuaz7162 {
    min-height: var(--space-32px);
  }
  ._1nzuaz7168 {
    min-height: var(--space-40px);
  }
  ._1nzuaz716e {
    min-height: var(--space-48px);
  }
  ._1nzuaz716k {
    min-height: var(--space-56px);
  }
  ._1nzuaz716q {
    min-height: var(--space-64px);
  }
  ._1nzuaz716w {
    min-height: 100%;
  }
  ._1nzuaz7172 {
    min-height: auto;
  }
  ._1nzuaz7178 {
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
  ._1nzuaz717e {
    min-height: 420px;
  }
  ._1nzuaz717k {
    min-height: 576px;
  }
  ._1nzuaz717q {
    min-height: 768px;
  }
  ._1nzuaz717w {
    min-height: 968px;
  }
  ._1nzuaz7182 {
    min-height: 1024px;
  }
  ._1nzuaz7188 {
    min-height: 100vh;
  }
  ._1nzuaz718e {
    min-width: var(--space-0px);
  }
  ._1nzuaz718k {
    min-width: var(--space-1rem);
  }
  ._1nzuaz718q {
    min-width: var(--space-1px);
  }
  ._1nzuaz718w {
    min-width: var(--space-2px);
  }
  ._1nzuaz7192 {
    min-width: var(--space-6px);
  }
  ._1nzuaz7198 {
    min-width: var(--space-4px);
  }
  ._1nzuaz719e {
    min-width: var(--space-8px);
  }
  ._1nzuaz719k {
    min-width: var(--space-12px);
  }
  ._1nzuaz719q {
    min-width: var(--space-14px);
  }
  ._1nzuaz719w {
    min-width: var(--space-16px);
  }
  ._1nzuaz71a2 {
    min-width: var(--space-20px);
  }
  ._1nzuaz71a8 {
    min-width: var(--space-24px);
  }
  ._1nzuaz71ae {
    min-width: var(--space-32px);
  }
  ._1nzuaz71ak {
    min-width: var(--space-40px);
  }
  ._1nzuaz71aq {
    min-width: var(--space-48px);
  }
  ._1nzuaz71aw {
    min-width: var(--space-56px);
  }
  ._1nzuaz71b2 {
    min-width: var(--space-64px);
  }
  ._1nzuaz71b8 {
    padding-bottom: var(--space-0px);
  }
  ._1nzuaz71be {
    padding-bottom: var(--space-1rem);
  }
  ._1nzuaz71bk {
    padding-bottom: var(--space-1px);
  }
  ._1nzuaz71bq {
    padding-bottom: var(--space-2px);
  }
  ._1nzuaz71bw {
    padding-bottom: var(--space-6px);
  }
  ._1nzuaz71c2 {
    padding-bottom: var(--space-4px);
  }
  ._1nzuaz71c8 {
    padding-bottom: var(--space-8px);
  }
  ._1nzuaz71ce {
    padding-bottom: var(--space-12px);
  }
  ._1nzuaz71ck {
    padding-bottom: var(--space-14px);
  }
  ._1nzuaz71cq {
    padding-bottom: var(--space-16px);
  }
  ._1nzuaz71cw {
    padding-bottom: var(--space-20px);
  }
  ._1nzuaz71d2 {
    padding-bottom: var(--space-24px);
  }
  ._1nzuaz71d8 {
    padding-bottom: var(--space-32px);
  }
  ._1nzuaz71de {
    padding-bottom: var(--space-40px);
  }
  ._1nzuaz71dk {
    padding-bottom: var(--space-48px);
  }
  ._1nzuaz71dq {
    padding-bottom: var(--space-56px);
  }
  ._1nzuaz71dw {
    padding-bottom: var(--space-64px);
  }
  ._1nzuaz71e2 {
    padding-left: var(--space-0px);
  }
  ._1nzuaz71e8 {
    padding-left: var(--space-1rem);
  }
  ._1nzuaz71ee {
    padding-left: var(--space-1px);
  }
  ._1nzuaz71ek {
    padding-left: var(--space-2px);
  }
  ._1nzuaz71eq {
    padding-left: var(--space-6px);
  }
  ._1nzuaz71ew {
    padding-left: var(--space-4px);
  }
  ._1nzuaz71f2 {
    padding-left: var(--space-8px);
  }
  ._1nzuaz71f8 {
    padding-left: var(--space-12px);
  }
  ._1nzuaz71fe {
    padding-left: var(--space-14px);
  }
  ._1nzuaz71fk {
    padding-left: var(--space-16px);
  }
  ._1nzuaz71fq {
    padding-left: var(--space-20px);
  }
  ._1nzuaz71fw {
    padding-left: var(--space-24px);
  }
  ._1nzuaz71g2 {
    padding-left: var(--space-32px);
  }
  ._1nzuaz71g8 {
    padding-left: var(--space-40px);
  }
  ._1nzuaz71ge {
    padding-left: var(--space-48px);
  }
  ._1nzuaz71gk {
    padding-left: var(--space-56px);
  }
  ._1nzuaz71gq {
    padding-left: var(--space-64px);
  }
  ._1nzuaz71gw {
    padding-right: var(--space-0px);
  }
  ._1nzuaz71h2 {
    padding-right: var(--space-1rem);
  }
  ._1nzuaz71h8 {
    padding-right: var(--space-1px);
  }
  ._1nzuaz71he {
    padding-right: var(--space-2px);
  }
  ._1nzuaz71hk {
    padding-right: var(--space-6px);
  }
  ._1nzuaz71hq {
    padding-right: var(--space-4px);
  }
  ._1nzuaz71hw {
    padding-right: var(--space-8px);
  }
  ._1nzuaz71i2 {
    padding-right: var(--space-12px);
  }
  ._1nzuaz71i8 {
    padding-right: var(--space-14px);
  }
  ._1nzuaz71ie {
    padding-right: var(--space-16px);
  }
  ._1nzuaz71ik {
    padding-right: var(--space-20px);
  }
  ._1nzuaz71iq {
    padding-right: var(--space-24px);
  }
  ._1nzuaz71iw {
    padding-right: var(--space-32px);
  }
  ._1nzuaz71j2 {
    padding-right: var(--space-40px);
  }
  ._1nzuaz71j8 {
    padding-right: var(--space-48px);
  }
  ._1nzuaz71je {
    padding-right: var(--space-56px);
  }
  ._1nzuaz71jk {
    padding-right: var(--space-64px);
  }
  ._1nzuaz71jq {
    padding-top: var(--space-0px);
  }
  ._1nzuaz71jw {
    padding-top: var(--space-1rem);
  }
  ._1nzuaz71k2 {
    padding-top: var(--space-1px);
  }
  ._1nzuaz71k8 {
    padding-top: var(--space-2px);
  }
  ._1nzuaz71ke {
    padding-top: var(--space-6px);
  }
  ._1nzuaz71kk {
    padding-top: var(--space-4px);
  }
  ._1nzuaz71kq {
    padding-top: var(--space-8px);
  }
  ._1nzuaz71kw {
    padding-top: var(--space-12px);
  }
  ._1nzuaz71l2 {
    padding-top: var(--space-14px);
  }
  ._1nzuaz71l8 {
    padding-top: var(--space-16px);
  }
  ._1nzuaz71le {
    padding-top: var(--space-20px);
  }
  ._1nzuaz71lk {
    padding-top: var(--space-24px);
  }
  ._1nzuaz71lq {
    padding-top: var(--space-32px);
  }
  ._1nzuaz71lw {
    padding-top: var(--space-40px);
  }
  ._1nzuaz71m2 {
    padding-top: var(--space-48px);
  }
  ._1nzuaz71m8 {
    padding-top: var(--space-56px);
  }
  ._1nzuaz71me {
    padding-top: var(--space-64px);
  }
  ._1nzuaz71mk {
    font-size: var(--fontSizes-10px);
  }
  ._1nzuaz71mq {
    font-size: var(--fontSizes-12px);
  }
  ._1nzuaz71mw {
    font-size: var(--fontSizes-16px);
  }
  ._1nzuaz71n2 {
    font-size: var(--fontSizes-14px);
  }
  ._1nzuaz71n8 {
    font-size: var(--fontSizes-20px);
  }
  ._1nzuaz71ne {
    font-size: var(--fontSizes-40px);
  }
  ._1nzuaz71nk {
    font-size: inherit;
  }
  ._1nzuaz71nq {
    flex: 1 1;
  }
  ._1nzuaz71nw {
    flex: 1 1 auto;
  }
  ._1nzuaz71o2 {
    flex: 0 1 auto;
  }
  ._1nzuaz71o8 {
    flex: none;
  }
  ._1nzuaz71oe {
    box-shadow: var(--shadows-level1);
  }
  ._1nzuaz71ok {
    box-shadow: var(--shadows-active);
  }
  ._1nzuaz71oq {
    box-shadow: var(--shadows-success);
  }
  ._1nzuaz71ow {
    box-shadow: var(--shadows-warning);
  }
  ._1nzuaz71p2 {
    box-shadow: var(--shadows-danger);
  }
  ._1nzuaz71p8 {
    box-shadow: var(--shadows-focus);
  }
  ._1nzuaz71pe {
    box-shadow: var(--shadows-inset);
  }
  ._1nzuaz71pk {
    box-shadow: var(--shadows-tooltip);
  }
  ._1nzuaz71pq {
    width: var(--space-0px);
  }
  ._1nzuaz71pw {
    width: var(--space-1rem);
  }
  ._1nzuaz71q2 {
    width: var(--space-1px);
  }
  ._1nzuaz71q8 {
    width: var(--space-2px);
  }
  ._1nzuaz71qe {
    width: var(--space-6px);
  }
  ._1nzuaz71qk {
    width: var(--space-4px);
  }
  ._1nzuaz71qq {
    width: var(--space-8px);
  }
  ._1nzuaz71qw {
    width: var(--space-12px);
  }
  ._1nzuaz71r2 {
    width: var(--space-14px);
  }
  ._1nzuaz71r8 {
    width: var(--space-16px);
  }
  ._1nzuaz71re {
    width: var(--space-20px);
  }
  ._1nzuaz71rk {
    width: var(--space-24px);
  }
  ._1nzuaz71rq {
    width: var(--space-32px);
  }
  ._1nzuaz71rw {
    width: var(--space-40px);
  }
  ._1nzuaz71s2 {
    width: var(--space-48px);
  }
  ._1nzuaz71s8 {
    width: var(--space-56px);
  }
  ._1nzuaz71se {
    width: var(--space-64px);
  }
  ._1nzuaz71sk {
    width: 100%;
  }
  ._1nzuaz71sq {
    width: auto;
  }
  ._1nzuaz71sw {
    width: -moz-fit-content;
    width: fit-content;
  }
  ._1nzuaz71t2 {
    width: 420px;
  }
  ._1nzuaz71t8 {
    width: 576px;
  }
  ._1nzuaz71te {
    width: 768px;
  }
  ._1nzuaz71tk {
    width: 968px;
  }
  ._1nzuaz71tq {
    width: 1024px;
  }
  ._1nzuaz71tw {
    z-index: 0;
  }
  ._1nzuaz71u2 {
    z-index: 1;
  }
  ._1nzuaz71u8 {
    z-index: 10;
  }
  ._1nzuaz71ue {
    z-index: 20;
  }
  ._1nzuaz71uk {
    z-index: 30;
  }
  ._1nzuaz71uq {
    z-index: 40;
  }
  ._1nzuaz71uw {
    z-index: 50;
  }
  ._1nzuaz71v2 {
    z-index: 75;
  }
  ._1nzuaz71v8 {
    z-index: 99;
  }
  ._1nzuaz71ve {
    z-index: 100;
  }
  ._1nzuaz71vk {
    z-index: 9;
  }
  ._1nzuaz71vq {
    z-index: 10;
  }
  ._1nzuaz71vw {
    z-index: 100;
  }
  ._1nzuaz71w2 {
    z-index: auto;
  }
  ._1nzuaz71w8 {
    border-top: 1px solid var(--colors-cardBorder);
  }
  ._1nzuaz71we {
    border-radius: var(--radii-0);
  }
  ._1nzuaz71wk {
    border-radius: var(--radii-8px);
  }
  ._1nzuaz71wq {
    border-radius: var(--radii-12px);
  }
  ._1nzuaz71ww {
    border-radius: var(--radii-20px);
  }
  ._1nzuaz71x2 {
    border-radius: var(--radii-32px);
  }
  ._1nzuaz71x8 {
    border-radius: var(--radii-small);
  }
  ._1nzuaz71xe {
    border-radius: var(--radii-default);
  }
  ._1nzuaz71xk {
    border-radius: var(--radii-card);
  }
  ._1nzuaz71xq {
    border-radius: var(--radii-circle);
  }
  ._1nzuaz71xw {
    border-top-left-radius: var(--radii-0);
  }
  ._1nzuaz71y2 {
    border-top-left-radius: var(--radii-8px);
  }
  ._1nzuaz71y8 {
    border-top-left-radius: var(--radii-12px);
  }
  ._1nzuaz71ye {
    border-top-left-radius: var(--radii-20px);
  }
  ._1nzuaz71yk {
    border-top-left-radius: var(--radii-32px);
  }
  ._1nzuaz71yq {
    border-top-left-radius: var(--radii-small);
  }
  ._1nzuaz71yw {
    border-top-left-radius: var(--radii-default);
  }
  ._1nzuaz71z2 {
    border-top-left-radius: var(--radii-card);
  }
  ._1nzuaz71z8 {
    border-top-left-radius: var(--radii-circle);
  }
  ._1nzuaz71ze {
    border-bottom-right-radius: var(--radii-0);
  }
  ._1nzuaz71zk {
    border-bottom-right-radius: var(--radii-8px);
  }
  ._1nzuaz71zq {
    border-bottom-right-radius: var(--radii-12px);
  }
  ._1nzuaz71zw {
    border-bottom-right-radius: var(--radii-20px);
  }
  ._1nzuaz7202 {
    border-bottom-right-radius: var(--radii-32px);
  }
  ._1nzuaz7208 {
    border-bottom-right-radius: var(--radii-small);
  }
  ._1nzuaz720e {
    border-bottom-right-radius: var(--radii-default);
  }
  ._1nzuaz720k {
    border-bottom-right-radius: var(--radii-card);
  }
  ._1nzuaz720q {
    border-bottom-right-radius: var(--radii-circle);
  }
  ._1nzuaz720w {
    border-top-right-radius: var(--radii-0);
  }
  ._1nzuaz7212 {
    border-top-right-radius: var(--radii-8px);
  }
  ._1nzuaz7218 {
    border-top-right-radius: var(--radii-12px);
  }
  ._1nzuaz721e {
    border-top-right-radius: var(--radii-20px);
  }
  ._1nzuaz721k {
    border-top-right-radius: var(--radii-32px);
  }
  ._1nzuaz721q {
    border-top-right-radius: var(--radii-small);
  }
  ._1nzuaz721w {
    border-top-right-radius: var(--radii-default);
  }
  ._1nzuaz7222 {
    border-top-right-radius: var(--radii-card);
  }
  ._1nzuaz7228 {
    border-top-right-radius: var(--radii-circle);
  }
  ._1nzuaz722e {
    border-bottom-left-radius: var(--radii-0);
  }
  ._1nzuaz722k {
    border-bottom-left-radius: var(--radii-8px);
  }
  ._1nzuaz722q {
    border-bottom-left-radius: var(--radii-12px);
  }
  ._1nzuaz722w {
    border-bottom-left-radius: var(--radii-20px);
  }
  ._1nzuaz7232 {
    border-bottom-left-radius: var(--radii-32px);
  }
  ._1nzuaz7238 {
    border-bottom-left-radius: var(--radii-small);
  }
  ._1nzuaz723e {
    border-bottom-left-radius: var(--radii-default);
  }
  ._1nzuaz723k {
    border-bottom-left-radius: var(--radii-card);
  }
  ._1nzuaz723q {
    border-bottom-left-radius: var(--radii-circle);
  }
  ._1nzuaz723w {
    gap: var(--space-0px);
  }
  ._1nzuaz7242 {
    gap: var(--space-1rem);
  }
  ._1nzuaz7248 {
    gap: var(--space-1px);
  }
  ._1nzuaz724e {
    gap: var(--space-2px);
  }
  ._1nzuaz724k {
    gap: var(--space-6px);
  }
  ._1nzuaz724q {
    gap: var(--space-4px);
  }
  ._1nzuaz724w {
    gap: var(--space-8px);
  }
  ._1nzuaz7252 {
    gap: var(--space-12px);
  }
  ._1nzuaz7258 {
    gap: var(--space-14px);
  }
  ._1nzuaz725e {
    gap: var(--space-16px);
  }
  ._1nzuaz725k {
    gap: var(--space-20px);
  }
  ._1nzuaz725q {
    gap: var(--space-24px);
  }
  ._1nzuaz725w {
    gap: var(--space-32px);
  }
  ._1nzuaz7262 {
    gap: var(--space-40px);
  }
  ._1nzuaz7268 {
    gap: var(--space-48px);
  }
  ._1nzuaz726e {
    gap: var(--space-56px);
  }
  ._1nzuaz726k {
    gap: var(--space-64px);
  }
  ._1nzuaz726q {
    gap: var(--space-8px);
  }
  ._1nzuaz726w {
    gap: var(--space-12px);
  }
  ._1nzuaz7272 {
    gap: var(--space-24px);
  }
  ._1nzuaz7278 {
    row-gap: var(--space-0px);
  }
  ._1nzuaz727e {
    row-gap: var(--space-1rem);
  }
  ._1nzuaz727k {
    row-gap: var(--space-1px);
  }
  ._1nzuaz727q {
    row-gap: var(--space-2px);
  }
  ._1nzuaz727w {
    row-gap: var(--space-6px);
  }
  ._1nzuaz7282 {
    row-gap: var(--space-4px);
  }
  ._1nzuaz7288 {
    row-gap: var(--space-8px);
  }
  ._1nzuaz728e {
    row-gap: var(--space-12px);
  }
  ._1nzuaz728k {
    row-gap: var(--space-14px);
  }
  ._1nzuaz728q {
    row-gap: var(--space-16px);
  }
  ._1nzuaz728w {
    row-gap: var(--space-20px);
  }
  ._1nzuaz7292 {
    row-gap: var(--space-24px);
  }
  ._1nzuaz7298 {
    row-gap: var(--space-32px);
  }
  ._1nzuaz729e {
    row-gap: var(--space-40px);
  }
  ._1nzuaz729k {
    row-gap: var(--space-48px);
  }
  ._1nzuaz729q {
    row-gap: var(--space-56px);
  }
  ._1nzuaz729w {
    row-gap: var(--space-64px);
  }
  ._1nzuaz72a2 {
    row-gap: var(--space-8px);
  }
  ._1nzuaz72a8 {
    row-gap: var(--space-12px);
  }
  ._1nzuaz72ae {
    row-gap: var(--space-24px);
  }
  ._1nzuaz72ak {
    column-gap: var(--space-0px);
  }
  ._1nzuaz72aq {
    column-gap: var(--space-1rem);
  }
  ._1nzuaz72aw {
    column-gap: var(--space-1px);
  }
  ._1nzuaz72b2 {
    column-gap: var(--space-2px);
  }
  ._1nzuaz72b8 {
    column-gap: var(--space-6px);
  }
  ._1nzuaz72be {
    column-gap: var(--space-4px);
  }
  ._1nzuaz72bk {
    column-gap: var(--space-8px);
  }
  ._1nzuaz72bq {
    column-gap: var(--space-12px);
  }
  ._1nzuaz72bw {
    column-gap: var(--space-14px);
  }
  ._1nzuaz72c2 {
    column-gap: var(--space-16px);
  }
  ._1nzuaz72c8 {
    column-gap: var(--space-20px);
  }
  ._1nzuaz72ce {
    column-gap: var(--space-24px);
  }
  ._1nzuaz72ck {
    column-gap: var(--space-32px);
  }
  ._1nzuaz72cq {
    column-gap: var(--space-40px);
  }
  ._1nzuaz72cw {
    column-gap: var(--space-48px);
  }
  ._1nzuaz72d2 {
    column-gap: var(--space-56px);
  }
  ._1nzuaz72d8 {
    column-gap: var(--space-64px);
  }
  ._1nzuaz72de {
    column-gap: var(--space-8px);
  }
  ._1nzuaz72dk {
    column-gap: var(--space-12px);
  }
  ._1nzuaz72dq {
    column-gap: var(--space-24px);
  }
  ._1nzuaz72dw {
    grid-auto-rows: auto;
  }
  ._1nzuaz72e2 {
    opacity: 0;
  }
  ._1nzuaz72e8 {
    opacity: 1;
  }
  ._1nzuaz72ee {
    opacity: 0.5;
  }
  ._1nzuaz72ek {
    opacity: 0.6;
  }
  ._1nzuaz72eq {
    line-height: var(--space-16px);
  }
  ._1nzuaz72ew {
    white50: var(--colors-light-white50);
    white100: var(--colors-light-white100);
    white200: var(--colors-light-white200);
    white300: var(--colors-light-white300);
    white500: var(--colors-light-white500);
    white800: var(--colors-light-white800);
    white: var(--colors-light-white);
    black: var(--colors-light-black);
    failure: var(--colors-light-failure);
    failure33: var(--colors-light-failure33);
    red100: var(--colors-light-red100);
    primary: var(--colors-light-primary);
    primary0f: var(--colors-light-primary0f);
    primary3-d: var(--colors-light-primary3D);
    primary-bright: var(--colors-light-primaryBright);
    primary-dark: var(--colors-light-primaryDark);
    success: var(--colors-light-success);
    success19: var(--colors-light-success19);
    warning: var(--colors-light-warning);
    warning2-d: var(--colors-light-warning2D);
    warning33: var(--colors-light-warning33);
    yellow100: var(--colors-light-yellow100);
    gray50: var(--colors-light-gray50);
    gray100: var(--colors-light-gray100);
    gray200: var(--colors-light-gray200);
    gray300: var(--colors-light-gray300);
    gray390: var(--colors-light-gray390);
    gray400: var(--colors-light-gray400);
    gray500: var(--colors-light-gray500);
    gray700: var(--colors-light-gray700);
    gray800: var(--colors-light-gray800);
    gray850: var(--colors-light-gray850);
    gray900: var(--colors-light-gray900);
    gray1000: var(--colors-light-gray1000);
    tint-gray80: var(--colors-light-tintGray80);
    gradient-primary: var(--colors-light-gradientPrimary);
    gradient-primary10: var(--colors-light-gradientPrimary10);
    gradient-primary20: var(--colors-light-gradientPrimary20);
    gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
    orange100: var(--colors-light-orange100);
    orange: var(--colors-light-orange);
    purple: var(--colors-light-purple);
    purple60: var(--colors-light-purple60);
    orange-dark: var(--colors-light-orangeDark);
    binance: var(--colors-light-binance);
    overlay: var(--colors-light-overlay);
    gold: var(--colors-light-gold);
    silver: var(--colors-light-silver);
    bronze: var(--colors-light-bronze);
    secondary: var(--colors-light-secondary);
    secondary80: var(--colors-light-secondary80);
    background: var(--colors-light-background);
    background-disabled: var(--colors-light-backgroundDisabled);
    background-alt: var(--colors-light-backgroundAlt);
    background-alt2: var(--colors-light-backgroundAlt2);
    card-border: var(--colors-light-cardBorder);
    contrast: var(--colors-light-contrast);
    dropdown: var(--colors-light-dropdown);
    dropdown-deep: var(--colors-light-dropdownDeep);
    inverted-contrast: var(--colors-light-invertedContrast);
    input: var(--colors-light-input);
    input-secondary: var(--colors-light-inputSecondary);
    tertiary: var(--colors-light-tertiary);
    text: var(--colors-light-text);
    text99: var(--colors-light-text99);
    text-disabled: var(--colors-light-textDisabled);
    text-subtle: var(--colors-light-textSubtle);
    disabled: var(--colors-light-disabled);
    gradient-bubblegum: var(--colors-light-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
    gradient-card-header: var(--colors-light-gradientCardHeader);
    gradient-blue: var(--colors-light-gradientBlue);
    gradient-violet: var(--colors-light-gradientViolet);
    gradient-violet-alt: var(--colors-light-gradientVioletAlt);
    gradient-gold: var(--colors-light-gradientGold);
    gradient-bold: var(--colors-light-gradientBold);
  }
  ._1nzuaz72f2 {
    white50: var(--colors-dark-white50);
    white100: var(--colors-dark-white100);
    white200: var(--colors-dark-white200);
    white300: var(--colors-dark-white300);
    white500: var(--colors-dark-white500);
    white800: var(--colors-dark-white800);
    white: var(--colors-dark-white);
    black: var(--colors-dark-black);
    failure: var(--colors-dark-failure);
    failure33: var(--colors-dark-failure33);
    red100: var(--colors-dark-red100);
    primary: var(--colors-dark-primary);
    primary0f: var(--colors-dark-primary0f);
    primary3-d: var(--colors-dark-primary3D);
    primary-bright: var(--colors-dark-primaryBright);
    primary-dark: var(--colors-dark-primaryDark);
    success: var(--colors-dark-success);
    success19: var(--colors-dark-success19);
    warning: var(--colors-dark-warning);
    warning2-d: var(--colors-dark-warning2D);
    warning33: var(--colors-dark-warning33);
    yellow100: var(--colors-dark-yellow100);
    gray50: var(--colors-dark-gray50);
    gray100: var(--colors-dark-gray100);
    gray200: var(--colors-dark-gray200);
    gray300: var(--colors-dark-gray300);
    gray390: var(--colors-dark-gray390);
    gray400: var(--colors-dark-gray400);
    gray500: var(--colors-dark-gray500);
    gray700: var(--colors-dark-gray700);
    gray800: var(--colors-dark-gray800);
    gray850: var(--colors-dark-gray850);
    gray900: var(--colors-dark-gray900);
    gray1000: var(--colors-dark-gray1000);
    tint-gray80: var(--colors-dark-tintGray80);
    gradient-primary: var(--colors-dark-gradientPrimary);
    gradient-primary10: var(--colors-dark-gradientPrimary10);
    gradient-primary20: var(--colors-dark-gradientPrimary20);
    gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
    orange100: var(--colors-dark-orange100);
    orange: var(--colors-dark-orange);
    purple: var(--colors-dark-purple);
    purple60: var(--colors-dark-purple60);
    orange-dark: var(--colors-dark-orangeDark);
    binance: var(--colors-dark-binance);
    overlay: var(--colors-dark-overlay);
    gold: var(--colors-dark-gold);
    silver: var(--colors-dark-silver);
    bronze: var(--colors-dark-bronze);
    secondary: var(--colors-dark-secondary);
    secondary80: var(--colors-dark-secondary80);
    background: var(--colors-dark-background);
    background-disabled: var(--colors-dark-backgroundDisabled);
    background-alt: var(--colors-dark-backgroundAlt);
    background-alt2: var(--colors-dark-backgroundAlt2);
    card-border: var(--colors-dark-cardBorder);
    contrast: var(--colors-dark-contrast);
    dropdown: var(--colors-dark-dropdown);
    dropdown-deep: var(--colors-dark-dropdownDeep);
    inverted-contrast: var(--colors-dark-invertedContrast);
    input: var(--colors-dark-input);
    input-secondary: var(--colors-dark-inputSecondary);
    tertiary: var(--colors-dark-tertiary);
    text: var(--colors-dark-text);
    text99: var(--colors-dark-text99);
    text-disabled: var(--colors-dark-textDisabled);
    text-subtle: var(--colors-dark-textSubtle);
    disabled: var(--colors-dark-disabled);
    gradient-bubblegum: var(--colors-dark-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
    gradient-card-header: var(--colors-dark-gradientCardHeader);
    gradient-blue: var(--colors-dark-gradientBlue);
    gradient-violet: var(--colors-dark-gradientViolet);
    gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
    gradient-gold: var(--colors-dark-gradientGold);
    gradient-bold: var(--colors-dark-gradientBold);
  }
  ._1nzuaz72f8 {
    border-bottom-color: var(--colors-white50);
  }
  ._1nzuaz72fe {
    border-bottom-color: var(--colors-white100);
  }
  ._1nzuaz72fk {
    border-bottom-color: var(--colors-white200);
  }
  ._1nzuaz72fq {
    border-bottom-color: var(--colors-white300);
  }
  ._1nzuaz72fw {
    border-bottom-color: var(--colors-white500);
  }
  ._1nzuaz72g2 {
    border-bottom-color: var(--colors-white800);
  }
  ._1nzuaz72g8 {
    border-bottom-color: var(--colors-white);
  }
  ._1nzuaz72ge {
    border-bottom-color: var(--colors-black);
  }
  ._1nzuaz72gk {
    border-bottom-color: var(--colors-failure);
  }
  ._1nzuaz72gq {
    border-bottom-color: var(--colors-failure33);
  }
  ._1nzuaz72gw {
    border-bottom-color: var(--colors-red100);
  }
  ._1nzuaz72h2 {
    border-bottom-color: var(--colors-primary);
  }
  ._1nzuaz72h8 {
    border-bottom-color: var(--colors-primary0f);
  }
  ._1nzuaz72he {
    border-bottom-color: var(--colors-primary3D);
  }
  ._1nzuaz72hk {
    border-bottom-color: var(--colors-primaryBright);
  }
  ._1nzuaz72hq {
    border-bottom-color: var(--colors-primaryDark);
  }
  ._1nzuaz72hw {
    border-bottom-color: var(--colors-success);
  }
  ._1nzuaz72i2 {
    border-bottom-color: var(--colors-success19);
  }
  ._1nzuaz72i8 {
    border-bottom-color: var(--colors-warning);
  }
  ._1nzuaz72ie {
    border-bottom-color: var(--colors-warning2D);
  }
  ._1nzuaz72ik {
    border-bottom-color: var(--colors-warning33);
  }
  ._1nzuaz72iq {
    border-bottom-color: var(--colors-yellow100);
  }
  ._1nzuaz72iw {
    border-bottom-color: var(--colors-gray50);
  }
  ._1nzuaz72j2 {
    border-bottom-color: var(--colors-gray100);
  }
  ._1nzuaz72j8 {
    border-bottom-color: var(--colors-gray200);
  }
  ._1nzuaz72je {
    border-bottom-color: var(--colors-gray300);
  }
  ._1nzuaz72jk {
    border-bottom-color: var(--colors-gray390);
  }
  ._1nzuaz72jq {
    border-bottom-color: var(--colors-gray400);
  }
  ._1nzuaz72jw {
    border-bottom-color: var(--colors-gray500);
  }
  ._1nzuaz72k2 {
    border-bottom-color: var(--colors-gray700);
  }
  ._1nzuaz72k8 {
    border-bottom-color: var(--colors-gray800);
  }
  ._1nzuaz72ke {
    border-bottom-color: var(--colors-gray850);
  }
  ._1nzuaz72kk {
    border-bottom-color: var(--colors-gray900);
  }
  ._1nzuaz72kq {
    border-bottom-color: var(--colors-gray1000);
  }
  ._1nzuaz72kw {
    border-bottom-color: var(--colors-tintGray80);
  }
  ._1nzuaz72l2 {
    border-bottom-color: var(--colors-gradientPrimary);
  }
  ._1nzuaz72l8 {
    border-bottom-color: var(--colors-gradientPrimary10);
  }
  ._1nzuaz72le {
    border-bottom-color: var(--colors-gradientPrimary20);
  }
  ._1nzuaz72lk {
    border-bottom-color: var(--colors-gradientPrimaryRadial);
  }
  ._1nzuaz72lq {
    border-bottom-color: var(--colors-orange100);
  }
  ._1nzuaz72lw {
    border-bottom-color: var(--colors-orange);
  }
  ._1nzuaz72m2 {
    border-bottom-color: var(--colors-purple);
  }
  ._1nzuaz72m8 {
    border-bottom-color: var(--colors-purple60);
  }
  ._1nzuaz72me {
    border-bottom-color: var(--colors-orangeDark);
  }
  ._1nzuaz72mk {
    border-bottom-color: var(--colors-binance);
  }
  ._1nzuaz72mq {
    border-bottom-color: var(--colors-overlay);
  }
  ._1nzuaz72mw {
    border-bottom-color: var(--colors-gold);
  }
  ._1nzuaz72n2 {
    border-bottom-color: var(--colors-silver);
  }
  ._1nzuaz72n8 {
    border-bottom-color: var(--colors-bronze);
  }
  ._1nzuaz72ne {
    border-bottom-color: var(--colors-secondary);
  }
  ._1nzuaz72nk {
    border-bottom-color: var(--colors-secondary80);
  }
  ._1nzuaz72nq {
    border-bottom-color: var(--colors-background);
  }
  ._1nzuaz72nw {
    border-bottom-color: var(--colors-backgroundDisabled);
  }
  ._1nzuaz72o2 {
    border-bottom-color: var(--colors-backgroundAlt);
  }
  ._1nzuaz72o8 {
    border-bottom-color: var(--colors-backgroundAlt2);
  }
  ._1nzuaz72oe {
    border-bottom-color: var(--colors-cardBorder);
  }
  ._1nzuaz72ok {
    border-bottom-color: var(--colors-contrast);
  }
  ._1nzuaz72oq {
    border-bottom-color: var(--colors-dropdown);
  }
  ._1nzuaz72ow {
    border-bottom-color: var(--colors-dropdownDeep);
  }
  ._1nzuaz72p2 {
    border-bottom-color: var(--colors-invertedContrast);
  }
  ._1nzuaz72p8 {
    border-bottom-color: var(--colors-input);
  }
  ._1nzuaz72pe {
    border-bottom-color: var(--colors-inputSecondary);
  }
  ._1nzuaz72pk {
    border-bottom-color: var(--colors-tertiary);
  }
  ._1nzuaz72pq {
    border-bottom-color: var(--colors-text);
  }
  ._1nzuaz72pw {
    border-bottom-color: var(--colors-text99);
  }
  ._1nzuaz72q2 {
    border-bottom-color: var(--colors-textDisabled);
  }
  ._1nzuaz72q8 {
    border-bottom-color: var(--colors-textSubtle);
  }
  ._1nzuaz72qe {
    border-bottom-color: var(--colors-disabled);
  }
  ._1nzuaz72qk {
    border-bottom-color: var(--colors-gradientBubblegum);
  }
  ._1nzuaz72qq {
    border-bottom-color: var(--colors-gradientInverseBubblegum);
  }
  ._1nzuaz72qw {
    border-bottom-color: var(--colors-gradientCardHeader);
  }
  ._1nzuaz72r2 {
    border-bottom-color: var(--colors-gradientBlue);
  }
  ._1nzuaz72r8 {
    border-bottom-color: var(--colors-gradientViolet);
  }
  ._1nzuaz72re {
    border-bottom-color: var(--colors-gradientVioletAlt);
  }
  ._1nzuaz72rk {
    border-bottom-color: var(--colors-gradientGold);
  }
  ._1nzuaz72rq {
    border-bottom-color: var(--colors-gradientBold);
  }
  ._1nzuaz72rw {
    border: 1px solid var(--colors-cardBorder);
  }
  ._1nzuaz72s2 {
    border-bottom: 1px solid var(--colors-cardBorder);
  }
}
@media (min-width: 968px) {
  ._1nzuaz73 {
    display: block;
  }
  ._1nzuaz79 {
    display: flex;
  }
  ._1nzuaz7f {
    display: grid;
  }
  ._1nzuaz7l {
    display: inline;
  }
  ._1nzuaz7r {
    display: inline-flex;
  }
  ._1nzuaz7x {
    display: inline-block;
  }
  ._1nzuaz713 {
    display: none;
  }
  ._1nzuaz719 {
    flex-direction: column;
  }
  ._1nzuaz71f {
    flex-direction: row;
  }
  ._1nzuaz71l {
    flex-direction: column-reverse;
  }
  ._1nzuaz71r {
    align-items: center;
  }
  ._1nzuaz71x {
    align-items: end;
  }
  ._1nzuaz723 {
    align-items: baseLine;
  }
  ._1nzuaz729 {
    align-items: inherit;
  }
  ._1nzuaz72f {
    align-items: flex-start;
  }
  ._1nzuaz72l {
    align-items: center;
  }
  ._1nzuaz72r {
    align-items: start;
  }
  ._1nzuaz72x {
    align-items: flex-end;
  }
  ._1nzuaz733 {
    align-items: stretch;
  }
  ._1nzuaz739 {
    align-self: flex-start;
  }
  ._1nzuaz73f {
    align-self: center;
  }
  ._1nzuaz73l {
    align-self: start;
  }
  ._1nzuaz73r {
    align-self: flex-end;
  }
  ._1nzuaz73x {
    align-self: stretch;
  }
  ._1nzuaz743 {
    flex-wrap: wrap;
  }
  ._1nzuaz749 {
    flex-wrap: nowrap;
  }
  ._1nzuaz74f {
    flex-grow: 1;
  }
  ._1nzuaz74l {
    overflow: auto;
  }
  ._1nzuaz74r {
    overflow: hidden;
  }
  ._1nzuaz74x {
    overflow: scroll;
  }
  ._1nzuaz753 {
    overflow: unset;
  }
  ._1nzuaz759 {
    overflow-y: auto;
  }
  ._1nzuaz75f {
    overflow-y: hidden;
  }
  ._1nzuaz75l {
    overflow-y: scroll;
  }
  ._1nzuaz75r {
    overflow-x: auto;
  }
  ._1nzuaz75x {
    overflow-x: hidden;
  }
  ._1nzuaz763 {
    overflow-x: scroll;
  }
  ._1nzuaz769 {
    position: absolute;
  }
  ._1nzuaz76f {
    position: fixed;
  }
  ._1nzuaz76l {
    position: relative;
  }
  ._1nzuaz76r {
    position: sticky;
  }
  ._1nzuaz76x {
    text-align: center;
  }
  ._1nzuaz773 {
    text-align: left;
  }
  ._1nzuaz779 {
    text-align: right;
  }
  ._1nzuaz77f {
    justify-content: flex-start;
  }
  ._1nzuaz77l {
    justify-content: center;
  }
  ._1nzuaz77r {
    justify-content: start;
  }
  ._1nzuaz77x {
    justify-content: flex-end;
  }
  ._1nzuaz783 {
    justify-content: stretch;
  }
  ._1nzuaz789 {
    justify-content: space-around;
  }
  ._1nzuaz78f {
    justify-content: space-between;
  }
  ._1nzuaz78l {
    justify-items: flex-start;
  }
  ._1nzuaz78r {
    justify-items: center;
  }
  ._1nzuaz78x {
    justify-items: start;
  }
  ._1nzuaz793 {
    justify-items: flex-end;
  }
  ._1nzuaz799 {
    justify-items: stretch;
  }
  ._1nzuaz79f {
    justify-items: space-around;
  }
  ._1nzuaz79l {
    justify-items: space-between;
  }
  ._1nzuaz79r {
    justify-self: flex-start;
  }
  ._1nzuaz79x {
    justify-self: center;
  }
  ._1nzuaz7a3 {
    justify-self: start;
  }
  ._1nzuaz7a9 {
    justify-self: flex-end;
  }
  ._1nzuaz7af {
    justify-self: stretch;
  }
  ._1nzuaz7al {
    inset: 0px;
  }
  ._1nzuaz7ar {
    height: var(--space-0px);
  }
  ._1nzuaz7ax {
    height: var(--space-1rem);
  }
  ._1nzuaz7b3 {
    height: var(--space-1px);
  }
  ._1nzuaz7b9 {
    height: var(--space-2px);
  }
  ._1nzuaz7bf {
    height: var(--space-6px);
  }
  ._1nzuaz7bl {
    height: var(--space-4px);
  }
  ._1nzuaz7br {
    height: var(--space-8px);
  }
  ._1nzuaz7bx {
    height: var(--space-12px);
  }
  ._1nzuaz7c3 {
    height: var(--space-14px);
  }
  ._1nzuaz7c9 {
    height: var(--space-16px);
  }
  ._1nzuaz7cf {
    height: var(--space-20px);
  }
  ._1nzuaz7cl {
    height: var(--space-24px);
  }
  ._1nzuaz7cr {
    height: var(--space-32px);
  }
  ._1nzuaz7cx {
    height: var(--space-40px);
  }
  ._1nzuaz7d3 {
    height: var(--space-48px);
  }
  ._1nzuaz7d9 {
    height: var(--space-56px);
  }
  ._1nzuaz7df {
    height: var(--space-64px);
  }
  ._1nzuaz7dl {
    height: 100%;
  }
  ._1nzuaz7dr {
    height: auto;
  }
  ._1nzuaz7dx {
    height: -moz-fit-content;
    height: fit-content;
  }
  ._1nzuaz7e3 {
    height: 420px;
  }
  ._1nzuaz7e9 {
    height: 576px;
  }
  ._1nzuaz7ef {
    height: 768px;
  }
  ._1nzuaz7el {
    height: 968px;
  }
  ._1nzuaz7er {
    height: 1024px;
  }
  ._1nzuaz7ex {
    height: 100vh;
  }
  ._1nzuaz7f3 {
    margin-bottom: var(--space-0px);
  }
  ._1nzuaz7f9 {
    margin-bottom: var(--space-1rem);
  }
  ._1nzuaz7ff {
    margin-bottom: var(--space-1px);
  }
  ._1nzuaz7fl {
    margin-bottom: var(--space-2px);
  }
  ._1nzuaz7fr {
    margin-bottom: var(--space-6px);
  }
  ._1nzuaz7fx {
    margin-bottom: var(--space-4px);
  }
  ._1nzuaz7g3 {
    margin-bottom: var(--space-8px);
  }
  ._1nzuaz7g9 {
    margin-bottom: var(--space-12px);
  }
  ._1nzuaz7gf {
    margin-bottom: var(--space-14px);
  }
  ._1nzuaz7gl {
    margin-bottom: var(--space-16px);
  }
  ._1nzuaz7gr {
    margin-bottom: var(--space-20px);
  }
  ._1nzuaz7gx {
    margin-bottom: var(--space-24px);
  }
  ._1nzuaz7h3 {
    margin-bottom: var(--space-32px);
  }
  ._1nzuaz7h9 {
    margin-bottom: var(--space-40px);
  }
  ._1nzuaz7hf {
    margin-bottom: var(--space-48px);
  }
  ._1nzuaz7hl {
    margin-bottom: var(--space-56px);
  }
  ._1nzuaz7hr {
    margin-bottom: var(--space-64px);
  }
  ._1nzuaz7hx {
    margin-bottom: auto;
  }
  ._1nzuaz7i3 {
    margin-left: var(--space-0px);
  }
  ._1nzuaz7i9 {
    margin-left: var(--space-1rem);
  }
  ._1nzuaz7if {
    margin-left: var(--space-1px);
  }
  ._1nzuaz7il {
    margin-left: var(--space-2px);
  }
  ._1nzuaz7ir {
    margin-left: var(--space-6px);
  }
  ._1nzuaz7ix {
    margin-left: var(--space-4px);
  }
  ._1nzuaz7j3 {
    margin-left: var(--space-8px);
  }
  ._1nzuaz7j9 {
    margin-left: var(--space-12px);
  }
  ._1nzuaz7jf {
    margin-left: var(--space-14px);
  }
  ._1nzuaz7jl {
    margin-left: var(--space-16px);
  }
  ._1nzuaz7jr {
    margin-left: var(--space-20px);
  }
  ._1nzuaz7jx {
    margin-left: var(--space-24px);
  }
  ._1nzuaz7k3 {
    margin-left: var(--space-32px);
  }
  ._1nzuaz7k9 {
    margin-left: var(--space-40px);
  }
  ._1nzuaz7kf {
    margin-left: var(--space-48px);
  }
  ._1nzuaz7kl {
    margin-left: var(--space-56px);
  }
  ._1nzuaz7kr {
    margin-left: var(--space-64px);
  }
  ._1nzuaz7kx {
    margin-left: auto;
  }
  ._1nzuaz7l3 {
    margin-right: var(--space-0px);
  }
  ._1nzuaz7l9 {
    margin-right: var(--space-1rem);
  }
  ._1nzuaz7lf {
    margin-right: var(--space-1px);
  }
  ._1nzuaz7ll {
    margin-right: var(--space-2px);
  }
  ._1nzuaz7lr {
    margin-right: var(--space-6px);
  }
  ._1nzuaz7lx {
    margin-right: var(--space-4px);
  }
  ._1nzuaz7m3 {
    margin-right: var(--space-8px);
  }
  ._1nzuaz7m9 {
    margin-right: var(--space-12px);
  }
  ._1nzuaz7mf {
    margin-right: var(--space-14px);
  }
  ._1nzuaz7ml {
    margin-right: var(--space-16px);
  }
  ._1nzuaz7mr {
    margin-right: var(--space-20px);
  }
  ._1nzuaz7mx {
    margin-right: var(--space-24px);
  }
  ._1nzuaz7n3 {
    margin-right: var(--space-32px);
  }
  ._1nzuaz7n9 {
    margin-right: var(--space-40px);
  }
  ._1nzuaz7nf {
    margin-right: var(--space-48px);
  }
  ._1nzuaz7nl {
    margin-right: var(--space-56px);
  }
  ._1nzuaz7nr {
    margin-right: var(--space-64px);
  }
  ._1nzuaz7nx {
    margin-right: auto;
  }
  ._1nzuaz7o3 {
    margin-top: var(--space-0px);
  }
  ._1nzuaz7o9 {
    margin-top: var(--space-1rem);
  }
  ._1nzuaz7of {
    margin-top: var(--space-1px);
  }
  ._1nzuaz7ol {
    margin-top: var(--space-2px);
  }
  ._1nzuaz7or {
    margin-top: var(--space-6px);
  }
  ._1nzuaz7ox {
    margin-top: var(--space-4px);
  }
  ._1nzuaz7p3 {
    margin-top: var(--space-8px);
  }
  ._1nzuaz7p9 {
    margin-top: var(--space-12px);
  }
  ._1nzuaz7pf {
    margin-top: var(--space-14px);
  }
  ._1nzuaz7pl {
    margin-top: var(--space-16px);
  }
  ._1nzuaz7pr {
    margin-top: var(--space-20px);
  }
  ._1nzuaz7px {
    margin-top: var(--space-24px);
  }
  ._1nzuaz7q3 {
    margin-top: var(--space-32px);
  }
  ._1nzuaz7q9 {
    margin-top: var(--space-40px);
  }
  ._1nzuaz7qf {
    margin-top: var(--space-48px);
  }
  ._1nzuaz7ql {
    margin-top: var(--space-56px);
  }
  ._1nzuaz7qr {
    margin-top: var(--space-64px);
  }
  ._1nzuaz7qx {
    margin-top: auto;
  }
  ._1nzuaz7r3 {
    margin: var(--space-0px);
  }
  ._1nzuaz7r9 {
    margin: var(--space-1rem);
  }
  ._1nzuaz7rf {
    margin: var(--space-1px);
  }
  ._1nzuaz7rl {
    margin: var(--space-2px);
  }
  ._1nzuaz7rr {
    margin: var(--space-6px);
  }
  ._1nzuaz7rx {
    margin: var(--space-4px);
  }
  ._1nzuaz7s3 {
    margin: var(--space-8px);
  }
  ._1nzuaz7s9 {
    margin: var(--space-12px);
  }
  ._1nzuaz7sf {
    margin: var(--space-14px);
  }
  ._1nzuaz7sl {
    margin: var(--space-16px);
  }
  ._1nzuaz7sr {
    margin: var(--space-20px);
  }
  ._1nzuaz7sx {
    margin: var(--space-24px);
  }
  ._1nzuaz7t3 {
    margin: var(--space-32px);
  }
  ._1nzuaz7t9 {
    margin: var(--space-40px);
  }
  ._1nzuaz7tf {
    margin: var(--space-48px);
  }
  ._1nzuaz7tl {
    margin: var(--space-56px);
  }
  ._1nzuaz7tr {
    margin: var(--space-64px);
  }
  ._1nzuaz7tx {
    margin: auto;
  }
  ._1nzuaz7u3 {
    padding: var(--space-0px);
  }
  ._1nzuaz7u9 {
    padding: var(--space-1rem);
  }
  ._1nzuaz7uf {
    padding: var(--space-1px);
  }
  ._1nzuaz7ul {
    padding: var(--space-2px);
  }
  ._1nzuaz7ur {
    padding: var(--space-6px);
  }
  ._1nzuaz7ux {
    padding: var(--space-4px);
  }
  ._1nzuaz7v3 {
    padding: var(--space-8px);
  }
  ._1nzuaz7v9 {
    padding: var(--space-12px);
  }
  ._1nzuaz7vf {
    padding: var(--space-14px);
  }
  ._1nzuaz7vl {
    padding: var(--space-16px);
  }
  ._1nzuaz7vr {
    padding: var(--space-20px);
  }
  ._1nzuaz7vx {
    padding: var(--space-24px);
  }
  ._1nzuaz7w3 {
    padding: var(--space-32px);
  }
  ._1nzuaz7w9 {
    padding: var(--space-40px);
  }
  ._1nzuaz7wf {
    padding: var(--space-48px);
  }
  ._1nzuaz7wl {
    padding: var(--space-56px);
  }
  ._1nzuaz7wr {
    padding: var(--space-64px);
  }
  ._1nzuaz7wx {
    max-height: var(--space-0px);
  }
  ._1nzuaz7x3 {
    max-height: var(--space-1rem);
  }
  ._1nzuaz7x9 {
    max-height: var(--space-1px);
  }
  ._1nzuaz7xf {
    max-height: var(--space-2px);
  }
  ._1nzuaz7xl {
    max-height: var(--space-6px);
  }
  ._1nzuaz7xr {
    max-height: var(--space-4px);
  }
  ._1nzuaz7xx {
    max-height: var(--space-8px);
  }
  ._1nzuaz7y3 {
    max-height: var(--space-12px);
  }
  ._1nzuaz7y9 {
    max-height: var(--space-14px);
  }
  ._1nzuaz7yf {
    max-height: var(--space-16px);
  }
  ._1nzuaz7yl {
    max-height: var(--space-20px);
  }
  ._1nzuaz7yr {
    max-height: var(--space-24px);
  }
  ._1nzuaz7yx {
    max-height: var(--space-32px);
  }
  ._1nzuaz7z3 {
    max-height: var(--space-40px);
  }
  ._1nzuaz7z9 {
    max-height: var(--space-48px);
  }
  ._1nzuaz7zf {
    max-height: var(--space-56px);
  }
  ._1nzuaz7zl {
    max-height: var(--space-64px);
  }
  ._1nzuaz7zr {
    max-width: var(--space-0px);
  }
  ._1nzuaz7zx {
    max-width: var(--space-1rem);
  }
  ._1nzuaz7103 {
    max-width: var(--space-1px);
  }
  ._1nzuaz7109 {
    max-width: var(--space-2px);
  }
  ._1nzuaz710f {
    max-width: var(--space-6px);
  }
  ._1nzuaz710l {
    max-width: var(--space-4px);
  }
  ._1nzuaz710r {
    max-width: var(--space-8px);
  }
  ._1nzuaz710x {
    max-width: var(--space-12px);
  }
  ._1nzuaz7113 {
    max-width: var(--space-14px);
  }
  ._1nzuaz7119 {
    max-width: var(--space-16px);
  }
  ._1nzuaz711f {
    max-width: var(--space-20px);
  }
  ._1nzuaz711l {
    max-width: var(--space-24px);
  }
  ._1nzuaz711r {
    max-width: var(--space-32px);
  }
  ._1nzuaz711x {
    max-width: var(--space-40px);
  }
  ._1nzuaz7123 {
    max-width: var(--space-48px);
  }
  ._1nzuaz7129 {
    max-width: var(--space-56px);
  }
  ._1nzuaz712f {
    max-width: var(--space-64px);
  }
  ._1nzuaz712l {
    max-width: 100%;
  }
  ._1nzuaz712r {
    max-width: auto;
  }
  ._1nzuaz712x {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  ._1nzuaz7133 {
    max-width: 420px;
  }
  ._1nzuaz7139 {
    max-width: 576px;
  }
  ._1nzuaz713f {
    max-width: 768px;
  }
  ._1nzuaz713l {
    max-width: 968px;
  }
  ._1nzuaz713r {
    max-width: 1024px;
  }
  ._1nzuaz713x {
    max-width: none;
  }
  ._1nzuaz7143 {
    min-height: var(--space-0px);
  }
  ._1nzuaz7149 {
    min-height: var(--space-1rem);
  }
  ._1nzuaz714f {
    min-height: var(--space-1px);
  }
  ._1nzuaz714l {
    min-height: var(--space-2px);
  }
  ._1nzuaz714r {
    min-height: var(--space-6px);
  }
  ._1nzuaz714x {
    min-height: var(--space-4px);
  }
  ._1nzuaz7153 {
    min-height: var(--space-8px);
  }
  ._1nzuaz7159 {
    min-height: var(--space-12px);
  }
  ._1nzuaz715f {
    min-height: var(--space-14px);
  }
  ._1nzuaz715l {
    min-height: var(--space-16px);
  }
  ._1nzuaz715r {
    min-height: var(--space-20px);
  }
  ._1nzuaz715x {
    min-height: var(--space-24px);
  }
  ._1nzuaz7163 {
    min-height: var(--space-32px);
  }
  ._1nzuaz7169 {
    min-height: var(--space-40px);
  }
  ._1nzuaz716f {
    min-height: var(--space-48px);
  }
  ._1nzuaz716l {
    min-height: var(--space-56px);
  }
  ._1nzuaz716r {
    min-height: var(--space-64px);
  }
  ._1nzuaz716x {
    min-height: 100%;
  }
  ._1nzuaz7173 {
    min-height: auto;
  }
  ._1nzuaz7179 {
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
  ._1nzuaz717f {
    min-height: 420px;
  }
  ._1nzuaz717l {
    min-height: 576px;
  }
  ._1nzuaz717r {
    min-height: 768px;
  }
  ._1nzuaz717x {
    min-height: 968px;
  }
  ._1nzuaz7183 {
    min-height: 1024px;
  }
  ._1nzuaz7189 {
    min-height: 100vh;
  }
  ._1nzuaz718f {
    min-width: var(--space-0px);
  }
  ._1nzuaz718l {
    min-width: var(--space-1rem);
  }
  ._1nzuaz718r {
    min-width: var(--space-1px);
  }
  ._1nzuaz718x {
    min-width: var(--space-2px);
  }
  ._1nzuaz7193 {
    min-width: var(--space-6px);
  }
  ._1nzuaz7199 {
    min-width: var(--space-4px);
  }
  ._1nzuaz719f {
    min-width: var(--space-8px);
  }
  ._1nzuaz719l {
    min-width: var(--space-12px);
  }
  ._1nzuaz719r {
    min-width: var(--space-14px);
  }
  ._1nzuaz719x {
    min-width: var(--space-16px);
  }
  ._1nzuaz71a3 {
    min-width: var(--space-20px);
  }
  ._1nzuaz71a9 {
    min-width: var(--space-24px);
  }
  ._1nzuaz71af {
    min-width: var(--space-32px);
  }
  ._1nzuaz71al {
    min-width: var(--space-40px);
  }
  ._1nzuaz71ar {
    min-width: var(--space-48px);
  }
  ._1nzuaz71ax {
    min-width: var(--space-56px);
  }
  ._1nzuaz71b3 {
    min-width: var(--space-64px);
  }
  ._1nzuaz71b9 {
    padding-bottom: var(--space-0px);
  }
  ._1nzuaz71bf {
    padding-bottom: var(--space-1rem);
  }
  ._1nzuaz71bl {
    padding-bottom: var(--space-1px);
  }
  ._1nzuaz71br {
    padding-bottom: var(--space-2px);
  }
  ._1nzuaz71bx {
    padding-bottom: var(--space-6px);
  }
  ._1nzuaz71c3 {
    padding-bottom: var(--space-4px);
  }
  ._1nzuaz71c9 {
    padding-bottom: var(--space-8px);
  }
  ._1nzuaz71cf {
    padding-bottom: var(--space-12px);
  }
  ._1nzuaz71cl {
    padding-bottom: var(--space-14px);
  }
  ._1nzuaz71cr {
    padding-bottom: var(--space-16px);
  }
  ._1nzuaz71cx {
    padding-bottom: var(--space-20px);
  }
  ._1nzuaz71d3 {
    padding-bottom: var(--space-24px);
  }
  ._1nzuaz71d9 {
    padding-bottom: var(--space-32px);
  }
  ._1nzuaz71df {
    padding-bottom: var(--space-40px);
  }
  ._1nzuaz71dl {
    padding-bottom: var(--space-48px);
  }
  ._1nzuaz71dr {
    padding-bottom: var(--space-56px);
  }
  ._1nzuaz71dx {
    padding-bottom: var(--space-64px);
  }
  ._1nzuaz71e3 {
    padding-left: var(--space-0px);
  }
  ._1nzuaz71e9 {
    padding-left: var(--space-1rem);
  }
  ._1nzuaz71ef {
    padding-left: var(--space-1px);
  }
  ._1nzuaz71el {
    padding-left: var(--space-2px);
  }
  ._1nzuaz71er {
    padding-left: var(--space-6px);
  }
  ._1nzuaz71ex {
    padding-left: var(--space-4px);
  }
  ._1nzuaz71f3 {
    padding-left: var(--space-8px);
  }
  ._1nzuaz71f9 {
    padding-left: var(--space-12px);
  }
  ._1nzuaz71ff {
    padding-left: var(--space-14px);
  }
  ._1nzuaz71fl {
    padding-left: var(--space-16px);
  }
  ._1nzuaz71fr {
    padding-left: var(--space-20px);
  }
  ._1nzuaz71fx {
    padding-left: var(--space-24px);
  }
  ._1nzuaz71g3 {
    padding-left: var(--space-32px);
  }
  ._1nzuaz71g9 {
    padding-left: var(--space-40px);
  }
  ._1nzuaz71gf {
    padding-left: var(--space-48px);
  }
  ._1nzuaz71gl {
    padding-left: var(--space-56px);
  }
  ._1nzuaz71gr {
    padding-left: var(--space-64px);
  }
  ._1nzuaz71gx {
    padding-right: var(--space-0px);
  }
  ._1nzuaz71h3 {
    padding-right: var(--space-1rem);
  }
  ._1nzuaz71h9 {
    padding-right: var(--space-1px);
  }
  ._1nzuaz71hf {
    padding-right: var(--space-2px);
  }
  ._1nzuaz71hl {
    padding-right: var(--space-6px);
  }
  ._1nzuaz71hr {
    padding-right: var(--space-4px);
  }
  ._1nzuaz71hx {
    padding-right: var(--space-8px);
  }
  ._1nzuaz71i3 {
    padding-right: var(--space-12px);
  }
  ._1nzuaz71i9 {
    padding-right: var(--space-14px);
  }
  ._1nzuaz71if {
    padding-right: var(--space-16px);
  }
  ._1nzuaz71il {
    padding-right: var(--space-20px);
  }
  ._1nzuaz71ir {
    padding-right: var(--space-24px);
  }
  ._1nzuaz71ix {
    padding-right: var(--space-32px);
  }
  ._1nzuaz71j3 {
    padding-right: var(--space-40px);
  }
  ._1nzuaz71j9 {
    padding-right: var(--space-48px);
  }
  ._1nzuaz71jf {
    padding-right: var(--space-56px);
  }
  ._1nzuaz71jl {
    padding-right: var(--space-64px);
  }
  ._1nzuaz71jr {
    padding-top: var(--space-0px);
  }
  ._1nzuaz71jx {
    padding-top: var(--space-1rem);
  }
  ._1nzuaz71k3 {
    padding-top: var(--space-1px);
  }
  ._1nzuaz71k9 {
    padding-top: var(--space-2px);
  }
  ._1nzuaz71kf {
    padding-top: var(--space-6px);
  }
  ._1nzuaz71kl {
    padding-top: var(--space-4px);
  }
  ._1nzuaz71kr {
    padding-top: var(--space-8px);
  }
  ._1nzuaz71kx {
    padding-top: var(--space-12px);
  }
  ._1nzuaz71l3 {
    padding-top: var(--space-14px);
  }
  ._1nzuaz71l9 {
    padding-top: var(--space-16px);
  }
  ._1nzuaz71lf {
    padding-top: var(--space-20px);
  }
  ._1nzuaz71ll {
    padding-top: var(--space-24px);
  }
  ._1nzuaz71lr {
    padding-top: var(--space-32px);
  }
  ._1nzuaz71lx {
    padding-top: var(--space-40px);
  }
  ._1nzuaz71m3 {
    padding-top: var(--space-48px);
  }
  ._1nzuaz71m9 {
    padding-top: var(--space-56px);
  }
  ._1nzuaz71mf {
    padding-top: var(--space-64px);
  }
  ._1nzuaz71ml {
    font-size: var(--fontSizes-10px);
  }
  ._1nzuaz71mr {
    font-size: var(--fontSizes-12px);
  }
  ._1nzuaz71mx {
    font-size: var(--fontSizes-16px);
  }
  ._1nzuaz71n3 {
    font-size: var(--fontSizes-14px);
  }
  ._1nzuaz71n9 {
    font-size: var(--fontSizes-20px);
  }
  ._1nzuaz71nf {
    font-size: var(--fontSizes-40px);
  }
  ._1nzuaz71nl {
    font-size: inherit;
  }
  ._1nzuaz71nr {
    flex: 1 1;
  }
  ._1nzuaz71nx {
    flex: 1 1 auto;
  }
  ._1nzuaz71o3 {
    flex: 0 1 auto;
  }
  ._1nzuaz71o9 {
    flex: none;
  }
  ._1nzuaz71of {
    box-shadow: var(--shadows-level1);
  }
  ._1nzuaz71ol {
    box-shadow: var(--shadows-active);
  }
  ._1nzuaz71or {
    box-shadow: var(--shadows-success);
  }
  ._1nzuaz71ox {
    box-shadow: var(--shadows-warning);
  }
  ._1nzuaz71p3 {
    box-shadow: var(--shadows-danger);
  }
  ._1nzuaz71p9 {
    box-shadow: var(--shadows-focus);
  }
  ._1nzuaz71pf {
    box-shadow: var(--shadows-inset);
  }
  ._1nzuaz71pl {
    box-shadow: var(--shadows-tooltip);
  }
  ._1nzuaz71pr {
    width: var(--space-0px);
  }
  ._1nzuaz71px {
    width: var(--space-1rem);
  }
  ._1nzuaz71q3 {
    width: var(--space-1px);
  }
  ._1nzuaz71q9 {
    width: var(--space-2px);
  }
  ._1nzuaz71qf {
    width: var(--space-6px);
  }
  ._1nzuaz71ql {
    width: var(--space-4px);
  }
  ._1nzuaz71qr {
    width: var(--space-8px);
  }
  ._1nzuaz71qx {
    width: var(--space-12px);
  }
  ._1nzuaz71r3 {
    width: var(--space-14px);
  }
  ._1nzuaz71r9 {
    width: var(--space-16px);
  }
  ._1nzuaz71rf {
    width: var(--space-20px);
  }
  ._1nzuaz71rl {
    width: var(--space-24px);
  }
  ._1nzuaz71rr {
    width: var(--space-32px);
  }
  ._1nzuaz71rx {
    width: var(--space-40px);
  }
  ._1nzuaz71s3 {
    width: var(--space-48px);
  }
  ._1nzuaz71s9 {
    width: var(--space-56px);
  }
  ._1nzuaz71sf {
    width: var(--space-64px);
  }
  ._1nzuaz71sl {
    width: 100%;
  }
  ._1nzuaz71sr {
    width: auto;
  }
  ._1nzuaz71sx {
    width: -moz-fit-content;
    width: fit-content;
  }
  ._1nzuaz71t3 {
    width: 420px;
  }
  ._1nzuaz71t9 {
    width: 576px;
  }
  ._1nzuaz71tf {
    width: 768px;
  }
  ._1nzuaz71tl {
    width: 968px;
  }
  ._1nzuaz71tr {
    width: 1024px;
  }
  ._1nzuaz71tx {
    z-index: 0;
  }
  ._1nzuaz71u3 {
    z-index: 1;
  }
  ._1nzuaz71u9 {
    z-index: 10;
  }
  ._1nzuaz71uf {
    z-index: 20;
  }
  ._1nzuaz71ul {
    z-index: 30;
  }
  ._1nzuaz71ur {
    z-index: 40;
  }
  ._1nzuaz71ux {
    z-index: 50;
  }
  ._1nzuaz71v3 {
    z-index: 75;
  }
  ._1nzuaz71v9 {
    z-index: 99;
  }
  ._1nzuaz71vf {
    z-index: 100;
  }
  ._1nzuaz71vl {
    z-index: 9;
  }
  ._1nzuaz71vr {
    z-index: 10;
  }
  ._1nzuaz71vx {
    z-index: 100;
  }
  ._1nzuaz71w3 {
    z-index: auto;
  }
  ._1nzuaz71w9 {
    border-top: 1px solid var(--colors-cardBorder);
  }
  ._1nzuaz71wf {
    border-radius: var(--radii-0);
  }
  ._1nzuaz71wl {
    border-radius: var(--radii-8px);
  }
  ._1nzuaz71wr {
    border-radius: var(--radii-12px);
  }
  ._1nzuaz71wx {
    border-radius: var(--radii-20px);
  }
  ._1nzuaz71x3 {
    border-radius: var(--radii-32px);
  }
  ._1nzuaz71x9 {
    border-radius: var(--radii-small);
  }
  ._1nzuaz71xf {
    border-radius: var(--radii-default);
  }
  ._1nzuaz71xl {
    border-radius: var(--radii-card);
  }
  ._1nzuaz71xr {
    border-radius: var(--radii-circle);
  }
  ._1nzuaz71xx {
    border-top-left-radius: var(--radii-0);
  }
  ._1nzuaz71y3 {
    border-top-left-radius: var(--radii-8px);
  }
  ._1nzuaz71y9 {
    border-top-left-radius: var(--radii-12px);
  }
  ._1nzuaz71yf {
    border-top-left-radius: var(--radii-20px);
  }
  ._1nzuaz71yl {
    border-top-left-radius: var(--radii-32px);
  }
  ._1nzuaz71yr {
    border-top-left-radius: var(--radii-small);
  }
  ._1nzuaz71yx {
    border-top-left-radius: var(--radii-default);
  }
  ._1nzuaz71z3 {
    border-top-left-radius: var(--radii-card);
  }
  ._1nzuaz71z9 {
    border-top-left-radius: var(--radii-circle);
  }
  ._1nzuaz71zf {
    border-bottom-right-radius: var(--radii-0);
  }
  ._1nzuaz71zl {
    border-bottom-right-radius: var(--radii-8px);
  }
  ._1nzuaz71zr {
    border-bottom-right-radius: var(--radii-12px);
  }
  ._1nzuaz71zx {
    border-bottom-right-radius: var(--radii-20px);
  }
  ._1nzuaz7203 {
    border-bottom-right-radius: var(--radii-32px);
  }
  ._1nzuaz7209 {
    border-bottom-right-radius: var(--radii-small);
  }
  ._1nzuaz720f {
    border-bottom-right-radius: var(--radii-default);
  }
  ._1nzuaz720l {
    border-bottom-right-radius: var(--radii-card);
  }
  ._1nzuaz720r {
    border-bottom-right-radius: var(--radii-circle);
  }
  ._1nzuaz720x {
    border-top-right-radius: var(--radii-0);
  }
  ._1nzuaz7213 {
    border-top-right-radius: var(--radii-8px);
  }
  ._1nzuaz7219 {
    border-top-right-radius: var(--radii-12px);
  }
  ._1nzuaz721f {
    border-top-right-radius: var(--radii-20px);
  }
  ._1nzuaz721l {
    border-top-right-radius: var(--radii-32px);
  }
  ._1nzuaz721r {
    border-top-right-radius: var(--radii-small);
  }
  ._1nzuaz721x {
    border-top-right-radius: var(--radii-default);
  }
  ._1nzuaz7223 {
    border-top-right-radius: var(--radii-card);
  }
  ._1nzuaz7229 {
    border-top-right-radius: var(--radii-circle);
  }
  ._1nzuaz722f {
    border-bottom-left-radius: var(--radii-0);
  }
  ._1nzuaz722l {
    border-bottom-left-radius: var(--radii-8px);
  }
  ._1nzuaz722r {
    border-bottom-left-radius: var(--radii-12px);
  }
  ._1nzuaz722x {
    border-bottom-left-radius: var(--radii-20px);
  }
  ._1nzuaz7233 {
    border-bottom-left-radius: var(--radii-32px);
  }
  ._1nzuaz7239 {
    border-bottom-left-radius: var(--radii-small);
  }
  ._1nzuaz723f {
    border-bottom-left-radius: var(--radii-default);
  }
  ._1nzuaz723l {
    border-bottom-left-radius: var(--radii-card);
  }
  ._1nzuaz723r {
    border-bottom-left-radius: var(--radii-circle);
  }
  ._1nzuaz723x {
    gap: var(--space-0px);
  }
  ._1nzuaz7243 {
    gap: var(--space-1rem);
  }
  ._1nzuaz7249 {
    gap: var(--space-1px);
  }
  ._1nzuaz724f {
    gap: var(--space-2px);
  }
  ._1nzuaz724l {
    gap: var(--space-6px);
  }
  ._1nzuaz724r {
    gap: var(--space-4px);
  }
  ._1nzuaz724x {
    gap: var(--space-8px);
  }
  ._1nzuaz7253 {
    gap: var(--space-12px);
  }
  ._1nzuaz7259 {
    gap: var(--space-14px);
  }
  ._1nzuaz725f {
    gap: var(--space-16px);
  }
  ._1nzuaz725l {
    gap: var(--space-20px);
  }
  ._1nzuaz725r {
    gap: var(--space-24px);
  }
  ._1nzuaz725x {
    gap: var(--space-32px);
  }
  ._1nzuaz7263 {
    gap: var(--space-40px);
  }
  ._1nzuaz7269 {
    gap: var(--space-48px);
  }
  ._1nzuaz726f {
    gap: var(--space-56px);
  }
  ._1nzuaz726l {
    gap: var(--space-64px);
  }
  ._1nzuaz726r {
    gap: var(--space-8px);
  }
  ._1nzuaz726x {
    gap: var(--space-12px);
  }
  ._1nzuaz7273 {
    gap: var(--space-24px);
  }
  ._1nzuaz7279 {
    row-gap: var(--space-0px);
  }
  ._1nzuaz727f {
    row-gap: var(--space-1rem);
  }
  ._1nzuaz727l {
    row-gap: var(--space-1px);
  }
  ._1nzuaz727r {
    row-gap: var(--space-2px);
  }
  ._1nzuaz727x {
    row-gap: var(--space-6px);
  }
  ._1nzuaz7283 {
    row-gap: var(--space-4px);
  }
  ._1nzuaz7289 {
    row-gap: var(--space-8px);
  }
  ._1nzuaz728f {
    row-gap: var(--space-12px);
  }
  ._1nzuaz728l {
    row-gap: var(--space-14px);
  }
  ._1nzuaz728r {
    row-gap: var(--space-16px);
  }
  ._1nzuaz728x {
    row-gap: var(--space-20px);
  }
  ._1nzuaz7293 {
    row-gap: var(--space-24px);
  }
  ._1nzuaz7299 {
    row-gap: var(--space-32px);
  }
  ._1nzuaz729f {
    row-gap: var(--space-40px);
  }
  ._1nzuaz729l {
    row-gap: var(--space-48px);
  }
  ._1nzuaz729r {
    row-gap: var(--space-56px);
  }
  ._1nzuaz729x {
    row-gap: var(--space-64px);
  }
  ._1nzuaz72a3 {
    row-gap: var(--space-8px);
  }
  ._1nzuaz72a9 {
    row-gap: var(--space-12px);
  }
  ._1nzuaz72af {
    row-gap: var(--space-24px);
  }
  ._1nzuaz72al {
    column-gap: var(--space-0px);
  }
  ._1nzuaz72ar {
    column-gap: var(--space-1rem);
  }
  ._1nzuaz72ax {
    column-gap: var(--space-1px);
  }
  ._1nzuaz72b3 {
    column-gap: var(--space-2px);
  }
  ._1nzuaz72b9 {
    column-gap: var(--space-6px);
  }
  ._1nzuaz72bf {
    column-gap: var(--space-4px);
  }
  ._1nzuaz72bl {
    column-gap: var(--space-8px);
  }
  ._1nzuaz72br {
    column-gap: var(--space-12px);
  }
  ._1nzuaz72bx {
    column-gap: var(--space-14px);
  }
  ._1nzuaz72c3 {
    column-gap: var(--space-16px);
  }
  ._1nzuaz72c9 {
    column-gap: var(--space-20px);
  }
  ._1nzuaz72cf {
    column-gap: var(--space-24px);
  }
  ._1nzuaz72cl {
    column-gap: var(--space-32px);
  }
  ._1nzuaz72cr {
    column-gap: var(--space-40px);
  }
  ._1nzuaz72cx {
    column-gap: var(--space-48px);
  }
  ._1nzuaz72d3 {
    column-gap: var(--space-56px);
  }
  ._1nzuaz72d9 {
    column-gap: var(--space-64px);
  }
  ._1nzuaz72df {
    column-gap: var(--space-8px);
  }
  ._1nzuaz72dl {
    column-gap: var(--space-12px);
  }
  ._1nzuaz72dr {
    column-gap: var(--space-24px);
  }
  ._1nzuaz72dx {
    grid-auto-rows: auto;
  }
  ._1nzuaz72e3 {
    opacity: 0;
  }
  ._1nzuaz72e9 {
    opacity: 1;
  }
  ._1nzuaz72ef {
    opacity: 0.5;
  }
  ._1nzuaz72el {
    opacity: 0.6;
  }
  ._1nzuaz72er {
    line-height: var(--space-16px);
  }
  ._1nzuaz72ex {
    white50: var(--colors-light-white50);
    white100: var(--colors-light-white100);
    white200: var(--colors-light-white200);
    white300: var(--colors-light-white300);
    white500: var(--colors-light-white500);
    white800: var(--colors-light-white800);
    white: var(--colors-light-white);
    black: var(--colors-light-black);
    failure: var(--colors-light-failure);
    failure33: var(--colors-light-failure33);
    red100: var(--colors-light-red100);
    primary: var(--colors-light-primary);
    primary0f: var(--colors-light-primary0f);
    primary3-d: var(--colors-light-primary3D);
    primary-bright: var(--colors-light-primaryBright);
    primary-dark: var(--colors-light-primaryDark);
    success: var(--colors-light-success);
    success19: var(--colors-light-success19);
    warning: var(--colors-light-warning);
    warning2-d: var(--colors-light-warning2D);
    warning33: var(--colors-light-warning33);
    yellow100: var(--colors-light-yellow100);
    gray50: var(--colors-light-gray50);
    gray100: var(--colors-light-gray100);
    gray200: var(--colors-light-gray200);
    gray300: var(--colors-light-gray300);
    gray390: var(--colors-light-gray390);
    gray400: var(--colors-light-gray400);
    gray500: var(--colors-light-gray500);
    gray700: var(--colors-light-gray700);
    gray800: var(--colors-light-gray800);
    gray850: var(--colors-light-gray850);
    gray900: var(--colors-light-gray900);
    gray1000: var(--colors-light-gray1000);
    tint-gray80: var(--colors-light-tintGray80);
    gradient-primary: var(--colors-light-gradientPrimary);
    gradient-primary10: var(--colors-light-gradientPrimary10);
    gradient-primary20: var(--colors-light-gradientPrimary20);
    gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
    orange100: var(--colors-light-orange100);
    orange: var(--colors-light-orange);
    purple: var(--colors-light-purple);
    purple60: var(--colors-light-purple60);
    orange-dark: var(--colors-light-orangeDark);
    binance: var(--colors-light-binance);
    overlay: var(--colors-light-overlay);
    gold: var(--colors-light-gold);
    silver: var(--colors-light-silver);
    bronze: var(--colors-light-bronze);
    secondary: var(--colors-light-secondary);
    secondary80: var(--colors-light-secondary80);
    background: var(--colors-light-background);
    background-disabled: var(--colors-light-backgroundDisabled);
    background-alt: var(--colors-light-backgroundAlt);
    background-alt2: var(--colors-light-backgroundAlt2);
    card-border: var(--colors-light-cardBorder);
    contrast: var(--colors-light-contrast);
    dropdown: var(--colors-light-dropdown);
    dropdown-deep: var(--colors-light-dropdownDeep);
    inverted-contrast: var(--colors-light-invertedContrast);
    input: var(--colors-light-input);
    input-secondary: var(--colors-light-inputSecondary);
    tertiary: var(--colors-light-tertiary);
    text: var(--colors-light-text);
    text99: var(--colors-light-text99);
    text-disabled: var(--colors-light-textDisabled);
    text-subtle: var(--colors-light-textSubtle);
    disabled: var(--colors-light-disabled);
    gradient-bubblegum: var(--colors-light-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
    gradient-card-header: var(--colors-light-gradientCardHeader);
    gradient-blue: var(--colors-light-gradientBlue);
    gradient-violet: var(--colors-light-gradientViolet);
    gradient-violet-alt: var(--colors-light-gradientVioletAlt);
    gradient-gold: var(--colors-light-gradientGold);
    gradient-bold: var(--colors-light-gradientBold);
  }
  ._1nzuaz72f3 {
    white50: var(--colors-dark-white50);
    white100: var(--colors-dark-white100);
    white200: var(--colors-dark-white200);
    white300: var(--colors-dark-white300);
    white500: var(--colors-dark-white500);
    white800: var(--colors-dark-white800);
    white: var(--colors-dark-white);
    black: var(--colors-dark-black);
    failure: var(--colors-dark-failure);
    failure33: var(--colors-dark-failure33);
    red100: var(--colors-dark-red100);
    primary: var(--colors-dark-primary);
    primary0f: var(--colors-dark-primary0f);
    primary3-d: var(--colors-dark-primary3D);
    primary-bright: var(--colors-dark-primaryBright);
    primary-dark: var(--colors-dark-primaryDark);
    success: var(--colors-dark-success);
    success19: var(--colors-dark-success19);
    warning: var(--colors-dark-warning);
    warning2-d: var(--colors-dark-warning2D);
    warning33: var(--colors-dark-warning33);
    yellow100: var(--colors-dark-yellow100);
    gray50: var(--colors-dark-gray50);
    gray100: var(--colors-dark-gray100);
    gray200: var(--colors-dark-gray200);
    gray300: var(--colors-dark-gray300);
    gray390: var(--colors-dark-gray390);
    gray400: var(--colors-dark-gray400);
    gray500: var(--colors-dark-gray500);
    gray700: var(--colors-dark-gray700);
    gray800: var(--colors-dark-gray800);
    gray850: var(--colors-dark-gray850);
    gray900: var(--colors-dark-gray900);
    gray1000: var(--colors-dark-gray1000);
    tint-gray80: var(--colors-dark-tintGray80);
    gradient-primary: var(--colors-dark-gradientPrimary);
    gradient-primary10: var(--colors-dark-gradientPrimary10);
    gradient-primary20: var(--colors-dark-gradientPrimary20);
    gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
    orange100: var(--colors-dark-orange100);
    orange: var(--colors-dark-orange);
    purple: var(--colors-dark-purple);
    purple60: var(--colors-dark-purple60);
    orange-dark: var(--colors-dark-orangeDark);
    binance: var(--colors-dark-binance);
    overlay: var(--colors-dark-overlay);
    gold: var(--colors-dark-gold);
    silver: var(--colors-dark-silver);
    bronze: var(--colors-dark-bronze);
    secondary: var(--colors-dark-secondary);
    secondary80: var(--colors-dark-secondary80);
    background: var(--colors-dark-background);
    background-disabled: var(--colors-dark-backgroundDisabled);
    background-alt: var(--colors-dark-backgroundAlt);
    background-alt2: var(--colors-dark-backgroundAlt2);
    card-border: var(--colors-dark-cardBorder);
    contrast: var(--colors-dark-contrast);
    dropdown: var(--colors-dark-dropdown);
    dropdown-deep: var(--colors-dark-dropdownDeep);
    inverted-contrast: var(--colors-dark-invertedContrast);
    input: var(--colors-dark-input);
    input-secondary: var(--colors-dark-inputSecondary);
    tertiary: var(--colors-dark-tertiary);
    text: var(--colors-dark-text);
    text99: var(--colors-dark-text99);
    text-disabled: var(--colors-dark-textDisabled);
    text-subtle: var(--colors-dark-textSubtle);
    disabled: var(--colors-dark-disabled);
    gradient-bubblegum: var(--colors-dark-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
    gradient-card-header: var(--colors-dark-gradientCardHeader);
    gradient-blue: var(--colors-dark-gradientBlue);
    gradient-violet: var(--colors-dark-gradientViolet);
    gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
    gradient-gold: var(--colors-dark-gradientGold);
    gradient-bold: var(--colors-dark-gradientBold);
  }
  ._1nzuaz72f9 {
    border-bottom-color: var(--colors-white50);
  }
  ._1nzuaz72ff {
    border-bottom-color: var(--colors-white100);
  }
  ._1nzuaz72fl {
    border-bottom-color: var(--colors-white200);
  }
  ._1nzuaz72fr {
    border-bottom-color: var(--colors-white300);
  }
  ._1nzuaz72fx {
    border-bottom-color: var(--colors-white500);
  }
  ._1nzuaz72g3 {
    border-bottom-color: var(--colors-white800);
  }
  ._1nzuaz72g9 {
    border-bottom-color: var(--colors-white);
  }
  ._1nzuaz72gf {
    border-bottom-color: var(--colors-black);
  }
  ._1nzuaz72gl {
    border-bottom-color: var(--colors-failure);
  }
  ._1nzuaz72gr {
    border-bottom-color: var(--colors-failure33);
  }
  ._1nzuaz72gx {
    border-bottom-color: var(--colors-red100);
  }
  ._1nzuaz72h3 {
    border-bottom-color: var(--colors-primary);
  }
  ._1nzuaz72h9 {
    border-bottom-color: var(--colors-primary0f);
  }
  ._1nzuaz72hf {
    border-bottom-color: var(--colors-primary3D);
  }
  ._1nzuaz72hl {
    border-bottom-color: var(--colors-primaryBright);
  }
  ._1nzuaz72hr {
    border-bottom-color: var(--colors-primaryDark);
  }
  ._1nzuaz72hx {
    border-bottom-color: var(--colors-success);
  }
  ._1nzuaz72i3 {
    border-bottom-color: var(--colors-success19);
  }
  ._1nzuaz72i9 {
    border-bottom-color: var(--colors-warning);
  }
  ._1nzuaz72if {
    border-bottom-color: var(--colors-warning2D);
  }
  ._1nzuaz72il {
    border-bottom-color: var(--colors-warning33);
  }
  ._1nzuaz72ir {
    border-bottom-color: var(--colors-yellow100);
  }
  ._1nzuaz72ix {
    border-bottom-color: var(--colors-gray50);
  }
  ._1nzuaz72j3 {
    border-bottom-color: var(--colors-gray100);
  }
  ._1nzuaz72j9 {
    border-bottom-color: var(--colors-gray200);
  }
  ._1nzuaz72jf {
    border-bottom-color: var(--colors-gray300);
  }
  ._1nzuaz72jl {
    border-bottom-color: var(--colors-gray390);
  }
  ._1nzuaz72jr {
    border-bottom-color: var(--colors-gray400);
  }
  ._1nzuaz72jx {
    border-bottom-color: var(--colors-gray500);
  }
  ._1nzuaz72k3 {
    border-bottom-color: var(--colors-gray700);
  }
  ._1nzuaz72k9 {
    border-bottom-color: var(--colors-gray800);
  }
  ._1nzuaz72kf {
    border-bottom-color: var(--colors-gray850);
  }
  ._1nzuaz72kl {
    border-bottom-color: var(--colors-gray900);
  }
  ._1nzuaz72kr {
    border-bottom-color: var(--colors-gray1000);
  }
  ._1nzuaz72kx {
    border-bottom-color: var(--colors-tintGray80);
  }
  ._1nzuaz72l3 {
    border-bottom-color: var(--colors-gradientPrimary);
  }
  ._1nzuaz72l9 {
    border-bottom-color: var(--colors-gradientPrimary10);
  }
  ._1nzuaz72lf {
    border-bottom-color: var(--colors-gradientPrimary20);
  }
  ._1nzuaz72ll {
    border-bottom-color: var(--colors-gradientPrimaryRadial);
  }
  ._1nzuaz72lr {
    border-bottom-color: var(--colors-orange100);
  }
  ._1nzuaz72lx {
    border-bottom-color: var(--colors-orange);
  }
  ._1nzuaz72m3 {
    border-bottom-color: var(--colors-purple);
  }
  ._1nzuaz72m9 {
    border-bottom-color: var(--colors-purple60);
  }
  ._1nzuaz72mf {
    border-bottom-color: var(--colors-orangeDark);
  }
  ._1nzuaz72ml {
    border-bottom-color: var(--colors-binance);
  }
  ._1nzuaz72mr {
    border-bottom-color: var(--colors-overlay);
  }
  ._1nzuaz72mx {
    border-bottom-color: var(--colors-gold);
  }
  ._1nzuaz72n3 {
    border-bottom-color: var(--colors-silver);
  }
  ._1nzuaz72n9 {
    border-bottom-color: var(--colors-bronze);
  }
  ._1nzuaz72nf {
    border-bottom-color: var(--colors-secondary);
  }
  ._1nzuaz72nl {
    border-bottom-color: var(--colors-secondary80);
  }
  ._1nzuaz72nr {
    border-bottom-color: var(--colors-background);
  }
  ._1nzuaz72nx {
    border-bottom-color: var(--colors-backgroundDisabled);
  }
  ._1nzuaz72o3 {
    border-bottom-color: var(--colors-backgroundAlt);
  }
  ._1nzuaz72o9 {
    border-bottom-color: var(--colors-backgroundAlt2);
  }
  ._1nzuaz72of {
    border-bottom-color: var(--colors-cardBorder);
  }
  ._1nzuaz72ol {
    border-bottom-color: var(--colors-contrast);
  }
  ._1nzuaz72or {
    border-bottom-color: var(--colors-dropdown);
  }
  ._1nzuaz72ox {
    border-bottom-color: var(--colors-dropdownDeep);
  }
  ._1nzuaz72p3 {
    border-bottom-color: var(--colors-invertedContrast);
  }
  ._1nzuaz72p9 {
    border-bottom-color: var(--colors-input);
  }
  ._1nzuaz72pf {
    border-bottom-color: var(--colors-inputSecondary);
  }
  ._1nzuaz72pl {
    border-bottom-color: var(--colors-tertiary);
  }
  ._1nzuaz72pr {
    border-bottom-color: var(--colors-text);
  }
  ._1nzuaz72px {
    border-bottom-color: var(--colors-text99);
  }
  ._1nzuaz72q3 {
    border-bottom-color: var(--colors-textDisabled);
  }
  ._1nzuaz72q9 {
    border-bottom-color: var(--colors-textSubtle);
  }
  ._1nzuaz72qf {
    border-bottom-color: var(--colors-disabled);
  }
  ._1nzuaz72ql {
    border-bottom-color: var(--colors-gradientBubblegum);
  }
  ._1nzuaz72qr {
    border-bottom-color: var(--colors-gradientInverseBubblegum);
  }
  ._1nzuaz72qx {
    border-bottom-color: var(--colors-gradientCardHeader);
  }
  ._1nzuaz72r3 {
    border-bottom-color: var(--colors-gradientBlue);
  }
  ._1nzuaz72r9 {
    border-bottom-color: var(--colors-gradientViolet);
  }
  ._1nzuaz72rf {
    border-bottom-color: var(--colors-gradientVioletAlt);
  }
  ._1nzuaz72rl {
    border-bottom-color: var(--colors-gradientGold);
  }
  ._1nzuaz72rr {
    border-bottom-color: var(--colors-gradientBold);
  }
  ._1nzuaz72rx {
    border: 1px solid var(--colors-cardBorder);
  }
  ._1nzuaz72s3 {
    border-bottom: 1px solid var(--colors-cardBorder);
  }
}
@media (min-width: 1024px) {
  ._1nzuaz74 {
    display: block;
  }
  ._1nzuaz7a {
    display: flex;
  }
  ._1nzuaz7g {
    display: grid;
  }
  ._1nzuaz7m {
    display: inline;
  }
  ._1nzuaz7s {
    display: inline-flex;
  }
  ._1nzuaz7y {
    display: inline-block;
  }
  ._1nzuaz714 {
    display: none;
  }
  ._1nzuaz71a {
    flex-direction: column;
  }
  ._1nzuaz71g {
    flex-direction: row;
  }
  ._1nzuaz71m {
    flex-direction: column-reverse;
  }
  ._1nzuaz71s {
    align-items: center;
  }
  ._1nzuaz71y {
    align-items: end;
  }
  ._1nzuaz724 {
    align-items: baseLine;
  }
  ._1nzuaz72a {
    align-items: inherit;
  }
  ._1nzuaz72g {
    align-items: flex-start;
  }
  ._1nzuaz72m {
    align-items: center;
  }
  ._1nzuaz72s {
    align-items: start;
  }
  ._1nzuaz72y {
    align-items: flex-end;
  }
  ._1nzuaz734 {
    align-items: stretch;
  }
  ._1nzuaz73a {
    align-self: flex-start;
  }
  ._1nzuaz73g {
    align-self: center;
  }
  ._1nzuaz73m {
    align-self: start;
  }
  ._1nzuaz73s {
    align-self: flex-end;
  }
  ._1nzuaz73y {
    align-self: stretch;
  }
  ._1nzuaz744 {
    flex-wrap: wrap;
  }
  ._1nzuaz74a {
    flex-wrap: nowrap;
  }
  ._1nzuaz74g {
    flex-grow: 1;
  }
  ._1nzuaz74m {
    overflow: auto;
  }
  ._1nzuaz74s {
    overflow: hidden;
  }
  ._1nzuaz74y {
    overflow: scroll;
  }
  ._1nzuaz754 {
    overflow: unset;
  }
  ._1nzuaz75a {
    overflow-y: auto;
  }
  ._1nzuaz75g {
    overflow-y: hidden;
  }
  ._1nzuaz75m {
    overflow-y: scroll;
  }
  ._1nzuaz75s {
    overflow-x: auto;
  }
  ._1nzuaz75y {
    overflow-x: hidden;
  }
  ._1nzuaz764 {
    overflow-x: scroll;
  }
  ._1nzuaz76a {
    position: absolute;
  }
  ._1nzuaz76g {
    position: fixed;
  }
  ._1nzuaz76m {
    position: relative;
  }
  ._1nzuaz76s {
    position: sticky;
  }
  ._1nzuaz76y {
    text-align: center;
  }
  ._1nzuaz774 {
    text-align: left;
  }
  ._1nzuaz77a {
    text-align: right;
  }
  ._1nzuaz77g {
    justify-content: flex-start;
  }
  ._1nzuaz77m {
    justify-content: center;
  }
  ._1nzuaz77s {
    justify-content: start;
  }
  ._1nzuaz77y {
    justify-content: flex-end;
  }
  ._1nzuaz784 {
    justify-content: stretch;
  }
  ._1nzuaz78a {
    justify-content: space-around;
  }
  ._1nzuaz78g {
    justify-content: space-between;
  }
  ._1nzuaz78m {
    justify-items: flex-start;
  }
  ._1nzuaz78s {
    justify-items: center;
  }
  ._1nzuaz78y {
    justify-items: start;
  }
  ._1nzuaz794 {
    justify-items: flex-end;
  }
  ._1nzuaz79a {
    justify-items: stretch;
  }
  ._1nzuaz79g {
    justify-items: space-around;
  }
  ._1nzuaz79m {
    justify-items: space-between;
  }
  ._1nzuaz79s {
    justify-self: flex-start;
  }
  ._1nzuaz79y {
    justify-self: center;
  }
  ._1nzuaz7a4 {
    justify-self: start;
  }
  ._1nzuaz7aa {
    justify-self: flex-end;
  }
  ._1nzuaz7ag {
    justify-self: stretch;
  }
  ._1nzuaz7am {
    inset: 0px;
  }
  ._1nzuaz7as {
    height: var(--space-0px);
  }
  ._1nzuaz7ay {
    height: var(--space-1rem);
  }
  ._1nzuaz7b4 {
    height: var(--space-1px);
  }
  ._1nzuaz7ba {
    height: var(--space-2px);
  }
  ._1nzuaz7bg {
    height: var(--space-6px);
  }
  ._1nzuaz7bm {
    height: var(--space-4px);
  }
  ._1nzuaz7bs {
    height: var(--space-8px);
  }
  ._1nzuaz7by {
    height: var(--space-12px);
  }
  ._1nzuaz7c4 {
    height: var(--space-14px);
  }
  ._1nzuaz7ca {
    height: var(--space-16px);
  }
  ._1nzuaz7cg {
    height: var(--space-20px);
  }
  ._1nzuaz7cm {
    height: var(--space-24px);
  }
  ._1nzuaz7cs {
    height: var(--space-32px);
  }
  ._1nzuaz7cy {
    height: var(--space-40px);
  }
  ._1nzuaz7d4 {
    height: var(--space-48px);
  }
  ._1nzuaz7da {
    height: var(--space-56px);
  }
  ._1nzuaz7dg {
    height: var(--space-64px);
  }
  ._1nzuaz7dm {
    height: 100%;
  }
  ._1nzuaz7ds {
    height: auto;
  }
  ._1nzuaz7dy {
    height: -moz-fit-content;
    height: fit-content;
  }
  ._1nzuaz7e4 {
    height: 420px;
  }
  ._1nzuaz7ea {
    height: 576px;
  }
  ._1nzuaz7eg {
    height: 768px;
  }
  ._1nzuaz7em {
    height: 968px;
  }
  ._1nzuaz7es {
    height: 1024px;
  }
  ._1nzuaz7ey {
    height: 100vh;
  }
  ._1nzuaz7f4 {
    margin-bottom: var(--space-0px);
  }
  ._1nzuaz7fa {
    margin-bottom: var(--space-1rem);
  }
  ._1nzuaz7fg {
    margin-bottom: var(--space-1px);
  }
  ._1nzuaz7fm {
    margin-bottom: var(--space-2px);
  }
  ._1nzuaz7fs {
    margin-bottom: var(--space-6px);
  }
  ._1nzuaz7fy {
    margin-bottom: var(--space-4px);
  }
  ._1nzuaz7g4 {
    margin-bottom: var(--space-8px);
  }
  ._1nzuaz7ga {
    margin-bottom: var(--space-12px);
  }
  ._1nzuaz7gg {
    margin-bottom: var(--space-14px);
  }
  ._1nzuaz7gm {
    margin-bottom: var(--space-16px);
  }
  ._1nzuaz7gs {
    margin-bottom: var(--space-20px);
  }
  ._1nzuaz7gy {
    margin-bottom: var(--space-24px);
  }
  ._1nzuaz7h4 {
    margin-bottom: var(--space-32px);
  }
  ._1nzuaz7ha {
    margin-bottom: var(--space-40px);
  }
  ._1nzuaz7hg {
    margin-bottom: var(--space-48px);
  }
  ._1nzuaz7hm {
    margin-bottom: var(--space-56px);
  }
  ._1nzuaz7hs {
    margin-bottom: var(--space-64px);
  }
  ._1nzuaz7hy {
    margin-bottom: auto;
  }
  ._1nzuaz7i4 {
    margin-left: var(--space-0px);
  }
  ._1nzuaz7ia {
    margin-left: var(--space-1rem);
  }
  ._1nzuaz7ig {
    margin-left: var(--space-1px);
  }
  ._1nzuaz7im {
    margin-left: var(--space-2px);
  }
  ._1nzuaz7is {
    margin-left: var(--space-6px);
  }
  ._1nzuaz7iy {
    margin-left: var(--space-4px);
  }
  ._1nzuaz7j4 {
    margin-left: var(--space-8px);
  }
  ._1nzuaz7ja {
    margin-left: var(--space-12px);
  }
  ._1nzuaz7jg {
    margin-left: var(--space-14px);
  }
  ._1nzuaz7jm {
    margin-left: var(--space-16px);
  }
  ._1nzuaz7js {
    margin-left: var(--space-20px);
  }
  ._1nzuaz7jy {
    margin-left: var(--space-24px);
  }
  ._1nzuaz7k4 {
    margin-left: var(--space-32px);
  }
  ._1nzuaz7ka {
    margin-left: var(--space-40px);
  }
  ._1nzuaz7kg {
    margin-left: var(--space-48px);
  }
  ._1nzuaz7km {
    margin-left: var(--space-56px);
  }
  ._1nzuaz7ks {
    margin-left: var(--space-64px);
  }
  ._1nzuaz7ky {
    margin-left: auto;
  }
  ._1nzuaz7l4 {
    margin-right: var(--space-0px);
  }
  ._1nzuaz7la {
    margin-right: var(--space-1rem);
  }
  ._1nzuaz7lg {
    margin-right: var(--space-1px);
  }
  ._1nzuaz7lm {
    margin-right: var(--space-2px);
  }
  ._1nzuaz7ls {
    margin-right: var(--space-6px);
  }
  ._1nzuaz7ly {
    margin-right: var(--space-4px);
  }
  ._1nzuaz7m4 {
    margin-right: var(--space-8px);
  }
  ._1nzuaz7ma {
    margin-right: var(--space-12px);
  }
  ._1nzuaz7mg {
    margin-right: var(--space-14px);
  }
  ._1nzuaz7mm {
    margin-right: var(--space-16px);
  }
  ._1nzuaz7ms {
    margin-right: var(--space-20px);
  }
  ._1nzuaz7my {
    margin-right: var(--space-24px);
  }
  ._1nzuaz7n4 {
    margin-right: var(--space-32px);
  }
  ._1nzuaz7na {
    margin-right: var(--space-40px);
  }
  ._1nzuaz7ng {
    margin-right: var(--space-48px);
  }
  ._1nzuaz7nm {
    margin-right: var(--space-56px);
  }
  ._1nzuaz7ns {
    margin-right: var(--space-64px);
  }
  ._1nzuaz7ny {
    margin-right: auto;
  }
  ._1nzuaz7o4 {
    margin-top: var(--space-0px);
  }
  ._1nzuaz7oa {
    margin-top: var(--space-1rem);
  }
  ._1nzuaz7og {
    margin-top: var(--space-1px);
  }
  ._1nzuaz7om {
    margin-top: var(--space-2px);
  }
  ._1nzuaz7os {
    margin-top: var(--space-6px);
  }
  ._1nzuaz7oy {
    margin-top: var(--space-4px);
  }
  ._1nzuaz7p4 {
    margin-top: var(--space-8px);
  }
  ._1nzuaz7pa {
    margin-top: var(--space-12px);
  }
  ._1nzuaz7pg {
    margin-top: var(--space-14px);
  }
  ._1nzuaz7pm {
    margin-top: var(--space-16px);
  }
  ._1nzuaz7ps {
    margin-top: var(--space-20px);
  }
  ._1nzuaz7py {
    margin-top: var(--space-24px);
  }
  ._1nzuaz7q4 {
    margin-top: var(--space-32px);
  }
  ._1nzuaz7qa {
    margin-top: var(--space-40px);
  }
  ._1nzuaz7qg {
    margin-top: var(--space-48px);
  }
  ._1nzuaz7qm {
    margin-top: var(--space-56px);
  }
  ._1nzuaz7qs {
    margin-top: var(--space-64px);
  }
  ._1nzuaz7qy {
    margin-top: auto;
  }
  ._1nzuaz7r4 {
    margin: var(--space-0px);
  }
  ._1nzuaz7ra {
    margin: var(--space-1rem);
  }
  ._1nzuaz7rg {
    margin: var(--space-1px);
  }
  ._1nzuaz7rm {
    margin: var(--space-2px);
  }
  ._1nzuaz7rs {
    margin: var(--space-6px);
  }
  ._1nzuaz7ry {
    margin: var(--space-4px);
  }
  ._1nzuaz7s4 {
    margin: var(--space-8px);
  }
  ._1nzuaz7sa {
    margin: var(--space-12px);
  }
  ._1nzuaz7sg {
    margin: var(--space-14px);
  }
  ._1nzuaz7sm {
    margin: var(--space-16px);
  }
  ._1nzuaz7ss {
    margin: var(--space-20px);
  }
  ._1nzuaz7sy {
    margin: var(--space-24px);
  }
  ._1nzuaz7t4 {
    margin: var(--space-32px);
  }
  ._1nzuaz7ta {
    margin: var(--space-40px);
  }
  ._1nzuaz7tg {
    margin: var(--space-48px);
  }
  ._1nzuaz7tm {
    margin: var(--space-56px);
  }
  ._1nzuaz7ts {
    margin: var(--space-64px);
  }
  ._1nzuaz7ty {
    margin: auto;
  }
  ._1nzuaz7u4 {
    padding: var(--space-0px);
  }
  ._1nzuaz7ua {
    padding: var(--space-1rem);
  }
  ._1nzuaz7ug {
    padding: var(--space-1px);
  }
  ._1nzuaz7um {
    padding: var(--space-2px);
  }
  ._1nzuaz7us {
    padding: var(--space-6px);
  }
  ._1nzuaz7uy {
    padding: var(--space-4px);
  }
  ._1nzuaz7v4 {
    padding: var(--space-8px);
  }
  ._1nzuaz7va {
    padding: var(--space-12px);
  }
  ._1nzuaz7vg {
    padding: var(--space-14px);
  }
  ._1nzuaz7vm {
    padding: var(--space-16px);
  }
  ._1nzuaz7vs {
    padding: var(--space-20px);
  }
  ._1nzuaz7vy {
    padding: var(--space-24px);
  }
  ._1nzuaz7w4 {
    padding: var(--space-32px);
  }
  ._1nzuaz7wa {
    padding: var(--space-40px);
  }
  ._1nzuaz7wg {
    padding: var(--space-48px);
  }
  ._1nzuaz7wm {
    padding: var(--space-56px);
  }
  ._1nzuaz7ws {
    padding: var(--space-64px);
  }
  ._1nzuaz7wy {
    max-height: var(--space-0px);
  }
  ._1nzuaz7x4 {
    max-height: var(--space-1rem);
  }
  ._1nzuaz7xa {
    max-height: var(--space-1px);
  }
  ._1nzuaz7xg {
    max-height: var(--space-2px);
  }
  ._1nzuaz7xm {
    max-height: var(--space-6px);
  }
  ._1nzuaz7xs {
    max-height: var(--space-4px);
  }
  ._1nzuaz7xy {
    max-height: var(--space-8px);
  }
  ._1nzuaz7y4 {
    max-height: var(--space-12px);
  }
  ._1nzuaz7ya {
    max-height: var(--space-14px);
  }
  ._1nzuaz7yg {
    max-height: var(--space-16px);
  }
  ._1nzuaz7ym {
    max-height: var(--space-20px);
  }
  ._1nzuaz7ys {
    max-height: var(--space-24px);
  }
  ._1nzuaz7yy {
    max-height: var(--space-32px);
  }
  ._1nzuaz7z4 {
    max-height: var(--space-40px);
  }
  ._1nzuaz7za {
    max-height: var(--space-48px);
  }
  ._1nzuaz7zg {
    max-height: var(--space-56px);
  }
  ._1nzuaz7zm {
    max-height: var(--space-64px);
  }
  ._1nzuaz7zs {
    max-width: var(--space-0px);
  }
  ._1nzuaz7zy {
    max-width: var(--space-1rem);
  }
  ._1nzuaz7104 {
    max-width: var(--space-1px);
  }
  ._1nzuaz710a {
    max-width: var(--space-2px);
  }
  ._1nzuaz710g {
    max-width: var(--space-6px);
  }
  ._1nzuaz710m {
    max-width: var(--space-4px);
  }
  ._1nzuaz710s {
    max-width: var(--space-8px);
  }
  ._1nzuaz710y {
    max-width: var(--space-12px);
  }
  ._1nzuaz7114 {
    max-width: var(--space-14px);
  }
  ._1nzuaz711a {
    max-width: var(--space-16px);
  }
  ._1nzuaz711g {
    max-width: var(--space-20px);
  }
  ._1nzuaz711m {
    max-width: var(--space-24px);
  }
  ._1nzuaz711s {
    max-width: var(--space-32px);
  }
  ._1nzuaz711y {
    max-width: var(--space-40px);
  }
  ._1nzuaz7124 {
    max-width: var(--space-48px);
  }
  ._1nzuaz712a {
    max-width: var(--space-56px);
  }
  ._1nzuaz712g {
    max-width: var(--space-64px);
  }
  ._1nzuaz712m {
    max-width: 100%;
  }
  ._1nzuaz712s {
    max-width: auto;
  }
  ._1nzuaz712y {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  ._1nzuaz7134 {
    max-width: 420px;
  }
  ._1nzuaz713a {
    max-width: 576px;
  }
  ._1nzuaz713g {
    max-width: 768px;
  }
  ._1nzuaz713m {
    max-width: 968px;
  }
  ._1nzuaz713s {
    max-width: 1024px;
  }
  ._1nzuaz713y {
    max-width: none;
  }
  ._1nzuaz7144 {
    min-height: var(--space-0px);
  }
  ._1nzuaz714a {
    min-height: var(--space-1rem);
  }
  ._1nzuaz714g {
    min-height: var(--space-1px);
  }
  ._1nzuaz714m {
    min-height: var(--space-2px);
  }
  ._1nzuaz714s {
    min-height: var(--space-6px);
  }
  ._1nzuaz714y {
    min-height: var(--space-4px);
  }
  ._1nzuaz7154 {
    min-height: var(--space-8px);
  }
  ._1nzuaz715a {
    min-height: var(--space-12px);
  }
  ._1nzuaz715g {
    min-height: var(--space-14px);
  }
  ._1nzuaz715m {
    min-height: var(--space-16px);
  }
  ._1nzuaz715s {
    min-height: var(--space-20px);
  }
  ._1nzuaz715y {
    min-height: var(--space-24px);
  }
  ._1nzuaz7164 {
    min-height: var(--space-32px);
  }
  ._1nzuaz716a {
    min-height: var(--space-40px);
  }
  ._1nzuaz716g {
    min-height: var(--space-48px);
  }
  ._1nzuaz716m {
    min-height: var(--space-56px);
  }
  ._1nzuaz716s {
    min-height: var(--space-64px);
  }
  ._1nzuaz716y {
    min-height: 100%;
  }
  ._1nzuaz7174 {
    min-height: auto;
  }
  ._1nzuaz717a {
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
  ._1nzuaz717g {
    min-height: 420px;
  }
  ._1nzuaz717m {
    min-height: 576px;
  }
  ._1nzuaz717s {
    min-height: 768px;
  }
  ._1nzuaz717y {
    min-height: 968px;
  }
  ._1nzuaz7184 {
    min-height: 1024px;
  }
  ._1nzuaz718a {
    min-height: 100vh;
  }
  ._1nzuaz718g {
    min-width: var(--space-0px);
  }
  ._1nzuaz718m {
    min-width: var(--space-1rem);
  }
  ._1nzuaz718s {
    min-width: var(--space-1px);
  }
  ._1nzuaz718y {
    min-width: var(--space-2px);
  }
  ._1nzuaz7194 {
    min-width: var(--space-6px);
  }
  ._1nzuaz719a {
    min-width: var(--space-4px);
  }
  ._1nzuaz719g {
    min-width: var(--space-8px);
  }
  ._1nzuaz719m {
    min-width: var(--space-12px);
  }
  ._1nzuaz719s {
    min-width: var(--space-14px);
  }
  ._1nzuaz719y {
    min-width: var(--space-16px);
  }
  ._1nzuaz71a4 {
    min-width: var(--space-20px);
  }
  ._1nzuaz71aa {
    min-width: var(--space-24px);
  }
  ._1nzuaz71ag {
    min-width: var(--space-32px);
  }
  ._1nzuaz71am {
    min-width: var(--space-40px);
  }
  ._1nzuaz71as {
    min-width: var(--space-48px);
  }
  ._1nzuaz71ay {
    min-width: var(--space-56px);
  }
  ._1nzuaz71b4 {
    min-width: var(--space-64px);
  }
  ._1nzuaz71ba {
    padding-bottom: var(--space-0px);
  }
  ._1nzuaz71bg {
    padding-bottom: var(--space-1rem);
  }
  ._1nzuaz71bm {
    padding-bottom: var(--space-1px);
  }
  ._1nzuaz71bs {
    padding-bottom: var(--space-2px);
  }
  ._1nzuaz71by {
    padding-bottom: var(--space-6px);
  }
  ._1nzuaz71c4 {
    padding-bottom: var(--space-4px);
  }
  ._1nzuaz71ca {
    padding-bottom: var(--space-8px);
  }
  ._1nzuaz71cg {
    padding-bottom: var(--space-12px);
  }
  ._1nzuaz71cm {
    padding-bottom: var(--space-14px);
  }
  ._1nzuaz71cs {
    padding-bottom: var(--space-16px);
  }
  ._1nzuaz71cy {
    padding-bottom: var(--space-20px);
  }
  ._1nzuaz71d4 {
    padding-bottom: var(--space-24px);
  }
  ._1nzuaz71da {
    padding-bottom: var(--space-32px);
  }
  ._1nzuaz71dg {
    padding-bottom: var(--space-40px);
  }
  ._1nzuaz71dm {
    padding-bottom: var(--space-48px);
  }
  ._1nzuaz71ds {
    padding-bottom: var(--space-56px);
  }
  ._1nzuaz71dy {
    padding-bottom: var(--space-64px);
  }
  ._1nzuaz71e4 {
    padding-left: var(--space-0px);
  }
  ._1nzuaz71ea {
    padding-left: var(--space-1rem);
  }
  ._1nzuaz71eg {
    padding-left: var(--space-1px);
  }
  ._1nzuaz71em {
    padding-left: var(--space-2px);
  }
  ._1nzuaz71es {
    padding-left: var(--space-6px);
  }
  ._1nzuaz71ey {
    padding-left: var(--space-4px);
  }
  ._1nzuaz71f4 {
    padding-left: var(--space-8px);
  }
  ._1nzuaz71fa {
    padding-left: var(--space-12px);
  }
  ._1nzuaz71fg {
    padding-left: var(--space-14px);
  }
  ._1nzuaz71fm {
    padding-left: var(--space-16px);
  }
  ._1nzuaz71fs {
    padding-left: var(--space-20px);
  }
  ._1nzuaz71fy {
    padding-left: var(--space-24px);
  }
  ._1nzuaz71g4 {
    padding-left: var(--space-32px);
  }
  ._1nzuaz71ga {
    padding-left: var(--space-40px);
  }
  ._1nzuaz71gg {
    padding-left: var(--space-48px);
  }
  ._1nzuaz71gm {
    padding-left: var(--space-56px);
  }
  ._1nzuaz71gs {
    padding-left: var(--space-64px);
  }
  ._1nzuaz71gy {
    padding-right: var(--space-0px);
  }
  ._1nzuaz71h4 {
    padding-right: var(--space-1rem);
  }
  ._1nzuaz71ha {
    padding-right: var(--space-1px);
  }
  ._1nzuaz71hg {
    padding-right: var(--space-2px);
  }
  ._1nzuaz71hm {
    padding-right: var(--space-6px);
  }
  ._1nzuaz71hs {
    padding-right: var(--space-4px);
  }
  ._1nzuaz71hy {
    padding-right: var(--space-8px);
  }
  ._1nzuaz71i4 {
    padding-right: var(--space-12px);
  }
  ._1nzuaz71ia {
    padding-right: var(--space-14px);
  }
  ._1nzuaz71ig {
    padding-right: var(--space-16px);
  }
  ._1nzuaz71im {
    padding-right: var(--space-20px);
  }
  ._1nzuaz71is {
    padding-right: var(--space-24px);
  }
  ._1nzuaz71iy {
    padding-right: var(--space-32px);
  }
  ._1nzuaz71j4 {
    padding-right: var(--space-40px);
  }
  ._1nzuaz71ja {
    padding-right: var(--space-48px);
  }
  ._1nzuaz71jg {
    padding-right: var(--space-56px);
  }
  ._1nzuaz71jm {
    padding-right: var(--space-64px);
  }
  ._1nzuaz71js {
    padding-top: var(--space-0px);
  }
  ._1nzuaz71jy {
    padding-top: var(--space-1rem);
  }
  ._1nzuaz71k4 {
    padding-top: var(--space-1px);
  }
  ._1nzuaz71ka {
    padding-top: var(--space-2px);
  }
  ._1nzuaz71kg {
    padding-top: var(--space-6px);
  }
  ._1nzuaz71km {
    padding-top: var(--space-4px);
  }
  ._1nzuaz71ks {
    padding-top: var(--space-8px);
  }
  ._1nzuaz71ky {
    padding-top: var(--space-12px);
  }
  ._1nzuaz71l4 {
    padding-top: var(--space-14px);
  }
  ._1nzuaz71la {
    padding-top: var(--space-16px);
  }
  ._1nzuaz71lg {
    padding-top: var(--space-20px);
  }
  ._1nzuaz71lm {
    padding-top: var(--space-24px);
  }
  ._1nzuaz71ls {
    padding-top: var(--space-32px);
  }
  ._1nzuaz71ly {
    padding-top: var(--space-40px);
  }
  ._1nzuaz71m4 {
    padding-top: var(--space-48px);
  }
  ._1nzuaz71ma {
    padding-top: var(--space-56px);
  }
  ._1nzuaz71mg {
    padding-top: var(--space-64px);
  }
  ._1nzuaz71mm {
    font-size: var(--fontSizes-10px);
  }
  ._1nzuaz71ms {
    font-size: var(--fontSizes-12px);
  }
  ._1nzuaz71my {
    font-size: var(--fontSizes-16px);
  }
  ._1nzuaz71n4 {
    font-size: var(--fontSizes-14px);
  }
  ._1nzuaz71na {
    font-size: var(--fontSizes-20px);
  }
  ._1nzuaz71ng {
    font-size: var(--fontSizes-40px);
  }
  ._1nzuaz71nm {
    font-size: inherit;
  }
  ._1nzuaz71ns {
    flex: 1 1;
  }
  ._1nzuaz71ny {
    flex: 1 1 auto;
  }
  ._1nzuaz71o4 {
    flex: 0 1 auto;
  }
  ._1nzuaz71oa {
    flex: none;
  }
  ._1nzuaz71og {
    box-shadow: var(--shadows-level1);
  }
  ._1nzuaz71om {
    box-shadow: var(--shadows-active);
  }
  ._1nzuaz71os {
    box-shadow: var(--shadows-success);
  }
  ._1nzuaz71oy {
    box-shadow: var(--shadows-warning);
  }
  ._1nzuaz71p4 {
    box-shadow: var(--shadows-danger);
  }
  ._1nzuaz71pa {
    box-shadow: var(--shadows-focus);
  }
  ._1nzuaz71pg {
    box-shadow: var(--shadows-inset);
  }
  ._1nzuaz71pm {
    box-shadow: var(--shadows-tooltip);
  }
  ._1nzuaz71ps {
    width: var(--space-0px);
  }
  ._1nzuaz71py {
    width: var(--space-1rem);
  }
  ._1nzuaz71q4 {
    width: var(--space-1px);
  }
  ._1nzuaz71qa {
    width: var(--space-2px);
  }
  ._1nzuaz71qg {
    width: var(--space-6px);
  }
  ._1nzuaz71qm {
    width: var(--space-4px);
  }
  ._1nzuaz71qs {
    width: var(--space-8px);
  }
  ._1nzuaz71qy {
    width: var(--space-12px);
  }
  ._1nzuaz71r4 {
    width: var(--space-14px);
  }
  ._1nzuaz71ra {
    width: var(--space-16px);
  }
  ._1nzuaz71rg {
    width: var(--space-20px);
  }
  ._1nzuaz71rm {
    width: var(--space-24px);
  }
  ._1nzuaz71rs {
    width: var(--space-32px);
  }
  ._1nzuaz71ry {
    width: var(--space-40px);
  }
  ._1nzuaz71s4 {
    width: var(--space-48px);
  }
  ._1nzuaz71sa {
    width: var(--space-56px);
  }
  ._1nzuaz71sg {
    width: var(--space-64px);
  }
  ._1nzuaz71sm {
    width: 100%;
  }
  ._1nzuaz71ss {
    width: auto;
  }
  ._1nzuaz71sy {
    width: -moz-fit-content;
    width: fit-content;
  }
  ._1nzuaz71t4 {
    width: 420px;
  }
  ._1nzuaz71ta {
    width: 576px;
  }
  ._1nzuaz71tg {
    width: 768px;
  }
  ._1nzuaz71tm {
    width: 968px;
  }
  ._1nzuaz71ts {
    width: 1024px;
  }
  ._1nzuaz71ty {
    z-index: 0;
  }
  ._1nzuaz71u4 {
    z-index: 1;
  }
  ._1nzuaz71ua {
    z-index: 10;
  }
  ._1nzuaz71ug {
    z-index: 20;
  }
  ._1nzuaz71um {
    z-index: 30;
  }
  ._1nzuaz71us {
    z-index: 40;
  }
  ._1nzuaz71uy {
    z-index: 50;
  }
  ._1nzuaz71v4 {
    z-index: 75;
  }
  ._1nzuaz71va {
    z-index: 99;
  }
  ._1nzuaz71vg {
    z-index: 100;
  }
  ._1nzuaz71vm {
    z-index: 9;
  }
  ._1nzuaz71vs {
    z-index: 10;
  }
  ._1nzuaz71vy {
    z-index: 100;
  }
  ._1nzuaz71w4 {
    z-index: auto;
  }
  ._1nzuaz71wa {
    border-top: 1px solid var(--colors-cardBorder);
  }
  ._1nzuaz71wg {
    border-radius: var(--radii-0);
  }
  ._1nzuaz71wm {
    border-radius: var(--radii-8px);
  }
  ._1nzuaz71ws {
    border-radius: var(--radii-12px);
  }
  ._1nzuaz71wy {
    border-radius: var(--radii-20px);
  }
  ._1nzuaz71x4 {
    border-radius: var(--radii-32px);
  }
  ._1nzuaz71xa {
    border-radius: var(--radii-small);
  }
  ._1nzuaz71xg {
    border-radius: var(--radii-default);
  }
  ._1nzuaz71xm {
    border-radius: var(--radii-card);
  }
  ._1nzuaz71xs {
    border-radius: var(--radii-circle);
  }
  ._1nzuaz71xy {
    border-top-left-radius: var(--radii-0);
  }
  ._1nzuaz71y4 {
    border-top-left-radius: var(--radii-8px);
  }
  ._1nzuaz71ya {
    border-top-left-radius: var(--radii-12px);
  }
  ._1nzuaz71yg {
    border-top-left-radius: var(--radii-20px);
  }
  ._1nzuaz71ym {
    border-top-left-radius: var(--radii-32px);
  }
  ._1nzuaz71ys {
    border-top-left-radius: var(--radii-small);
  }
  ._1nzuaz71yy {
    border-top-left-radius: var(--radii-default);
  }
  ._1nzuaz71z4 {
    border-top-left-radius: var(--radii-card);
  }
  ._1nzuaz71za {
    border-top-left-radius: var(--radii-circle);
  }
  ._1nzuaz71zg {
    border-bottom-right-radius: var(--radii-0);
  }
  ._1nzuaz71zm {
    border-bottom-right-radius: var(--radii-8px);
  }
  ._1nzuaz71zs {
    border-bottom-right-radius: var(--radii-12px);
  }
  ._1nzuaz71zy {
    border-bottom-right-radius: var(--radii-20px);
  }
  ._1nzuaz7204 {
    border-bottom-right-radius: var(--radii-32px);
  }
  ._1nzuaz720a {
    border-bottom-right-radius: var(--radii-small);
  }
  ._1nzuaz720g {
    border-bottom-right-radius: var(--radii-default);
  }
  ._1nzuaz720m {
    border-bottom-right-radius: var(--radii-card);
  }
  ._1nzuaz720s {
    border-bottom-right-radius: var(--radii-circle);
  }
  ._1nzuaz720y {
    border-top-right-radius: var(--radii-0);
  }
  ._1nzuaz7214 {
    border-top-right-radius: var(--radii-8px);
  }
  ._1nzuaz721a {
    border-top-right-radius: var(--radii-12px);
  }
  ._1nzuaz721g {
    border-top-right-radius: var(--radii-20px);
  }
  ._1nzuaz721m {
    border-top-right-radius: var(--radii-32px);
  }
  ._1nzuaz721s {
    border-top-right-radius: var(--radii-small);
  }
  ._1nzuaz721y {
    border-top-right-radius: var(--radii-default);
  }
  ._1nzuaz7224 {
    border-top-right-radius: var(--radii-card);
  }
  ._1nzuaz722a {
    border-top-right-radius: var(--radii-circle);
  }
  ._1nzuaz722g {
    border-bottom-left-radius: var(--radii-0);
  }
  ._1nzuaz722m {
    border-bottom-left-radius: var(--radii-8px);
  }
  ._1nzuaz722s {
    border-bottom-left-radius: var(--radii-12px);
  }
  ._1nzuaz722y {
    border-bottom-left-radius: var(--radii-20px);
  }
  ._1nzuaz7234 {
    border-bottom-left-radius: var(--radii-32px);
  }
  ._1nzuaz723a {
    border-bottom-left-radius: var(--radii-small);
  }
  ._1nzuaz723g {
    border-bottom-left-radius: var(--radii-default);
  }
  ._1nzuaz723m {
    border-bottom-left-radius: var(--radii-card);
  }
  ._1nzuaz723s {
    border-bottom-left-radius: var(--radii-circle);
  }
  ._1nzuaz723y {
    gap: var(--space-0px);
  }
  ._1nzuaz7244 {
    gap: var(--space-1rem);
  }
  ._1nzuaz724a {
    gap: var(--space-1px);
  }
  ._1nzuaz724g {
    gap: var(--space-2px);
  }
  ._1nzuaz724m {
    gap: var(--space-6px);
  }
  ._1nzuaz724s {
    gap: var(--space-4px);
  }
  ._1nzuaz724y {
    gap: var(--space-8px);
  }
  ._1nzuaz7254 {
    gap: var(--space-12px);
  }
  ._1nzuaz725a {
    gap: var(--space-14px);
  }
  ._1nzuaz725g {
    gap: var(--space-16px);
  }
  ._1nzuaz725m {
    gap: var(--space-20px);
  }
  ._1nzuaz725s {
    gap: var(--space-24px);
  }
  ._1nzuaz725y {
    gap: var(--space-32px);
  }
  ._1nzuaz7264 {
    gap: var(--space-40px);
  }
  ._1nzuaz726a {
    gap: var(--space-48px);
  }
  ._1nzuaz726g {
    gap: var(--space-56px);
  }
  ._1nzuaz726m {
    gap: var(--space-64px);
  }
  ._1nzuaz726s {
    gap: var(--space-8px);
  }
  ._1nzuaz726y {
    gap: var(--space-12px);
  }
  ._1nzuaz7274 {
    gap: var(--space-24px);
  }
  ._1nzuaz727a {
    row-gap: var(--space-0px);
  }
  ._1nzuaz727g {
    row-gap: var(--space-1rem);
  }
  ._1nzuaz727m {
    row-gap: var(--space-1px);
  }
  ._1nzuaz727s {
    row-gap: var(--space-2px);
  }
  ._1nzuaz727y {
    row-gap: var(--space-6px);
  }
  ._1nzuaz7284 {
    row-gap: var(--space-4px);
  }
  ._1nzuaz728a {
    row-gap: var(--space-8px);
  }
  ._1nzuaz728g {
    row-gap: var(--space-12px);
  }
  ._1nzuaz728m {
    row-gap: var(--space-14px);
  }
  ._1nzuaz728s {
    row-gap: var(--space-16px);
  }
  ._1nzuaz728y {
    row-gap: var(--space-20px);
  }
  ._1nzuaz7294 {
    row-gap: var(--space-24px);
  }
  ._1nzuaz729a {
    row-gap: var(--space-32px);
  }
  ._1nzuaz729g {
    row-gap: var(--space-40px);
  }
  ._1nzuaz729m {
    row-gap: var(--space-48px);
  }
  ._1nzuaz729s {
    row-gap: var(--space-56px);
  }
  ._1nzuaz729y {
    row-gap: var(--space-64px);
  }
  ._1nzuaz72a4 {
    row-gap: var(--space-8px);
  }
  ._1nzuaz72aa {
    row-gap: var(--space-12px);
  }
  ._1nzuaz72ag {
    row-gap: var(--space-24px);
  }
  ._1nzuaz72am {
    column-gap: var(--space-0px);
  }
  ._1nzuaz72as {
    column-gap: var(--space-1rem);
  }
  ._1nzuaz72ay {
    column-gap: var(--space-1px);
  }
  ._1nzuaz72b4 {
    column-gap: var(--space-2px);
  }
  ._1nzuaz72ba {
    column-gap: var(--space-6px);
  }
  ._1nzuaz72bg {
    column-gap: var(--space-4px);
  }
  ._1nzuaz72bm {
    column-gap: var(--space-8px);
  }
  ._1nzuaz72bs {
    column-gap: var(--space-12px);
  }
  ._1nzuaz72by {
    column-gap: var(--space-14px);
  }
  ._1nzuaz72c4 {
    column-gap: var(--space-16px);
  }
  ._1nzuaz72ca {
    column-gap: var(--space-20px);
  }
  ._1nzuaz72cg {
    column-gap: var(--space-24px);
  }
  ._1nzuaz72cm {
    column-gap: var(--space-32px);
  }
  ._1nzuaz72cs {
    column-gap: var(--space-40px);
  }
  ._1nzuaz72cy {
    column-gap: var(--space-48px);
  }
  ._1nzuaz72d4 {
    column-gap: var(--space-56px);
  }
  ._1nzuaz72da {
    column-gap: var(--space-64px);
  }
  ._1nzuaz72dg {
    column-gap: var(--space-8px);
  }
  ._1nzuaz72dm {
    column-gap: var(--space-12px);
  }
  ._1nzuaz72ds {
    column-gap: var(--space-24px);
  }
  ._1nzuaz72dy {
    grid-auto-rows: auto;
  }
  ._1nzuaz72e4 {
    opacity: 0;
  }
  ._1nzuaz72ea {
    opacity: 1;
  }
  ._1nzuaz72eg {
    opacity: 0.5;
  }
  ._1nzuaz72em {
    opacity: 0.6;
  }
  ._1nzuaz72es {
    line-height: var(--space-16px);
  }
  ._1nzuaz72ey {
    white50: var(--colors-light-white50);
    white100: var(--colors-light-white100);
    white200: var(--colors-light-white200);
    white300: var(--colors-light-white300);
    white500: var(--colors-light-white500);
    white800: var(--colors-light-white800);
    white: var(--colors-light-white);
    black: var(--colors-light-black);
    failure: var(--colors-light-failure);
    failure33: var(--colors-light-failure33);
    red100: var(--colors-light-red100);
    primary: var(--colors-light-primary);
    primary0f: var(--colors-light-primary0f);
    primary3-d: var(--colors-light-primary3D);
    primary-bright: var(--colors-light-primaryBright);
    primary-dark: var(--colors-light-primaryDark);
    success: var(--colors-light-success);
    success19: var(--colors-light-success19);
    warning: var(--colors-light-warning);
    warning2-d: var(--colors-light-warning2D);
    warning33: var(--colors-light-warning33);
    yellow100: var(--colors-light-yellow100);
    gray50: var(--colors-light-gray50);
    gray100: var(--colors-light-gray100);
    gray200: var(--colors-light-gray200);
    gray300: var(--colors-light-gray300);
    gray390: var(--colors-light-gray390);
    gray400: var(--colors-light-gray400);
    gray500: var(--colors-light-gray500);
    gray700: var(--colors-light-gray700);
    gray800: var(--colors-light-gray800);
    gray850: var(--colors-light-gray850);
    gray900: var(--colors-light-gray900);
    gray1000: var(--colors-light-gray1000);
    tint-gray80: var(--colors-light-tintGray80);
    gradient-primary: var(--colors-light-gradientPrimary);
    gradient-primary10: var(--colors-light-gradientPrimary10);
    gradient-primary20: var(--colors-light-gradientPrimary20);
    gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
    orange100: var(--colors-light-orange100);
    orange: var(--colors-light-orange);
    purple: var(--colors-light-purple);
    purple60: var(--colors-light-purple60);
    orange-dark: var(--colors-light-orangeDark);
    binance: var(--colors-light-binance);
    overlay: var(--colors-light-overlay);
    gold: var(--colors-light-gold);
    silver: var(--colors-light-silver);
    bronze: var(--colors-light-bronze);
    secondary: var(--colors-light-secondary);
    secondary80: var(--colors-light-secondary80);
    background: var(--colors-light-background);
    background-disabled: var(--colors-light-backgroundDisabled);
    background-alt: var(--colors-light-backgroundAlt);
    background-alt2: var(--colors-light-backgroundAlt2);
    card-border: var(--colors-light-cardBorder);
    contrast: var(--colors-light-contrast);
    dropdown: var(--colors-light-dropdown);
    dropdown-deep: var(--colors-light-dropdownDeep);
    inverted-contrast: var(--colors-light-invertedContrast);
    input: var(--colors-light-input);
    input-secondary: var(--colors-light-inputSecondary);
    tertiary: var(--colors-light-tertiary);
    text: var(--colors-light-text);
    text99: var(--colors-light-text99);
    text-disabled: var(--colors-light-textDisabled);
    text-subtle: var(--colors-light-textSubtle);
    disabled: var(--colors-light-disabled);
    gradient-bubblegum: var(--colors-light-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
    gradient-card-header: var(--colors-light-gradientCardHeader);
    gradient-blue: var(--colors-light-gradientBlue);
    gradient-violet: var(--colors-light-gradientViolet);
    gradient-violet-alt: var(--colors-light-gradientVioletAlt);
    gradient-gold: var(--colors-light-gradientGold);
    gradient-bold: var(--colors-light-gradientBold);
  }
  ._1nzuaz72f4 {
    white50: var(--colors-dark-white50);
    white100: var(--colors-dark-white100);
    white200: var(--colors-dark-white200);
    white300: var(--colors-dark-white300);
    white500: var(--colors-dark-white500);
    white800: var(--colors-dark-white800);
    white: var(--colors-dark-white);
    black: var(--colors-dark-black);
    failure: var(--colors-dark-failure);
    failure33: var(--colors-dark-failure33);
    red100: var(--colors-dark-red100);
    primary: var(--colors-dark-primary);
    primary0f: var(--colors-dark-primary0f);
    primary3-d: var(--colors-dark-primary3D);
    primary-bright: var(--colors-dark-primaryBright);
    primary-dark: var(--colors-dark-primaryDark);
    success: var(--colors-dark-success);
    success19: var(--colors-dark-success19);
    warning: var(--colors-dark-warning);
    warning2-d: var(--colors-dark-warning2D);
    warning33: var(--colors-dark-warning33);
    yellow100: var(--colors-dark-yellow100);
    gray50: var(--colors-dark-gray50);
    gray100: var(--colors-dark-gray100);
    gray200: var(--colors-dark-gray200);
    gray300: var(--colors-dark-gray300);
    gray390: var(--colors-dark-gray390);
    gray400: var(--colors-dark-gray400);
    gray500: var(--colors-dark-gray500);
    gray700: var(--colors-dark-gray700);
    gray800: var(--colors-dark-gray800);
    gray850: var(--colors-dark-gray850);
    gray900: var(--colors-dark-gray900);
    gray1000: var(--colors-dark-gray1000);
    tint-gray80: var(--colors-dark-tintGray80);
    gradient-primary: var(--colors-dark-gradientPrimary);
    gradient-primary10: var(--colors-dark-gradientPrimary10);
    gradient-primary20: var(--colors-dark-gradientPrimary20);
    gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
    orange100: var(--colors-dark-orange100);
    orange: var(--colors-dark-orange);
    purple: var(--colors-dark-purple);
    purple60: var(--colors-dark-purple60);
    orange-dark: var(--colors-dark-orangeDark);
    binance: var(--colors-dark-binance);
    overlay: var(--colors-dark-overlay);
    gold: var(--colors-dark-gold);
    silver: var(--colors-dark-silver);
    bronze: var(--colors-dark-bronze);
    secondary: var(--colors-dark-secondary);
    secondary80: var(--colors-dark-secondary80);
    background: var(--colors-dark-background);
    background-disabled: var(--colors-dark-backgroundDisabled);
    background-alt: var(--colors-dark-backgroundAlt);
    background-alt2: var(--colors-dark-backgroundAlt2);
    card-border: var(--colors-dark-cardBorder);
    contrast: var(--colors-dark-contrast);
    dropdown: var(--colors-dark-dropdown);
    dropdown-deep: var(--colors-dark-dropdownDeep);
    inverted-contrast: var(--colors-dark-invertedContrast);
    input: var(--colors-dark-input);
    input-secondary: var(--colors-dark-inputSecondary);
    tertiary: var(--colors-dark-tertiary);
    text: var(--colors-dark-text);
    text99: var(--colors-dark-text99);
    text-disabled: var(--colors-dark-textDisabled);
    text-subtle: var(--colors-dark-textSubtle);
    disabled: var(--colors-dark-disabled);
    gradient-bubblegum: var(--colors-dark-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
    gradient-card-header: var(--colors-dark-gradientCardHeader);
    gradient-blue: var(--colors-dark-gradientBlue);
    gradient-violet: var(--colors-dark-gradientViolet);
    gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
    gradient-gold: var(--colors-dark-gradientGold);
    gradient-bold: var(--colors-dark-gradientBold);
  }
  ._1nzuaz72fa {
    border-bottom-color: var(--colors-white50);
  }
  ._1nzuaz72fg {
    border-bottom-color: var(--colors-white100);
  }
  ._1nzuaz72fm {
    border-bottom-color: var(--colors-white200);
  }
  ._1nzuaz72fs {
    border-bottom-color: var(--colors-white300);
  }
  ._1nzuaz72fy {
    border-bottom-color: var(--colors-white500);
  }
  ._1nzuaz72g4 {
    border-bottom-color: var(--colors-white800);
  }
  ._1nzuaz72ga {
    border-bottom-color: var(--colors-white);
  }
  ._1nzuaz72gg {
    border-bottom-color: var(--colors-black);
  }
  ._1nzuaz72gm {
    border-bottom-color: var(--colors-failure);
  }
  ._1nzuaz72gs {
    border-bottom-color: var(--colors-failure33);
  }
  ._1nzuaz72gy {
    border-bottom-color: var(--colors-red100);
  }
  ._1nzuaz72h4 {
    border-bottom-color: var(--colors-primary);
  }
  ._1nzuaz72ha {
    border-bottom-color: var(--colors-primary0f);
  }
  ._1nzuaz72hg {
    border-bottom-color: var(--colors-primary3D);
  }
  ._1nzuaz72hm {
    border-bottom-color: var(--colors-primaryBright);
  }
  ._1nzuaz72hs {
    border-bottom-color: var(--colors-primaryDark);
  }
  ._1nzuaz72hy {
    border-bottom-color: var(--colors-success);
  }
  ._1nzuaz72i4 {
    border-bottom-color: var(--colors-success19);
  }
  ._1nzuaz72ia {
    border-bottom-color: var(--colors-warning);
  }
  ._1nzuaz72ig {
    border-bottom-color: var(--colors-warning2D);
  }
  ._1nzuaz72im {
    border-bottom-color: var(--colors-warning33);
  }
  ._1nzuaz72is {
    border-bottom-color: var(--colors-yellow100);
  }
  ._1nzuaz72iy {
    border-bottom-color: var(--colors-gray50);
  }
  ._1nzuaz72j4 {
    border-bottom-color: var(--colors-gray100);
  }
  ._1nzuaz72ja {
    border-bottom-color: var(--colors-gray200);
  }
  ._1nzuaz72jg {
    border-bottom-color: var(--colors-gray300);
  }
  ._1nzuaz72jm {
    border-bottom-color: var(--colors-gray390);
  }
  ._1nzuaz72js {
    border-bottom-color: var(--colors-gray400);
  }
  ._1nzuaz72jy {
    border-bottom-color: var(--colors-gray500);
  }
  ._1nzuaz72k4 {
    border-bottom-color: var(--colors-gray700);
  }
  ._1nzuaz72ka {
    border-bottom-color: var(--colors-gray800);
  }
  ._1nzuaz72kg {
    border-bottom-color: var(--colors-gray850);
  }
  ._1nzuaz72km {
    border-bottom-color: var(--colors-gray900);
  }
  ._1nzuaz72ks {
    border-bottom-color: var(--colors-gray1000);
  }
  ._1nzuaz72ky {
    border-bottom-color: var(--colors-tintGray80);
  }
  ._1nzuaz72l4 {
    border-bottom-color: var(--colors-gradientPrimary);
  }
  ._1nzuaz72la {
    border-bottom-color: var(--colors-gradientPrimary10);
  }
  ._1nzuaz72lg {
    border-bottom-color: var(--colors-gradientPrimary20);
  }
  ._1nzuaz72lm {
    border-bottom-color: var(--colors-gradientPrimaryRadial);
  }
  ._1nzuaz72ls {
    border-bottom-color: var(--colors-orange100);
  }
  ._1nzuaz72ly {
    border-bottom-color: var(--colors-orange);
  }
  ._1nzuaz72m4 {
    border-bottom-color: var(--colors-purple);
  }
  ._1nzuaz72ma {
    border-bottom-color: var(--colors-purple60);
  }
  ._1nzuaz72mg {
    border-bottom-color: var(--colors-orangeDark);
  }
  ._1nzuaz72mm {
    border-bottom-color: var(--colors-binance);
  }
  ._1nzuaz72ms {
    border-bottom-color: var(--colors-overlay);
  }
  ._1nzuaz72my {
    border-bottom-color: var(--colors-gold);
  }
  ._1nzuaz72n4 {
    border-bottom-color: var(--colors-silver);
  }
  ._1nzuaz72na {
    border-bottom-color: var(--colors-bronze);
  }
  ._1nzuaz72ng {
    border-bottom-color: var(--colors-secondary);
  }
  ._1nzuaz72nm {
    border-bottom-color: var(--colors-secondary80);
  }
  ._1nzuaz72ns {
    border-bottom-color: var(--colors-background);
  }
  ._1nzuaz72ny {
    border-bottom-color: var(--colors-backgroundDisabled);
  }
  ._1nzuaz72o4 {
    border-bottom-color: var(--colors-backgroundAlt);
  }
  ._1nzuaz72oa {
    border-bottom-color: var(--colors-backgroundAlt2);
  }
  ._1nzuaz72og {
    border-bottom-color: var(--colors-cardBorder);
  }
  ._1nzuaz72om {
    border-bottom-color: var(--colors-contrast);
  }
  ._1nzuaz72os {
    border-bottom-color: var(--colors-dropdown);
  }
  ._1nzuaz72oy {
    border-bottom-color: var(--colors-dropdownDeep);
  }
  ._1nzuaz72p4 {
    border-bottom-color: var(--colors-invertedContrast);
  }
  ._1nzuaz72pa {
    border-bottom-color: var(--colors-input);
  }
  ._1nzuaz72pg {
    border-bottom-color: var(--colors-inputSecondary);
  }
  ._1nzuaz72pm {
    border-bottom-color: var(--colors-tertiary);
  }
  ._1nzuaz72ps {
    border-bottom-color: var(--colors-text);
  }
  ._1nzuaz72py {
    border-bottom-color: var(--colors-text99);
  }
  ._1nzuaz72q4 {
    border-bottom-color: var(--colors-textDisabled);
  }
  ._1nzuaz72qa {
    border-bottom-color: var(--colors-textSubtle);
  }
  ._1nzuaz72qg {
    border-bottom-color: var(--colors-disabled);
  }
  ._1nzuaz72qm {
    border-bottom-color: var(--colors-gradientBubblegum);
  }
  ._1nzuaz72qs {
    border-bottom-color: var(--colors-gradientInverseBubblegum);
  }
  ._1nzuaz72qy {
    border-bottom-color: var(--colors-gradientCardHeader);
  }
  ._1nzuaz72r4 {
    border-bottom-color: var(--colors-gradientBlue);
  }
  ._1nzuaz72ra {
    border-bottom-color: var(--colors-gradientViolet);
  }
  ._1nzuaz72rg {
    border-bottom-color: var(--colors-gradientVioletAlt);
  }
  ._1nzuaz72rm {
    border-bottom-color: var(--colors-gradientGold);
  }
  ._1nzuaz72rs {
    border-bottom-color: var(--colors-gradientBold);
  }
  ._1nzuaz72ry {
    border: 1px solid var(--colors-cardBorder);
  }
  ._1nzuaz72s4 {
    border-bottom: 1px solid var(--colors-cardBorder);
  }
}
@media (min-width: 1200px) {
  ._1nzuaz75 {
    display: block;
  }
  ._1nzuaz7b {
    display: flex;
  }
  ._1nzuaz7h {
    display: grid;
  }
  ._1nzuaz7n {
    display: inline;
  }
  ._1nzuaz7t {
    display: inline-flex;
  }
  ._1nzuaz7z {
    display: inline-block;
  }
  ._1nzuaz715 {
    display: none;
  }
  ._1nzuaz71b {
    flex-direction: column;
  }
  ._1nzuaz71h {
    flex-direction: row;
  }
  ._1nzuaz71n {
    flex-direction: column-reverse;
  }
  ._1nzuaz71t {
    align-items: center;
  }
  ._1nzuaz71z {
    align-items: end;
  }
  ._1nzuaz725 {
    align-items: baseLine;
  }
  ._1nzuaz72b {
    align-items: inherit;
  }
  ._1nzuaz72h {
    align-items: flex-start;
  }
  ._1nzuaz72n {
    align-items: center;
  }
  ._1nzuaz72t {
    align-items: start;
  }
  ._1nzuaz72z {
    align-items: flex-end;
  }
  ._1nzuaz735 {
    align-items: stretch;
  }
  ._1nzuaz73b {
    align-self: flex-start;
  }
  ._1nzuaz73h {
    align-self: center;
  }
  ._1nzuaz73n {
    align-self: start;
  }
  ._1nzuaz73t {
    align-self: flex-end;
  }
  ._1nzuaz73z {
    align-self: stretch;
  }
  ._1nzuaz745 {
    flex-wrap: wrap;
  }
  ._1nzuaz74b {
    flex-wrap: nowrap;
  }
  ._1nzuaz74h {
    flex-grow: 1;
  }
  ._1nzuaz74n {
    overflow: auto;
  }
  ._1nzuaz74t {
    overflow: hidden;
  }
  ._1nzuaz74z {
    overflow: scroll;
  }
  ._1nzuaz755 {
    overflow: unset;
  }
  ._1nzuaz75b {
    overflow-y: auto;
  }
  ._1nzuaz75h {
    overflow-y: hidden;
  }
  ._1nzuaz75n {
    overflow-y: scroll;
  }
  ._1nzuaz75t {
    overflow-x: auto;
  }
  ._1nzuaz75z {
    overflow-x: hidden;
  }
  ._1nzuaz765 {
    overflow-x: scroll;
  }
  ._1nzuaz76b {
    position: absolute;
  }
  ._1nzuaz76h {
    position: fixed;
  }
  ._1nzuaz76n {
    position: relative;
  }
  ._1nzuaz76t {
    position: sticky;
  }
  ._1nzuaz76z {
    text-align: center;
  }
  ._1nzuaz775 {
    text-align: left;
  }
  ._1nzuaz77b {
    text-align: right;
  }
  ._1nzuaz77h {
    justify-content: flex-start;
  }
  ._1nzuaz77n {
    justify-content: center;
  }
  ._1nzuaz77t {
    justify-content: start;
  }
  ._1nzuaz77z {
    justify-content: flex-end;
  }
  ._1nzuaz785 {
    justify-content: stretch;
  }
  ._1nzuaz78b {
    justify-content: space-around;
  }
  ._1nzuaz78h {
    justify-content: space-between;
  }
  ._1nzuaz78n {
    justify-items: flex-start;
  }
  ._1nzuaz78t {
    justify-items: center;
  }
  ._1nzuaz78z {
    justify-items: start;
  }
  ._1nzuaz795 {
    justify-items: flex-end;
  }
  ._1nzuaz79b {
    justify-items: stretch;
  }
  ._1nzuaz79h {
    justify-items: space-around;
  }
  ._1nzuaz79n {
    justify-items: space-between;
  }
  ._1nzuaz79t {
    justify-self: flex-start;
  }
  ._1nzuaz79z {
    justify-self: center;
  }
  ._1nzuaz7a5 {
    justify-self: start;
  }
  ._1nzuaz7ab {
    justify-self: flex-end;
  }
  ._1nzuaz7ah {
    justify-self: stretch;
  }
  ._1nzuaz7an {
    inset: 0px;
  }
  ._1nzuaz7at {
    height: var(--space-0px);
  }
  ._1nzuaz7az {
    height: var(--space-1rem);
  }
  ._1nzuaz7b5 {
    height: var(--space-1px);
  }
  ._1nzuaz7bb {
    height: var(--space-2px);
  }
  ._1nzuaz7bh {
    height: var(--space-6px);
  }
  ._1nzuaz7bn {
    height: var(--space-4px);
  }
  ._1nzuaz7bt {
    height: var(--space-8px);
  }
  ._1nzuaz7bz {
    height: var(--space-12px);
  }
  ._1nzuaz7c5 {
    height: var(--space-14px);
  }
  ._1nzuaz7cb {
    height: var(--space-16px);
  }
  ._1nzuaz7ch {
    height: var(--space-20px);
  }
  ._1nzuaz7cn {
    height: var(--space-24px);
  }
  ._1nzuaz7ct {
    height: var(--space-32px);
  }
  ._1nzuaz7cz {
    height: var(--space-40px);
  }
  ._1nzuaz7d5 {
    height: var(--space-48px);
  }
  ._1nzuaz7db {
    height: var(--space-56px);
  }
  ._1nzuaz7dh {
    height: var(--space-64px);
  }
  ._1nzuaz7dn {
    height: 100%;
  }
  ._1nzuaz7dt {
    height: auto;
  }
  ._1nzuaz7dz {
    height: -moz-fit-content;
    height: fit-content;
  }
  ._1nzuaz7e5 {
    height: 420px;
  }
  ._1nzuaz7eb {
    height: 576px;
  }
  ._1nzuaz7eh {
    height: 768px;
  }
  ._1nzuaz7en {
    height: 968px;
  }
  ._1nzuaz7et {
    height: 1024px;
  }
  ._1nzuaz7ez {
    height: 100vh;
  }
  ._1nzuaz7f5 {
    margin-bottom: var(--space-0px);
  }
  ._1nzuaz7fb {
    margin-bottom: var(--space-1rem);
  }
  ._1nzuaz7fh {
    margin-bottom: var(--space-1px);
  }
  ._1nzuaz7fn {
    margin-bottom: var(--space-2px);
  }
  ._1nzuaz7ft {
    margin-bottom: var(--space-6px);
  }
  ._1nzuaz7fz {
    margin-bottom: var(--space-4px);
  }
  ._1nzuaz7g5 {
    margin-bottom: var(--space-8px);
  }
  ._1nzuaz7gb {
    margin-bottom: var(--space-12px);
  }
  ._1nzuaz7gh {
    margin-bottom: var(--space-14px);
  }
  ._1nzuaz7gn {
    margin-bottom: var(--space-16px);
  }
  ._1nzuaz7gt {
    margin-bottom: var(--space-20px);
  }
  ._1nzuaz7gz {
    margin-bottom: var(--space-24px);
  }
  ._1nzuaz7h5 {
    margin-bottom: var(--space-32px);
  }
  ._1nzuaz7hb {
    margin-bottom: var(--space-40px);
  }
  ._1nzuaz7hh {
    margin-bottom: var(--space-48px);
  }
  ._1nzuaz7hn {
    margin-bottom: var(--space-56px);
  }
  ._1nzuaz7ht {
    margin-bottom: var(--space-64px);
  }
  ._1nzuaz7hz {
    margin-bottom: auto;
  }
  ._1nzuaz7i5 {
    margin-left: var(--space-0px);
  }
  ._1nzuaz7ib {
    margin-left: var(--space-1rem);
  }
  ._1nzuaz7ih {
    margin-left: var(--space-1px);
  }
  ._1nzuaz7in {
    margin-left: var(--space-2px);
  }
  ._1nzuaz7it {
    margin-left: var(--space-6px);
  }
  ._1nzuaz7iz {
    margin-left: var(--space-4px);
  }
  ._1nzuaz7j5 {
    margin-left: var(--space-8px);
  }
  ._1nzuaz7jb {
    margin-left: var(--space-12px);
  }
  ._1nzuaz7jh {
    margin-left: var(--space-14px);
  }
  ._1nzuaz7jn {
    margin-left: var(--space-16px);
  }
  ._1nzuaz7jt {
    margin-left: var(--space-20px);
  }
  ._1nzuaz7jz {
    margin-left: var(--space-24px);
  }
  ._1nzuaz7k5 {
    margin-left: var(--space-32px);
  }
  ._1nzuaz7kb {
    margin-left: var(--space-40px);
  }
  ._1nzuaz7kh {
    margin-left: var(--space-48px);
  }
  ._1nzuaz7kn {
    margin-left: var(--space-56px);
  }
  ._1nzuaz7kt {
    margin-left: var(--space-64px);
  }
  ._1nzuaz7kz {
    margin-left: auto;
  }
  ._1nzuaz7l5 {
    margin-right: var(--space-0px);
  }
  ._1nzuaz7lb {
    margin-right: var(--space-1rem);
  }
  ._1nzuaz7lh {
    margin-right: var(--space-1px);
  }
  ._1nzuaz7ln {
    margin-right: var(--space-2px);
  }
  ._1nzuaz7lt {
    margin-right: var(--space-6px);
  }
  ._1nzuaz7lz {
    margin-right: var(--space-4px);
  }
  ._1nzuaz7m5 {
    margin-right: var(--space-8px);
  }
  ._1nzuaz7mb {
    margin-right: var(--space-12px);
  }
  ._1nzuaz7mh {
    margin-right: var(--space-14px);
  }
  ._1nzuaz7mn {
    margin-right: var(--space-16px);
  }
  ._1nzuaz7mt {
    margin-right: var(--space-20px);
  }
  ._1nzuaz7mz {
    margin-right: var(--space-24px);
  }
  ._1nzuaz7n5 {
    margin-right: var(--space-32px);
  }
  ._1nzuaz7nb {
    margin-right: var(--space-40px);
  }
  ._1nzuaz7nh {
    margin-right: var(--space-48px);
  }
  ._1nzuaz7nn {
    margin-right: var(--space-56px);
  }
  ._1nzuaz7nt {
    margin-right: var(--space-64px);
  }
  ._1nzuaz7nz {
    margin-right: auto;
  }
  ._1nzuaz7o5 {
    margin-top: var(--space-0px);
  }
  ._1nzuaz7ob {
    margin-top: var(--space-1rem);
  }
  ._1nzuaz7oh {
    margin-top: var(--space-1px);
  }
  ._1nzuaz7on {
    margin-top: var(--space-2px);
  }
  ._1nzuaz7ot {
    margin-top: var(--space-6px);
  }
  ._1nzuaz7oz {
    margin-top: var(--space-4px);
  }
  ._1nzuaz7p5 {
    margin-top: var(--space-8px);
  }
  ._1nzuaz7pb {
    margin-top: var(--space-12px);
  }
  ._1nzuaz7ph {
    margin-top: var(--space-14px);
  }
  ._1nzuaz7pn {
    margin-top: var(--space-16px);
  }
  ._1nzuaz7pt {
    margin-top: var(--space-20px);
  }
  ._1nzuaz7pz {
    margin-top: var(--space-24px);
  }
  ._1nzuaz7q5 {
    margin-top: var(--space-32px);
  }
  ._1nzuaz7qb {
    margin-top: var(--space-40px);
  }
  ._1nzuaz7qh {
    margin-top: var(--space-48px);
  }
  ._1nzuaz7qn {
    margin-top: var(--space-56px);
  }
  ._1nzuaz7qt {
    margin-top: var(--space-64px);
  }
  ._1nzuaz7qz {
    margin-top: auto;
  }
  ._1nzuaz7r5 {
    margin: var(--space-0px);
  }
  ._1nzuaz7rb {
    margin: var(--space-1rem);
  }
  ._1nzuaz7rh {
    margin: var(--space-1px);
  }
  ._1nzuaz7rn {
    margin: var(--space-2px);
  }
  ._1nzuaz7rt {
    margin: var(--space-6px);
  }
  ._1nzuaz7rz {
    margin: var(--space-4px);
  }
  ._1nzuaz7s5 {
    margin: var(--space-8px);
  }
  ._1nzuaz7sb {
    margin: var(--space-12px);
  }
  ._1nzuaz7sh {
    margin: var(--space-14px);
  }
  ._1nzuaz7sn {
    margin: var(--space-16px);
  }
  ._1nzuaz7st {
    margin: var(--space-20px);
  }
  ._1nzuaz7sz {
    margin: var(--space-24px);
  }
  ._1nzuaz7t5 {
    margin: var(--space-32px);
  }
  ._1nzuaz7tb {
    margin: var(--space-40px);
  }
  ._1nzuaz7th {
    margin: var(--space-48px);
  }
  ._1nzuaz7tn {
    margin: var(--space-56px);
  }
  ._1nzuaz7tt {
    margin: var(--space-64px);
  }
  ._1nzuaz7tz {
    margin: auto;
  }
  ._1nzuaz7u5 {
    padding: var(--space-0px);
  }
  ._1nzuaz7ub {
    padding: var(--space-1rem);
  }
  ._1nzuaz7uh {
    padding: var(--space-1px);
  }
  ._1nzuaz7un {
    padding: var(--space-2px);
  }
  ._1nzuaz7ut {
    padding: var(--space-6px);
  }
  ._1nzuaz7uz {
    padding: var(--space-4px);
  }
  ._1nzuaz7v5 {
    padding: var(--space-8px);
  }
  ._1nzuaz7vb {
    padding: var(--space-12px);
  }
  ._1nzuaz7vh {
    padding: var(--space-14px);
  }
  ._1nzuaz7vn {
    padding: var(--space-16px);
  }
  ._1nzuaz7vt {
    padding: var(--space-20px);
  }
  ._1nzuaz7vz {
    padding: var(--space-24px);
  }
  ._1nzuaz7w5 {
    padding: var(--space-32px);
  }
  ._1nzuaz7wb {
    padding: var(--space-40px);
  }
  ._1nzuaz7wh {
    padding: var(--space-48px);
  }
  ._1nzuaz7wn {
    padding: var(--space-56px);
  }
  ._1nzuaz7wt {
    padding: var(--space-64px);
  }
  ._1nzuaz7wz {
    max-height: var(--space-0px);
  }
  ._1nzuaz7x5 {
    max-height: var(--space-1rem);
  }
  ._1nzuaz7xb {
    max-height: var(--space-1px);
  }
  ._1nzuaz7xh {
    max-height: var(--space-2px);
  }
  ._1nzuaz7xn {
    max-height: var(--space-6px);
  }
  ._1nzuaz7xt {
    max-height: var(--space-4px);
  }
  ._1nzuaz7xz {
    max-height: var(--space-8px);
  }
  ._1nzuaz7y5 {
    max-height: var(--space-12px);
  }
  ._1nzuaz7yb {
    max-height: var(--space-14px);
  }
  ._1nzuaz7yh {
    max-height: var(--space-16px);
  }
  ._1nzuaz7yn {
    max-height: var(--space-20px);
  }
  ._1nzuaz7yt {
    max-height: var(--space-24px);
  }
  ._1nzuaz7yz {
    max-height: var(--space-32px);
  }
  ._1nzuaz7z5 {
    max-height: var(--space-40px);
  }
  ._1nzuaz7zb {
    max-height: var(--space-48px);
  }
  ._1nzuaz7zh {
    max-height: var(--space-56px);
  }
  ._1nzuaz7zn {
    max-height: var(--space-64px);
  }
  ._1nzuaz7zt {
    max-width: var(--space-0px);
  }
  ._1nzuaz7zz {
    max-width: var(--space-1rem);
  }
  ._1nzuaz7105 {
    max-width: var(--space-1px);
  }
  ._1nzuaz710b {
    max-width: var(--space-2px);
  }
  ._1nzuaz710h {
    max-width: var(--space-6px);
  }
  ._1nzuaz710n {
    max-width: var(--space-4px);
  }
  ._1nzuaz710t {
    max-width: var(--space-8px);
  }
  ._1nzuaz710z {
    max-width: var(--space-12px);
  }
  ._1nzuaz7115 {
    max-width: var(--space-14px);
  }
  ._1nzuaz711b {
    max-width: var(--space-16px);
  }
  ._1nzuaz711h {
    max-width: var(--space-20px);
  }
  ._1nzuaz711n {
    max-width: var(--space-24px);
  }
  ._1nzuaz711t {
    max-width: var(--space-32px);
  }
  ._1nzuaz711z {
    max-width: var(--space-40px);
  }
  ._1nzuaz7125 {
    max-width: var(--space-48px);
  }
  ._1nzuaz712b {
    max-width: var(--space-56px);
  }
  ._1nzuaz712h {
    max-width: var(--space-64px);
  }
  ._1nzuaz712n {
    max-width: 100%;
  }
  ._1nzuaz712t {
    max-width: auto;
  }
  ._1nzuaz712z {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }
  ._1nzuaz7135 {
    max-width: 420px;
  }
  ._1nzuaz713b {
    max-width: 576px;
  }
  ._1nzuaz713h {
    max-width: 768px;
  }
  ._1nzuaz713n {
    max-width: 968px;
  }
  ._1nzuaz713t {
    max-width: 1024px;
  }
  ._1nzuaz713z {
    max-width: none;
  }
  ._1nzuaz7145 {
    min-height: var(--space-0px);
  }
  ._1nzuaz714b {
    min-height: var(--space-1rem);
  }
  ._1nzuaz714h {
    min-height: var(--space-1px);
  }
  ._1nzuaz714n {
    min-height: var(--space-2px);
  }
  ._1nzuaz714t {
    min-height: var(--space-6px);
  }
  ._1nzuaz714z {
    min-height: var(--space-4px);
  }
  ._1nzuaz7155 {
    min-height: var(--space-8px);
  }
  ._1nzuaz715b {
    min-height: var(--space-12px);
  }
  ._1nzuaz715h {
    min-height: var(--space-14px);
  }
  ._1nzuaz715n {
    min-height: var(--space-16px);
  }
  ._1nzuaz715t {
    min-height: var(--space-20px);
  }
  ._1nzuaz715z {
    min-height: var(--space-24px);
  }
  ._1nzuaz7165 {
    min-height: var(--space-32px);
  }
  ._1nzuaz716b {
    min-height: var(--space-40px);
  }
  ._1nzuaz716h {
    min-height: var(--space-48px);
  }
  ._1nzuaz716n {
    min-height: var(--space-56px);
  }
  ._1nzuaz716t {
    min-height: var(--space-64px);
  }
  ._1nzuaz716z {
    min-height: 100%;
  }
  ._1nzuaz7175 {
    min-height: auto;
  }
  ._1nzuaz717b {
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
  ._1nzuaz717h {
    min-height: 420px;
  }
  ._1nzuaz717n {
    min-height: 576px;
  }
  ._1nzuaz717t {
    min-height: 768px;
  }
  ._1nzuaz717z {
    min-height: 968px;
  }
  ._1nzuaz7185 {
    min-height: 1024px;
  }
  ._1nzuaz718b {
    min-height: 100vh;
  }
  ._1nzuaz718h {
    min-width: var(--space-0px);
  }
  ._1nzuaz718n {
    min-width: var(--space-1rem);
  }
  ._1nzuaz718t {
    min-width: var(--space-1px);
  }
  ._1nzuaz718z {
    min-width: var(--space-2px);
  }
  ._1nzuaz7195 {
    min-width: var(--space-6px);
  }
  ._1nzuaz719b {
    min-width: var(--space-4px);
  }
  ._1nzuaz719h {
    min-width: var(--space-8px);
  }
  ._1nzuaz719n {
    min-width: var(--space-12px);
  }
  ._1nzuaz719t {
    min-width: var(--space-14px);
  }
  ._1nzuaz719z {
    min-width: var(--space-16px);
  }
  ._1nzuaz71a5 {
    min-width: var(--space-20px);
  }
  ._1nzuaz71ab {
    min-width: var(--space-24px);
  }
  ._1nzuaz71ah {
    min-width: var(--space-32px);
  }
  ._1nzuaz71an {
    min-width: var(--space-40px);
  }
  ._1nzuaz71at {
    min-width: var(--space-48px);
  }
  ._1nzuaz71az {
    min-width: var(--space-56px);
  }
  ._1nzuaz71b5 {
    min-width: var(--space-64px);
  }
  ._1nzuaz71bb {
    padding-bottom: var(--space-0px);
  }
  ._1nzuaz71bh {
    padding-bottom: var(--space-1rem);
  }
  ._1nzuaz71bn {
    padding-bottom: var(--space-1px);
  }
  ._1nzuaz71bt {
    padding-bottom: var(--space-2px);
  }
  ._1nzuaz71bz {
    padding-bottom: var(--space-6px);
  }
  ._1nzuaz71c5 {
    padding-bottom: var(--space-4px);
  }
  ._1nzuaz71cb {
    padding-bottom: var(--space-8px);
  }
  ._1nzuaz71ch {
    padding-bottom: var(--space-12px);
  }
  ._1nzuaz71cn {
    padding-bottom: var(--space-14px);
  }
  ._1nzuaz71ct {
    padding-bottom: var(--space-16px);
  }
  ._1nzuaz71cz {
    padding-bottom: var(--space-20px);
  }
  ._1nzuaz71d5 {
    padding-bottom: var(--space-24px);
  }
  ._1nzuaz71db {
    padding-bottom: var(--space-32px);
  }
  ._1nzuaz71dh {
    padding-bottom: var(--space-40px);
  }
  ._1nzuaz71dn {
    padding-bottom: var(--space-48px);
  }
  ._1nzuaz71dt {
    padding-bottom: var(--space-56px);
  }
  ._1nzuaz71dz {
    padding-bottom: var(--space-64px);
  }
  ._1nzuaz71e5 {
    padding-left: var(--space-0px);
  }
  ._1nzuaz71eb {
    padding-left: var(--space-1rem);
  }
  ._1nzuaz71eh {
    padding-left: var(--space-1px);
  }
  ._1nzuaz71en {
    padding-left: var(--space-2px);
  }
  ._1nzuaz71et {
    padding-left: var(--space-6px);
  }
  ._1nzuaz71ez {
    padding-left: var(--space-4px);
  }
  ._1nzuaz71f5 {
    padding-left: var(--space-8px);
  }
  ._1nzuaz71fb {
    padding-left: var(--space-12px);
  }
  ._1nzuaz71fh {
    padding-left: var(--space-14px);
  }
  ._1nzuaz71fn {
    padding-left: var(--space-16px);
  }
  ._1nzuaz71ft {
    padding-left: var(--space-20px);
  }
  ._1nzuaz71fz {
    padding-left: var(--space-24px);
  }
  ._1nzuaz71g5 {
    padding-left: var(--space-32px);
  }
  ._1nzuaz71gb {
    padding-left: var(--space-40px);
  }
  ._1nzuaz71gh {
    padding-left: var(--space-48px);
  }
  ._1nzuaz71gn {
    padding-left: var(--space-56px);
  }
  ._1nzuaz71gt {
    padding-left: var(--space-64px);
  }
  ._1nzuaz71gz {
    padding-right: var(--space-0px);
  }
  ._1nzuaz71h5 {
    padding-right: var(--space-1rem);
  }
  ._1nzuaz71hb {
    padding-right: var(--space-1px);
  }
  ._1nzuaz71hh {
    padding-right: var(--space-2px);
  }
  ._1nzuaz71hn {
    padding-right: var(--space-6px);
  }
  ._1nzuaz71ht {
    padding-right: var(--space-4px);
  }
  ._1nzuaz71hz {
    padding-right: var(--space-8px);
  }
  ._1nzuaz71i5 {
    padding-right: var(--space-12px);
  }
  ._1nzuaz71ib {
    padding-right: var(--space-14px);
  }
  ._1nzuaz71ih {
    padding-right: var(--space-16px);
  }
  ._1nzuaz71in {
    padding-right: var(--space-20px);
  }
  ._1nzuaz71it {
    padding-right: var(--space-24px);
  }
  ._1nzuaz71iz {
    padding-right: var(--space-32px);
  }
  ._1nzuaz71j5 {
    padding-right: var(--space-40px);
  }
  ._1nzuaz71jb {
    padding-right: var(--space-48px);
  }
  ._1nzuaz71jh {
    padding-right: var(--space-56px);
  }
  ._1nzuaz71jn {
    padding-right: var(--space-64px);
  }
  ._1nzuaz71jt {
    padding-top: var(--space-0px);
  }
  ._1nzuaz71jz {
    padding-top: var(--space-1rem);
  }
  ._1nzuaz71k5 {
    padding-top: var(--space-1px);
  }
  ._1nzuaz71kb {
    padding-top: var(--space-2px);
  }
  ._1nzuaz71kh {
    padding-top: var(--space-6px);
  }
  ._1nzuaz71kn {
    padding-top: var(--space-4px);
  }
  ._1nzuaz71kt {
    padding-top: var(--space-8px);
  }
  ._1nzuaz71kz {
    padding-top: var(--space-12px);
  }
  ._1nzuaz71l5 {
    padding-top: var(--space-14px);
  }
  ._1nzuaz71lb {
    padding-top: var(--space-16px);
  }
  ._1nzuaz71lh {
    padding-top: var(--space-20px);
  }
  ._1nzuaz71ln {
    padding-top: var(--space-24px);
  }
  ._1nzuaz71lt {
    padding-top: var(--space-32px);
  }
  ._1nzuaz71lz {
    padding-top: var(--space-40px);
  }
  ._1nzuaz71m5 {
    padding-top: var(--space-48px);
  }
  ._1nzuaz71mb {
    padding-top: var(--space-56px);
  }
  ._1nzuaz71mh {
    padding-top: var(--space-64px);
  }
  ._1nzuaz71mn {
    font-size: var(--fontSizes-10px);
  }
  ._1nzuaz71mt {
    font-size: var(--fontSizes-12px);
  }
  ._1nzuaz71mz {
    font-size: var(--fontSizes-16px);
  }
  ._1nzuaz71n5 {
    font-size: var(--fontSizes-14px);
  }
  ._1nzuaz71nb {
    font-size: var(--fontSizes-20px);
  }
  ._1nzuaz71nh {
    font-size: var(--fontSizes-40px);
  }
  ._1nzuaz71nn {
    font-size: inherit;
  }
  ._1nzuaz71nt {
    flex: 1 1;
  }
  ._1nzuaz71nz {
    flex: 1 1 auto;
  }
  ._1nzuaz71o5 {
    flex: 0 1 auto;
  }
  ._1nzuaz71ob {
    flex: none;
  }
  ._1nzuaz71oh {
    box-shadow: var(--shadows-level1);
  }
  ._1nzuaz71on {
    box-shadow: var(--shadows-active);
  }
  ._1nzuaz71ot {
    box-shadow: var(--shadows-success);
  }
  ._1nzuaz71oz {
    box-shadow: var(--shadows-warning);
  }
  ._1nzuaz71p5 {
    box-shadow: var(--shadows-danger);
  }
  ._1nzuaz71pb {
    box-shadow: var(--shadows-focus);
  }
  ._1nzuaz71ph {
    box-shadow: var(--shadows-inset);
  }
  ._1nzuaz71pn {
    box-shadow: var(--shadows-tooltip);
  }
  ._1nzuaz71pt {
    width: var(--space-0px);
  }
  ._1nzuaz71pz {
    width: var(--space-1rem);
  }
  ._1nzuaz71q5 {
    width: var(--space-1px);
  }
  ._1nzuaz71qb {
    width: var(--space-2px);
  }
  ._1nzuaz71qh {
    width: var(--space-6px);
  }
  ._1nzuaz71qn {
    width: var(--space-4px);
  }
  ._1nzuaz71qt {
    width: var(--space-8px);
  }
  ._1nzuaz71qz {
    width: var(--space-12px);
  }
  ._1nzuaz71r5 {
    width: var(--space-14px);
  }
  ._1nzuaz71rb {
    width: var(--space-16px);
  }
  ._1nzuaz71rh {
    width: var(--space-20px);
  }
  ._1nzuaz71rn {
    width: var(--space-24px);
  }
  ._1nzuaz71rt {
    width: var(--space-32px);
  }
  ._1nzuaz71rz {
    width: var(--space-40px);
  }
  ._1nzuaz71s5 {
    width: var(--space-48px);
  }
  ._1nzuaz71sb {
    width: var(--space-56px);
  }
  ._1nzuaz71sh {
    width: var(--space-64px);
  }
  ._1nzuaz71sn {
    width: 100%;
  }
  ._1nzuaz71st {
    width: auto;
  }
  ._1nzuaz71sz {
    width: -moz-fit-content;
    width: fit-content;
  }
  ._1nzuaz71t5 {
    width: 420px;
  }
  ._1nzuaz71tb {
    width: 576px;
  }
  ._1nzuaz71th {
    width: 768px;
  }
  ._1nzuaz71tn {
    width: 968px;
  }
  ._1nzuaz71tt {
    width: 1024px;
  }
  ._1nzuaz71tz {
    z-index: 0;
  }
  ._1nzuaz71u5 {
    z-index: 1;
  }
  ._1nzuaz71ub {
    z-index: 10;
  }
  ._1nzuaz71uh {
    z-index: 20;
  }
  ._1nzuaz71un {
    z-index: 30;
  }
  ._1nzuaz71ut {
    z-index: 40;
  }
  ._1nzuaz71uz {
    z-index: 50;
  }
  ._1nzuaz71v5 {
    z-index: 75;
  }
  ._1nzuaz71vb {
    z-index: 99;
  }
  ._1nzuaz71vh {
    z-index: 100;
  }
  ._1nzuaz71vn {
    z-index: 9;
  }
  ._1nzuaz71vt {
    z-index: 10;
  }
  ._1nzuaz71vz {
    z-index: 100;
  }
  ._1nzuaz71w5 {
    z-index: auto;
  }
  ._1nzuaz71wb {
    border-top: 1px solid var(--colors-cardBorder);
  }
  ._1nzuaz71wh {
    border-radius: var(--radii-0);
  }
  ._1nzuaz71wn {
    border-radius: var(--radii-8px);
  }
  ._1nzuaz71wt {
    border-radius: var(--radii-12px);
  }
  ._1nzuaz71wz {
    border-radius: var(--radii-20px);
  }
  ._1nzuaz71x5 {
    border-radius: var(--radii-32px);
  }
  ._1nzuaz71xb {
    border-radius: var(--radii-small);
  }
  ._1nzuaz71xh {
    border-radius: var(--radii-default);
  }
  ._1nzuaz71xn {
    border-radius: var(--radii-card);
  }
  ._1nzuaz71xt {
    border-radius: var(--radii-circle);
  }
  ._1nzuaz71xz {
    border-top-left-radius: var(--radii-0);
  }
  ._1nzuaz71y5 {
    border-top-left-radius: var(--radii-8px);
  }
  ._1nzuaz71yb {
    border-top-left-radius: var(--radii-12px);
  }
  ._1nzuaz71yh {
    border-top-left-radius: var(--radii-20px);
  }
  ._1nzuaz71yn {
    border-top-left-radius: var(--radii-32px);
  }
  ._1nzuaz71yt {
    border-top-left-radius: var(--radii-small);
  }
  ._1nzuaz71yz {
    border-top-left-radius: var(--radii-default);
  }
  ._1nzuaz71z5 {
    border-top-left-radius: var(--radii-card);
  }
  ._1nzuaz71zb {
    border-top-left-radius: var(--radii-circle);
  }
  ._1nzuaz71zh {
    border-bottom-right-radius: var(--radii-0);
  }
  ._1nzuaz71zn {
    border-bottom-right-radius: var(--radii-8px);
  }
  ._1nzuaz71zt {
    border-bottom-right-radius: var(--radii-12px);
  }
  ._1nzuaz71zz {
    border-bottom-right-radius: var(--radii-20px);
  }
  ._1nzuaz7205 {
    border-bottom-right-radius: var(--radii-32px);
  }
  ._1nzuaz720b {
    border-bottom-right-radius: var(--radii-small);
  }
  ._1nzuaz720h {
    border-bottom-right-radius: var(--radii-default);
  }
  ._1nzuaz720n {
    border-bottom-right-radius: var(--radii-card);
  }
  ._1nzuaz720t {
    border-bottom-right-radius: var(--radii-circle);
  }
  ._1nzuaz720z {
    border-top-right-radius: var(--radii-0);
  }
  ._1nzuaz7215 {
    border-top-right-radius: var(--radii-8px);
  }
  ._1nzuaz721b {
    border-top-right-radius: var(--radii-12px);
  }
  ._1nzuaz721h {
    border-top-right-radius: var(--radii-20px);
  }
  ._1nzuaz721n {
    border-top-right-radius: var(--radii-32px);
  }
  ._1nzuaz721t {
    border-top-right-radius: var(--radii-small);
  }
  ._1nzuaz721z {
    border-top-right-radius: var(--radii-default);
  }
  ._1nzuaz7225 {
    border-top-right-radius: var(--radii-card);
  }
  ._1nzuaz722b {
    border-top-right-radius: var(--radii-circle);
  }
  ._1nzuaz722h {
    border-bottom-left-radius: var(--radii-0);
  }
  ._1nzuaz722n {
    border-bottom-left-radius: var(--radii-8px);
  }
  ._1nzuaz722t {
    border-bottom-left-radius: var(--radii-12px);
  }
  ._1nzuaz722z {
    border-bottom-left-radius: var(--radii-20px);
  }
  ._1nzuaz7235 {
    border-bottom-left-radius: var(--radii-32px);
  }
  ._1nzuaz723b {
    border-bottom-left-radius: var(--radii-small);
  }
  ._1nzuaz723h {
    border-bottom-left-radius: var(--radii-default);
  }
  ._1nzuaz723n {
    border-bottom-left-radius: var(--radii-card);
  }
  ._1nzuaz723t {
    border-bottom-left-radius: var(--radii-circle);
  }
  ._1nzuaz723z {
    gap: var(--space-0px);
  }
  ._1nzuaz7245 {
    gap: var(--space-1rem);
  }
  ._1nzuaz724b {
    gap: var(--space-1px);
  }
  ._1nzuaz724h {
    gap: var(--space-2px);
  }
  ._1nzuaz724n {
    gap: var(--space-6px);
  }
  ._1nzuaz724t {
    gap: var(--space-4px);
  }
  ._1nzuaz724z {
    gap: var(--space-8px);
  }
  ._1nzuaz7255 {
    gap: var(--space-12px);
  }
  ._1nzuaz725b {
    gap: var(--space-14px);
  }
  ._1nzuaz725h {
    gap: var(--space-16px);
  }
  ._1nzuaz725n {
    gap: var(--space-20px);
  }
  ._1nzuaz725t {
    gap: var(--space-24px);
  }
  ._1nzuaz725z {
    gap: var(--space-32px);
  }
  ._1nzuaz7265 {
    gap: var(--space-40px);
  }
  ._1nzuaz726b {
    gap: var(--space-48px);
  }
  ._1nzuaz726h {
    gap: var(--space-56px);
  }
  ._1nzuaz726n {
    gap: var(--space-64px);
  }
  ._1nzuaz726t {
    gap: var(--space-8px);
  }
  ._1nzuaz726z {
    gap: var(--space-12px);
  }
  ._1nzuaz7275 {
    gap: var(--space-24px);
  }
  ._1nzuaz727b {
    row-gap: var(--space-0px);
  }
  ._1nzuaz727h {
    row-gap: var(--space-1rem);
  }
  ._1nzuaz727n {
    row-gap: var(--space-1px);
  }
  ._1nzuaz727t {
    row-gap: var(--space-2px);
  }
  ._1nzuaz727z {
    row-gap: var(--space-6px);
  }
  ._1nzuaz7285 {
    row-gap: var(--space-4px);
  }
  ._1nzuaz728b {
    row-gap: var(--space-8px);
  }
  ._1nzuaz728h {
    row-gap: var(--space-12px);
  }
  ._1nzuaz728n {
    row-gap: var(--space-14px);
  }
  ._1nzuaz728t {
    row-gap: var(--space-16px);
  }
  ._1nzuaz728z {
    row-gap: var(--space-20px);
  }
  ._1nzuaz7295 {
    row-gap: var(--space-24px);
  }
  ._1nzuaz729b {
    row-gap: var(--space-32px);
  }
  ._1nzuaz729h {
    row-gap: var(--space-40px);
  }
  ._1nzuaz729n {
    row-gap: var(--space-48px);
  }
  ._1nzuaz729t {
    row-gap: var(--space-56px);
  }
  ._1nzuaz729z {
    row-gap: var(--space-64px);
  }
  ._1nzuaz72a5 {
    row-gap: var(--space-8px);
  }
  ._1nzuaz72ab {
    row-gap: var(--space-12px);
  }
  ._1nzuaz72ah {
    row-gap: var(--space-24px);
  }
  ._1nzuaz72an {
    column-gap: var(--space-0px);
  }
  ._1nzuaz72at {
    column-gap: var(--space-1rem);
  }
  ._1nzuaz72az {
    column-gap: var(--space-1px);
  }
  ._1nzuaz72b5 {
    column-gap: var(--space-2px);
  }
  ._1nzuaz72bb {
    column-gap: var(--space-6px);
  }
  ._1nzuaz72bh {
    column-gap: var(--space-4px);
  }
  ._1nzuaz72bn {
    column-gap: var(--space-8px);
  }
  ._1nzuaz72bt {
    column-gap: var(--space-12px);
  }
  ._1nzuaz72bz {
    column-gap: var(--space-14px);
  }
  ._1nzuaz72c5 {
    column-gap: var(--space-16px);
  }
  ._1nzuaz72cb {
    column-gap: var(--space-20px);
  }
  ._1nzuaz72ch {
    column-gap: var(--space-24px);
  }
  ._1nzuaz72cn {
    column-gap: var(--space-32px);
  }
  ._1nzuaz72ct {
    column-gap: var(--space-40px);
  }
  ._1nzuaz72cz {
    column-gap: var(--space-48px);
  }
  ._1nzuaz72d5 {
    column-gap: var(--space-56px);
  }
  ._1nzuaz72db {
    column-gap: var(--space-64px);
  }
  ._1nzuaz72dh {
    column-gap: var(--space-8px);
  }
  ._1nzuaz72dn {
    column-gap: var(--space-12px);
  }
  ._1nzuaz72dt {
    column-gap: var(--space-24px);
  }
  ._1nzuaz72dz {
    grid-auto-rows: auto;
  }
  ._1nzuaz72e5 {
    opacity: 0;
  }
  ._1nzuaz72eb {
    opacity: 1;
  }
  ._1nzuaz72eh {
    opacity: 0.5;
  }
  ._1nzuaz72en {
    opacity: 0.6;
  }
  ._1nzuaz72et {
    line-height: var(--space-16px);
  }
  ._1nzuaz72ez {
    white50: var(--colors-light-white50);
    white100: var(--colors-light-white100);
    white200: var(--colors-light-white200);
    white300: var(--colors-light-white300);
    white500: var(--colors-light-white500);
    white800: var(--colors-light-white800);
    white: var(--colors-light-white);
    black: var(--colors-light-black);
    failure: var(--colors-light-failure);
    failure33: var(--colors-light-failure33);
    red100: var(--colors-light-red100);
    primary: var(--colors-light-primary);
    primary0f: var(--colors-light-primary0f);
    primary3-d: var(--colors-light-primary3D);
    primary-bright: var(--colors-light-primaryBright);
    primary-dark: var(--colors-light-primaryDark);
    success: var(--colors-light-success);
    success19: var(--colors-light-success19);
    warning: var(--colors-light-warning);
    warning2-d: var(--colors-light-warning2D);
    warning33: var(--colors-light-warning33);
    yellow100: var(--colors-light-yellow100);
    gray50: var(--colors-light-gray50);
    gray100: var(--colors-light-gray100);
    gray200: var(--colors-light-gray200);
    gray300: var(--colors-light-gray300);
    gray390: var(--colors-light-gray390);
    gray400: var(--colors-light-gray400);
    gray500: var(--colors-light-gray500);
    gray700: var(--colors-light-gray700);
    gray800: var(--colors-light-gray800);
    gray850: var(--colors-light-gray850);
    gray900: var(--colors-light-gray900);
    gray1000: var(--colors-light-gray1000);
    tint-gray80: var(--colors-light-tintGray80);
    gradient-primary: var(--colors-light-gradientPrimary);
    gradient-primary10: var(--colors-light-gradientPrimary10);
    gradient-primary20: var(--colors-light-gradientPrimary20);
    gradient-primary-radial: var(--colors-light-gradientPrimaryRadial);
    orange100: var(--colors-light-orange100);
    orange: var(--colors-light-orange);
    purple: var(--colors-light-purple);
    purple60: var(--colors-light-purple60);
    orange-dark: var(--colors-light-orangeDark);
    binance: var(--colors-light-binance);
    overlay: var(--colors-light-overlay);
    gold: var(--colors-light-gold);
    silver: var(--colors-light-silver);
    bronze: var(--colors-light-bronze);
    secondary: var(--colors-light-secondary);
    secondary80: var(--colors-light-secondary80);
    background: var(--colors-light-background);
    background-disabled: var(--colors-light-backgroundDisabled);
    background-alt: var(--colors-light-backgroundAlt);
    background-alt2: var(--colors-light-backgroundAlt2);
    card-border: var(--colors-light-cardBorder);
    contrast: var(--colors-light-contrast);
    dropdown: var(--colors-light-dropdown);
    dropdown-deep: var(--colors-light-dropdownDeep);
    inverted-contrast: var(--colors-light-invertedContrast);
    input: var(--colors-light-input);
    input-secondary: var(--colors-light-inputSecondary);
    tertiary: var(--colors-light-tertiary);
    text: var(--colors-light-text);
    text99: var(--colors-light-text99);
    text-disabled: var(--colors-light-textDisabled);
    text-subtle: var(--colors-light-textSubtle);
    disabled: var(--colors-light-disabled);
    gradient-bubblegum: var(--colors-light-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-light-gradientInverseBubblegum);
    gradient-card-header: var(--colors-light-gradientCardHeader);
    gradient-blue: var(--colors-light-gradientBlue);
    gradient-violet: var(--colors-light-gradientViolet);
    gradient-violet-alt: var(--colors-light-gradientVioletAlt);
    gradient-gold: var(--colors-light-gradientGold);
    gradient-bold: var(--colors-light-gradientBold);
  }
  ._1nzuaz72f5 {
    white50: var(--colors-dark-white50);
    white100: var(--colors-dark-white100);
    white200: var(--colors-dark-white200);
    white300: var(--colors-dark-white300);
    white500: var(--colors-dark-white500);
    white800: var(--colors-dark-white800);
    white: var(--colors-dark-white);
    black: var(--colors-dark-black);
    failure: var(--colors-dark-failure);
    failure33: var(--colors-dark-failure33);
    red100: var(--colors-dark-red100);
    primary: var(--colors-dark-primary);
    primary0f: var(--colors-dark-primary0f);
    primary3-d: var(--colors-dark-primary3D);
    primary-bright: var(--colors-dark-primaryBright);
    primary-dark: var(--colors-dark-primaryDark);
    success: var(--colors-dark-success);
    success19: var(--colors-dark-success19);
    warning: var(--colors-dark-warning);
    warning2-d: var(--colors-dark-warning2D);
    warning33: var(--colors-dark-warning33);
    yellow100: var(--colors-dark-yellow100);
    gray50: var(--colors-dark-gray50);
    gray100: var(--colors-dark-gray100);
    gray200: var(--colors-dark-gray200);
    gray300: var(--colors-dark-gray300);
    gray390: var(--colors-dark-gray390);
    gray400: var(--colors-dark-gray400);
    gray500: var(--colors-dark-gray500);
    gray700: var(--colors-dark-gray700);
    gray800: var(--colors-dark-gray800);
    gray850: var(--colors-dark-gray850);
    gray900: var(--colors-dark-gray900);
    gray1000: var(--colors-dark-gray1000);
    tint-gray80: var(--colors-dark-tintGray80);
    gradient-primary: var(--colors-dark-gradientPrimary);
    gradient-primary10: var(--colors-dark-gradientPrimary10);
    gradient-primary20: var(--colors-dark-gradientPrimary20);
    gradient-primary-radial: var(--colors-dark-gradientPrimaryRadial);
    orange100: var(--colors-dark-orange100);
    orange: var(--colors-dark-orange);
    purple: var(--colors-dark-purple);
    purple60: var(--colors-dark-purple60);
    orange-dark: var(--colors-dark-orangeDark);
    binance: var(--colors-dark-binance);
    overlay: var(--colors-dark-overlay);
    gold: var(--colors-dark-gold);
    silver: var(--colors-dark-silver);
    bronze: var(--colors-dark-bronze);
    secondary: var(--colors-dark-secondary);
    secondary80: var(--colors-dark-secondary80);
    background: var(--colors-dark-background);
    background-disabled: var(--colors-dark-backgroundDisabled);
    background-alt: var(--colors-dark-backgroundAlt);
    background-alt2: var(--colors-dark-backgroundAlt2);
    card-border: var(--colors-dark-cardBorder);
    contrast: var(--colors-dark-contrast);
    dropdown: var(--colors-dark-dropdown);
    dropdown-deep: var(--colors-dark-dropdownDeep);
    inverted-contrast: var(--colors-dark-invertedContrast);
    input: var(--colors-dark-input);
    input-secondary: var(--colors-dark-inputSecondary);
    tertiary: var(--colors-dark-tertiary);
    text: var(--colors-dark-text);
    text99: var(--colors-dark-text99);
    text-disabled: var(--colors-dark-textDisabled);
    text-subtle: var(--colors-dark-textSubtle);
    disabled: var(--colors-dark-disabled);
    gradient-bubblegum: var(--colors-dark-gradientBubblegum);
    gradient-inverse-bubblegum: var(--colors-dark-gradientInverseBubblegum);
    gradient-card-header: var(--colors-dark-gradientCardHeader);
    gradient-blue: var(--colors-dark-gradientBlue);
    gradient-violet: var(--colors-dark-gradientViolet);
    gradient-violet-alt: var(--colors-dark-gradientVioletAlt);
    gradient-gold: var(--colors-dark-gradientGold);
    gradient-bold: var(--colors-dark-gradientBold);
  }
  ._1nzuaz72fb {
    border-bottom-color: var(--colors-white50);
  }
  ._1nzuaz72fh {
    border-bottom-color: var(--colors-white100);
  }
  ._1nzuaz72fn {
    border-bottom-color: var(--colors-white200);
  }
  ._1nzuaz72ft {
    border-bottom-color: var(--colors-white300);
  }
  ._1nzuaz72fz {
    border-bottom-color: var(--colors-white500);
  }
  ._1nzuaz72g5 {
    border-bottom-color: var(--colors-white800);
  }
  ._1nzuaz72gb {
    border-bottom-color: var(--colors-white);
  }
  ._1nzuaz72gh {
    border-bottom-color: var(--colors-black);
  }
  ._1nzuaz72gn {
    border-bottom-color: var(--colors-failure);
  }
  ._1nzuaz72gt {
    border-bottom-color: var(--colors-failure33);
  }
  ._1nzuaz72gz {
    border-bottom-color: var(--colors-red100);
  }
  ._1nzuaz72h5 {
    border-bottom-color: var(--colors-primary);
  }
  ._1nzuaz72hb {
    border-bottom-color: var(--colors-primary0f);
  }
  ._1nzuaz72hh {
    border-bottom-color: var(--colors-primary3D);
  }
  ._1nzuaz72hn {
    border-bottom-color: var(--colors-primaryBright);
  }
  ._1nzuaz72ht {
    border-bottom-color: var(--colors-primaryDark);
  }
  ._1nzuaz72hz {
    border-bottom-color: var(--colors-success);
  }
  ._1nzuaz72i5 {
    border-bottom-color: var(--colors-success19);
  }
  ._1nzuaz72ib {
    border-bottom-color: var(--colors-warning);
  }
  ._1nzuaz72ih {
    border-bottom-color: var(--colors-warning2D);
  }
  ._1nzuaz72in {
    border-bottom-color: var(--colors-warning33);
  }
  ._1nzuaz72it {
    border-bottom-color: var(--colors-yellow100);
  }
  ._1nzuaz72iz {
    border-bottom-color: var(--colors-gray50);
  }
  ._1nzuaz72j5 {
    border-bottom-color: var(--colors-gray100);
  }
  ._1nzuaz72jb {
    border-bottom-color: var(--colors-gray200);
  }
  ._1nzuaz72jh {
    border-bottom-color: var(--colors-gray300);
  }
  ._1nzuaz72jn {
    border-bottom-color: var(--colors-gray390);
  }
  ._1nzuaz72jt {
    border-bottom-color: var(--colors-gray400);
  }
  ._1nzuaz72jz {
    border-bottom-color: var(--colors-gray500);
  }
  ._1nzuaz72k5 {
    border-bottom-color: var(--colors-gray700);
  }
  ._1nzuaz72kb {
    border-bottom-color: var(--colors-gray800);
  }
  ._1nzuaz72kh {
    border-bottom-color: var(--colors-gray850);
  }
  ._1nzuaz72kn {
    border-bottom-color: var(--colors-gray900);
  }
  ._1nzuaz72kt {
    border-bottom-color: var(--colors-gray1000);
  }
  ._1nzuaz72kz {
    border-bottom-color: var(--colors-tintGray80);
  }
  ._1nzuaz72l5 {
    border-bottom-color: var(--colors-gradientPrimary);
  }
  ._1nzuaz72lb {
    border-bottom-color: var(--colors-gradientPrimary10);
  }
  ._1nzuaz72lh {
    border-bottom-color: var(--colors-gradientPrimary20);
  }
  ._1nzuaz72ln {
    border-bottom-color: var(--colors-gradientPrimaryRadial);
  }
  ._1nzuaz72lt {
    border-bottom-color: var(--colors-orange100);
  }
  ._1nzuaz72lz {
    border-bottom-color: var(--colors-orange);
  }
  ._1nzuaz72m5 {
    border-bottom-color: var(--colors-purple);
  }
  ._1nzuaz72mb {
    border-bottom-color: var(--colors-purple60);
  }
  ._1nzuaz72mh {
    border-bottom-color: var(--colors-orangeDark);
  }
  ._1nzuaz72mn {
    border-bottom-color: var(--colors-binance);
  }
  ._1nzuaz72mt {
    border-bottom-color: var(--colors-overlay);
  }
  ._1nzuaz72mz {
    border-bottom-color: var(--colors-gold);
  }
  ._1nzuaz72n5 {
    border-bottom-color: var(--colors-silver);
  }
  ._1nzuaz72nb {
    border-bottom-color: var(--colors-bronze);
  }
  ._1nzuaz72nh {
    border-bottom-color: var(--colors-secondary);
  }
  ._1nzuaz72nn {
    border-bottom-color: var(--colors-secondary80);
  }
  ._1nzuaz72nt {
    border-bottom-color: var(--colors-background);
  }
  ._1nzuaz72nz {
    border-bottom-color: var(--colors-backgroundDisabled);
  }
  ._1nzuaz72o5 {
    border-bottom-color: var(--colors-backgroundAlt);
  }
  ._1nzuaz72ob {
    border-bottom-color: var(--colors-backgroundAlt2);
  }
  ._1nzuaz72oh {
    border-bottom-color: var(--colors-cardBorder);
  }
  ._1nzuaz72on {
    border-bottom-color: var(--colors-contrast);
  }
  ._1nzuaz72ot {
    border-bottom-color: var(--colors-dropdown);
  }
  ._1nzuaz72oz {
    border-bottom-color: var(--colors-dropdownDeep);
  }
  ._1nzuaz72p5 {
    border-bottom-color: var(--colors-invertedContrast);
  }
  ._1nzuaz72pb {
    border-bottom-color: var(--colors-input);
  }
  ._1nzuaz72ph {
    border-bottom-color: var(--colors-inputSecondary);
  }
  ._1nzuaz72pn {
    border-bottom-color: var(--colors-tertiary);
  }
  ._1nzuaz72pt {
    border-bottom-color: var(--colors-text);
  }
  ._1nzuaz72pz {
    border-bottom-color: var(--colors-text99);
  }
  ._1nzuaz72q5 {
    border-bottom-color: var(--colors-textDisabled);
  }
  ._1nzuaz72qb {
    border-bottom-color: var(--colors-textSubtle);
  }
  ._1nzuaz72qh {
    border-bottom-color: var(--colors-disabled);
  }
  ._1nzuaz72qn {
    border-bottom-color: var(--colors-gradientBubblegum);
  }
  ._1nzuaz72qt {
    border-bottom-color: var(--colors-gradientInverseBubblegum);
  }
  ._1nzuaz72qz {
    border-bottom-color: var(--colors-gradientCardHeader);
  }
  ._1nzuaz72r5 {
    border-bottom-color: var(--colors-gradientBlue);
  }
  ._1nzuaz72rb {
    border-bottom-color: var(--colors-gradientViolet);
  }
  ._1nzuaz72rh {
    border-bottom-color: var(--colors-gradientVioletAlt);
  }
  ._1nzuaz72rn {
    border-bottom-color: var(--colors-gradientGold);
  }
  ._1nzuaz72rt {
    border-bottom-color: var(--colors-gradientBold);
  }
  ._1nzuaz72rz {
    border: 1px solid var(--colors-cardBorder);
  }
  ._1nzuaz72s5 {
    border-bottom: 1px solid var(--colors-cardBorder);
  }
}
@keyframes _6h9tv90 {
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}
._6h9tv92 {
  animation: _6h9tv90 3s ease infinite;
  background-size: 400% 400%;
}
._6h9tv94 {
  display: flex;
}
._6h9tv95 {
  width: 100%;
  margin-bottom: 0;
}
._6h9tv96 {
  max-width: 100%;
}
._6h9tv97 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8px;
}
._6h9tv98 {
  width: 56px;
  height: 56px;
  border-radius: 16px;
}
@media screen and (min-width: 768px) {
  ._6h9tv95 {
    height: 490px;
  }
}
@media screen and (min-width: 576px) {
  ._6h9tv96 {
    max-width: 360px;
  }
}
@media screen and (min-width: 968px) {
  ._6h9tv95 {
    width: 792px;
  }
  ._6h9tv96 {
    max-width: 408px;
  }
}
@font-face {
font-family: '__generalSans_db677b';
src: url(/_next/static/media/d9532b38a05b0008-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__generalSans_db677b';
src: url(/_next/static/media/d9532b38a05b0008-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__generalSans_db677b';
src: url(/_next/static/media/d9532b38a05b0008-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: '__generalSans_Fallback_db677b';src: local("Arial");ascent-override: 91.05%;descent-override: 21.64%;line-gap-override: 9.01%;size-adjust: 110.93%
}.__className_db677b {font-family: '__generalSans_db677b', '__generalSans_Fallback_db677b'
}.__variable_db677b {--bs-body-font-family: '__generalSans_db677b', '__generalSans_Fallback_db677b'
}

